import { Checkbox, NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { Button } from '@progress/kendo-react-buttons';
import {
    DropDownList,
    DropDownListChangeEvent,
    MultiSelect,
    MultiSelectChangeEvent,
    MultiSelectFilterChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { InputSuffix } from '@progress/kendo-react-inputs';
import { chevronDownIcon, searchIcon } from '@progress/kendo-svg-icons';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PortalsApi } from '~api/portals.api';
import { SettingsApi } from '~api/settings.api';
import Loader from '~components/loader';
import TextAreaWithChips from '~components/textarea-with-chips/text-area-with-chips';
import Typography, { TypographyToken } from '~components/typography';
import { Permission } from '~constants';
import { useApi } from '~contexts/api';
import { useHasPermission } from '~contexts/auth';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { PanelPortal } from '~contexts/overlay/overlay.components/panelPortal.overlay.component';
import { useSettings } from '~contexts/settings';
import { useAsyncEffect } from '~hooks/effects';
import useKendoLazyLoad from '~hooks/form';
import { ValidateUtils } from '~lib';
import { LanguageProfile } from '~models';
import { UserGroupsListItemModel } from '~models/user-management.models';
import './invite-users-overlay-panel.scss';

export interface InviteUsersOverviewPanelProps {
    visible: boolean;
    onClose: () => void;
}

const PageSize = 10;

const InviteUserOverlayPanel = ({ visible, onClose }: InviteUsersOverviewPanelProps) => {
    const canCreateSharedContact = useHasPermission(Permission.General_ActionCreateSharedContact);
    const editExistingSharedContact = useHasPermission(Permission.General_ActionEditSharedContact);
    const portalsApi = useApi(PortalsApi);

    const fetchData = useCallback(async (page: number, pageSize: number, filterValues: {
        [filter: string]: string;
    }) => {
        return await portalsApi.getUserGroups({
            page: page,
            maxQuantity: pageSize,
            filterValues,
        });
    }, [portalsApi]);


    const { data, onFilterChange, onOpen, dataLoading } = useKendoLazyLoad<UserGroupsListItemModel>({
        pageSize: PageSize,
        visible,
        fetchData: fetchData,
        selector: '.k-list-content',
    });

    const {
        availableLanguages,
        language,
    } = useLanguages();

    const { environmentSettings: { automaticUserGroupId } } = useSettings();
    const settingsApi = useApi(SettingsApi);

    const { handleSubmit } = useForm();

    const { t: tUser } = useTranslation('user-management');
    const { t: tError } = useTranslation('error');
    const { t: tNotifications } = useTranslation('notifications');


    const [emailChips, setEmailChips] = useState<string[]>([]);
    const [selectedUserGroups, setSelectedUserGroups] = useState<string[]>([]);
    const [selectedLanguage, setLanguage] = useState<LanguageProfile>(language);
    const [isCreateSharedContactSelected, setCreateSharedContactSelected] = useState(canCreateSharedContact);
    const [loading, setLoading] = useState(false);
    const [hasUserGroupError, setUserGroupError] = useState(false);
    const showNotification = useShowNotification();

    const formRef = useRef<HTMLFormElement>(null);

    useAsyncEffect(() => async (signal) => {

        if (visible) {
            const autoUserGroupResponse = await settingsApi.getUserGroup(automaticUserGroupId, signal);

            setSelectedUserGroups([autoUserGroupResponse.name]);
        }


    }, [portalsApi, automaticUserGroupId, settingsApi, visible]);


    const onUserGroupChange = useCallback((event: MultiSelectChangeEvent) => {
        setUserGroupError(false);
        setSelectedUserGroups([...event.value]);
    }, []);

    const handleEmailChips = useCallback((chips: string[]) => {
        setEmailChips(chips);
    }, []);

    const onLanguageChange = useCallback((event: DropDownListChangeEvent) => {
        const newLanguage = availableLanguages.find((lang) => lang.displayName === event.target.value);

        setLanguage(newLanguage!);
    }, [availableLanguages]);


    const handlePanelOnClose = useCallback(() => {
        onClose();
        setLanguage(language);
        setSelectedUserGroups([]);
        setCreateSharedContactSelected(canCreateSharedContact);
        setEmailChips([]);
        setUserGroupError(false);
    }, [onClose, language, canCreateSharedContact]);


    const onSubmitCallback = useCallback(async () => {
        setLoading(true);
        try {
            await portalsApi.inviteUsers({
                emails: emailChips,
                isSharedContact: isCreateSharedContactSelected,
                invitationLanguage: selectedLanguage.isoCultureCode!,
                userGroups: selectedUserGroups!,
            });
            showNotification({
                type: 'success',
                title: tNotifications('user-invite-success', { count: emailChips.length }),
                hideAfterMs: 2000,
            });
            handlePanelOnClose();
        } catch (error) {
            showNotification({
                type: 'success',
                title: tNotifications('unknown-error-title'),
                message: tNotifications('unknown-error-message'),
                hideAfterMs: 2000,
            });
            console.error(error);
            //TODO Will handle error and validation in separate ticket

        } finally {
            setLoading(false);
        }
    }, [
        portalsApi,
        emailChips,
        isCreateSharedContactSelected,
        selectedLanguage.isoCultureCode,
        selectedUserGroups,
        showNotification,
        tNotifications,
        handlePanelOnClose,
    ]);

    const validateEmail = useCallback((email: string) => {
        const regexResult = ValidateUtils.email(email);
        const maxLengthResult = email.length <= 256;

        return (regexResult.email && maxLengthResult);
    }, []);

    const validateUserGroups = () => {
        if (selectedUserGroups?.length === 0) {
            setUserGroupError(true);
        } else {
            setUserGroupError(false);
        }
    };

    const noDataRender = () => {
        return (
            <div className='c-invite-users-overview-panel__loader-container'>
                {dataLoading
                    ? <Loader center size={SizesEnums.SMALL} />
                    :
                    <div className='c-invite-users-overview-panel__no-data'>{tUser('invite-user-no-items-match')}</div>}
            </div>
        );
    };

    return (
        <PanelPortal visible={visible} onClose={handlePanelOnClose}>
            {loading
                ? <Loader center size={SizesEnums.XLARGE} /> :
                <form
                    className='c-invite-users-overview-panel'
                    ref={formRef}
                    onSubmit={handleSubmit(onSubmitCallback)}
                    noValidate
                >
                    <div className='c-invite-users-overview-panel__layout-wrapper'>
                        <h1>{tUser('invite-user-panel-header')}</h1>

                        <TextAreaWithChips
                            label={tUser('invite-user-panel-add-email-addresses-label')}
                            onChange={handleEmailChips}
                            value={emailChips}
                            validate={validateEmail}
                            errorMessage={tError('invite-user-please-enter-valid-email')}
                            chips={emailChips}
                        />
                        <div className='c-invite-users-overview-panel__multiselect-container'>
                            <Typography
                                token={TypographyToken.DesktopDescriptionMd}
                                text={tUser('invite-user-panel-add-to-usergroup')}
                                tagName={'label'}
                                className={'c-invite-users-overview-panel__invite-input-label'}
                                htmlFor='invite-users-multi-select'
                            />
                            <MultiSelect
                                id='invite-users-multi-select'
                                className={`${hasUserGroupError ? 'k-multiselect--error' : ''}`}
                                suffix={() => (
                                    <InputSuffix orientation='horizontal'>
                                        <Button
                                            type='button'
                                            svgIcon={chevronDownIcon}
                                        />
                                    </InputSuffix>
                                )}
                                fillMode={'solid'}
                                rounded={'medium'}
                                prefix={() => (
                                    <InputSuffix orientation='vertical'>
                                        <Button
                                            type='button'
                                            fillMode={'flat'}
                                            rounded={null}
                                            svgIcon={searchIcon}
                                        />
                                    </InputSuffix>
                                )}
                                data={data.map((item) => item.name)}
                                filterable
                                onChange={onUserGroupChange}
                                onOpen={onOpen}
                                value={selectedUserGroups}
                                onBlur={validateUserGroups}
                                onFilterChange={(e: MultiSelectFilterChangeEvent) => onFilterChange(e.filter.value)}
                                listNoDataRender={noDataRender}
                            />
                            {hasUserGroupError ?
                                <Typography
                                    token={TypographyToken.DesktopDescriptionMd}
                                    text={tError('invite-user-please-select-user-group')}
                                    className={'c-invite-users-overview-panel__error-hint'}
                                /> :
                                <div className='c-invite-users-overview-panel__error-spacing' />}
                        </div>
                        <div>
                            <Typography
                                token={TypographyToken.DesktopDescriptionMd}
                                text={tUser('invite-user-panel-choose-language')}
                                tagName={'label'}
                                className={'c-invite-users-overview-panel__invite-input-label'}
                                htmlFor='invite-users-dropdown'
                            />
                            <DropDownList
                                id='invite-users-dropdown'
                                data={availableLanguages.map((language) => language.displayName)}
                                value={selectedLanguage.displayName}
                                onChange={onLanguageChange}
                                svgIcon={chevronDownIcon}
                            />
                        </div>

                    </div>
                    <div
                        className='c-invite-users-overview-panel__action-footer-container'
                        style={{ justifyContent: editExistingSharedContact ? '' : 'flex-end' }}
                    >
                        {
                            editExistingSharedContact &&
                            <Checkbox
                                defaultChecked={canCreateSharedContact}
                                value={isCreateSharedContactSelected}
                                size={SizesEnums.SMALL}
                                name={'shared-contact'}
                                onChange={() => {
                                    setCreateSharedContactSelected(prev => !prev);
                                }}
                                label={tUser('invite-user-panel-create-shared-contact')}
                            />
                        }
                        <div className='c-invite-users-overview-panel__button-action-container'>
                            <NdsButton
                                size={SizesEnums.SMALL}
                                label={tUser('btn-cancel')}
                                buttonType={NdsButtonTypesEnum.NEUTRAL}
                                onClick={handlePanelOnClose}
                            ></NdsButton>
                            <NdsButton
                                state={(emailChips.length
                                    && selectedUserGroups.length)
                                    ? StatesEnum.DEFAULT
                                    : StatesEnum.DISABLED}
                                size={SizesEnums.SMALL}
                                label={tUser('btn-invite-user')}
                                onClick={() => formRef?.current?.requestSubmit()}
                            ></NdsButton>
                        </div>
                    </div>
                </form>
            }
        </PanelPortal>
    );
};


export default InviteUserOverlayPanel;
