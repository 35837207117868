import { Modal, ModalContent, ModalFooter, NdsButton, NdsMenuItem } from '@gonitro/rcl';
import { BkgModesEnum, NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { useState } from 'react';
import { ContactsApi } from '~api/contacts.api';
import Error from '~components/error';
import { CloudContactProviderEnum } from '~constants/cloud-provider';
import { ImportType, ImportTypeI18n, ImportTypeIcons } from '~constants/import-type';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { ModalPortal, useShowNotification } from '~contexts/overlay';
import { useSettings } from '~contexts/settings';
import { useTableContext } from '~contexts/table';
import { useAsyncEffect } from '~hooks/effects';
import { LanguageDataLabel } from '~models';
import { CloudContactStatus } from '~models/contacts.models';
import googleButtonIcon from './../../../resources/icons/google-16.svg';
import googleGreyIcon from './../../../resources/icons/google-grey.svg';
import microsoftButtonIcon from './../../../resources/icons/microsoft-16.svg';
import microsoftGreyIcon from './../../../resources/icons/windows-grey.svg';
import ImportCloudContactContent from './import-cloud-contact-content';
import ImportCsvContent from './import-csv-content';
import './importContactsModal.scss';

export interface ImportContactsModalProps {
    isOpen: boolean;
    handleCloseModal: () => void;
}

function ImportContactsModal({ isOpen, handleCloseModal }: ImportContactsModalProps) {
    const contactsApi = useApi(ContactsApi);
    const settings = useSettings();
    const { t } = useTranslation('contacts');
    const { t: tNotifications } = useTranslation('notifications');
    const [file, setFile] = useState<File | null>(null);
    const [selectedContent, setSelectedContent] = useState<ImportType>(ImportType.CSV);
    const [importTypes, setImportTypes] = useState<ImportType[]>([]);
    const [error, setError] = useState<LanguageDataLabel<'error'> | undefined>(undefined);
    const [cloudAccountsStatus, setCloudAccountsStatus] = useState<{ google: CloudContactStatus | undefined; microsoft: CloudContactStatus | undefined}>({
        google: undefined,
        microsoft: undefined,
    });
    const { refresh } = useTableContext();
    const showNotification = useShowNotification();
    const { google, microsoft } = settings.cloudSettings;


    // Function to handle the import of contacts request api
    const importContacts = async () => {
        setError(undefined);

        if (file) {
            try {
                await contactsApi.importContactsCSV({ file });
                refresh();
                showNotification({
                    type: 'success',
                    title: tNotifications('import-csv-success-message'),
                    hideAfterMs: 2000,
                });
                handleCloseModal();
            } catch (error) {
                showNotification({
                    type: 'error',
                    title: tNotifications('import-csv-error-message'),
                    hideAfterMs: 2000,
                });
                setError('something-went-wrong');
            }
        }
    };

    // Function to handle the modal close button click and clear error state
    const onCloseModalClik = () => {
        setError(undefined);

        handleCloseModal();
    };

    const checkAndUpdateCloudStatus = async(contactsProvider: CloudContactProviderEnum) => {
        try {
            if(contactsProvider === CloudContactProviderEnum.Google) {
                const googleCloudAccountInfo = await contactsApi.checkStatusCloudProvider({ contactsProvider });

                setCloudAccountsStatus((prev) => ({
                    ...prev,
                    google: googleCloudAccountInfo,
                }));
            } else {
                const microsoftCloudAcountInfo = await contactsApi.checkStatusCloudProvider({ contactsProvider });

                setCloudAccountsStatus((prev) => ({
                    ...prev,
                    microsoft: microsoftCloudAcountInfo,
                }));
            }
        } catch (e) {
            console.warn(e);
        }
    };

    const checkSyncButton = (type: ImportType) => {
        if (type === ImportType.Google)  {
            return cloudAccountsStatus.google?.isAccountLinked;
        } else if ( type === ImportType.Microsoft) {
            return cloudAccountsStatus.microsoft?.isAccountLinked;
        } else {
            return false;
        }
    };

    // Function to handle account linking status
    useAsyncEffect(()  => async () => {
        // Method used to get the status and check the icon
        // Initialize the import types array with CSV as the default import type
        const updatedImportTypes = [ImportType.CSV];

        // Check Google account linking status and update state accordingly
        if (google.oauthSettings.isEnabled) {
            checkAndUpdateCloudStatus(CloudContactProviderEnum.Google);
            updatedImportTypes.push(ImportType.Google);
        }
        // Check Microsoft account linking status and update state accordingly
        if (microsoft.oauthSettings.isEnabled) {
            try {
                checkAndUpdateCloudStatus(CloudContactProviderEnum.Microsoft);
                updatedImportTypes.push(ImportType.Microsoft);
            } catch (error) {
                console.warn('Error checking Microsoft account status:', error);
            }
        }
        // Update the import types state with the new array
        setImportTypes(updatedImportTypes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [google.oauthSettings.isEnabled, microsoft.oauthSettings.isEnabled]);

    // Handle sync button from the NdsMenuItem button that is present if account is linked and update the status for the last sync info
    const handleSyncCloudAccount = async(type: ImportType) => {
        try {
            if (type === ImportType.Google) {
                await contactsApi.syncContacts({ contactsProvider:  CloudContactProviderEnum.Google });
                checkAndUpdateCloudStatus(CloudContactProviderEnum.Google);
            } else {
                await contactsApi.syncContacts({ contactsProvider:  CloudContactProviderEnum.Microsoft });
                checkAndUpdateCloudStatus(CloudContactProviderEnum.Microsoft);
            }
        } catch (e) {
            console.warn(e);
        }
    };

    return (
        <ModalPortal visible={isOpen}>
            {isOpen && (
                <Modal
                    headerLabel={t('import-contacts')}
                    bkgMode={BkgModesEnum.LIGHT}
                    size={SizesEnums.LARGE}
                    onCloseClick={onCloseModalClik}
                    withClose={true}
                    className={'c-import-contacts-modal'}
                    renderContent={
                        <ModalContent>
                            {error && <Error className={'c-import-contacts-modal__error-text'} i18nKey={error} />}
                            <div className={'c-import-contacts-modal__modal-content'}>
                                <div className={'c-import-contacts-modal__import-menu'}>
                                    {importTypes.map((type, index) => (
                                        <div key={index} className={'c-import-contacts-modal__menu-item'}>
                                            <NdsMenuItem
                                                primaryText={t(ImportTypeI18n[type])}
                                                state={
                                                    selectedContent === type
                                                        ? StatesEnum.SELECTED
                                                        : StatesEnum.DEFAULT
                                                }
                                                className={'c-import-contacts-modal__menu-item-label'}
                                                bgMode={BkgModesEnum.LIGHT}
                                                icon={ImportTypeIcons[type]}
                                                rounded
                                                onClick={() => setSelectedContent(type)}
                                            />
                                            {
                                                checkSyncButton(type) &&
                                                    <NdsButton
                                                        slot='button'
                                                        buttonType={NdsButtonTypesEnum.NEUTRAL}
                                                        buttonStyle={NdsButtonStylesEnum.DEFAULT}
                                                        size={SizesEnums.XSMALL}
                                                        leftIcon='fa-solid-rotate'
                                                        onClick={() => handleSyncCloudAccount(type)}
                                                    />
                                            }
                                        </div>
                                    ))}
                                </div>
                                {selectedContent === ImportType.CSV && (
                                    <ImportCsvContent
                                        error={error}
                                        setError={(error) => setError(error)}
                                        file={file}
                                        setFile={setFile}
                                    />
                                )}
                                {selectedContent === ImportType.Google && (
                                    <ImportCloudContactContent
                                        icon={googleGreyIcon}
                                        title={t('import-contacts-gmail')}
                                        buttonIcon={googleButtonIcon}
                                        buttonLabel={t('sign-in-google')}
                                        cloudProvider={CloudContactProviderEnum.Google}
                                        cloudStatus = {cloudAccountsStatus.google}
                                        onDisconnectClick = {() => checkAndUpdateCloudStatus(CloudContactProviderEnum.Google)}
                                    />
                                )}
                                {selectedContent === ImportType.Microsoft && (
                                    <ImportCloudContactContent
                                        icon={microsoftGreyIcon}
                                        title={t('import-contacts-microsoft')}
                                        buttonIcon={microsoftButtonIcon}
                                        buttonLabel={t('sign-in-microsoft')}
                                        cloudStatus = {cloudAccountsStatus.microsoft}
                                        onDisconnectClick = {() => checkAndUpdateCloudStatus(CloudContactProviderEnum.Microsoft)}

                                    />
                                )}
                            </div>
                        </ModalContent>
                    }
                    renderFooter={
                        <ModalFooter>
                            <div className={'c-import-contacts-modal__footer-buttons'}>
                                <NdsButton
                                    size={SizesEnums.SMALL}
                                    label={t('cancel-button')}
                                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                                    onClick={onCloseModalClik}
                                />
                                <NdsButton
                                    state={file && !error ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                                    size={SizesEnums.SMALL}
                                    label={t('import-contacts')}
                                    onClick={() => importContacts()}
                                />
                            </div>
                        </ModalFooter>
                    }
                />
            )}
        </ModalPortal>
    );
}

export default ImportContactsModal;
