import { Button } from '@progress/kendo-react-buttons';
import { TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { plusIcon } from '@progress/kendo-svg-icons';
import { useCallback, useState } from 'react';
import { UserManagementApi } from '~api/user-management.api';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useAsyncEffect } from '~hooks/effects';
import { AlternateNames } from '../user-group-panel';
import SelectedNameItem from './selected-name-item/selected-name-item';
import './alternate-name-tab.scss';


export interface AlternateNameTabProps {
    userGroupId: number | undefined;
    alternateNames: AlternateNames[];
    handleSetAlternateNames: (name: string, action: string, id: string | undefined) => void;
    setInitialAlternateNames: (names: AlternateNames[]) => void;
    handleHasLoadedAlternateNames: (loaded: boolean) => void;
    hasLoadedAlternateNames: boolean;
    hasAlternateNameError: boolean;
    isViewMode: boolean;
}


const AlternateNameTab = ({
    alternateNames,
    handleSetAlternateNames,
    userGroupId,
    setInitialAlternateNames,
    hasLoadedAlternateNames,
    handleHasLoadedAlternateNames,
    hasAlternateNameError,
    isViewMode,
}: AlternateNameTabProps) => {

    const { t } = useTranslation('user-management');
    const userManagementApi = useApi(UserManagementApi);
    const [alternateName, setAlternateName] = useState('');

    useAsyncEffect(
        () => async (signal: AbortSignal) => {
            if (userGroupId && !hasLoadedAlternateNames) {
                try {
                    const alternateNamesResponse = await userManagementApi.getAlternateNames(
                        { userGroupId },
                        signal,
                    );

                    setInitialAlternateNames(alternateNamesResponse.map((altName) => ({
                        name: altName,
                        id: altName,
                    })));
                    handleHasLoadedAlternateNames(true);
                } catch (error) {
                    if (signal.aborted) {
                        console.warn('Fetch aborted:', error);
                    } else {
                        console.error('Error fetching alternate names:', error);
                    }
                }
            }
        },
        [
            userManagementApi,
            userGroupId,
            setInitialAlternateNames,
            hasLoadedAlternateNames,
            handleHasLoadedAlternateNames,
        ],
    );

    const handleNameChange = useCallback((e: TextBoxChangeEvent) => {
        if (typeof e.value === 'string') {
            setAlternateName(e.value);
        }
    }, [setAlternateName]);

    const handleAddAlternateName = useCallback(() => {
        if (alternateName.length && !alternateNames.some(altName => altName.name === alternateName)) {
            handleSetAlternateNames(alternateName, 'add', undefined);
            setAlternateName('');
        }
    }, [alternateName, alternateNames, handleSetAlternateNames]);

    const handleEditAlternateName = useCallback((editedName: string, action: string, id: string | undefined) => {

        handleSetAlternateNames(editedName, action, id);
    }, [handleSetAlternateNames]);

    return (
        <div className='alternate-name-tab'>
            <fieldset>
                <Typography token={TypographyToken.UiFormsLabelSm} text={t('alternate-name-tab-title')} />
                <div className='alternate-name-tab__add-name-container'>
                    <TextBox
                        disabled={isViewMode}
                        value={alternateName}
                        onChange={handleNameChange}
                    />
                    <Button
                        disabled={isViewMode}
                        type='button'
                        fillMode={'solid'}
                        themeColor={'dark'}
                        svgIcon={plusIcon}
                        onClick={handleAddAlternateName}
                    >
                        <span className='alternate-name-tab__add-btn-label'>{t('alternate-tab-add-button')}</span>
                    </Button>
                </div>
                {hasAlternateNameError && <Typography
                    text={t('alternate-tab-duplicate-name-error')}
                    token={TypographyToken.DesktopDescriptionSm}
                    className={'alternate-name-tab__duplicate-name-error'} />}
            </fieldset>
            <div className='alternate-name-tab__selected-item-container'>
                {alternateNames.map((alternateName, index) => (
                    alternateName.action !== 'delete' && (
                        <SelectedNameItem
                            isViewMode={isViewMode}
                            key={index}
                            alternateName={alternateName}
                            onClick={handleSetAlternateNames}
                            onChange={handleEditAlternateName}
                        />
                    )
                ))}
            </div>
        </div>
    );
};

export default AlternateNameTab;
