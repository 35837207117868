import { NdsButton, SidePanel } from '@gonitro/rcl';
import { NdsButtonTypesEnum, NdsInputTypes, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import NdsInput from '@gonitro/rcl/lib/nds-input';
import { useCallback, useRef, useState } from 'react';
import { AuthApi } from '~api/auth.api';
import Error from '~components/error';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { ValidateUtils } from '~lib';
import { LanguageDataLabel } from '~models';
import './change-email-panel.scss';

export interface ChangeEmailPanelProps {
    closePanel: () => void;
}

type FormValues = {
    email: string;
    confirmEmail: string;
};

const ChangeEmailPanel = ({ closePanel }: ChangeEmailPanelProps) => {
    const authApi = useApi(AuthApi);
    const formRef = useRef<HTMLFormElement>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const { t } = useTranslation('user-management');
    const { t: tNotifications } = useTranslation('notifications');
    const showNotification = useShowNotification();

    const {
        registerNdsInput,
        handleSubmit,
        formState: { isValid },
    } = useEnhancedForm<FormValues>({ mode: 'onBlur' });


    const onSubmit = useCallback(async (data: FormValues) => {
        setLoading(true);
        try {
            await authApi.changeEmail({ email: data.email });
            showNotification({
                type: 'success',
                title: tNotifications('email-verification-sent-title', { emailAddress: data.email }),
                message: tNotifications('email-verification-sent-message'),
                hideAfterMs: 2000,
            });
            closePanel();
        } catch (error) {
            showNotification({
                type: 'error',
                title: tNotifications('email-address-updated-failed'),
                hideAfterMs: 2000,
            });
            setError('something-went-wrong');
        } finally {
            setLoading(false);
        }
    }, [authApi, showNotification, tNotifications, closePanel]);

    return (
        <SidePanel className={'c-change-email-panel'} onClosePanel={closePanel}>
            <form
                className={'c-change-email-panel__form'}
                ref={formRef}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
            >
                <Typography
                    token={TypographyToken.DesktopHeaderXs}
                    className={'edit-user-panel__title'}
                >
                    {t('change-email-address')}
                </Typography>
                <div className={'reset-password-form__input-wrapper'}>
                    <NdsInput
                        required
                        inputType={NdsInputTypes.TEXT}
                        label={t('new-email')}
                        {...registerNdsInput('email', {
                            required: true,
                            fieldLabel: t('email'),
                            validate: (email: string) => {
                                const regexResult = ValidateUtils.email(email);
                                const maxLengthResult = email.length <= 256;

                                if (!regexResult.email || !maxLengthResult) {
                                    return 'pattern-email';
                                }
                            },
                        })}
                    />
                </div>
                <div className={'reset-password-form__input-wrapper'}>
                    <NdsInput
                        required
                        inputType={NdsInputTypes.TEXT}
                        label={t('confirm-email-address')}
                        {...registerNdsInput('confirmEmail', {
                            required: true,
                            fieldLabel: t('confirm-email-address'),
                            validate: (val: string, formValues: FormValues) => {
                                const regexResult = ValidateUtils.email(val);
                                const maxLengthResult = val.length <= 256;

                                if (!regexResult.email || !maxLengthResult) {
                                    return 'pattern-email';
                                }
                                if (formValues.email !== val) {
                                    return 'confirmation-email-does-not-match';
                                }
                            },
                        })}
                    />
                </div>
                {error && <Error i18nKey={error} />}
            </form>
            <div
                className={'c-change-email-panel__action-footer-container'}
            >
                <NdsButton
                    size={SizesEnums.SMALL}
                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                    label={t('btn-cancel')}
                    onClick={closePanel}
                />
                <NdsButton
                    size={SizesEnums.SMALL}
                    label={t('btn-save')}
                    state={isValid && !loading ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                    onClick={() => formRef?.current?.requestSubmit()}
                />
            </div>
        </SidePanel>
    );
};

export default ChangeEmailPanel;
