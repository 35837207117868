import { PathParam } from '@remix-run/router/utils';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useMatches, useNavigate } from 'react-router-dom';
import { BaseRoutes, NestedRoutes } from '~constants/routes';
import { useAppContext } from '~contexts/app';
import { Guid } from '~models';
import { FlowStateType } from './flow.context';
import {
    FlowEntityType,
    FlowFocusedEntityData,
    FlowNavigateToStep,
    FlowStep,
    FlowType,
    SetFlowFocusedEntity,
} from './flow.types';

export const useFlowInternalTypeAndStep: () => { flowType: FlowType, flowStep: FlowStep } = () => {
    const matches = useMatches();

    return useMemo(() => {
        const [type, step] = [...matches].pop()!.id.split('-');

        if (!type || !step) {
            throw new Error('Invalid hook usage. Hook has to be used inside of Flow Routes');
        }

        return {
            flowType: FlowType[type as keyof typeof FlowType],
            flowStep: FlowStep[step as keyof typeof FlowStep],
        };
    }, [matches]);
};


export const useFlowInternalNavigateToPortal = ({ flowState, flowType }: {
    flowState: FlowStateType,
    flowType: FlowType,
}) => {
    const navigate = useNavigate();
    const { lastPortalPath } = useAppContext();

    return useCallback(() => {
        if (flowState.properties?.documentGroupId) {
            if (flowType === FlowType.Package) {
                navigate(BaseRoutes.DocumentPortal + '/' + flowState.properties?.documentGroupId);
            } else {
                navigate(BaseRoutes.TemplatePortal + '/' + flowState.properties?.documentGroupId);
            }
        } else {
            navigate(lastPortalPath);
        }
    }, [flowState.properties?.documentGroupId, flowType, lastPortalPath, navigate]);
};

export const useFlowInternalNavigateToStep = ({ flowState, flowTypePath }: {
    flowState: FlowStateType,
    flowTypePath: string,
}) => {
    const navigate = useNavigate();

    return useCallback<FlowNavigateToStep>((
        step,
        { state, flowId: explicitFlowId, replace } = {},
    ) => {
        const generatePathParams: Record<PathParam<'/:flowTypePath/:stepPath/:flowId?'>, string | null> = {
            flowTypePath,
            stepPath: null,
            flowId: null,
        };

        if (explicitFlowId) {
            generatePathParams.flowId = explicitFlowId;
        } else if (flowState.flowId) {
            generatePathParams.flowId = flowState.flowId;
        }

        switch (step) {
            case FlowStep.Documents:
                generatePathParams.stepPath = NestedRoutes.Documents;
                break;
            case FlowStep.Processing:
                generatePathParams.stepPath = NestedRoutes.Processing;
                break;
            case FlowStep.Detail:
                generatePathParams.stepPath = NestedRoutes.Flow;
                break;
        }
        navigate(generatePath(
            '/:flowTypePath/:stepPath/:flowId?',
            generatePathParams,
        ), {
            state,
            replace,
        });
    }, [flowState.flowId, flowTypePath, navigate]);
};

export const useFlowInternalFocusedEntity = () => {
    const [focusedEntityId, setFocusedEntityId] = useState<Guid | null>(null);
    const [focusedEntityType, setFocusedEntityType] = useState<FlowEntityType>(FlowEntityType.Package);
    const [focusedEntityData, setFocusedEntityData] = useState<FlowFocusedEntityData | null>(null);

    const setFocusedEntity = useCallback<SetFlowFocusedEntity>((entityType, entityId, optionalData) => {
        setFocusedEntityType(entityType);
        setFocusedEntityId(entityId ?? null);
        setFocusedEntityData(optionalData ?? null);
    }, []);

    return {
        focusedEntityId,
        focusedEntityType,
        focusedEntityData,
        setFocusedEntity,
    };
};
