import { NdsButton, NdsIconFont } from '@gonitro/rcl';
import {
    NdsButtonStylesEnum,
    NdsButtonTypesEnum,
    SizesEnums,
} from '@gonitro/rcl/lib/_types';
import { useState } from 'react';
import CountryPrefixFlag from '~components/country-prefix-flag';
import Typography, { TypographyToken } from '~components/typography';
import { UserManagementTabsEnum } from '~constants';
import { countryInfoList } from '~constants/country-info';
import { AuthorizedPageContainer } from '~containers/page';
import { useAuthContext, useUserInfo } from '~contexts/auth';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import ChangeEmailPanel from './change-email-panel/change-email-panel';
import ChangePasswordPanel from './change-password-panel/change-password-panel';
import EditProfilePanel from './edit-profile-panel';
import './profile.view.scss';

export interface ProfileViewProps {}

function ProfileView() {
    const { availableLanguages } = useLanguages();
    const { absences, refreshUser } = useAuthContext();
    const userInfo = useUserInfo();
    const language = availableLanguages.find(
        (x) => x.isoCultureCode === userInfo?.language,
    );
    const countryInfo = countryInfoList.find(
        (x) => x.countryCode === userInfo?.defaultPhoneNumberCountry,
    );
    const { t } = useTranslation('user-management');

    const [isEditProfilePanelOpen, setIsEditProfilePanelOpen] = useState(false);
    const [isOpenChangeEmailPanel, setIsOpenChangeEmailPanel] = useState(false);
    const [isOpenChangePasswordPanel, setIsOpenChangePasswordPanel] =
        useState(false);
    const [editProfileInitialTab, setEditProfileInitialTab] =
        useState<UserManagementTabsEnum | undefined>();

    return (
        <>
            <AuthorizedPageContainer>
                <div className={'v-profile-view'}>
                    <div className={'v-profile-view__title'}>
                        <h2>{t('my-account-title')}</h2>
                    </div>
                    <div className={'v-profile-view-container'}>
                        <div className={'v-profile-view-container-info'}>
                            <div
                                className={
                                    'v-profile-view-container-info__header'
                                }
                            >
                                <Typography
                                    token={TypographyToken.DesktopHeaderMd}
                                    text={t('personal-info')}
                                />
                                <NdsButton
                                    size={SizesEnums.XSMALL}
                                    label={t('edit-profile')}
                                    buttonType={NdsButtonTypesEnum.SECONDARY}
                                    buttonStyle={NdsButtonStylesEnum.ALT}
                                    onClick={() => {
                                        setIsEditProfilePanelOpen(true);
                                    }}
                                />
                            </div>
                            <div
                                className={
                                    'v-profile-view-container-info__content'
                                }
                            >
                                <div
                                    className={
                                        'v-profile-view-container-info__content-row'
                                    }
                                >
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__caption'
                                        }
                                    >
                                        <Typography
                                            token={
                                                TypographyToken.DesktopDescriptionSm
                                            }
                                            text={t('first-name')}
                                        />
                                    </div>
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__value'
                                        }
                                    >
                                        <Typography
                                            token={
                                                TypographyToken.DesktopDescriptionSm
                                            }
                                            text={userInfo?.firstName}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        'v-profile-view-container-info__content-row'
                                    }
                                >
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__caption'
                                        }
                                    >
                                        <Typography
                                            token={
                                                TypographyToken.DesktopDescriptionSm
                                            }
                                            text={t('last-name')}
                                        />
                                    </div>
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__value'
                                        }
                                    >
                                        <Typography
                                            token={
                                                TypographyToken.DesktopDescriptionSm
                                            }
                                            text={userInfo?.lastName}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        'v-profile-view-container-info__content-row'
                                    }
                                >
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__caption'
                                        }
                                    >
                                        <Typography
                                            token={
                                                TypographyToken.DesktopDescriptionSm
                                            }
                                            text={t('email')}
                                        />
                                        {userInfo?.cloudProviderName && (
                                            <span
                                                className={
                                                    'v-profile-view-container-info__content-row__caption-sub'
                                                }
                                            >
                                                {userInfo?.cloudProviderName}
                                            </span>
                                        )}
                                    </div>
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__value'
                                        }
                                    >
                                        <Typography
                                            token={
                                                TypographyToken.DesktopDescriptionSm
                                            }
                                            text={userInfo?.emailAddress}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        'v-profile-view-container-info__content-row'
                                    }
                                >
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__caption'
                                        }
                                    >
                                        <Typography
                                            token={
                                                TypographyToken.DesktopDescriptionSm
                                            }
                                            text={t('company')}
                                        />
                                    </div>
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__value'
                                        }
                                    >
                                        <Typography
                                            token={
                                                TypographyToken.DesktopDescriptionSm
                                            }
                                            text={userInfo?.company}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        'v-profile-view-container-info__content-row'
                                    }
                                >
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__caption'
                                        }
                                    >
                                        <Typography
                                            token={
                                                TypographyToken.DesktopDescriptionSm
                                            }
                                            text={t('language')}
                                        />
                                        <span
                                            className={
                                                'v-profile-view-container-info__content-row__caption-sub'
                                            }
                                        >
                                            {t('language-subtitle')}
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__value'
                                        }
                                    >
                                        <Typography
                                            token={
                                                TypographyToken.DesktopDescriptionSm
                                            }
                                            text={language?.nativeName}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        'v-profile-view-container-info__content-row'
                                    }
                                >
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__caption'
                                        }
                                    >
                                        <Typography
                                            token={
                                                TypographyToken.DesktopDescriptionSm
                                            }
                                            text={t('country-prefix')}
                                        />
                                        <span
                                            className={
                                                'v-profile-view-container-info__content-row__caption-sub'
                                            }
                                        >
                                            {t('country-prefix-subtitle')}
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            'v-profile-view-container-info__content-row__value'
                                        }
                                    >
                                        {userInfo?.defaultPhoneNumberCountry && (
                                            <>
                                                <CountryPrefixFlag
                                                    countryCode={
                                                        userInfo?.defaultPhoneNumberCountry
                                                    }
                                                ></CountryPrefixFlag>
                                                <Typography
                                                    token={
                                                        TypographyToken.DesktopDescriptionSm
                                                    }
                                                    text={`${countryInfo?.countryName} (${countryInfo?.phonePrefix})`}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'v-profile-view-container-actions'}>
                            <div
                                className={
                                    'v-profile-view-container-actions__header'
                                }
                            >
                                <div
                                    className={
                                        'v-profile-view-container-actions__header-icon-container'
                                    }
                                >
                                    <NdsIconFont fontName="fa-solid-user" />
                                </div>
                                <div
                                    className={
                                        'v-profile-view-container-actions__header-name-container'
                                    }
                                >
                                    <Typography
                                        token={
                                            TypographyToken.DesktopDescriptionBoldMd
                                        }
                                        text={`${userInfo?.firstName ?? ''} ${
                                            userInfo?.lastName ?? ''
                                        }`}
                                    />
                                    <span
                                        className={
                                            'v-profile-view-container-actions__header-name-container-mail'
                                        }
                                    >
                                        {userInfo?.emailAddress}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={
                                    'v-profile-view-container-actions__content'
                                }
                            >
                                <div
                                    className={
                                        'v-profile-view-container-actions__content-action'
                                    }
                                    onClick={() => {
                                        setEditProfileInitialTab(
                                            UserManagementTabsEnum.Signature,
                                        );
                                        setIsEditProfilePanelOpen(true);
                                    }}
                                >
                                    <div
                                        className={
                                            'v-profile-view-container-actions__content-action-icon-container'
                                        }
                                    >
                                        <NdsIconFont
                                            fontName="fa-solid-signature"
                                            size={SizesEnums.SMALL}
                                        />
                                    </div>
                                    <Typography
                                        token={
                                            TypographyToken.DesktopDescriptionSm
                                        }
                                        text={userInfo?.signatureImageB64? t('change-your-signature') : t('add-your-signature')}
                                    />
                                </div>
                                {userInfo?.signatureImageB64 && <div className={'v-profile-view__signature-image-content'}>
                                    <img
                                        src={userInfo?.signatureImageB64}
                                        alt='signature'
                                        className={'v-profile-view__signature-image-content-signature'}
                                    />
                                </div>}
                                <div
                                    className={
                                        'v-profile-view-container-actions__content-action'
                                    }
                                    onClick={() => {
                                        setEditProfileInitialTab(
                                            UserManagementTabsEnum.OutOfOffice,
                                        );
                                        setIsEditProfilePanelOpen(true);
                                    }}
                                >
                                    <div
                                        className={
                                            'v-profile-view-container-actions__content-action-icon-container'
                                        }
                                    >
                                        <NdsIconFont
                                            fontName="fa-solid-calendar"
                                            size={SizesEnums.SMALL}
                                        />
                                    </div>
                                    <Typography
                                        token={
                                            TypographyToken.DesktopDescriptionSm
                                        }
                                        text={t('manage-out-of-office')}
                                    />
                                </div>
                                <div
                                    className={
                                        'v-profile-view-container-actions__content-action'
                                    }
                                    onClick={() => {
                                        if (!userInfo?.cloudProviderName) {
                                            setIsOpenChangeEmailPanel(true);
                                        }
                                    }}
                                >
                                    <div
                                        className={
                                            'v-profile-view-container-actions__content-action-icon-container'
                                        }
                                    >
                                        <NdsIconFont
                                            fontName="fa-regular-at"
                                            size={SizesEnums.SMALL}
                                        />
                                    </div>
                                    <Typography
                                        token={
                                            TypographyToken.DesktopDescriptionSm
                                        }
                                        text={t('change-email-address')}
                                    />
                                </div>
                                <div
                                    className={
                                        'v-profile-view-container-actions__content-action'
                                    }
                                    onClick={() => {
                                        if (!userInfo?.cloudProviderName) {
                                            setIsOpenChangePasswordPanel(true);
                                        }
                                    }}
                                >
                                    <div
                                        className={
                                            'v-profile-view-container-actions__content-action-icon-container'
                                        }
                                    >
                                        <NdsIconFont
                                            fontName="fa-solid-lock"
                                            size={SizesEnums.SMALL}
                                        />
                                    </div>
                                    <Typography
                                        token={
                                            TypographyToken.DesktopDescriptionSm
                                        }
                                        text={t('change-password')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AuthorizedPageContainer>
            {isEditProfilePanelOpen && userInfo && (
                <OverlayPortal type={'panel'} visible={isEditProfilePanelOpen}>
                    {({ close }) => (
                        <EditProfilePanel
                            initialTab={editProfileInitialTab}
                            userProfile={userInfo}
                            absences={absences ?? []}
                            closePanel={async () => {
                                await refreshUser();
                                setIsEditProfilePanelOpen(false);
                                setEditProfileInitialTab(undefined);
                                close();
                            }}
                        />
                    )}
                </OverlayPortal>
            )}
            {isOpenChangeEmailPanel && (
                <OverlayPortal type={'panel'} visible={isOpenChangeEmailPanel}>
                    {({ close }) => (
                        <ChangeEmailPanel
                            closePanel={() => {
                                setIsOpenChangeEmailPanel(false);
                                close();
                            }}
                        />
                    )}
                </OverlayPortal>
            )}
            {isOpenChangePasswordPanel && (
                <OverlayPortal
                    type={'panel'}
                    visible={isOpenChangePasswordPanel}
                >
                    {({ close }) => (
                        <ChangePasswordPanel
                            closePanel={() => {
                                setIsOpenChangePasswordPanel(false);
                                close();
                            }}
                        />
                    )}
                </OverlayPortal>
            )}
        </>
    );
}

export default ProfileView;
