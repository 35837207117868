import { NdsButton, NdsIconFont } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { useMemo, useState } from 'react';
import FlowSigningMethodModal from '~components/flow/flow-signing-method-modal/flowSigningMethodModal';
import LegalNotice from '~components/legal-notice/legalNotice';
import PackageRecipientDropdown from '~components/package-recipient-dropdown';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType } from '~constants';
import { useFlowContext, useSetFlowState } from '~contexts/flow';
import { FlowError } from '~contexts/flow/flow.error';
import { useAddActor } from '~contexts/flow/flow.hooks';
import { FlowActionType, FlowEntityType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useCurrentLanguage, useTranslation } from '~contexts/i18n';
import { useSigningTypes } from '~contexts/settings';
import { FlowSigningFieldModel } from '~models';
import './flowEditorSigningFieldProperties.scss';

export interface FlowEditorSigningFieldPropertiesProps {

}

function b64ToSvg(b64: string): string {
    const svgContent = atob(b64);

    const scriptTagRegex = /<\s*script\b[^<]*(?:(?!<\s*\/script\s*>)<[^<]*)*<\s*\/script\s*>/gim;
    const strippedScripTagsSvgContent = svgContent.replace(scriptTagRegex, '');

    const evilAttributeRegex = /\s?on(?:\w+)=/gm;
    const sanitizedSvgContent = strippedScripTagsSvgContent.replace(evilAttributeRegex, () => '');

    return sanitizedSvgContent;
}

function FlowEditorSigningFieldProperties(props: FlowEditorSigningFieldPropertiesProps) {
    const {
        focusedEntityId,
        flowState: { stakeholders, elements, flow },
        hasError,
        getErrorInfo,
        setFocusedEntity,
    } = useFlowContext();
    const found = elements.find(el => el.localId === focusedEntityId) as FlowSigningFieldModel;
    const { t: tFlow } = useTranslation('flow');

    const actorStakeholderLocalId = useMemo(() => {
        return FlowUtils.findActorByElementId(flow, found?.localId)?.actor.stakeholderLocalId;
    }, [found, flow]);

    const language = useCurrentLanguage();
    const signingTypes = useSigningTypes();
    const setFlowState = useSetFlowState();
    const addActorCallback = useAddActor();

    const recipientError = useMemo<{ text: any }>(() => {
        if (focusedEntityId) {
            if (hasError(FlowError.FieldStakeholderPersonHasMissingPhoneNumberForSMSSigning, focusedEntityId)) {
                return getErrorInfo(FlowError.FieldStakeholderPersonHasMissingPhoneNumberForSMSSigning, focusedEntityId);
            }
        }
    }, [focusedEntityId, hasError, getErrorInfo]);

    const [isSigningMethodModalOpen, setIsSigningMethodModalOpen] = useState(false);

    return (
        <div className={'c-flow-editor-signing-field-properties c-flow-editor-right-pane-component'}>
            <PanelBar>
                <PanelBarItem
                    title={'Recipient'}
                    expanded
                >
                    <PackageRecipientDropdown
                        stakeholders={stakeholders}
                        selectedStakeholderLocalId={actorStakeholderLocalId}
                        onRecipientChange={(stakeholder) => {
                            const existing = FlowUtils.findStakeholderActors(flow, stakeholder.localId).filter((actorResult) => actorResult.actor.actorType === ActorType.Signer);
                            const originalActor = FlowUtils.findActorByElementId(flow, found.localId);

                            if (existing.length > 0) {
                                setFlowState(FlowActionType.AddActorElements, {
                                    actorId: existing[0].actor.localId,
                                    elementIds: [found.localId],
                                });
                            } else {
                                addActorCallback(ActorType.Signer, stakeholder.localId, 'last', { elements: [found.localId] });
                            }

                            if (originalActor) {
                                setFlowState(FlowActionType.DeleteActorElements, {
                                    actorId: originalActor.actor.localId,
                                    elementIds: [found.localId],
                                });
                            }

                            found.stakeholderLocalId = stakeholder.localId;
                        }}
                    />
                    {recipientError &&
                        <Typography
                            className={'flow-error-text'}
                            text={tFlow(recipientError.text)}
                            token={TypographyToken.DesktopDescriptionSm}
                        />
                    }
                </PanelBarItem>
                <PanelBarItem
                    title={'Signing methods'}
                    expanded
                >
                    <div className='c-flow-editor-signing-field-properties-methods-container'>
                        {found?.signingMethods && found?.signingMethods.map((signingMethod, index) => {
                            const signingMethodInfo = signingTypes.find((signingType) => signingType.name.toUpperCase() === signingMethod.name.toUpperCase());

                            return (
                                <div
                                    key={index}
                                    className='c-flow-editor-signing-field-properties-methods-container-method'
                                >
                                    <div className='c-flow-editor-signing-field-properties-methods-container-method_icon'>
                                        {signingMethodInfo && signingMethodInfo.icon &&
                                            <div dangerouslySetInnerHTML={{ __html: b64ToSvg(signingMethodInfo.icon) }} />
                                        }
                                    </div>
                                    <div className='c-flow-editor-signing-field-properties-methods-container-method_method-info'>
                                        <div className='c-flow-editor-signing-field-properties-methods-container-method_method-info-title'>
                                            <Typography
                                                token={TypographyToken.MobileDescriptionMd}
                                                text={signingMethodInfo?.displayNames[language.languageCode]}
                                            />
                                            <NdsIconFont
                                                style={{ cursor: 'pointer' }}
                                                size={SizesEnums.SMALL}
                                                fontName={'fa-solid-trash'}
                                                onClick={() => {
                                                    const signingMethods = found.signingMethods.slice();

                                                    signingMethods.splice(index, 1);

                                                    setFlowState(FlowActionType.UpdateElement, {
                                                        ...found,
                                                        signingMethods,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <NdsButton
                        size={SizesEnums.SMALL}
                        label={'Add a signing method'}
                        buttonType={NdsButtonTypesEnum.SECONDARY}
                        buttonStyle={NdsButtonStylesEnum.ALT}
                        leftIcon='fa-solid-plus'
                        onClick={() => setIsSigningMethodModalOpen(true)}
                    />
                </PanelBarItem>
            </PanelBar>
            <div className={'c-flow-editor-signing-field-properties-footer'}>
                <LegalNotice
                    legalNoticeCode={found?.legalNoticeCode}
                    legalNoticeText={found?.legalNoticeText}
                    onLegalNoticeChange={(legalNotice) => {
                        const field = { ...found };

                        field.legalNoticeText = legalNotice?.legalNoticeText;
                        field.legalNoticeCode = legalNotice?.legalNoticeCode;

                        setFlowState(FlowActionType.UpdateElement, { ...field });
                    }}
                />
                <div className={'c-flow-editor-signing-field-properties-footer-button-container'}>
                    <NdsButton
                        size={SizesEnums.SMALL}
                        label={'Remove signature field'}
                        buttonType={NdsButtonTypesEnum.SECONDARY}
                        buttonStyle={NdsButtonStylesEnum.ALT}
                        leftIcon='fa-light-trash'
                        onClick={() => {
                            setFlowState(FlowActionType.DeleteElement, {
                                localId: found.localId,
                                type: found.type,
                            });

                            setFocusedEntity(FlowEntityType.Package);
                        }}
                    />
                </div>
            </div>
            {isSigningMethodModalOpen && <FlowSigningMethodModal
                onCloseClick={() => setIsSigningMethodModalOpen(false)}
                field={found}
                openSigningMethodModal={isSigningMethodModalOpen}
            />}
        </div>
    );
}

FlowEditorSigningFieldProperties.Header = () => {
    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                <NdsIconFont fontName={'fa-regular-signature'} />
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                Signature field
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}></div>
        </div>
    );
};
export default FlowEditorSigningFieldProperties;
