import { NdsBadge, NdsIconFont } from '@gonitro/rcl';
import { NdsBadgeTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { SvgIcon } from '@progress/kendo-react-common';
import { ComboBox, ComboBoxProps, ListItemProps } from '@progress/kendo-react-dropdowns';
import { InputPrefix } from '@progress/kendo-react-inputs';
import { searchIcon } from '@progress/kendo-svg-icons';
import classNames from 'classnames';
import { cloneElement } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { DocumentGroupThemesModel } from '~models/user-management.models';

interface ThemesTabProps {
    defaultTheme: DocumentGroupThemesModel;
    selectedThemes: DocumentGroupThemesModel[]
    setDefaultTheme: (theme: DocumentGroupThemesModel) => void;
    removeSelectedTheme: (theme: DocumentGroupThemesModel) => void;
    themeComboboxProps: ComboBoxProps;
    isViewMode: boolean;
}

const ThemesTab = ({ defaultTheme, selectedThemes, themeComboboxProps, isViewMode, setDefaultTheme, removeSelectedTheme }: ThemesTabProps) => {
    const { t } = useTranslation('user-management');
    const renderThemeItem = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const itemChildren = (
            <div className={'user-group-tab__user-group-combo-list-item'}>
                <div className='user-group-tab__combo-list-item-title'>
                    <Typography
                        className='user-group-tab__combo-list-item-name'
                        token={TypographyToken.UiMenuSm}
                        text={`${itemProps.dataItem.name}`}
                    ></Typography>
                </div>
                {
                    selectedThemes &&
                    selectedThemes.map((theme: DocumentGroupThemesModel) => theme.themeId === itemProps.dataItem.themeId &&
                        <NdsIconFont
                            key={theme.themeId}
                            className='user-group-tab__combo-check-icon'
                            fontName='fa-solid-check'
                        />)
                }

            </div>);

        return cloneElement(li, li.props, itemChildren);
    };

    return (
        <>
            <Typography token={TypographyToken.DesktopDescriptionSm}>{t('manage-document-groups-search-for-themes')}</Typography>
            <ComboBox
                disabled={isViewMode}
                {...themeComboboxProps}
                itemRender={renderThemeItem}
                suggest
                clearButton={false}
                prefix={() => (
                    <InputPrefix orientation='horizontal'>
                        <SvgIcon
                            icon={searchIcon}
                        />
                    </InputPrefix>
                )}
            />
            {
                selectedThemes && selectedThemes.length > 0 &&
                selectedThemes.map((theme: DocumentGroupThemesModel) => {
                    return (
                        <SelectedThemePanel
                            isViewMode={isViewMode}
                            key={theme.themeId}
                            selectedTheme={theme}
                            defaultTheme={defaultTheme!}
                            setDefaultThemeHandler={() => setDefaultTheme(theme)}
                            removeSelectedThemeHandler={() => removeSelectedTheme(theme)} />
                    );
                })
            }
        </>
    );
};

export default ThemesTab;

interface SelectedThemePanelProps {
    isViewMode: boolean;
    selectedTheme: DocumentGroupThemesModel;
    defaultTheme: DocumentGroupThemesModel;
    removeSelectedThemeHandler: () => void;
    setDefaultThemeHandler: () => void;
}

const SelectedThemePanel = ({ selectedTheme, defaultTheme, isViewMode, removeSelectedThemeHandler, setDefaultThemeHandler }: SelectedThemePanelProps) => {
    const { t } = useTranslation('user-management');

    return (
        <div className={'manage-documents-panel__theme-panel'}>
            <span>
                <Typography token={TypographyToken.DesktopDescriptionSm}>{selectedTheme.name}</Typography>
            </span>
            <div className={'manage-documents-panel__theme-actions'}>
                {
                    defaultTheme.themeId === selectedTheme.themeId ?
                        <div>
                            <NdsBadge text={t('manage-document-groups-default-theme-tag')} state={'success'} badgeType={NdsBadgeTypesEnum.STATUS}  />
                        </div>
                        :
                        <button
                            disabled={isViewMode}
                            type={'button'}
                            onClick={setDefaultThemeHandler}
                            className='manage-documents-panel__set-default-theme-button'
                        >
                            <Typography token={TypographyToken.UiFormsLabelXs}>{t('manage-document-groups-set-as-default-theme')}</Typography>
                        </button>
                }
                {
                    defaultTheme.themeId !== selectedTheme.themeId &&
                        <button
                            type={'button'}
                            className='manage-documents-panel__remove-selected-list-item'
                        >
                            <NdsIconFont
                                className={classNames( { 'manage-documents-panel--disabled': isViewMode })}
                                onClick={removeSelectedThemeHandler}
                                size={SizesEnums.SMALL}
                                fontName='fa-solid-minus-circle' />
                        </button>
                }
            </div>
        </div>

    );
};
