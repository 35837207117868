import { NdsIconFont } from '@gonitro/rcl';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { ReactNode, useMemo } from 'react';
import { FieldTypeIcon } from '~components/flow/flow-util-components/index';
import Typography, { TypographyToken } from '~components/typography';
import { useFlowContext } from '~contexts/flow';
import {
    FlowDetailsErrors,
    FlowElementErrors,
    FlowError,
    FlowErrorTextI18n,
    FlowErrorTitleI18n,
    FlowStakeholderErrors,
} from '~contexts/flow/flow.error';
import { AnyTypeToFlowEntityType, FlowEntityType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useTranslation } from '~contexts/i18n';
import { Guid } from '~models';
import './flowEditorErrors.scss';


interface FlowEditorError {
    key: FlowError;
    entityId: Guid;
    entityType?: FlowEntityType;
    info: any;
    title: string;
    description: string;
    icon?: ReactNode;
}

export interface FlowEditorErrorsProps {

}

function FlowEditorErrors(props: FlowEditorErrorsProps) {
    const { errors, setFocusedEntity, flowState: { elements, stakeholders } } = useFlowContext();
    const { t } = useTranslation('flow');

    const { packageErrors, stakeholderErrors, elementErrors, otherErrors } = useMemo(() => {
        const packageErrors: FlowEditorError[] = [];
        const stakeholderErrors: FlowEditorError[] = [];
        const elementErrors: FlowEditorError[] = [];
        const otherErrors: FlowEditorError[] = [];

        for (const [keyFull, info] of Object.entries(errors)) {
            const split = keyFull.split(':');

            const key = split[0] as FlowError;
            const entityId = split[1];

            const titleKey = FlowErrorTitleI18n[key];
            const descriptionKey = FlowErrorTextI18n[key];

            if ((FlowDetailsErrors as unknown as FlowError[]).includes(key)) {
                packageErrors.push({
                    key,
                    entityId,
                    title: titleKey ? t(titleKey) : '',
                    description: descriptionKey ? t(descriptionKey) : '',
                    info,
                });
            } else if ((FlowStakeholderErrors as unknown as FlowError[]).includes(key)) {
                const stakeholder = stakeholders.find(el => el.localId === entityId);
                const displayName = FlowUtils.getStakeholderDisplayName(stakeholder);

                stakeholderErrors.push({
                    key,
                    entityId,
                    entityType: FlowEntityType.Stakeholder,
                    title: titleKey ? t(titleKey, { stakeholder: displayName }) : displayName,
                    description: descriptionKey ? t(descriptionKey) : '',
                    info,
                });
            } else if ((FlowElementErrors as unknown as FlowError[]).includes(key)) {
                const element = elements.find(el => el.localId === entityId);

                if (element) {

                    const icon = <FieldTypeIcon type={element.type} />;

                    elementErrors.push({
                        key,
                        entityId,
                        entityType: AnyTypeToFlowEntityType[element.type],
                        title: titleKey ? t(titleKey) : '',
                        description: descriptionKey ? t(descriptionKey) : '',
                        info,
                        icon,
                    });
                } else {
                    otherErrors.push({
                        key,
                        entityId,
                        title: titleKey ? t(titleKey) : '',
                        description: descriptionKey ? t(descriptionKey) : '',
                        info,
                    });
                }
            } else {
                otherErrors.push({
                    key,
                    entityId,
                    title: titleKey ? t(titleKey) : '',
                    description: descriptionKey ? t(descriptionKey) : '',
                    info,
                });
            }
        }

        return {
            packageErrors,
            stakeholderErrors,
            elementErrors,
            otherErrors,
        };
    }, [elements, errors, stakeholders, t]);

    // rendering each error group as array items due to weird panelbar proptype check error
    const renderedErrors: ReactNode[] = [];

    if (packageErrors.length) {
        renderedErrors.push(
            <PanelBarItem
                key={'packageErrors'}
                title={`${packageErrors.length} Package errors`}
                expanded
            >
                <div className={'c-flow-editor-errors-cards-container'}>
                    {packageErrors.map((error, index) => {
                        return (
                            <FlowEditorErrorCard
                                key={index}
                                error={error}
                                onClick={() => {
                                    setFocusedEntity(FlowEntityType.Package);
                                }}
                            />
                        );
                    })}
                </div>
            </PanelBarItem>,
        );
    }
    if (stakeholderErrors.length > 0) {
        renderedErrors.push(
            <PanelBarItem
                key={'stakeholderErrors'}
                title={`${stakeholderErrors.length} Recipient errors`}
                expanded
            >
                <div className={'c-flow-editor-errors-cards-container'}>
                    {stakeholderErrors.map((error, index) => {
                        return (
                            <FlowEditorErrorCard
                                key={index}
                                error={error}
                                onClick={() => {
                                    setFocusedEntity(FlowEntityType.Stakeholder, error.entityId);
                                }}
                            />
                        );
                    })}
                </div>
            </PanelBarItem>,
        );
    }
    if (elementErrors.length > 0) {
        renderedErrors.push(
            <PanelBarItem
                key={'elementErrors'}
                title={`${elementErrors.length} Field errors`}
                expanded
            >
                <div className={'c-flow-editor-errors-cards-container'}>
                    {elementErrors.map((error, index) => {
                        return (
                            <FlowEditorErrorCard
                                key={index}
                                error={error}
                                onClick={() => {
                                    setFocusedEntity(error.entityType!, error.entityId);
                                }}
                            />
                        );
                    })}
                </div>
            </PanelBarItem>,
        );
    }
    if (otherErrors.length > 0) {
        renderedErrors.push(
            <PanelBarItem
                key={'elementErrors'}
                title={`${elementErrors.length} Field errors`}
                expanded
            >
                <div className={'c-flow-editor-errors-cards-container'}>
                    {otherErrors.map((error, index) => {
                        return (
                            <FlowEditorErrorCard
                                key={index}
                                error={error}
                            />
                        );
                    })}
                </div>
            </PanelBarItem>,
        );
    }

    return (
        <div className={'c-flow-editor-errors'}>
            <div className={'c-flow-editor-errors__header'}>
                <Typography
                    token={TypographyToken.DesktopDescriptionSm}
                    text={'Some fields and/or recipients have missing properties.'}
                />
            </div>
            {renderedErrors.length > 0 && (
                <PanelBar>
                    {renderedErrors}
                </PanelBar>
            )}
        </div>
    );
}

export interface FlowEditorErrorCardProps {
    error: FlowEditorError;
    onClick?: () => void;
}

function FlowEditorErrorCard({ error, onClick }: FlowEditorErrorCardProps) {
    return (
        <div className={'c-flow-editor-error-card'} onClick={onClick}>
            <div className={'c-flow-editor-error-card__icon-container'}>
                {error.icon}
            </div>
            <div className={'c-flow-editor-error-card__text-container'}>
                <Typography
                    token={TypographyToken.DesktopDescriptionSm}
                    text={error.title}
                />
                {error.info.isWarning
                    ? (
                        <Typography
                            className={'c-flow-editor-error-card__text-container-warning-text'}
                            token={TypographyToken.DesktopDescriptionSm}
                            text={error.description}
                        />)
                    : (
                        <Typography
                            className={'c-flow-editor-error-card__text-container-error-text'}
                            token={TypographyToken.DesktopDescriptionSm}
                            text={error.description}
                        />
                    )}
            </div>
            <div className={'c-flow-editor-error-card__arrow-container'}>
                <NdsIconFont fontName='fa-solid-arrow-right' />
            </div>
        </div>
    );
}

export default FlowEditorErrors;
