import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import { FlowDetailsErrors, FlowElementErrors, FlowStakeholderErrors } from '~contexts/flow/flow.error';
import { FlowFieldModel, FlowStakeholderModel, Guid, SettingsModel, SigningTypes } from '~models';
import { FlowStateType } from './flow.context';
import {
    FlowReducerActorActionPayloads,
    FlowReducerCommonActionPayloads,
    FlowReducerDocumentActionPayloads,
    FlowReducerEditorInitActionPayloads,
    FlowReducerElementActionPayloads,
    FlowReducerPageObjectActionPayloads,
    FlowReducerPropertiesActionPayloads,
    FlowReducerStakeholderActionPayloads,
    FlowReducerTemplateActionPayloads,
} from './reducers';

export enum FlowType {
    Package = 'Package',
    Template = 'Template',
}

export enum FlowStep {
    Documents = 'Documents',
    Processing = 'Processing',
    Detail = 'Detail',
}

export enum FlowValidationResult {
    Valid,
    Warning,
    Error,
}

export type FlowValidationInfo<T> = {
    error: T;
    result: FlowValidationResult.Error | FlowValidationResult.Warning;
    info?: object,
} | {
    result: FlowValidationResult.Valid;
}

// add here whatever extra data is needed for validator and also add i tin entity-validator hooks
export type FlowValidatorExtrasParam = {
    settings: SettingsModel,
    signingTypes: SigningTypes,
}
export type FlowDetailsValidator = (flowState: FlowStateType, extras: FlowValidatorExtrasParam) => FlowValidationInfo<typeof FlowDetailsErrors[number]>;
export type FlowStakeholderValidator = (stakeholder: FlowStakeholderModel, flowState: FlowStateType, extras: FlowValidatorExtrasParam) => FlowValidationInfo<typeof FlowStakeholderErrors[number]>;
export type FlowFieldValidator = (field: FlowFieldModel, flowState: FlowStateType, extras: FlowValidatorExtrasParam) => FlowValidationInfo<typeof FlowElementErrors[number]>;


export enum FlowActionType {
    SetFlowId,
    SetDocuments, // overrides whole document list
    AddDocuments,
    DeleteDocument,
    UpdateDocument,
    SetTemplate,
    RemoveTemplate,
    SetTemplateName,
    SetInitialDetails,
    SetInitialLegalNoticeConfig,
    SetDocumentsThumbnails,
    SetInitialFlow,
    UpdateProperties,
    UpdatePackageName,
    UpdateTemplateName,
    MovePageObject,
    AddElement,
    DeleteElement,
    UpdateElement,
    AddStakeholder,
    UpdateStakeholder,
    HideStakeholder,
    ShowStakeholder,
    SetStakeholders,
    AddActor,
    DeleteActor,
    AddActorElements,
    DeleteActorElements,
    SetActorStakeholder,
}

export type FlowReducerActionPayloads =
    FlowReducerActorActionPayloads
    & FlowReducerCommonActionPayloads
    & FlowReducerDocumentActionPayloads
    & FlowReducerEditorInitActionPayloads
    & FlowReducerElementActionPayloads
    & FlowReducerPageObjectActionPayloads
    & FlowReducerPropertiesActionPayloads
    & FlowReducerStakeholderActionPayloads
    & FlowReducerTemplateActionPayloads
    ;

export interface FlowStateAction<T extends FlowActionType = FlowActionType> {
    type: T,
    payload: FlowReducerActionPayloads[T],
}

export type FlowReducerFunc<T extends FlowActionType> = (state: FlowStateType, payload: FlowReducerActionPayloads[T]) => FlowStateType;

export type FlowNavigateToStep = (
    step: FlowStep,
    params?: {
        state?: any;
        flowId?: Guid;
        replace?: boolean;
    },
) => void;

export enum FlowEntityType {
    Package,
    SigningField,
    TextField,
    CheckboxField,
    RadioField,
    Stakeholder,
    Approver,
    Actor,
}

export type FlowFocusedEntityData = {
    selectedActor?: Guid;
}

export type SetFlowFocusedEntity = (entityType: FlowEntityType, entityId?: Guid, optionalData?: FlowFocusedEntityData) => void;

export enum FlowGroupType {
    FormFillersApprovers = 'ffa',
    Signers = 's',
    Receivers = 'r'
}

export const ActorTypeToFlowGroupType = {
    [ActorType.Approver]: FlowGroupType.FormFillersApprovers,
    [ActorType.FormFiller]: FlowGroupType.FormFillersApprovers,
    [ActorType.Signer]: FlowGroupType.Signers,
    [ActorType.Receiver]: FlowGroupType.Receivers,
};
export const AnyTypeToFlowEntityType = {
    [FieldType.TextBox]: FlowEntityType.TextField,
    [FieldType.CheckBox]: FlowEntityType.CheckboxField,
    [FieldType.RadioGroup]: FlowEntityType.RadioField,
    [FieldType.SigningField]: FlowEntityType.SigningField,
    [ActorType.Approver]: FlowEntityType.Approver,
};
