import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Button } from '@progress/kendo-react-buttons';
import classNames from 'classnames';
import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import './panel.scss';

export interface PanelProps extends PropsWithChildren, HTMLAttributes<HTMLDivElement> {
    title?: string;
    titlePrefix?: ReactNode;
    titleSuffix?: ReactNode;
    onCloseClick?: () => void;
    header?: ReactNode;
    actions?: ReactNode;
    extraActions?: ReactNode;
    contentClassName?: string;
    showLoader?: boolean;
}

function Panel({
    title,
    titlePrefix,
    titleSuffix,
    onCloseClick,
    actions,
    extraActions,
    className,
    contentClassName,
    showLoader,
    children,
    ...props
}: PanelProps) {
    return (
        <aside className={classNames('c-panel', className)} {...props}>
            <header className={'c-panel__header'}>
                {titlePrefix && <div className={'c-panel__header-prefix'}>{titlePrefix}</div>}
                {title && (
                    <div className={'c-panel__header-title'}>
                        <Typography token={TypographyToken.DesktopHeaderXs} text={title} tagName={'h1'} />
                    </div>
                )}
                {titleSuffix && <div className={'c-panel__header-suffix'}>{titleSuffix}</div>}
                {onCloseClick && (
                    <div className={'c-panel__header-close'}>
                        <Button
                            disabled={showLoader}
                            iconClass={'fa-regular fa-xmark fa-xl'}
                            fillMode={'flat'}
                            size={'large'}
                            onClick={onCloseClick}
                        />
                    </div>
                )}
            </header>
            <div className={classNames('c-panel__content', contentClassName)}>
                {children}
                {showLoader && (
                    <div className={'c-panel__content-loader'}>
                        <Loader size={SizesEnums.XLARGE} center />
                    </div>
                )}
            </div>
            <footer className={'c-panel__footer'}>
                <div className={'c-panel__footer-actions'}>{extraActions}</div>
                <div className={'c-panel__footer-actions'}>{actions}</div>
            </footer>
        </aside>
    );
}

export default Panel;
