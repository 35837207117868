import { NdsButton } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { useCallback, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import { UserManagementApi } from '~api/user-management.api';
import Content from '~components/content';
import NavTabs from '~components/nav-tabs';
import { Permission, USER_MANAGEMENT_PORTAL_TABS } from '~constants';
import { UserManagementAction } from '~constants/user-management-action';
import { PortalTableContainer } from '~containers/portal-table';
import { useApi } from '~contexts/api';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { TableContext, TableContextProvider } from '~contexts/table';
import { FetchTableDataDelegate } from '~contexts/table/table.types';
import useNavTabs from '~hooks/tabs';
import { DocumentGroupsListTableDataModel, DocumentGroupThemesModel, UserGroupsListItemModel } from '~models/user-management.models';
import DeleteDocumentGroupModal from './delete-document-group-modal';
import DocumentGroupsViewQuickActions from './document-groups-view-quick-actions/document-groups-view-quick-actions';
import ManageDocumentGroupsPanel from './manage-document-groups-panel/manage-document-groups-panel';
import { DocumentGroupsTableDefinition } from './table-definition';
import { useDocumentGroupsTableFilters } from './use-document-groups-table-filter';
import useFilterTabConditions from '../hooks/useFilterTabConditions';

export interface DocumentGroupsPortalViewProps {
}

const DocumentGroupsPortalView = () => {
    const portalsApi = useApi(PortalsApi);
    const userManagementApi = useApi(UserManagementApi);
    const { t } = useTranslation('user-management');
    const filters = useDocumentGroupsTableFilters();
    const [isCreateDocumentGroupsPanelVisible, setIsCreateDocumentGroupsPanelVisible] = useState(false);
    const [selectedDocumentGroup, setSelectedDocumentGroup] = useState<{
        name: string;
        documentGroupId: number;
        userGroups: UserGroupsListItemModel[];
        initialThemes: {
            defaultTheme: DocumentGroupThemesModel;
            themes: DocumentGroupThemesModel[];
        }
    }>();
    const [documentGroupToDelete, setDocumentGroupToDelete] = useState<DocumentGroupsListTableDataModel>();
    const [isViewMode, setIsViewMode] = useState(false);
    const canCreateDocumentGroup = useHasPermission(Permission.AccessUserManagementPortal_ActionCreateDocumentGroup);

    const filterTabConditions = useFilterTabConditions();

    const { filteredTabs } = useNavTabs(USER_MANAGEMENT_PORTAL_TABS, filterTabConditions);

    const fetchData: FetchTableDataDelegate<DocumentGroupsListTableDataModel> = useCallback(async (data, signal) => {
        return portalsApi.getDocumentGroups(data, signal);
    }, [portalsApi]);

    const onClickQuickAction = useCallback(async (data: DocumentGroupsListTableDataModel, action: UserManagementAction) => {
        if (action === UserManagementAction.Edit || action === UserManagementAction.View) {
            if(action === UserManagementAction.View) {
                setIsViewMode(true);
            }
            if (data.documentGroupId) {
                const result = await userManagementApi.getUserGroupsByDocumentGroup({
                    documentGroupId: data.documentGroupId,
                    page: 1,
                    maxQuantity: 50,
                });

                const userGroups = result.items.map((item) => {
                    return {
                        userGroupId: item.userGroupId,
                        name: item.name,
                        code: '', //?
                        isImmutable: false,
                        areRightsImmutable: false,
                        permissions: item.permissions,
                    };
                });

                const resultThemes = await userManagementApi.getThemesByDocumentGroup({ documentGroupId: data.documentGroupId });

                setSelectedDocumentGroup({
                    name: data.name,
                    documentGroupId: data.documentGroupId,
                    userGroups,
                    initialThemes: resultThemes,
                });
            }

            setIsCreateDocumentGroupsPanelVisible(true);
        } else if (action === UserManagementAction.Delete) {
            setDocumentGroupToDelete(data);
        }

        return;
    }, [userManagementApi]);

    return (
        <Content noDefaultPadding>
            <TableContextProvider<'documentGroupId', DocumentGroupsListTableDataModel>
                definition={DocumentGroupsTableDefinition}
                fetchData={fetchData}
            >
                <PortalTableContainer<'documentGroupId', DocumentGroupsListTableDataModel>
                    tabs={<NavTabs navTabs={filteredTabs} />}
                    pageTitle={t('user-management-portal-view-title-name')}
                    filtersDefinition={filters}
                    headerButton={canCreateDocumentGroup && <HeaderButton openCreateDocumentGroupsModal={() => setIsCreateDocumentGroupsPanelVisible(true)} />}
                    renderColumns={(data) => {
                        return {
                            name: data.name,
                            action: <DocumentGroupsViewQuickActions
                                data={data}
                                onClickQuickAction={onClickQuickAction}
                            />,
                        };
                    }}
                />
                <TableContext.Consumer>
                    {({ refresh }) => (
                        <>
                            <OverlayPortal type={'panel'} visible={isCreateDocumentGroupsPanelVisible}>
                                {
                                    ({ close }) => {
                                        return (
                                            <ManageDocumentGroupsPanel
                                                isViewMode={isViewMode}
                                                documentGroupId={selectedDocumentGroup?.documentGroupId}
                                                documentGroupName={selectedDocumentGroup?.name}
                                                initialUserGroups={selectedDocumentGroup?.userGroups}
                                                initialThemes={selectedDocumentGroup?.initialThemes}
                                                onClosePanel={() => {
                                                    setSelectedDocumentGroup(undefined);
                                                    setIsCreateDocumentGroupsPanelVisible(false);
                                                    refresh();
                                                    close();
                                                    setIsViewMode(false);
                                                }}
                                            />
                                        );
                                    }
                                }
                            </OverlayPortal>
                            <DeleteDocumentGroupModal
                                documentGroup={documentGroupToDelete}
                                openDeleteModal={documentGroupToDelete != null}
                                onCloseModalClick={() => {
                                    setDocumentGroupToDelete(undefined);
                                    refresh();
                                }}
                            />
                        </>
                    )}
                </TableContext.Consumer>
            </TableContextProvider>
        </Content>
    );
};

interface HeaderButtonProps {
    openCreateDocumentGroupsModal: () => void;
}

const HeaderButton = ({ openCreateDocumentGroupsModal }: HeaderButtonProps) => {

    const { t } = useTranslation('user-management');
    const hasInvitePermission = useHasPermission(Permission.AccessUserManagementPortal_ActionCreateDocumentGroup);

    return hasInvitePermission
        ? <NdsButton
            label={t('document-groups-portal-header-button')}
            size={SizesEnums.SMALL}
            onClick={openCreateDocumentGroupsModal}
        />
        :
        null;
};

export default DocumentGroupsPortalView;
