import { useContext, useEffect } from 'react';
import { FlowContext } from '~contexts/flow/flow.context';
import {
    FlowDetailsErrors,
    FlowElementErrors,
    FlowError,
    FlowErrorsType,
    FlowStakeholderErrors,
} from '~contexts/flow/flow.error';
import {
    useFlowInternalFieldValidate,
    useFlowInternalPackageValidate,
    useFlowInternalStakeholdersValidator,
} from '~contexts/flow/flow.hooks-internal';
import {
    FlowDetailsValidator,
    FlowFieldValidator,
    FlowStakeholderValidator,
} from '~contexts/flow/flow.types';
import { StakeholderType } from '~models';

const parseKey = (key: string): [FlowError, string | undefined] => {
    const k = key.split(':');

    return [k[0] as FlowError, k[1] as string | undefined];
};


export interface FlowValidatorProps {
    detailsValidators?: FlowDetailsValidator[];
    stakeholderValidators?: FlowStakeholderValidator[];
    fieldValidators?: FlowFieldValidator[];
}

export const FlowValidator = ({
    detailsValidators,
    stakeholderValidators,
    fieldValidators,
}: FlowValidatorProps) => {
    const { flowState, step, _setErrorsInternal } = useContext(FlowContext);

    const validateStakeholder = useFlowInternalStakeholdersValidator(stakeholderValidators);
    const validateDetails = useFlowInternalPackageValidate(detailsValidators);
    const validateFields = useFlowInternalFieldValidate(fieldValidators);

    useEffect(() => {
        _setErrorsInternal((current) => {
            const copy = { ...current };
            for (const key of Object.keys(current)) {
                const [errKey] = parseKey(key);

                if ((FlowStakeholderErrors as unknown as FlowError[]).includes(errKey)) {
                    delete copy[key as FlowError];
                }
            }

            return copy;
        });

        if (!validateStakeholder) {
            return;
        }

        const errors: FlowErrorsType = {};
        for (const stakeholder of flowState.stakeholders) {
            if (stakeholder.type === StakeholderType.Undecided) {
                continue;
            }
            Object.assign(errors, validateStakeholder(stakeholder));
        }

        if (Object.keys(errors).length) {
            _setErrorsInternal((current) => {
                return {
                    ...current,
                    ...errors,
                };
            });
        }
    }, [
        flowState.stakeholders,
        _setErrorsInternal,
        step,
        stakeholderValidators?.length,
        validateStakeholder,
    ]);

    useEffect(() => {

        _setErrorsInternal((current) => {
            const copy = { ...current };
            for (const key of Object.keys(current)) {
                const [errKey] = parseKey(key);

                if ((FlowElementErrors as unknown as FlowError[]).includes(errKey)) {
                    delete copy[key as FlowError];
                }
            }

            return copy;
        });


        if (!validateFields) {
            return;
        }

        const errors: FlowErrorsType = {};
        for (const stakeholder of flowState.elements) {
            Object.assign(errors, validateFields(stakeholder));
        }

        if (Object.keys(errors).length) {
            _setErrorsInternal((current) => {
                return {
                    ...current,
                    ...errors,
                };
            });
        }
    }, [flowState.elements, _setErrorsInternal, step, validateFields]);

    useEffect(() => {
        _setErrorsInternal((current) => {
            const copy = { ...current };
            for (const key of Object.keys(current)) {
                const [errKey] = parseKey(key);

                if ((FlowDetailsErrors as unknown as FlowError[]).includes(errKey)) {
                    delete copy[key as FlowError];
                }
            }

            return copy;
        });

        if (!validateDetails) {
            return;
        }

        const errors: FlowErrorsType = validateDetails();

        if (Object.keys(errors).length) {
            _setErrorsInternal((current) => {
                return {
                    ...current,
                    ...errors,
                };
            });
        }

    }, [_setErrorsInternal, validateDetails]);

    return null;
};
