import Typography, { TypographyToken } from '~components/typography';
import './delete-user-modal-content.scss';

interface DeleteUserModalContentProps {
    userFullName?: string;
    userDeleteError?: string;
    isInvitedUserDelete?: boolean;
    userEmail: string;

}

const DeleteUserModalContent = ({ userFullName, userDeleteError, isInvitedUserDelete=false, userEmail }: DeleteUserModalContentProps) => {

    return (
        <>
            <div className='delete-user-modal-content'>

                <div className='delete-user-modal-content__full-name'>
                    <Typography token={TypographyToken.UiFormsLabelSm}>
                        {isInvitedUserDelete ? `${userEmail}` : `${userFullName}`}
                    </Typography>
                </div>
                {
                    !isInvitedUserDelete &&
                    <div className='delete-user-modal-content__email-address'>
                        <Typography token={TypographyToken.UiMenuSm}>
                            {userEmail}
                        </Typography>
                    </div>
                }
            </div>
            {
                userDeleteError &&
                    <div className={'delete-user-error-message'}>{<Typography token={TypographyToken.UiMenuXs}>{userDeleteError}</Typography>}</div>
            }
        </>
    );
};

export default DeleteUserModalContent;
