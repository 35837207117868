import { SigningBehavior } from '~constants';
import { FieldType } from '~constants/field-type';
import { FlowError } from '~contexts/flow/flow.error';
import { FlowFieldValidator, FlowValidationResult } from '~contexts/flow/flow.types';
import { SigningType, StakeholderType } from '~models';

export const uniqueNameRequired: FlowFieldValidator = (element, { properties }, extras) => {
    if ((
        element.type === FieldType.TextBox
        || element.type === FieldType.CheckBox
        || element.type === FieldType.RadioGroup
    ) && !element.name?.length) {
        return {
            error: FlowError.FieldMissingUniqueName,
            result: FlowValidationResult.Error,
        };
    }

    return { result: FlowValidationResult.Valid };
};

export const nameUniqueInDocument: FlowFieldValidator = (element, { elements }) => {
    if (element.type === FieldType.SigningField || !element.location) {
        return { result: FlowValidationResult.Valid };
    }

    const otherElementOnDoc = elements.find(el => (
        el.type !== FieldType.SigningField
        && el.location?.documentNumber === element.location?.documentNumber
        && el.localId !== element.localId
        && el.name === element.name
    ));

    if (otherElementOnDoc) {
        return {
            error: FlowError.FieldNameNotUniqueOnDocument,
            result: FlowValidationResult.Error,
        };
    }

    return { result: FlowValidationResult.Valid };
};


export const signingTypeSelectionRequired: FlowFieldValidator = (element) => {
    if (element.type === FieldType.SigningField && !element.signingMethods.length) {
        return {
            error: FlowError.FieldMissingSigningTypeSelection,
            result: FlowValidationResult.Error,
        };
    }

    return { result: FlowValidationResult.Valid };
};

export const recipientsPhoneNumberRequired: FlowFieldValidator = (element, { stakeholders }) => {
    if (element.type !== FieldType.SigningField || !element.signingMethods.length) {
        return { result: FlowValidationResult.Valid };
    }
    const stakeholder = stakeholders.find((stakeholder) => stakeholder.localId === element.stakeholderLocalId);

    if (!stakeholder || stakeholder.type === StakeholderType.Undecided) {
        return { result: FlowValidationResult.Valid };
    }

    const hasSMSSigningMethod = element.signingMethods.some((signingMethod) => {
        const { behavior } = SigningType.parseSigningTypeName(signingMethod.name);

        return behavior.toUpperCase() === SigningBehavior.SmsCode;
    });

    if (!hasSMSSigningMethod) {
        return { result: FlowValidationResult.Valid };
    }

    if (stakeholder.type === StakeholderType.Person) {
        if (!stakeholder.phoneNumber) {
            return {
                error: FlowError.FieldStakeholderPersonHasMissingPhoneNumberForSMSSigning,
                result: FlowValidationResult.Error,
            };
        }
    } else {
        const membersLength = stakeholder.members.length;
        let membersWithPhone = 0;
        for (const m of stakeholder.members) {
            if (m.phoneNumber) {
                membersWithPhone++;
            }
        }
        if (membersWithPhone === 0) {
            return {
                error: FlowError.FieldStakeholderGroupAllHasMissingPhoneNumberForSMSSigning,
                result: FlowValidationResult.Error,
            };
        } else if (membersWithPhone < membersLength) {
            return {
                error: FlowError.FieldStakeholderGroupPartialHasMissingPhoneNumberForSMSSigning,
                result: FlowValidationResult.Warning,
            };
        }
    }


    return { result: FlowValidationResult.Valid };
};

export const fieldMustBePlaced: FlowFieldValidator = (element) => {
    if (!element.location?.documentNumber) {
        return {
            error: FlowError.FieldIsNotPlacedOnDocument,
            result: FlowValidationResult.Error,
        };
    }

    return { result: FlowValidationResult.Valid };

};
export const signingFieldOnDocWithSigningFields: FlowFieldValidator = (element, { detailDocuments }) => {
    if (element.type !== FieldType.SigningField || !element.location?.documentNumber) {
        return { result: FlowValidationResult.Valid };
    }

    const doc = detailDocuments[element.location.documentNumber - 1];

    if (doc?.hasSignatures) {
        return {
            error: FlowError.SigningFieldPlacedOnDocWithSignatures,
            result: FlowValidationResult.Error,
        };
    }

    return { result: FlowValidationResult.Valid };

};
