import { useCallback } from 'react';
import { FieldError, FieldErrors } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { ValidationRule } from 'react-hook-form/dist/types/validator';
import { useTranslation } from '~contexts/i18n';
import { StringUtils } from '~lib';
import { LanguageDataLabel } from '~models';
import { EnhancedRegisterOptions } from './enhanced-register';

type ValidationLocale = LanguageDataLabel<'validation'>;

// validation locales can contain this value for interpolation
// if fieldName isn't passed generic field name translation will be used
// to avoid this mechanism use different translation for field (which will be probably more specific for that field than generic error)
const FIELD_LABEL_TRANSLATION_KEY = 'fieldLabel';

const getValidatorMessage = (error: FieldError, fallback: ValidationLocale) => {
    return error.message?.length ? error.message as ValidationLocale : fallback;
};

const getPrimitiveValidatorOptionValue = (option?: ValidationRule<number | string>): string | undefined => {
    if (option !== undefined) {
        if (typeof option !== 'object') {
            return typeof option === 'string' ? option : option.toString();
        }
        if ('value' in option) {
            return typeof option.value === 'string' ? option.value : option.value.toString();
        }
    }
};

export const useTranslateValidationError = <TFieldValues extends FieldValues>() => {
    const { t } = useTranslation('validation');
    const fieldGenericLabel = t('field-label-fallback');

    return useCallback(<TFieldName extends FieldPath<TFieldValues>>(
        fieldError: FieldErrors<TFieldValues>[TFieldName],
        options?: EnhancedRegisterOptions<TFieldValues, TFieldName>,
    ) => {
        if (!fieldError || !fieldError.type) { //
            return undefined;
        }
        const error = fieldError as FieldError;

        if (!options) { // for current form lib knowledge it shouldn't happen, but also to avoid further false checks
            return error.message ? t(error.message as ValidationLocale) ?? error.message : '';
        }

        let i18nKey: ValidationLocale;
        const i18nValues: Record<string, string | undefined> = {};

        if (options.fieldLabel) {
            i18nValues[FIELD_LABEL_TRANSLATION_KEY] = options.fieldLabel;
        }
        switch (error.type) {
            case 'required':
                i18nKey = getValidatorMessage(error, 'required');
                break;
            case 'min':
                i18nKey = getValidatorMessage(error, 'min-value');
                i18nValues.minValue = getPrimitiveValidatorOptionValue(options['min']);
                break;
            case 'max':
                i18nKey = getValidatorMessage(error, 'max-value');
                i18nValues.maxValue = getPrimitiveValidatorOptionValue(options['max']);
                break;
            case 'maxLength':
                i18nKey = getValidatorMessage(error, 'max-length');
                i18nValues.maxLength = getPrimitiveValidatorOptionValue(options['maxLength']);
                break;
            case 'minLength':
                i18nKey = getValidatorMessage(error, 'min-length');
                i18nValues.minLength = getPrimitiveValidatorOptionValue(options['minLength']);
                break;
            case 'pattern':
                i18nKey = getValidatorMessage(error, 'pattern');
                break;
            case 'validate':
                i18nKey = getValidatorMessage(error, 'invalid-generic');
                break;
            default:
                i18nKey = 'invalid-generic';
        }

        const translated = t(i18nKey, i18nValues);
        const idx = translated.search(`{{${FIELD_LABEL_TRANSLATION_KEY}}}`);

        if (idx > -1) {
            return translated.replace(
                `{{${FIELD_LABEL_TRANSLATION_KEY}}}`,
                idx === 0 ? StringUtils.capitalizeFirstLetter(fieldGenericLabel) : fieldGenericLabel,
            );
        }

        return translated;
    }, [fieldGenericLabel, t]);
};