import { NdsIconFont } from '@gonitro/rcl';
import { Avatar } from '@progress/kendo-react-layout';
import PanelDropdown from '~components/panel-dropdown';
import Typography, { TypographyToken } from '~components/typography';
import { useUserInfo } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { ApproverModel, FormFillerModel, PackageDetailActorBasic, ReceiverModel, SignerModel } from '~models';
import './packageDetailsDropdown.scss';

export interface PackageDetailsDropdownProps {
    title: string;
    userList: SignerModel[] | ReceiverModel[] | ApproverModel[] | FormFillerModel[] | PackageDetailActorBasic[];
    reassignedUser?: string;
    openDropDown?:boolean;
}

function PackageDetailsDropdown({ title, userList, reassignedUser, openDropDown }: PackageDetailsDropdownProps) {
    const userInfo = useUserInfo();
    const { t } = useTranslation('base');
    const { t: tflow } = useTranslation('flow');

    const withRecipients = userList.filter((user) => user.firstName || user.lastName);
    const noRecipientExists = userList.some((user) => !user.firstName && !user.lastName);

    const hasSubstitutes = (user: unknown): user is { substitutes: string[] } => {
        return (
            typeof user === 'object' &&
            user !== null &&
            'substitutes' in user &&
            Array.isArray((user as any).substitutes)
        );
    };

    return (
        <div className="c-package-details-dropdown">
            <PanelDropdown headerText={title} openDropdown={openDropDown}>
                <ul className="c-package-details-dropdown__list">
                    {noRecipientExists && (
                        <li className="c-package-details-dropdown__item">
                            <div className="c-package-details-dropdown__user-content">
                                <Avatar rounded="full" type="text" className="c-package-details-dropdown__avatar">
                                    {t('no-recipient-defined').charAt(0)}
                                </Avatar>
                                <div className="c-package-details-dropdown__info">
                                    <Typography
                                        token={TypographyToken.UiMenuSm}
                                        text={t('no-recipient-defined')}
                                        tagName="div"
                                    />
                                    <Typography
                                        token={TypographyToken.UiMenuXs}
                                        text=""
                                        tagName="div"
                                        className="c-package-details-dropdown__email"
                                    />
                                </div>
                            </div>
                        </li>
                    )}
                    {withRecipients.map((user, index) => {
                        const { firstName, lastName, emailAddress } = user;
                        const isCurrentUser = emailAddress === userInfo?.emailAddress;

                        return (
                            <li className="c-package-details-dropdown__item" key={index}>
                                <div className="c-package-details-dropdown__user-content">
                                    <Avatar rounded="full" type="text" className="c-package-details-dropdown__avatar">
                                        {firstName?.[0]}
                                    </Avatar>
                                    <div className="c-package-details-dropdown__info">
                                        <Typography
                                            token={TypographyToken.UiMenuSm}
                                            text={`${firstName} ${lastName} ${isCurrentUser ? tflow('you') : ''}`}
                                            tagName="div"
                                        />
                                        <Typography
                                            token={TypographyToken.UiMenuXs}
                                            text={emailAddress}
                                            tagName="div"
                                            className="c-package-details-dropdown__email"
                                        />
                                    </div>
                                </div>
                                {firstName && lastName && hasSubstitutes(user)&& (
                                    <div className="c-package-details-dropdown__banner">
                                        <NdsIconFont fontName="fa-solid-calendar-day" />
                                        <Typography
                                            token={TypographyToken.UiMenuXs}
                                            text={reassignedUser
                                                ? ' '
                                                : t('substitute-label', {
                                                    outOfOfficeUser: `${firstName} ${lastName}`,
                                                    substituteUser: user.substitutes[0],
                                                })}
                                            tagName="div"
                                        />
                                    </div>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </PanelDropdown>
        </div>
    );
}

export default PackageDetailsDropdown;