import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Input } from '@progress/kendo-react-inputs';
import classNames from 'classnames';
import { AlternateNames } from '../../user-group-panel';
import './selected-name-item.scss';


export interface SelectedUserItemProps {
    alternateName: AlternateNames;
    isViewMode: boolean;
    onClick: (alternateName: string, action: string, id: string | undefined) => void;
    onChange: (editedName: string, action: string, id: string | undefined) => void;
}

const SelectedNameItem = ({ alternateName, isViewMode, onClick, onChange }: SelectedUserItemProps) => {

    return (
        <div className='selected-name-item'>
            <Input
                className='selected-name-item__alt-name-input'
                disabled={isViewMode}
                value={alternateName.name}
                onChange={(e) => onChange(e.value, 'edit', alternateName.id)} />
            <NdsIconFont
                onClick={() => !isViewMode ? onClick(alternateName.name, 'delete', alternateName.id) : null}
                className={classNames('selected-name-item__delete-icon', { 'selected-name-item--disabled': isViewMode })}
                fontName='fa-solid-minus-circle'
                size={SizesEnums.SMALL}
            />
        </div>
    );
};

export default SelectedNameItem;
