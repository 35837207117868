import { useCallback, useMemo } from 'react';
import QuickActionsTable from '~components/quick-actions-table';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { Permission } from '~constants';
import { UserManagementAction } from '~constants/user-management-action';
import { useHasPermission } from '~contexts/auth';
import { DocumentGroupsListItemModel } from '~models/user-management.models';

export interface DocumentGroupsViewQuickActionsProps {
    data: DocumentGroupsListItemModel;
    // data is any because no model has been set for user by user id yet
    onClickQuickAction: (data: any, action: UserManagementAction) => void;
}

type ActionFunctions = {
    [key in UserManagementAction]?: () => Promise<boolean> | void | Promise<void>;
};

const UserGroupsViewQuickActions = ({ data, onClickQuickAction }: DocumentGroupsViewQuickActionsProps) => {

    const hasEditDocumentGroupPermission = useHasPermission(Permission.AccessUserManagementPortal_ActionEditDocumentGroup);
    const hasDeleteDocumentGroupPermission = useHasPermission(Permission.AccessUserManagementPortal_ActionDeleteDocumentGroup);
    const canViewDocumentGroup = useHasPermission(Permission.AccessUserManagementPortal_ActionViewDocumentGroup);

    const quickActions = useMemo((): QuickAction<UserManagementAction>[][] => {
        const pendingParentGroup: QuickAction<UserManagementAction>[][] = [];

        if (hasEditDocumentGroupPermission) {
            pendingParentGroup.push([
                {
                    icon: 'fa-solid-edit',
                    type: UserManagementAction.Edit,
                },
            ]);
        } else if(canViewDocumentGroup) {
            pendingParentGroup.push([
                {
                    icon: 'fa-solid-eye',
                    type: UserManagementAction.View,
                },
            ]);
        }

        if (hasDeleteDocumentGroupPermission) {
            pendingParentGroup.push([
                {
                    icon: 'fa-solid-trash',
                    type: UserManagementAction.Delete,
                },
            ]);
        }


        return pendingParentGroup;
    }, [canViewDocumentGroup, hasDeleteDocumentGroupPermission, hasEditDocumentGroupPermission]);


    const handleQuickActionClick = useCallback(
        (actionType: UserManagementAction) => {

            const actionFunctions: ActionFunctions = {
                [UserManagementAction.Edit]: () => onClickQuickAction(data, actionType),
                [UserManagementAction.Delete]: () => onClickQuickAction(data, actionType),
                [UserManagementAction.View]: () => onClickQuickAction(data, actionType),
            };

            const actionFunc = actionFunctions[actionType];

            if (actionFunc) {
                actionFunc();
            } else {
                console.error('Unsupported action type:', actionType);
            }

        }, [data, onClickQuickAction],
    );

    const openQuickActionsModal = (actionType: UserManagementAction) => {
        handleQuickActionClick(actionType);
    };

    return (
        <QuickActionsTable
            actions={quickActions}
            handleActionClick={openQuickActionsModal}
        />
    );
};

export default UserGroupsViewQuickActions;
