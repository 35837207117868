import { NdsButton } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import Typography, { TypographyToken } from '~components/typography';
import { ContactBasicModel } from '~models/contact.model';
import './existingContactItem.scss';

export interface ExistingContactItemProps {
    onMergeClick : () => void;
    onSkipClick: () => void;
    contactDetails: ContactBasicModel;
}

function ExistingContactItem ({ contactDetails, onMergeClick, onSkipClick }: ExistingContactItemProps) {
    return (
        <div className={'c-existing-contact-item'}>
            <Typography
                className='c-existing-contact-item__contact-name'
                tagName='div'
                token={TypographyToken.UiFormsLabelSm}
                text={contactDetails.displayName} />
            <Typography
                className='c-existing-contact-item__contact-email'
                tagName='div'
                token={TypographyToken.UiFormsLabelSm}
                text={contactDetails.emailAddress} />
            <div className='c-existing-contact-item__buttons' >
                <NdsButton
                    slot='button'
                    label={'Merge'}
                    buttonType={NdsButtonTypesEnum.SECONDARY}
                    buttonStyle={NdsButtonStylesEnum.ALT}
                    size={SizesEnums.SMALL}
                    onClick={onMergeClick}
                />
                <NdsButton
                    slot='button'
                    label={'Skip'}
                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                    size={SizesEnums.SMALL}
                    onClick={onSkipClick}
                />
            </div>
        </div>
    );
}

export default ExistingContactItem;