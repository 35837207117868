import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { SvgIcon } from '@progress/kendo-react-common';
import { ComboBox, ComboBoxProps } from '@progress/kendo-react-dropdowns';
import { InputPrefix, Switch } from '@progress/kendo-react-inputs';
import { searchIcon } from '@progress/kendo-svg-icons';
import classNames from 'classnames';
import { useState } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { UserGroupsListItemModel } from '~models/user-management.models';
import { hardCodedPermissionsType } from '../manage-document-groups-panel';

interface UserGroupsTabProps {
    userGroupComboboxProps: ComboBoxProps;
    selectedUserGroups: UserGroupsListItemModel[];
    removeSelectedUserGroupHandler: (userGroup: UserGroupsListItemModel) => void;
    setSelectedUserGroups: any;
    isViewMode: boolean
}

const UserGroupsTab = ({ userGroupComboboxProps, selectedUserGroups, setSelectedUserGroups, isViewMode, removeSelectedUserGroupHandler }: UserGroupsTabProps) => {

    const { t } = useTranslation('user-management');

    return (
        <>
            <Typography token={TypographyToken.DesktopDescriptionSm}>{t('manage-document-groups-search-for-user-groups')}</Typography>
            <ComboBox
                disabled={isViewMode}
                popupSettings={{ height: 203 }}
                clearButton={false}
                suggest
                {...userGroupComboboxProps}
                prefix={() => (
                    <InputPrefix orientation='horizontal'>
                        <SvgIcon
                            icon={searchIcon}
                        />
                    </InputPrefix>
                )}
            />
            {
                selectedUserGroups && selectedUserGroups.length > 0 &&
                selectedUserGroups.map((userGroup: UserGroupsListItemModel) => {
                    return (
                        <SelectedUserGroupPanel
                            isViewMode={isViewMode}
                            key={userGroup.userGroupId}
                            userGroup={userGroup}
                            removeSelectedUserGroupHandler={() => removeSelectedUserGroupHandler(userGroup)}
                            setSelectedUserGroups={setSelectedUserGroups}
                        />
                    );
                })
            }
        </>
    );
};

export default UserGroupsTab;

interface SelectedUserGroupPanelProps {
    userGroup: UserGroupsListItemModel;
    isViewMode: boolean;
    removeSelectedUserGroupHandler: () => void;
    setSelectedUserGroups: (usergroupId: number, permission: hardCodedPermissionsType) => void;
}

const SelectedUserGroupPanel = ({ userGroup, isViewMode, removeSelectedUserGroupHandler, setSelectedUserGroups }: SelectedUserGroupPanelProps) => {
    const [isUserGroupPermissionsOpen, setIsUserGroupPermissionsOpen] = useState(false);
    const { t } = useTranslation('user-management');

    return (
        <div className='manage-documents-panel__document-group-panel'>
            <div className={'manage-documents-panel__document-group-panel-header'}>
                <button
                    type={'button'}
                    className={'manage-documents-panel__panel-button'}
                    onClick={() => setIsUserGroupPermissionsOpen(!isUserGroupPermissionsOpen)}
                >
                    <NdsIconFont
                        className={'manage-documents-panel__caret-icon'}
                        size={SizesEnums.MEDIUM}
                        fontName={isUserGroupPermissionsOpen ? 'fa-solid-caret-down': 'fa-solid-caret-right'}
                    />
                    <span>
                        <Typography token={TypographyToken.DesktopDescriptionSm}>{userGroup.name}</Typography>
                    </span>
                </button>
                <button
                    type={'button'}
                    className='manage-documents-panel__remove-selected-list-item'>
                    <NdsIconFont
                        onClick={() => !isViewMode ? removeSelectedUserGroupHandler() : null}
                        size={SizesEnums.SMALL}
                        className={classNames('manage-documents-panel__caret-icon', { 'manage-documents-panel--disabled': isViewMode })}
                        fontName='fa-solid-minus-circle' />
                </button>
            </div>
            {
                isUserGroupPermissionsOpen &&
                    <>
                        <Typography
                            token={TypographyToken.DesktopTagXs}
                            className={'manage-documents-panel__permissions-panel-title'}>
                            {t('manage-document-groups-permissions-title')}
                        </Typography>
                        <div className='manage-documents-panel__document-group-permissions-panel'>
                            {
                                userGroup.permissions.map((permission) => {
                                    return (
                                        <div key={permission.permissionId} className={'manage-documents-panel__permission-container'}>
                                            <Typography
                                                token={TypographyToken.DesktopDescriptionSm}
                                            >
                                                {permission.permission}
                                            </Typography>
                                            <Switch checked={permission.hasPermission} disabled={permission.disabled || isViewMode} onChange={() => setSelectedUserGroups(userGroup.userGroupId, permission)} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </>
            }
        </div>
    );
};
