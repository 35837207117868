import { NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { useTranslation } from '~contexts/i18n';

interface EditUserFooterProps {
    closePanel: () => void;
    handleOnSaveClick: () => void;
}

const EditProfileFooter = ({ closePanel, handleOnSaveClick }: EditUserFooterProps) => {
    const { t } = useTranslation('user-management');

    return (
        <div
            className={'edit-user-panel__action-footer-container'}
        >
            <NdsButton
                size={SizesEnums.SMALL}
                buttonType={NdsButtonTypesEnum.NEUTRAL}
                label={t('user-management-edit-user-profile-tab-cancel-button-label')}
                onClick={closePanel}
            />
            <NdsButton
                size={SizesEnums.SMALL}
                label={t('user-management-edit-user-profile-tab-save-button-label')}
                onClick={handleOnSaveClick}
            />
        </div>
    );
};

export default EditProfileFooter;
