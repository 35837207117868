import {
    GetAvailableLegalNoticesModel,
    GetThemesPerDocumentGroupModel,
    ReadSettingsModels,
    ReadSigningTypesModels,
} from '~api/settings.models';
import { IApi } from '~contexts/api';
import { PromiseUtils } from '~lib';
import Http from '~lib/http';
import { UserGroupsListItemModel } from '~models/user-management.models';

export class SettingsApi implements IApi {
    constructor(private http: Http) {
    }

    public async readSettings(signal?: AbortSignal): Promise<ReadSettingsModels.Result> {
        try {
            const response = await this.http
                .get('portalapi/v1/config/configuration/visible')
                .execute(signal);

            return await response.jsonAs<ReadSettingsModels.Result>();
        } catch (e) {
            if (signal?.aborted) {
                return PromiseUtils.forever();
            }
            throw e;
        }
    }

    public async readSigningTypes(signal?: AbortSignal): Promise<ReadSigningTypesModels.Result> {
        const response = await this.http
            .get('portalapi/v1/config/configuration/signingtypes')
            .execute(signal);

        return await response.jsonAs<ReadSigningTypesModels.Result>();
    }

    // TODO: Add missing queryParams as it's pageable list call.
    public async getUserGroup(userGroupId: number, signal?: AbortSignal): Promise<UserGroupsListItemModel> {
        const response = await this.http
            .get(`portalapi/v1/config/usergroups/${userGroupId}`)
            .execute(signal);

        return await response.jsonAs<UserGroupsListItemModel>();
    }

    async getAvailableLegalNotices(signal?: AbortSignal): Promise<GetAvailableLegalNoticesModel.Result> {
        try {
            const response = await this.http.get('/portalapi/v1/config/legalnotices')
                .execute(signal);

            return response.jsonAs<GetAvailableLegalNoticesModel.Result>(true);
        } catch (e) {
            throw e;
        }
    }

    async getThemesPerDocumentGroup(data: GetThemesPerDocumentGroupModel.Params, signal?: AbortSignal): Promise<GetThemesPerDocumentGroupModel.Result> {
        try {
            const response = await this.http.get(`portalapi/v1/config/documentgroups/${data.documentGroupId}/themes`)
                .execute(signal);

            return response.jsonAs<GetThemesPerDocumentGroupModel.Result>(true);
        } catch (e) {
            throw e;
        }
    }

    /// $ADD_API_TEMPLATE_MARKER
}
