import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { TableContext, TableContextProvider } from 'contexts/table';
import { useCallback, useState } from 'react';
import { ContactsApi } from '~api/contacts.api';
import ContactForm from '~components/contact-form';
import Content from '~components/content';
import MergeContactModal from '~components/merge-contact-modal';
import NavTabs from '~components/nav-tabs';
import { ContactType, Permission } from '~constants';
import { CONTACT_TABS } from '~constants/contact-tabs';
import { PortalTableContainer } from '~containers/portal-table';
import { useApi } from '~contexts/api';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { FetchTableDataDelegate } from '~contexts/table/table.types';
import { ContactUtils } from '~lib';
import { Guid } from '~models';
import { ContactBasicModel } from '~models/contact.model';
import { ContactModel } from '~models/contacts.models';
import ContactQuickActions from './contact-quick-actions';
import ContactsViewHeaderButton from './contacts-view-header-button';
import { ContactsHeaderButtonActionType } from './contacts-view-header-button/contactsViewHeaderButton';
import CopyShareContactsModal from './copy-share-contacts-modal';
import DeleteContactModal from './delete-contact-modal';
import ExistingContactModal from './existing-contact-modal';
import ImportContactsModal from './import-contacts-modal';
import NoContactsCreatedYet from './no-contacts-created-yet/noContactsCreatedYet';
import { ContactsTableDefinition } from './table-definition';
import { useContactsTableFilters } from './use-contacts-table-filter';
import './contacts.view.scss';

const ContactTableTypeLabel: any = {
    [ContactType.Shared]: 'contact-type-shared-table-label',
    [ContactType.Personal]: 'contact-type-personal-table-label',
    [ContactType.Cloud]: 'contact-type-cloud-table-label',
};

export interface ContactsViewProps {
}

export default function ContactsView() {
    const contactsApi = useApi(ContactsApi);
    const { t } = useTranslation('contacts');
    const { t: tNotifications } = useTranslation('notifications');
    const hasCreateSharedContactPermission = useHasPermission(Permission.General_ActionCreateSharedContact);
    const hasEditSharedContactPermission = useHasPermission(Permission.General_ActionEditSharedContact);
    const [addContact, setAddContact] = useState<boolean>(false);
    const [contactViewMode, setContactViewMode] = useState<boolean| undefined>();
    const [contactToEdit, setContactToEdit] = useState<ContactModel | undefined>(undefined);
    const [openImportContacts, setOpenImportContacts] = useState<boolean>(false);
    const [openMergeModal, setOpenMergeModal] = useState<boolean>(false);
    const [conflictContacts, setConflictContacts] = useState<any>();
    const [contactsToProcess, setContactsToProcess] = useState<{
        contacts: ContactBasicModel[] | undefined;
        actionType: ContactsHeaderButtonActionType | undefined;
    }>({
        contacts: undefined,
        actionType: undefined,
    });

    const [failedContacts, setFailedContacts] = useState<{
        contacts: ContactBasicModel[] | undefined;
        actionType: ContactsHeaderButtonActionType | undefined;
    }>({
        contacts: undefined,
        actionType: undefined,
    });

    const [contactToDelete, setContactToDelete] = useState<ContactModel>();
    const fetchData: FetchTableDataDelegate<ContactModel> = useCallback(
        async (params, signal?: AbortSignal) => {
            return await contactsApi.getContactsList(params, signal);
        },
        [contactsApi],
    );
    const showNotification = useShowNotification();

    // Set available filters
    const filters = useContactsTableFilters();

    //Check if the row is selectable or not based on permissions
    const isRowSelectable = useCallback(
        (contact: ContactModel) => {
            if (contact.type === ContactType.Shared) {
                return hasEditSharedContactPermission;
            } else if (contact.type === ContactType.Personal) {
                return hasCreateSharedContactPermission;
            } else {
                return false;
            }
        },
        [hasCreateSharedContactPermission, hasEditSharedContactPermission],
    );

    // Method that handles copy or share contacts based on the actionType
    const handleProcessedContacts = (ids: ContactBasicModel[], actionType: ContactsHeaderButtonActionType) => {
        if (actionType === ContactsHeaderButtonActionType.Copy) {
            setContactsToProcess({
                contacts: ids,
                actionType: actionType,
            });
        } else {
            setContactsToProcess({
                contacts: ids,
                actionType: ContactsHeaderButtonActionType.Share,
            });
        }
    };

    // Method to open the add/edit contact panel to add new contact
    const openContact = () => {
        setAddContact(true);
    };

    // Method to close the add/edit contact panel to add new contact
    const closeContact = useCallback((refresh: () => void) => {
        setAddContact(false);
        setContactToEdit(undefined);
        refresh();
    }, []);

    // Method that handles the edit of a contact and open the add/edit contact panel
    const handleOnEditClick = async (contactId: Guid, viewMode?: boolean) => {
        const contact = await contactsApi.getContact({ contactId });

        setContactToEdit(contact);
        setContactViewMode(viewMode);
        setAddContact(true);
    };

    const handleSkipItemClick = (contactToSkip: ContactBasicModel) => {
        setFailedContacts(prevState => ({
            ...prevState,
            contacts: prevState.contacts?.filter(contact => contact.contactId !== contactToSkip.contactId),
        }));
    };

    const handleMergeContact = async(contact: any) => {
        let existingContact;

        if(failedContacts.actionType === ContactsHeaderButtonActionType.Copy) {
            existingContact =  await contactsApi.getContactByEmail({
                emailAddress: contact.emailAddress,
                isShared: false,
            });
        } else {
            existingContact = await contactsApi.getContactByEmail({
                emailAddress: contact.emailAddress,
                isShared: true,
            });
        }

        setConflictContacts({
            actionType: failedContacts.actionType,
            contactToMerge: contact,
            existingContact: existingContact,
        });
        setOpenMergeModal(true);
    };

    const handleConfirmMergeContact = async(editedContact: any, contactIdToDelete?: Guid) => {
        try {
            await contactsApi.editContact(editedContact);
            setOpenMergeModal(false);
            showNotification({
                type: 'success',
                title: tNotifications('merge-success-message'),
                hideAfterMs: 2000,
            });
            setFailedContacts(prevState => ({
                ...prevState,
                contacts: prevState.contacts?.filter(contact => contact.contactId !== conflictContacts.contactToMerge.contactId),
            }));

            if(contactIdToDelete) {
                const contact = await contactsApi.getContact({ contactId: contactIdToDelete });

                setContactToDelete(contact);
            }
        } catch (error) {
            showNotification({
                type: 'error',
                title: tNotifications('merge-error-message'),
                hideAfterMs: 2000,
            });
        }
    };

    return (
        <Content noDefaultPadding>
            <TableContextProvider<'contactId', ContactModel>
                definition={ContactsTableDefinition}
                fetchData={fetchData}
                isRowSelectable={isRowSelectable}
            >
                <PortalTableContainer<'contactId', ContactModel>
                    pageTitle={t('contacts-title')}
                    filtersDefinition={filters}
                    tabs={<NavTabs navTabs={CONTACT_TABS} />}
                    headerButton={<ContactsViewHeaderButton
                        onAddContact={openContact}
                        onImportContactsClick={() => setOpenImportContacts(true)}
                        onCopyContactClick={(contactIds: ContactBasicModel[]) => handleProcessedContacts(contactIds, ContactsHeaderButtonActionType.Copy)}
                        onSharePersonalContactsClick={(contactIds: ContactBasicModel[]) => handleProcessedContacts(contactIds, ContactsHeaderButtonActionType.Share)}
                    />}
                    renderColumns={(data) => {
                        return {
                            type: (
                                <>
                                    <NdsIconFont
                                        fontName={ContactUtils.getTypeIcon(data.origin, data.isShared)}
                                        size={SizesEnums.SMALL}
                                    />
                                    {t(ContactTableTypeLabel[data.type])}
                                </>
                            ),
                            action: <ContactQuickActions
                                isShared={data.isShared}
                                onEditClick={() => handleOnEditClick(data.contactId)}
                                onDeleteClick={() => setContactToDelete(data)}
                                onViewClick={() => handleOnEditClick(data.contactId, true)}
                            />,
                        };
                    }}
                    renderNoItemsYet={
                        <NoContactsCreatedYet handleAddContactClick={() => alert('addContact click')} />
                    }
                />
                <TableContext.Consumer>
                    {({ refresh }) => (
                        <OverlayPortal type={'panel'} visible={addContact}>
                            {() => (
                                <ContactForm
                                    viewMode={contactViewMode}
                                    open={true}
                                    onClose={() => closeContact(refresh)}
                                    contact={contactToEdit}
                                />
                            )}
                        </OverlayPortal>
                    )}
                </TableContext.Consumer>
                <DeleteContactModal
                    onCloseModalClick={() => setContactToDelete(undefined)}
                    contact={contactToDelete}
                />
                <CopyShareContactsModal
                    contactsToProcess={contactsToProcess.contacts}
                    setFailedContacts={(failedContacts: ContactBasicModel[]) => {
                        setFailedContacts({
                            contacts: failedContacts,
                            actionType: contactsToProcess.actionType,
                        }) ;
                    }}
                    onCloseModalClik={() => setContactsToProcess({
                        contacts: undefined,
                        actionType: undefined,
                    })}
                    actionType={contactsToProcess.actionType}
                />

                <ExistingContactModal
                    contactsToMerge={failedContacts.contacts}
                    actionType={failedContacts.actionType}
                    onSkipItemClick={(contact: ContactBasicModel) => handleSkipItemClick(contact)}
                    onCloseModalClik={() => {
                        setFailedContacts({
                            contacts: undefined,
                            actionType: undefined,
                        });
                    }}
                    onMergeContact={(mergeContact) => handleMergeContact(mergeContact)}/>
                <OverlayPortal id='mergeModalContactsdasda' type={'modal'} visible={openMergeModal}>
                    {({ close }) => <MergeContactModal
                        mergingContactLabel={conflictContacts.actionType === ContactsHeaderButtonActionType.Copy? 'Shared': 'Personal'}
                        existingContactLabel={conflictContacts.actionType === ContactsHeaderButtonActionType.Copy? 'Personal': 'Shared'}
                        isShare={failedContacts.actionType === ContactsHeaderButtonActionType.Share}
                        contactsToMerge={conflictContacts}
                        onCloseModalClik={() =>  {
                            setOpenMergeModal(false) ; close();
                        }}
                        onMergeContact={(contact, contactIdToDelete) => handleConfirmMergeContact(contact, contactIdToDelete)}/>
                    }
                </OverlayPortal>
                <ImportContactsModal
                    isOpen={openImportContacts}
                    handleCloseModal={() => setOpenImportContacts(false)}
                />
            </TableContextProvider>
        </Content>
    );
}
