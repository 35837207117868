import { useState } from 'react';
import { UserGroupsListItemModel } from '~models/user-management.models';
import { hardCodedPermissionsType } from '../manage-document-groups-panel';

const useHandleToggleSwitch = () => {
    const [selectedUserGroups, setSelectedUserGroups] = useState<UserGroupsListItemModel[]>();

    const handleToggleSwitch = (ugId: number, perm: hardCodedPermissionsType) => {
        setSelectedUserGroups((prev?: UserGroupsListItemModel[]) => {
            return prev?.map((group: UserGroupsListItemModel) => {
                if (ugId === group.userGroupId) {
                    let updatedPermissionToggles = group.permissions.map((permission: hardCodedPermissionsType) => {

                        if (permission.permissionId === perm.permissionId) {
                            return {
                                ...permission,
                                hasPermission: !permission.hasPermission,
                            };
                        } else {
                            return permission;
                        }

                    });

                    updatedPermissionToggles = updateDisabledToggles(updatedPermissionToggles);

                    return {
                        ...group,
                        permissions: updatedPermissionToggles,
                    };
                } else {
                    return group;
                }
            });
        });
    };

    const updateDisabledToggles = (updatedToggles: hardCodedPermissionsType[]) => {
        // Update based on Audit permission
        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Audit' || tog.permission === 'Delete') && tog.hasPermission,
            );

            if (toggle.permission === 'View' && hasPermission) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }

            return toggle;
        });

        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Audit' || tog.permission === 'Delete') && tog.hasPermission,
            );

            if (toggle.permission === 'View' && hasPermission) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }

            return toggle;
        });

        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Upload' || tog.permission === 'Download') && tog.hasPermission,
            );

            if ((toggle.permission === 'Audit') && hasPermission) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }

            return toggle;
        });

        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Upload' || tog.permission === 'Download') && tog.hasPermission,
            );

            if ((toggle.permission === 'Audit') && !hasPermission) {
                return {
                    ...toggle,
                    disabled: false,
                };
            }

            return toggle;
        });

        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Audit') && tog.hasPermission,
            );

            if ((toggle.permission === 'View') && hasPermission) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }

            return toggle;
        });

        // Update based on Audit and Delete permission
        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Audit' || tog.permission === 'Delete') && tog.hasPermission,
            );

            if (toggle.permission === 'View' && !hasPermission) {
                return {
                    ...toggle,
                    disabled: false,
                };
            }

            return toggle;
        });

        // Update based on ViewTemplate and Delete/Create permission
        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'DeleteTemplate' || tog.permission === 'CreateTemplate') && tog.hasPermission,
            );

            if (toggle.permission === 'ViewTemplate' && hasPermission) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }

            return toggle;
        });

        // Update based on ViewTemplate and no Delete/Create permission
        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'DeleteTemplate' || tog.permission === 'CreateTemplate') && tog.hasPermission,
            );

            if (toggle.permission === 'ViewTemplate' && !hasPermission) {
                return {
                    ...toggle,
                    disabled: false,
                };
            }

            return toggle;
        });

        return updatedToggles;
    };

    return {
        selectedUserGroups,
        setSelectedUserGroups,
        handleToggleSwitch,
    };
};

export default useHandleToggleSwitch;
