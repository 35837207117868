import { ActorType } from '~constants';
import { FlowStatusEnum } from '~constants/flow-status';
import { IApi } from '~contexts/api';
import Http from '~lib/http';
import {
    CreateBulkSignSessionModel,
    DeletePackageModel,
    DeleteTemplateModel,
    DuplicateTemplateModel,
    EndPackageModel,
    ExtendPackageModel,
    GetActionTokensModel,
    GetActorsReassignModel,
    GetDocumentDetails,
    GetDocumentGroupsModel,
    GetDocumentsFromPackagesModel,
    GetDownloadPackageTokenModel,
    GetPackageDetailsModel,
    GetPackagesListModel,
    GetRequiredDataReasignModel,
    GetSigningSessionList,
    GetTemplateListModel,
    GetUserGroupsListModel,
    GetUsersListModel,
    InviteUsersModel,
    ReassignPackageModel,
    RevokePackageModel,
    RunBulkActionForPackageIdsModel,
    SendPackageReminderModel,
    SoftDeletePackageModel,
} from './portals.models';

export class PortalsApi implements IApi {
    constructor(private http: Http) {
    }

    async getPackagesList(
        data: GetPackagesListModel.Params,
        signal?: AbortSignal,
    ): Promise<GetPackagesListModel.Result> {
        try {
            const response = await this.http
                .get(`/portalapi/v1/documentgroups/${data.workspaceId}`)
                .withParams({
                    sortOrder: data.sortOrder || 'asc',
                    sortField: data.sortField || 'createdOn',
                    continuationToken: data.page - 1,
                    maxQuantity: data.maxQuantity,
                    language: data.language,
                    ...data.filterValues,
                })
                .execute(signal);
            const parsed = await response.jsonAs<GetPackagesListModel.Response>(true);

            parsed.items.forEach((pkg) => {
                if (pkg.status !== FlowStatusEnum.Pending) {
                    return;
                }
                if (pkg.totalApprovers > pkg.nonPendingApprovers && !pkg.isApproved) {
                    pkg.status = FlowStatusEnum.PendingApproval;
                } else if (pkg.totalFormFillers > pkg.nonPendingFormFillers && !pkg.isFormFilled) {
                    pkg.status = FlowStatusEnum.PendingFormFill;
                } else {
                    pkg.status = FlowStatusEnum.PendingSigning;
                }
            });

            return {
                nextPage: parseInt(parsed.continuationToken),
                items: parsed.items,
                totalItems: parsed.total,
                itemsPerPage: parsed.maxQuantity,
            };
        } catch (e) {
            throw e;
        }
    }

    async getSigningSessionList(
        data: GetSigningSessionList.Params,
        signal?: AbortSignal,
    ): Promise<GetSigningSessionList.Result> {
        try {
            const response = await this.http
                .get('/portalapi/v1/myaccount/signingsessions')
                .withParams({
                    sortOrder: data.sortOrder || 'asc',
                    sortField: data.sortField || 'createdOn',
                    continuationToken: data.page - 1,
                    maxQuantity: data.maxQuantity,
                    language: data.language,
                    ...data.filterValues,
                })
                .execute(signal);

            const parsed = await response.jsonAs<GetSigningSessionList.Response>(true);

            parsed.items.forEach((pkg) => {
                if (pkg.status === FlowStatusEnum.Pending) {
                    switch (pkg.resourceType) {
                        case ActorType.Signer:
                            pkg.status = FlowStatusEnum.PendingSigning;
                            break;
                        case ActorType.Approver:
                            pkg.status = FlowStatusEnum.PendingApproval;
                            break;
                        case ActorType.FormFiller:
                            pkg.status = FlowStatusEnum.PendingFormFill;
                            break;
                    }
                }
                // Map Signed status to Finished on Signer Portal
                if (pkg.status === FlowStatusEnum.Signed) {
                    pkg.status = FlowStatusEnum.Finished;
                }
            });

            return {
                nextPage: parseInt(parsed.continuationToken),
                items: parsed.items,
                totalItems: parsed.total,
                itemsPerPage: parsed.maxQuantity,
            };
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async createBulkSignSession(
        data: CreateBulkSignSessionModel.Params,
        signal?: AbortSignal,
    ): Promise<CreateBulkSignSessionModel.Result> {
        try {
            const response = await this.http
                .post('/signingportal/sign/bulk')
                .withJsonBody({ ...data })
                .execute(signal);

            return await response.jsonAs<CreateBulkSignSessionModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getDocumentsFromPackages(
        data: GetDocumentsFromPackagesModel.Params,
        signal?: AbortSignal,
    ): Promise<GetDocumentsFromPackagesModel.Result> {
        try {
            const response = await this.http.get(`/signingportal/items/${data.actionId}`).execute(signal);

            return await response.jsonAs<GetDocumentsFromPackagesModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async runBulkActionForPackageIds(
        { packageIds, action, target, language, expirationTimestamp }: RunBulkActionForPackageIdsModel.Params,
        signal?: AbortSignal,
    ): Promise<string> {
        try {
            const response = await this.http
                .post(`/portalapi/v1/packages/${action}/bulk`)
                .withJsonBody<RunBulkActionForPackageIdsModel.Body>({
                    PackageIds: packageIds,
                    Target: target,
                    Language: language,
                    ExpirationTimestamp: expirationTimestamp,
                })
                .execute(signal);

            return await response.json();
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getTemplatesList(
        data: GetTemplateListModel.Params,
        signal?: AbortSignal,
    ): Promise<GetTemplateListModel.Result> {
        try {
            const response = await this.http
                .get(`/templateportalapi/v1/templates/${data.workspaceId}`)
                .withParams({
                    sortOrder: data.sortOrder || 'asc',
                    sortField: data.sortField || 'createdOn',
                    continuationToken: data.page - 1,
                    maxQuantity: data.maxQuantity,
                    language: data.language,
                    ...data.filterValues,
                })
                .execute(signal);
            const parsed = await response.jsonAs<GetTemplateListModel.Response>(true);

            // Currently no createdOn property in items response

            return {
                nextPage: parseInt(parsed.continuationToken),
                items: parsed.items,
                totalItems: parsed.total,
                itemsPerPage: parsed.maxQuantity,
            };
        } catch (e) {
            throw e;
        }
    }

    async getDownloadPackageToken(
        data: GetDownloadPackageTokenModel.Params,
        signal?: AbortSignal,
    ): Promise<GetDownloadPackageTokenModel.Result> {
        try {
            const response = await this.http.get(`portalapi/v1/packages/${data.packageId}/download`).execute(signal);

            return await response.jsonAs<GetDownloadPackageTokenModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async deletePackage(data: DeletePackageModel.Params, signal?: AbortSignal): Promise<DeletePackageModel.Result> {
        try {
            const response = await this.http.delete(`portalapi/v1/packages/${data.packageId}`).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async softDeletePackage(
        data: SoftDeletePackageModel.Params,
        signal?: AbortSignal,
    ): Promise<SoftDeletePackageModel.Result> {
        try {
            const response = await this.http.delete(`signingportal/${data.packageId}/softdelete`).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getActionTokens(
        data: GetActionTokensModel.Params,
        signal?: AbortSignal,
    ): Promise<GetActionTokensModel.Result> {
        try {
            const response = await this.http
                .get(`portalapi/v1/myaccount/actions/${data.actionId}/tokens`)
                .execute(signal);

            return await response.jsonAs<GetActionTokensModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async sendPackageReminder(
        data: SendPackageReminderModel.Params,
        signal?: AbortSignal,
    ): Promise<SendPackageReminderModel.Result> {
        try {
            const response = await this.http.post(`portalapi/v1/packages/${data.packageId}/reminder`).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async endPackage(data: EndPackageModel.Params, signal?: AbortSignal): Promise<EndPackageModel.Result> {
        try {
            const response = await this.http
                .post(`portalapi/v1/packages/${data.packageId}/end`)
                .withJsonBody<EndPackageModel.Body>({ target: data.endPackage ? 'toFinished' : 'toSigners' })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async revokePackage(data: RevokePackageModel.Params, signal?: AbortSignal): Promise<RevokePackageModel.Result> {
        try {
            const response = await this.http.post(`portalapi/v1/packages/${data.packageId}/revoke`).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getPackageDetails(
        params: GetPackageDetailsModel.Params,
        signal?: AbortSignal,
    ): Promise<GetPackageDetailsModel.Result> {
        try {
            const response = await this.http.get(`portalapi/v1/packages/${params.packageId}`).execute(signal);

            return await response.jsonAs<GetPackageDetailsModel.Result>(true);

        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async duplicateTemplate(
        data: DuplicateTemplateModel.Params,
        signal?: AbortSignal,
    ): Promise<DuplicateTemplateModel.Result> {
        try {
            const response = await this.http.post(`portalapi/v1/templates/${data.templateId}/clone`).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async deleteTemplate(
        data: DeleteTemplateModel.Params,
        signal?: AbortSignal,
    ): Promise<DuplicateTemplateModel.Result> {
        try {
            const response = await this.http.delete(`portalapi/v1/templates/${data.templateId}`).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async extendPackage(data: ExtendPackageModel.Params, signal?: AbortSignal): Promise<ExtendPackageModel.Result> {
        try {
            const { expirationTimestamp, packageId } = data;
            const response = await this.http
                .put(`portalapi/v1/packages/${packageId}/expirationtimestamp`)
                .withJsonBody<ExtendPackageModel.Body>({ ExpirationTimestamp: expirationTimestamp })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getRequiredDataReassign(
        params: GetRequiredDataReasignModel.Params,
        signal?: AbortSignal,
    ): Promise<GetRequiredDataReasignModel.Result> {
        try {
            const response = await this.http
                .get(`portalapi/v1/packages/signers/${params.actionId}/requiredData`)
                .execute(signal);

            return await response.jsonAs<GetRequiredDataReasignModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getActorsReassign(
        params: GetActorsReassignModel.Params,
        signal?: AbortSignal,
    ): Promise<GetActorsReassignModel.Result> {
        const status = params.status.toLowerCase().includes('pending') ? 'Pending' : '';

        try {
            const response = await this.http
                .get(`portalapi/v1/packages/${params.packageId}/actors`)
                .withParams({ status: status })
                .execute(signal);

            let parsed = await response.jsonAs<GetActorsReassignModel.Result>(true);


            parsed = parsed.map(obj => {
                return {
                    ...obj,
                    type: obj.type.toUpperCase() as ActorType,
                };
            });

            return parsed;

        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async reassignPackage(
        { contactId, isCloudContact, reassignmentReason, packageId, selectedRecipient }: ReassignPackageModel.Params,
        signal?: AbortSignal,
    ): Promise<ReassignPackageModel.Result> {
        try {
            const response = await this.http
                .post(`portalapi/v1/packages/${packageId}/actions/${selectedRecipient}/reassign`)
                .withJsonBody<ReassignPackageModel.Body>({
                    ContactId: contactId,
                    IsCloudContact: isCloudContact,
                    ReassignmentReason: reassignmentReason,
                })
                .execute(signal);

            return await response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getUserGroups(
        data: GetUserGroupsListModel.Params, signal?: AbortSignal,
    ): Promise<GetUserGroupsListModel.Result> {
        try {
            const response = await this.http
                .get('/portalapi/v1/config/usergroups')
                .withParams({
                    sortOrder: data.sortOrder || 'asc',
                    sortField: data.sortField || 'name',
                    continuationToken: data.page - 1,
                    maxQuantity: data.maxQuantity,
                    ...data.filterValues,
                })
                .execute(signal);
            const parsed = await response.jsonAs<GetUserGroupsListModel.Response>(true);

            return {
                nextPage: parseInt(parsed.continuationToken),
                items: parsed.items,
                totalItems: parsed.total,
                itemsPerPage: parsed.maxQuantity,
            };
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async inviteUsers(
        data: InviteUsersModel.Params,
        signal?: AbortSignal,
    ): Promise<InviteUsersModel.Result> {
        try {
            const { emails, userGroups, isSharedContact, invitationLanguage } = data;

            const response = await this.http
                .post('/portalapi/v1/config/users/invite').withJsonBody<InviteUsersModel.Body>({
                    Emails: emails,
                    MetaData: {
                        IsSharedContact: isSharedContact,
                        InvitationLanguage: invitationLanguage,
                    },
                    UserGroups: userGroups,
                })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getUsers(data: any, signal?: AbortSignal): Promise<GetUsersListModel.Result> {
        try {
            const response = await this.http
                .get('portalapi/v1/config/users')
                .withParams({
                    sortOrder: data.sortOrder || 'asc',
                    sortField: data.sortField || 'registrationDate',
                    continuationToken: data.page - 1,
                    maxQuantity: data.maxQuantity,
                    language: data.language || 'en',
                    ...data.filterValues,
                })
                .execute(signal);

            const parsed = await response.jsonAs<GetUsersListModel.Response>(true);

            return {
                nextPage: parseInt(parsed.continuationToken),
                items: parsed.items,
                totalItems: parsed.total,
                itemsPerPage: parsed.maxQuantity,
            };

        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getDocumentGroups(data: any, signal?: AbortSignal): Promise<GetDocumentGroupsModel.Result> {

        const response = await this.http
            .get('/portalapi/v1/config/documentgroups')
            .withParams({
                sortOrder: data.sortOrder || 'asc',
                continuationToken: data.page - 1,
                maxQuantity: data.maxQuantity,
                language: data.language || 'en',
                ...data.filterValues,
            })
            .execute(signal);

        const parsed = await response.jsonAs<GetDocumentGroupsModel.Response>(true);

        return {
            nextPage: parseInt(parsed.continuationToken),
            items: parsed.items,
            totalItems: parsed.total,
            itemsPerPage: parsed.maxQuantity,
        };
    }

    async getDocumentDetail(data: GetDocumentDetails.Params, signal?: AbortSignal): Promise<GetDocumentDetails.Result> {
        const response = await this.http.get(`/portalapi/v1/documents/${data.documentSignigFlowId}`).execute(signal);

        return await response.jsonAs<GetDocumentDetails.Result>(true);

    }

    /// $ADD_API_TEMPLATE_MARKER

    async getThemes(signal?: AbortSignal): Promise<any> {
        const response = await this.http.get('/portalapi/v1/config/themes').execute(signal);

        return await response.jsonAs(true);
    }
}
