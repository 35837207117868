import { FlowActionType, FlowReducerFunc } from '~contexts/flow/flow.types';
import { StringUtils } from '~lib';
import { FlowStakeholderModel, Guid, StakeholderModel, StakeholderType } from '~models';

export type FlowReducerStakeholderActionPayloads = {
    [FlowActionType.AddStakeholder]: StakeholderModel | FlowStakeholderModel;
    [FlowActionType.UpdateStakeholder]: FlowStakeholderModel;
    [FlowActionType.ShowStakeholder]: Guid | undefined;
    [FlowActionType.HideStakeholder]: Guid | undefined;
    [FlowActionType.SetStakeholders]: StakeholderModel[] | FlowStakeholderModel[];
}

export const AddStakeholderReducer: FlowReducerFunc<FlowActionType.AddStakeholder> = (
    state,
    payload,
) => {
    let data: FlowStakeholderModel;

    if (!('localId' in payload)) {
        data = {
            ...payload,
            localId: StringUtils.guid(),
        };
    } else {
        data = payload;
    }

    return {
        ...state,
        stakeholders: [...state.stakeholders, data],
    };
};

export const UpdateStakeholderReducer: FlowReducerFunc<FlowActionType.UpdateStakeholder> = (
    state,
    payload,
) => {
    const stakeholders = state.stakeholders.slice();

    if (payload.type !== StakeholderType.Undecided && 'localId' in payload) {
        const index = stakeholders.findIndex((stakeholder) => {
            return stakeholder.localId === payload.localId;
        });

        if (index > -1) {
            stakeholders[index] = payload;
        }
    }

    return {
        ...state,
        stakeholders,
    };
};
export const ShowStakeholderReducer: FlowReducerFunc<FlowActionType.ShowStakeholder> = (
    state,
    localId,
) => {
    const stakeholders = state.stakeholders.slice();
    let index = stakeholders.findIndex(el => el.localId === localId);

    if (index < 0) {
        index = stakeholders.findIndex(el => el.type === StakeholderType.Undecided);

        if (index < 0) {
            return { ...state };
        }
    }
    stakeholders.splice(index, 1, {
        ...stakeholders[index],
        hidden: false,
    });

    return {
        ...state,
        stakeholders,
    };
};
export const HideStakeholderReducer: FlowReducerFunc<FlowActionType.HideStakeholder> = (
    state,
    localId,
) => {
    const stakeholders = state.stakeholders.slice();
    let index = stakeholders.findIndex(el => el.localId === localId);

    if (index < 0) {
        index = stakeholders.findIndex(el => el.type === StakeholderType.Undecided);

        if (index < 0) {
            return { ...state };
        }
    }
    stakeholders.splice(index, 1, {
        ...stakeholders[index],
        hidden: true,
    });

    return {
        ...state,
        stakeholders,
    };
};

export const SetStakeholdersReducer: FlowReducerFunc<FlowActionType.SetStakeholders> = (
    state,
    payload,
) => {
    const orgStakeholders = state.stakeholders;
    const stakeholders: FlowStakeholderModel[] = payload.map((newStakeholder) => {
        const orgStakeholder = orgStakeholders.find(el => el.id === newStakeholder.id);
        const localId = 'localId' in newStakeholder && newStakeholder.localId
            ? newStakeholder.localId
            : orgStakeholder?.localId ?? StringUtils.guid();

        return {
            ...newStakeholder,
            localId,
            hidden: orgStakeholder?.hidden ?? false,
        };
    });


    return {
        ...state,
        stakeholders,
    };
};
