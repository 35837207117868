import { DateFilter, Filter, NdsInput, NdsInputChangeEvent } from '@gonitro/rcl';
import {
    ContentAlignEnum,
    DateFilterOption,
    DateFilterTranslations,
    FilterOption,
    NdsInputTypes,
    SizesEnums,
    StatesEnum,
} from '@gonitro/rcl/lib/_types';
import { DateFilterEnum } from '@gonitro/rcl/lib/_types/filter';
import classNames from 'classnames';
import { useCallback, useMemo, useRef } from 'react';
import { DateFilterOptions } from '~constants/filter-options';
import { useTranslation } from '~contexts/i18n';
import { useTableFilters } from '~contexts/table';
import { TableFilterArrayValue, TableFilterObjectValue } from '~contexts/table/table.types';
import { CommonUtils } from '~lib/common.utils';
import './portal-table-filters.scss';

export type PortalTableFiltersDateDropdownDefinition = {
    type: 'date';
    key: string;
    options: DateFilterOption[] | 'default'; // default is all dates
    defaultOption: DateFilterEnum;
    translations?: DateFilterTranslations;
}
export type PortalTableFiltersStandardDropdownDefinition = {
    type: 'single' | 'multi'; // so radio or checkbox
    key: string;
    options: FilterOption[];
    defaultOption: FilterOption['value'];
    translations: { allItems: string; label: string; none: string; };
}
export type PortalTableFiltersDropdownDefinition =
    PortalTableFiltersStandardDropdownDefinition
    | PortalTableFiltersDateDropdownDefinition;

export interface PortalTableFiltersDefinition {
    searchAll?: {
        type: 'single' | 'multi'; // just one search value at a time or multiples possible
        key: string; // key string only, for multi the [] will be automatically added
        regex?: RegExp; // limit characters TODO
        placeholder: string;
    };
    dropdowns?: Array<PortalTableFiltersDropdownDefinition>;
}

export interface PortalTableFiltersProps {
    filters: PortalTableFiltersDefinition;
}

const debounce = CommonUtils.delayFunc({
    debounce: 500,
    throttle: 100,
});

function PortalTableFilters({ filters }: PortalTableFiltersProps) {
    const { t: baseT } = useTranslation('base');
    const { filterValues, setFilterValue, clearFilterValue } = useTableFilters();
    const localCacheRef = useRef<Record<string, any>>({});

    const handleDropdownChange = useCallback((value: any, name: string) => {
        const def: PortalTableFiltersDropdownDefinition = filters.dropdowns![parseInt(name)];

        if (def.type === 'date') {
            // need some localStore as don't want to push empty strings as values to not put them in url nor send to be
            if (value.start === '' || value.end === '') {
                localCacheRef.current = {
                    ...localCacheRef.current,
                    [`${def.key}-isCustom`]: true,
                };
                setFilterValue(def.key, {
                    start: value.start === '' ? undefined : value.start,
                    end: value.end === '' ? undefined : value.end,
                });
            } else {
                localCacheRef.current = {
                    ...localCacheRef.current,
                    [`${def.key}-isCustom`]: false,
                };

                if (value.start === undefined && value.end === undefined) {
                    clearFilterValue(def.key);
                } else {
                    setFilterValue(def.key, {
                        start: value.start,
                        end: value.end,
                    });
                }
            }
        } else if (def.type === 'single') {
            if (value[0] === def.defaultOption || !value.length) {
                clearFilterValue(def.key);
            } else {
                setFilterValue(def.key, value[0]);
            }
        } else if (def.type === 'multi') {
            if (value[0] === def.defaultOption) {
                clearFilterValue(def.key);
            } else {
                setFilterValue(def.key, value);
            }
        }
    }, [clearFilterValue, filters.dropdowns, setFilterValue]);

    const handleInputChange = useCallback((e: NdsInputChangeEvent) => {
        const value = e.detail.value.trim();

        if (value.length) {
            debounce(() => {
                if (filters.searchAll!.type === 'single') {
                    setFilterValue(filters.searchAll!.key, value);
                } else if (filters.searchAll!.type === 'multi') {
                    setFilterValue<string[]>(filters.searchAll!.key, (prev = []) => [...prev, value]);
                }
            });
        } else {
            clearFilterValue(filters.searchAll!.key);
        }
    }, [clearFilterValue, filters.searchAll, setFilterValue]);

    const translatedDateFilterOptions = useMemo(() => {
        return DateFilterOptions.map(({ label, value }) => ({
            label: baseT(label),
            value,
        }));
    }, [baseT]);


    return (
        <div className={classNames('ct-portal-table-filters')}>
            <div className={classNames('ct-portal-table-filters__dropdowns')}>
                {filters.dropdowns?.map((def, index) => {
                    if (def.type === 'date') {
                        const value = (filterValues[def.key] || {}) as TableFilterObjectValue<{
                            start?: string;
                            end?: string;
                        }>;

                        if (localCacheRef.current[`${def.key}-isCustom`]) {
                            value.start = value.start || '';
                            value.end = value.end || '';
                        }

                        return (
                            <DateFilter
                                key={index}
                                name={index.toString()}
                                value={value}
                                onChange={handleDropdownChange}
                                options={translatedDateFilterOptions}
                                contentAlign={ContentAlignEnum.BOTTOM_RIGHT}
                                translations={def.translations ?? {
                                    from: baseT('filter-from'),
                                    to: baseT('filter-to'),
                                    date: baseT('filter-date'),
                                }}
                            />
                        );
                    } else if (def.type === 'single') {
                        const value = filterValues[def.key] ? [filterValues[def.key] as string] : [];

                        return (
                            <Filter
                                key={index}
                                name={index.toString()}
                                onChange={handleDropdownChange}
                                value={value}
                                options={def.options}
                                contentAlign={ContentAlignEnum.BOTTOM_RIGHT}
                                translations={def.translations}
                            />
                        );
                    } else if (def.type === 'multi') {
                        let  value = (filterValues[def.key] || []) as TableFilterArrayValue<string[]>;

                        if(def.defaultOption === '*' && !filterValues[def.key]) {
                            value = def.options.map(option => option.value);
                        }

                        return (
                            <Filter
                                key={index}
                                name={index.toString()}
                                onChange={handleDropdownChange}
                                value={value}
                                options={def.options}
                                contentAlign={ContentAlignEnum.BOTTOM_RIGHT}
                                translations={def.translations}
                                multiple={def.type === 'multi'} // TODO Filter component needs update to make this working properly, not used now so leaving as is
                            />
                        );
                    }

                    return null;
                })}
            </div>
            {filters.searchAll && (
                <div className={classNames('ct-portal-table-filters__search')}>
                    <NdsInput
                        state={StatesEnum.DEFAULT}
                        size={SizesEnums.SMALL}
                        inputType={NdsInputTypes.SEARCH}
                        placeholder={filters.searchAll.placeholder ?? ''}
                        onNdsChange={handleInputChange}
                        value={filterValues[filters.searchAll.key]?.toString() ?? ''}
                    />
                </div>
            )}
        </div>

    );
}

export default PortalTableFilters;
