import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { Switch } from '@progress/kendo-react-inputs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { plusIcon } from '@progress/kendo-svg-icons';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { Dispatch, MouseEvent, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ContactsApi } from '~api/contacts.api';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType, ContactType, Permission } from '~constants';
import { useApi } from '~contexts/api';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { useEnhancedFormContext } from '~hooks/enhanced-form/enhanced-form-context';
import usePrevious from '~hooks/usePrevious';
import { ValidateUtils } from '~lib';
import { PageableListParams } from '~models/pageable-list.models';
import { Substitute, UsersDataModel } from '~models/user-management.models';
import SubtituteItem from './substitute-item/substitute-item';
import SubtitutePanel from './substitute-panel/substitute-panel';
import useHandleAddSub from './useHandleAddSub';
import './out-of-office-tab.scss';


export interface OutOfOfficeTabProps {
    closePanel: () => void;
    userData: UsersDataModel;
    setToggleEnabled: Dispatch<SetStateAction<boolean>>;
    isToggleEnabled: boolean;
    setSubstitutes: Dispatch<SetStateAction<Substitute[]>>;
    substitutes: Substitute[];
    viewMode?: boolean;
}

export type OutOfOfficeTabFormValues = {
    startDate: Date;
    startTime: Date;
    endDate: Date;
    endTime: Date;
    formfiller: boolean;
    approver: boolean;
    signer: boolean;
    receiver: boolean;
    reassign: boolean;
};

export interface SubstituteTabContactsListItem {
    disabled?: boolean;
    fullName: string;
    contactId: number | string; // should be number, temporary until Cristian PR is merged
    emailAddress: string;
}

const OutOfOfficeTab = ({
    userData,
    isToggleEnabled,
    setSubstitutes,
    setToggleEnabled,
    substitutes,
    viewMode,
}: OutOfOfficeTabProps) => {

    const { t } = useTranslation('user-management');
    const { t: tValidation } = useTranslation('validation');

    const { registerCheckbox, formState, setValue, getValues, reset, control, trigger } = useEnhancedFormContext();

    const contactsApi = useApi(ContactsApi);
    const {
        customizationSettings: { isOutOfOfficeEnabled } = {},
        cloudSettings,
    } = useSettings();
    const { google, microsoft } = cloudSettings;
    const previousToggle = usePrevious(isToggleEnabled);

    const hasViewSharedContactsPermission = useHasPermission(Permission.General_ActionViewSharedContact);

    const [contacts, setContacts] = useState<SubstituteTabContactsListItem[]>([]);
    const [selectedContact, setSelectedContact] = useState<SubstituteTabContactsListItem | null>(null);
    const [selectedSub, setSelectedSub] = useState<Substitute>();
    const [showSubPanel, setShowSubPanel] = useState(true);
    const [isEditMode, setEditMode] = useState(false);

    const previousSub = useRef<number>();

    useEffect(() => {
        if (previousToggle !== isToggleEnabled) {
            trigger(['startDate', 'endDate', 'startTime', 'endTime']);
        }
    }, [isToggleEnabled, trigger, previousToggle]);

    const getContactTypes = useCallback(() => {
        const contactTypes = [ContactType.Personal];

        if (hasViewSharedContactsPermission) {
            contactTypes.push(ContactType.Shared);
        } else if (google.oauthSettings.isEnabled || microsoft.oauthSettings.isEnabled) {
            contactTypes.push(ContactType.Cloud);
        }

        return contactTypes;
    }, [google.oauthSettings.isEnabled, hasViewSharedContactsPermission, microsoft.oauthSettings.isEnabled]);

    const fetchData = useCallback(async (data: PageableListParams, signal?: AbortSignal) => {

        const response = await contactsApi.getContactBucket({
            contactTypes: getContactTypes(),
            ...data,
            searchString: data.filterValues!.searchString,
        });

        const filteredContacts = response.items.map(item => ({
            ...item,
            firstName: item.firstName ?? '',
        }));
        const updatedContacts = filteredContacts.map(contact => ({
            fullName: `${contact.firstName} ${contact.lastName}`,
            contactId: contact.contactId,
            disabled: userData.emailAddress === contact.emailAddress || false,
            emailAddress: contact.emailAddress,
        }));

        const updatedResponse = {
            ...response,
            items: [...updatedContacts],
        };

        return updatedResponse;

    }, [contactsApi, getContactTypes, userData.emailAddress]);


    useEffect(() => {
        const updatedContacts = contacts.map(contact => {
            const isDisabled = substitutes.some(sub => sub.contactId === contact.contactId);

            return {
                ...contact,
                disabled: isDisabled,
            };
        });

        if (!isEqual(updatedContacts, contacts)) {
            setContacts(updatedContacts);
        }

    }, [substitutes, contacts]);

    const extractFormValues = useCallback(() => {
        const {
            formfiller,
            approver,
            signer,
            receiver,
            startDate,
            endDate,
            startTime,
            endTime,
        } = getValues();

        return {
            formfiller,
            approver,
            signer,
            receiver,
            startDate,
            endDate,
            startTime,
            endTime,
        };
    }, [getValues]);

    const handleAddSub = useHandleAddSub({
        selectedContact,
        setSelectedContact,
        setShowSubPanel,
        setSubstitutes,
        substitutes,
        setContacts,
        isEditMode,
        setEditMode,
        previousSub,
        getValues,
        reset,
    });

    const handleNewSubstitute = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowSubPanel(true);
    };

    const checkIfRoleAlreadyTaken = useCallback(() => {
        const actionTypes: string[] = [];

        substitutes.forEach((sub) => {
            sub.actionTypes.forEach((actionType) => {

                if (!actionTypes.includes(actionType)) {
                    actionTypes.push(actionType);
                }
            });
        });

        if (selectedSub && isEditMode) {
            const filteredActions = actionTypes.filter(item => !selectedSub.actionTypes.includes(item));

            return filteredActions;
        }

        return actionTypes;
    }, [isEditMode, selectedSub, substitutes]);

    const handleEditSubstitute = useCallback((sub: Substitute) => {
        setShowSubPanel(true);
        setSelectedSub(sub);
        setEditMode(true);
        previousSub.current = sub.contactId as number;
        setSelectedContact({
            fullName: sub.displayName,
            contactId: sub.contactId,
            emailAddress: sub.emailAddress,
        });
        setValue('formfiller', sub.actionTypes.includes(ActorType.FormFiller));
        setValue('signer', sub.actionTypes.includes(ActorType.Signer));
        setValue('approver', sub.actionTypes.includes(ActorType.Approver));
        setValue('receiver', sub.actionTypes.includes(ActorType.Receiver));
        setValue('reassign', sub.allowedToReassign);
    }, [setValue]);

    const handleDeleteSubstitute = useCallback((substitute: Substitute) => {
        const filteredSubstitutes = substitutes.filter((sub) => sub.contactId !== substitute.contactId);

        setSubstitutes(filteredSubstitutes);

    }, [substitutes, setSubstitutes]);

    const handleCancel = useCallback(() => {
        const currentFieldValues = getValues();

        setSelectedContact(null);
        reset({
            ...currentFieldValues,
            formfiller: false,
            approver: false,
            reassign: false,
            signer: false,
            receiver: false,
        }, { keepErrors: true });
        setShowSubPanel(false);
        setEditMode(false);
    }, [getValues, reset]);

    //TODO add confirmation modal for delete and remove

    const handleRemove = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        if (isEditMode) {
            handleDeleteSubstitute(selectedSub!);
        }
        const currentFieldValues = getValues();

        setSelectedContact(null);
        reset({
            ...currentFieldValues,
            formfiller: false,
            approver: false,
            reassign: false,
            signer: false,
            receiver: false,
        }, { keepErrors: true });
        setShowSubPanel(false);
        setEditMode(false);
    }, [
        getValues,
        reset,
        isEditMode,
        selectedSub,
        handleDeleteSubstitute,
    ]);

    const contactListOnChange = useCallback((contact: SubstituteTabContactsListItem | null) => {
        if (!contact) {
            setSelectedContact(null);

            return;
        }
        setSelectedContact(contact);
    }, []);

    return (
        <div className={'out-of-office-tab'}>
            <div className={'out-of-office-tab__switch-container'}>
                <Switch
                    checked={isToggleEnabled}
                    disabled={!isOutOfOfficeEnabled || viewMode}
                    onChange={() => setToggleEnabled((prev) => !prev)} />
                <Typography
                    token={TypographyToken.MobileDescriptionMd}
                    text={t(isToggleEnabled ? 'out-of-office-toggle-enabled' : 'out-of-office-toggle-disabled')}
                    tagName={'label'}
                />
            </div>
            <div className={classNames('out-of-office-tab__form', { 'out-of-office-tab__form--disabled': !isOutOfOfficeEnabled || !isToggleEnabled })}>
                <div className='out-of-office-tab__date-picker-container'>

                    <div className='out-of-office-tab__start-date-container'>
                        <div>
                            <Typography
                                token={TypographyToken.DesktopDescriptionSm}
                                text={t('out-of-office-start-date')}
                                tagName={'label'}
                                htmlFor='date-picker-start'
                            />
                            <Controller
                                name="startDate"
                                control={control}
                                rules={{
                                    required: tValidation('required', { fieldLabel: t('out-of-office-start-date') }),
                                    validate: (value, formValues) => {
                                        const shouldUpdate = (isToggleEnabled && (userData.absencePeriods.length || !userData.absencePeriods.length)
                                            && formState.dirtyFields?.startDate) || (!userData.absencePeriods.length && isToggleEnabled);


                                        if (shouldUpdate) {
                                            return (
                                                ValidateUtils.dateNotInPast(value, formValues.startTime).dateNotInPast ||
                                                tValidation('date-time-not-past')
                                            );
                                        }

                                        return true;
                                    },
                                    deps: ['startTime', 'endDate', 'endTime'],
                                }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <DatePicker
                                            disabled={!isOutOfOfficeEnabled}
                                            min={getValues().initialStartDate}
                                            id='date-picker-start'
                                            className='out-of-office-tab__date-picker'
                                            format="dd/MM/yyyy"
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div>
                            <Typography
                                token={TypographyToken.DesktopDescriptionSm}
                                text={t('out-of-office-time')}
                                tagName={'label'}
                                htmlFor='time-picker-start'
                            />
                            <Controller
                                name="startTime"
                                control={control}
                                rules={{
                                    required: tValidation('required', { fieldLabel: t('out-of-office-start-date') }),
                                    validate: (value, formValues) => {
                                        const shouldUpdate = (isToggleEnabled && (userData.absencePeriods.length || !userData.absencePeriods.length)
                                            && formState.dirtyFields?.startTime) || (!userData.absencePeriods.length && isToggleEnabled);


                                        if (shouldUpdate) {
                                            return (
                                                ValidateUtils.dateNotInPast(formValues.startDate, value).dateNotInPast ||
                                                tValidation('date-time-not-past')
                                            );
                                        }

                                        return true;

                                    },
                                    deps: ['startDate', 'endDate', 'endTime'],
                                }}
                                render={({ field: { onChange, value } }) => {

                                    return (
                                        <TimePicker
                                            disabled={!isOutOfOfficeEnabled}
                                            className='out-of-office-tab__date-time'
                                            id='time-picker-start'
                                            format="HH:mm"
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                        />
                                    );
                                }
                                }
                            />
                        </div>

                    </div>
                    {formState.errors
                        ? <Typography
                            token={TypographyToken.DesktopDescriptionMd}
                            text={
                                formState.errors.startDate?.message as string ||
                                formState.errors.startTime?.message as string}
                            className={'out-of-office-tab__error-hint'}
                        />
                        : <div className='out-of-office-tab__error-spacing' />
                    }


                    <div className='out-of-office-tab__end-date-container'>
                        <div>
                            <Typography
                                token={TypographyToken.DesktopDescriptionSm}
                                text={t('out-of-office-end-date')}
                                tagName={'label'}
                                htmlFor='date-picker-end'
                            />
                            <Controller
                                name="endDate"
                                control={control}
                                rules={{
                                    required: tValidation('required', { fieldLabel: t('out-of-office-end-date') }),
                                    validate: {
                                        notInPast: (value, formValues) => {
                                            if (isToggleEnabled) {
                                                return (ValidateUtils.dateNotInPast(value, formValues.endTime).dateNotInPast
                                                    || tValidation('date-time-not-past')
                                                );
                                            }

                                            return true;
                                        },
                                        dateGreaterThanDate: (value, formValues) => {
                                            if (isToggleEnabled) {
                                                return ValidateUtils.dateGreaterThanDate(
                                                    {
                                                        date: formValues.startDate,
                                                        time: formValues.startTime,
                                                    },
                                                    {
                                                        date: value,
                                                        time: formValues.endTime,
                                                    },
                                                ).dateGreaterThanDate || t('out-of-office-start-past-end-date');
                                            }

                                            return true;
                                        },
                                    },
                                    deps: ['startDate', 'startTime', 'endTime'],
                                }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <DatePicker
                                            disabled={!isOutOfOfficeEnabled}
                                            min={getValues().initialEndDate}
                                            id={'date-picker-end'}
                                            className='out-of-office-tab__date-picker'
                                            format="dd/MM/yyyy"
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div>
                            <Typography
                                token={TypographyToken.DesktopDescriptionSm}
                                text={t('out-of-office-time')}
                                tagName={'label'}
                                htmlFor='time-picker-end'
                            />
                            <Controller
                                name="endTime"
                                control={control}
                                rules={{
                                    required: tValidation('required', { fieldLabel: t('out-of-office-time') }),
                                    validate: {
                                        notInPast: (value, formValues) => {
                                            if (isToggleEnabled) {
                                                return (ValidateUtils.dateNotInPast(formValues.endDate, value).dateNotInPast
                                                    || tValidation('date-time-not-past')
                                                );
                                            }

                                            return true;
                                        },
                                        dateGreaterThanDate: (value, formValues) => {
                                            if (isToggleEnabled) {
                                                return ValidateUtils.dateGreaterThanDate(
                                                    {
                                                        date: formValues.startDate,
                                                        time: formValues.startTime,
                                                    },
                                                    {
                                                        date: formValues.endDate,
                                                        time: value,
                                                    },
                                                ).dateGreaterThanDate || t('out-of-office-start-past-end-date');
                                            }

                                            return true;
                                        },
                                    },
                                    deps: ['startDate', 'startTime', 'endDate'],
                                }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TimePicker
                                            disabled={!isOutOfOfficeEnabled}
                                            id='time-picker-end'
                                            className='out-of-office-tab__date-time'
                                            format="HH:mm"
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                        />);
                                }}
                            />
                        </div>
                    </div>
                    {formState.errors
                        ? <Typography
                            token={TypographyToken.DesktopDescriptionMd}
                            text={
                                formState.errors.endDate?.message as string ||
                                formState.errors.endTime?.message as string}
                            className={'out-of-office-tab__error-hint'}
                        />
                        : <div className='out-of-office-tab__error-spacing' />
                    }
                </div>

                <div className={'out-of-office-tab__substitute-header'}>
                    <Typography
                        token={TypographyToken.DesktopDescriptionLg}
                        text={t('out-of-office-substitutes-header')}
                        tagName={'header'}
                        className={'out-of-office-tab__substitute-header-label'}
                    />
                    <Tooltip style={{ maxWidth: 260 }} position="top" content={() => t('out-of-office-substitute-tooltip')}>
                        <div className="out-of-office-tab__icon-wrapper">
                            <NdsIconFont
                                size={SizesEnums.SMALL}
                                className="out-of-office-tab__info-icon"
                                fontName="fa-solid-info-circle"
                            />
                            <div title={'hidden'} className="out-of-office-tab__hover-div"></div>
                        </div>
                    </Tooltip>
                </div>
                <div className={'out-of-office-tab__substitute-config'}>
                    {!isEditMode && substitutes.map((sub) =>
                        <SubtituteItem
                            key={sub.contactId}
                            substitute={sub}
                            handleEditSubstitute={handleEditSubstitute}
                            handleDeleteSubstitute={handleDeleteSubstitute}
                        />)}

                    {showSubPanel && checkIfRoleAlreadyTaken().length !== 4 &&
                        <SubtitutePanel
                            extractFormValues={extractFormValues}
                            registerCheckbox={registerCheckbox}
                            isOutOfOfficeEnabled={isOutOfOfficeEnabled}
                            contactListOnChange={contactListOnChange}
                            selectedContact={selectedContact}
                            checkIfRoleAlreadyTaken={checkIfRoleAlreadyTaken}
                            handleRemove={handleRemove}
                            handleCancel={handleCancel}
                            handleAddSub={handleAddSub}
                            fetchData={fetchData}
                            setSelectedContact={setSelectedContact}
                        />}
                    {!showSubPanel && checkIfRoleAlreadyTaken().length !== 4 && <Button
                        className='out-of-office-tab__add-button'
                        onClick={(e) => handleNewSubstitute(e)}
                        svgIcon={plusIcon}>
                        {t('out-of-office-add-substitute-button')}
                    </Button>}
                </div>
            </div>
        </div>
    );
};

export default OutOfOfficeTab;
