import { DocumentGroupPermission } from '~constants';

export const hardCodedPermissions = [
    {
        hasPermission: false,
        permissionId: 1,
        permission: DocumentGroupPermission.Upload,
    },
    {
        hasPermission: false,
        permissionId: 2,
        permission: DocumentGroupPermission.Sign,
    },
    {
        hasPermission: false,
        permissionId: 4,
        permission: DocumentGroupPermission.Download,
    },
    {
        hasPermission: false,
        permissionId: 8,
        permission: DocumentGroupPermission.Delete,
    },
    {
        hasPermission: false,
        permissionId: 16,
        permission: DocumentGroupPermission.View,
        relatedIds: ['Upload', 'Download', 'Delete'],
    },
    {
        hasPermission: false,
        permissionId: 32,
        permission: DocumentGroupPermission.Audit,
        relatedIds: ['Upload', 'Download'],
    },
    {
        hasPermission: false,
        permissionId: 64,
        permission: DocumentGroupPermission.ViewTemplate,
    },
    {
        hasPermission: false,
        permissionId: 128,
        permission: DocumentGroupPermission.CreateTemplate,
    },
    {
        hasPermission: false,
        permissionId: 256,
        permission: DocumentGroupPermission.DeleteTemplate,
    },
];
