import { Button } from '@progress/kendo-react-buttons';
import { AutoCompleteChangeEvent } from '@progress/kendo-react-dropdowns';
import { PanelBar, PanelBarItem, PanelBarSelectEventArguments } from '@progress/kendo-react-layout';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType, IS_DEVELOPMENT_ENV } from '~constants';
import {
    useAddActorBulk,
    useAddStakeholder,
    useFlowContext,
    useFlowState,
    useGetStakeholderInfo,
    useGetStakeholderInfoCallback,
    useMoveActor,
    useMoveActorElement,
    useRefreshStakeholders,
    useUndecidedStakeholderId,
} from '~contexts/flow/flow.hooks';
import { FlowGroupType, FlowType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import {
    ContactBucketItemModel,
    FlowActorCommonModel,
    FlowActorModel,
    FlowApproverModel,
    FlowFormFillerModel,
    FlowSignerModel,
    Guid,
    Offset,
    StakeholderType,
} from '~models';
import FlowEditorAddRecipientPanel
    from './flow-editor-add-recipient-panel';
import FlowEditorLeftPaneSearch from '../flow-editor-left-pane-search';
import FlowEditorActor, { FlowRecipientDragData } from './flow-editor-actor';
import FlowEditorRecipientsReceivers
    from './flow-editor-recipients-receivers';
import FlowEditorUnassignedRecipients
    from './flow-editor-unassigned-recipients';
import './flowEditorRecipients.scss';

export interface FlowEditorRecipientsRef {
    expandStep: (dragGroup: FlowGroupType, index: number) => void;
}

export interface FlowEditorRecipientsProps {
}

export type LocalFormFillerModel = FlowFormFillerModel & {
    approverActor?: FlowApproverModel;
}

function sortActors<T extends FlowActorCommonModel>(getStakeholderInfo: useGetStakeholderInfoCallback, step: T[]) {
    return step.sort((a, b) => {
        const sA = getStakeholderInfo(a.stakeholderLocalId);
        const sB = getStakeholderInfo(b.stakeholderLocalId);

        if (!sA) {
            return -1;
        }
        if (!sB) {
            return 1;
        }
        const nameA = sA.type === StakeholderType.Person ? (sA.firstName || '' + sA.lastName).toLowerCase() : sA.name.toLowerCase();
        const nameB = sB.type === StakeholderType.Person ? (sB.firstName || '' + sB.lastName).toLowerCase() : sB.name.toLowerCase();

        return nameA < nameB ? -1 : 1;
    });
}

function filterNamesPredicate(actor: FlowActorModel | LocalFormFillerModel, search: string | undefined, getStakeholderInfo: useGetStakeholderInfoCallback) {
    if (!search || !search.length) {
        return true;
    }
    const s = getStakeholderInfo(actor.stakeholderLocalId) as any;

    const str = s ? ((s.firstName ?? '').trim() + (s.lastName ?? '').trim() + (s.name ?? '').trim()).toLowerCase() : '';

    return str.search(search) > -1;
}

function FlowEditorRecipients(props: FlowEditorRecipientsProps) {
    const { t } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');
    const { flow } = useFlowState();
    const { flowType, recipientsTabRef } = useFlowContext();
    const [filteredNames] = useState<string[]>([]);
    const [filterValue, setFilterValue] = useState<string>();
    const onSearchChange = useCallback((event: AutoCompleteChangeEvent) => {
        if (!event.value) {
            setFilterValue(undefined);
        } else {
            setFilterValue(event.value.toLowerCase());
        }
    }, []);

    const getStakeholderInfo = useGetStakeholderInfo();
    const [addRecipientType, setAddRecipientType] = useState<'ffas' | 'r'>();

    const addStakeholderCallback = useAddStakeholder();
    const refreshStakeholdersCallback = useRefreshStakeholders();
    const addActorBulkCallback = useAddActorBulk();
    const undecidedId = useUndecidedStakeholderId();

    const addRecipientCallback = useCallback(async (refreshStakeholder: boolean, contacts?: ContactBucketItemModel[], hidden = false) => {
        let stakeholderLocalIds = [undecidedId];

        if (contacts?.length) {
            stakeholderLocalIds = await Promise.all(
                contacts.map(contact => addStakeholderCallback(contact, hidden).catch(() => undefined)),
            );

            if (stakeholderLocalIds.some(el => !el)) {
                // TODO show notification about errors
            }
        }
        setAddRecipientType(undefined);

        if (refreshStakeholder) {
            await refreshStakeholdersCallback();
        }

        return stakeholderLocalIds.filter(el => !!el);
    }, [addStakeholderCallback, refreshStakeholdersCallback, undecidedId]);

    const addReceiverCallback = useCallback(async (refreshStakeholder: boolean, contacts?: ContactBucketItemModel[]) => {
        const stakeholderLocalIds = (await addRecipientCallback(false, contacts, true))
            .filter(el => el !== undefined && el !== undecidedId) as string[];

        addActorBulkCallback(ActorType.Receiver, stakeholderLocalIds, 'last');

        if (refreshStakeholder) {
            await refreshStakeholdersCallback();
        }
        setExpandedR(true);
    }, [addActorBulkCallback, addRecipientCallback, refreshStakeholdersCallback, undecidedId]);

    const [expandedFfa, setExpandedFfa] = useState<string[]>([]);
    const [expandedS, setExpandedS] = useState<string[]>([]);
    const [expandedR, setExpandedR] = useState<boolean>(false);
    const isInitialized = useRef(false);
    const handleExpandedFfaChange = useCallback((event: PanelBarSelectEventArguments) => {
        if (event.expandedItems) {
            setExpandedFfa(event.expandedItems);
        }
    }, []);
    const handleExpandedSChange = useCallback((event: PanelBarSelectEventArguments) => {
        if (event.expandedItems) {
            setExpandedS(event.expandedItems);
        }
    }, []);

    // ===================== PARSE FLOW TO GROUPS & STEPS

    const { ffa, s, r } = useMemo(() => {
        const initiallyOpenedFfaSteps: string[] = [];
        const initiallyOpenedSSteps: string[] = [];

        const ffa: (LocalFormFillerModel | FlowApproverModel)[][] = [];
        const s: FlowSignerModel[][] = [];
        let r = false;
        let si = 0;
        let fi = 0;
        for (const group of flow) {
            if (!group.length) {
                continue;
            }
            switch (group[0].actorType) {
                case ActorType.Approver:
                case ActorType.FormFiller: {
                    initiallyOpenedFfaSteps.push(`recipients-ffa-${fi}`);
                    fi++;
                    const cloned = cloneDeep(group) as (LocalFormFillerModel | FlowApproverModel)[];

                    ffa.push(sortActors(getStakeholderInfo, cloned.filter((el) => filterNamesPredicate(el, filterValue, getStakeholderInfo))));
                    break;
                }
                case ActorType.Signer: {
                    initiallyOpenedSSteps.push(`recipients-s-${si}`);
                    si++;

                    const cloned = cloneDeep(group) as FlowSignerModel[];

                    s.push(sortActors(getStakeholderInfo, cloned.filter((el) => filterNamesPredicate(el, filterValue, getStakeholderInfo))));
                    break;
                }
                case ActorType.Receiver: {
                    r = true;
                }
            }
        }
        for (let i = 0; i < ffa.length; i++) {
            const step = ffa[i];
            const approvers = step.filter((el) => el.actorType === ActorType.Approver) as FlowApproverModel[];
            const idsToFilterOut: Guid[] = [];
            for (const actor of step) {
                if (actor.actorType === ActorType.Approver) {
                    continue;
                }
                const found = approvers.find(el => el.stakeholderLocalId === actor.stakeholderLocalId);

                if (found) {
                    actor.approverActor = found;
                    idsToFilterOut.push(found.localId);
                }
            }
            ffa[i] = step.filter(el => !idsToFilterOut.includes(el.localId));
        }

        if (!isInitialized.current) {
            isInitialized.current = true;

            if (initiallyOpenedSSteps.length) {
                setExpandedS(initiallyOpenedSSteps);
            }
            if (initiallyOpenedFfaSteps.length) {
                setExpandedFfa(initiallyOpenedFfaSteps);
            }
            if (r) {
                setExpandedR(true);
            }
        }

        return {
            ffa,
            s,
            r,
        };
    }, [filterValue, flow, getStakeholderInfo]);


    const contentRef = useRef<HTMLDivElement>(null);
    const portalRef = useRef<HTMLDivElement>(null);

    const [draggingInGroup, setDraggingInGroup] = useState<FlowGroupType>();
    const [draggingFromStep, setDraggingFromStep] = useState<number>();
    const [draggingType, setDraggingType] = useState<'actor' | 'field'>();
    const [dragData, setDragData] = useState<FlowRecipientDragData>();
    const [draggedOverStep, setDraggedOverStep] = useState<string>();
    const [stepToExpand, setStepToExpand] = useState<string>();

    useEffect(() => {
        if (
            !draggedOverStep
            || (draggingInGroup === FlowGroupType.FormFillersApprovers && expandedFfa.includes(draggedOverStep))
            || (draggingInGroup === FlowGroupType.Signers && expandedS.includes(draggedOverStep))
        ) {
            return;
        }
        setStepToExpand(draggedOverStep);
        const timeout = setTimeout(() => {
            setStepToExpand(undefined);

            if (draggingInGroup === FlowGroupType.FormFillersApprovers) {
                setExpandedFfa((current) => [...current, draggedOverStep]);
            } else if (draggingInGroup === FlowGroupType.Signers) {
                setExpandedS((current) => [...current, draggedOverStep]);
            }
        }, 1000);

        return () => {
            setStepToExpand(undefined);
            clearTimeout(timeout);
        };
    }, [expandedS, expandedFfa, draggedOverStep, draggingInGroup]);

    // ========================================= STEPS EXPANDING CALLBACKS

    const shuffleExpandedStepsAfterMove = useCallback((dragGroup: FlowGroupType, fromStep: number, toStep: number, wasAlone: boolean) => {
        const callback = (value: string[]): string[] => {
            let newVal = [...value];

            if (wasAlone) { // reindex opened steps
                newVal = newVal.filter(el => el !== `recipients-${dragGroup}-${fromStep}`);
                const openedSteps = newVal.filter(el => el.startsWith(`recipients-${dragGroup}-`));
                for (const opened of openedSteps) {
                    const i = parseInt(opened.split('-')[2]);

                    if (isNaN(i) || i < fromStep) {
                        continue;
                    }
                    newVal = newVal.filter(el => el !== `recipients-${dragGroup}-${i}`);

                    if (!newVal.includes(`recipients-${dragGroup}-${i - 1}`)) {
                        newVal.push(`recipients-${dragGroup}-${i - 1}`);
                    }
                }
            }

            if (!newVal.includes(`recipients-${dragGroup}-${toStep}`)) {
                newVal.push(`recipients-${dragGroup}-${toStep}`);
            }

            return newVal;
        };

        if (dragGroup === FlowGroupType.FormFillersApprovers) {
            setExpandedFfa(callback);
        }
        if (dragGroup === FlowGroupType.Signers) {
            setExpandedS(callback);
        }
    }, []);

    // ========================================= MOVE CALLBACKS

    const moveActorCallback = useMoveActor();

    const moveActor = useCallback((dragData: FlowRecipientDragData, targetStep: number | 'new') => {
        const indexes = FlowUtils.findFlowActorGroupIndexes(flow, dragData.group);
        const fromIndex = dragData.step + indexes.first;

        let moveInfo: {
            wasAlone: boolean;
            groupIndex: number;
            groupOffset: number;
        } | null;

        let wasAlone = false;

        if (dragData.nestedApprover && dragData.nestedApprover.localId !== dragData.actor.localId) { // there is nested approver in actor and this approver is not actual actor
            wasAlone = flow[fromIndex].length === 2;
            const toNextStep = targetStep === 'new' || targetStep > dragData.step;

            if (fromIndex === indexes.last && targetStep === 'new' && wasAlone) {
                return; // moving formfiller and approver actor from last group to new group does nothing so do nothing
            }
            moveInfo = moveActorCallback(dragData.nestedApprover.localId, targetStep, true);

            if (!moveInfo) {
                return;
            }
            moveInfo = moveActorCallback(dragData.actor.localId, moveInfo.groupIndex - (toNextStep && wasAlone ? 1 : 0), false);

            if (!moveInfo) {
                return;
            }
        } else {
            if (fromIndex === indexes.last && targetStep === 'new' && flow[fromIndex].length === 1) {
                return; // moving single actor from last group to new group does nothing so do nothing
            }
            moveInfo = moveActorCallback(dragData.actor.localId, targetStep, true);

            if (!moveInfo) {
                return;
            }
            wasAlone = moveInfo?.wasAlone;
        }

        shuffleExpandedStepsAfterMove(dragData.group, dragData.step, moveInfo.groupIndex - moveInfo.groupOffset, wasAlone);
    }, [flow, moveActorCallback, shuffleExpandedStepsAfterMove]);

    const moveActorFieldCallback = useMoveActorElement();
    const moveField = useCallback((dragData: FlowRecipientDragData, targetStep: number | 'new') => {
        if (!dragData.fieldId) {
            throw new Error('missing fieldId in drag data');
        }
        const ret = dragData.fieldId && dragData.nestedApprover && dragData.fieldId === dragData.nestedApprover.localId
            ? moveActorCallback(dragData.nestedApprover.localId, targetStep, true)// dragging approver 'field' which is actually an actor
            : moveActorFieldCallback(dragData.actor.localId, dragData.fieldId, targetStep, true);

        if (!ret) {
            return;
        }

        shuffleExpandedStepsAfterMove(dragData.group, dragData.step, ret.groupIndex - ret.groupOffset, ret.wasAlone);
    }, [moveActorCallback, moveActorFieldCallback, shuffleExpandedStepsAfterMove]);

    // ========================================= DRAG HANDLERS

    const onDragStart = useCallback((group: FlowGroupType, step: number, type: 'actor' | 'field', data: any) => {
        setDraggingInGroup(group);
        setDraggingFromStep(step);
        setDraggingType(type);
        setDragData(data);
    }, []);

    const onDrag = useCallback(({ left, top }: Offset) => {
        if (!draggingInGroup) {
            return;
        }
        if (left === 0 && top === 0) {
            setDraggedOverStep(undefined);

            return;
        }
        const els = document.elementsFromPoint(left, top);
        for (const el of els) {
            if (el.id.startsWith(`recipients-${draggingInGroup}-`)) {
                setDraggedOverStep(el.id);
            }
        }
    }, [draggingInGroup]);
    const onDragEnd = useCallback(async () => {
        setDraggingInGroup(undefined);
        setDraggingFromStep(undefined);
        setDraggingType(undefined);
        setDragData(undefined);
        setStepToExpand(undefined);
        setDraggedOverStep(undefined);
        try {

            const target = draggedOverStep!.split('-')[2];
            const targetStep = target === 'drop' ? 'new' : parseInt(target);

            if (!dragData || dragData.step === targetStep) {
                return;
            }
            if (dragData?.dragType === 'actor') {
                moveActor(dragData, targetStep);
            } else {
                moveField(dragData, targetStep);
            }
        } catch (e) {
        }

    }, [dragData, draggedOverStep, moveField, moveActor]);

    // ===================== DRAG HANDLERS END

    useImperativeHandle(recipientsTabRef, () => {
        return {
            expandStep(dragGroup: FlowGroupType, index: number) {
                const callback = (current: string[]): string[] => {
                    const newVal = current.slice();

                    if (!newVal.includes(`recipients-${dragGroup}-${index}`)) {
                        newVal.push(`recipients-${dragGroup}-${index}`);
                    }

                    return newVal;
                };

                if (dragGroup === FlowGroupType.FormFillersApprovers) {
                    setExpandedFfa(callback);
                } else if (dragGroup === FlowGroupType.Signers) {
                    setExpandedS(callback);
                }
            },
        };
    }, []);

    const onAddReceiverClick = useCallback(() => setAddRecipientType('r'), []);

    return (
        <div className={'c-flow-editor-recipients c-flow-editor-left-pane-component'}>
            <div className={'c-flow-editor-left-pane-component__search'}>
                <FlowEditorLeftPaneSearch
                    disabled={!s.length && !ffa.length && !r}
                    data={filteredNames}
                    placeholder={t('search-recipients')}
                    onChange={onSearchChange}
                />
            </div>

            <div className={'c-flow-editor-recipients__add'}>
                <Typography
                    token={TypographyToken.DesktopDescriptionBoldMd}
                    text={t('build-your-workflow-title')}
                />
                <Typography
                    token={TypographyToken.DesktopDescriptionSm}
                    text={t('build-your-workflow-description')}
                />
                <Button
                    themeColor={'secondary'}
                    className={'c-flow-editor-left-pane-component__add-btn'}
                    onClick={() => setAddRecipientType('ffas')}
                >{t('add-recipient-btn')}</Button>
            </div>
            <div
                className={'c-flow-editor-recipients__content'}
                ref={contentRef}
            >
                <FlowEditorUnassignedRecipients
                    filter={filterValue}
                    portalContainer={portalRef.current}
                    paneContainer={contentRef.current}
                />
                {ffa.length > 0 && (
                    <PanelBar
                        className={classNames('c-flow-editor-recipients__group c-flow-editor-recipients__group--ffa', {
                            'c-flow-editor-recipients__group--dragging-in': draggingInGroup === FlowGroupType.FormFillersApprovers,
                            'c-flow-editor-recipients__group--dragging-out': draggingInGroup && draggingInGroup !== FlowGroupType.FormFillersApprovers,
                            'c-flow-editor-recipients__group--all-collapsed': !expandedFfa.length || (expandedFfa.length === 1 && expandedFfa[0] === 'recipients-ffa-drop'),
                        })}
                        isControlled
                        onSelect={handleExpandedFfaChange}
                        expanded={expandedFfa}
                    >
                        {ffa.reduce((acc: ReactNode[], group, idx) => {
                            const container = contentRef.current?.querySelector<HTMLElement>('.c-flow-editor-recipients__group--ffa');
                            const id = `recipients-ffa-${idx}`;

                            acc.push(
                                <PanelBarItem
                                    id={id}
                                    key={idx}
                                    itemType={'step'}
                                    className={classNames('c-flow-editor-recipients__step', { 'c-flow-editor-recipients__step--to-expand': stepToExpand === id })}
                                    title={<Typography
                                        token={TypographyToken.DesktopTagXs}
                                        text={t('step-x', { x: idx + 1 }) + (IS_DEVELOPMENT_ENV ? `; gIdx= ${idx}; absIdx= ${idx}` : '')}
                                    />}
                                >
                                    {/* empty div is required so each element here won't be rendered as another nested panelbaritems */}
                                    <div>
                                        {group.map((el, sidx) => (
                                            <FlowEditorActor
                                                actor={el}
                                                group={FlowGroupType.FormFillersApprovers}
                                                step={idx}
                                                key={sidx}
                                                dragContainer={container}
                                                portalContainer={portalRef.current}
                                                paneContainer={contentRef.current}
                                                onDragStart={onDragStart}
                                                onDrag={onDrag}
                                                onDragEnd={onDragEnd}
                                            />
                                        ))}
                                        {draggedOverStep === id && idx !== draggingFromStep && (
                                            <div className={'c-flow-editor-recipients__step-drop-here'}>
                                                <Typography
                                                    token={TypographyToken.DesktopDescriptionBoldSm}
                                                    className={'c-flow-editor-recipients__step-drop-here-content'}
                                                    tagName={'div'}
                                                >
                                                    {draggingType === 'actor' && t('existing-step-drop-recipient-instruction')}
                                                    {draggingType === 'field' && t('existing-step-drop-element-instruction')}
                                                </Typography>
                                            </div>
                                        )}
                                        {!group.length && filterValue && filterValue.length > 0 && (
                                            <Typography
                                                token={TypographyToken.DesktopDescriptionSm}
                                                className={'c-flow-editor-recipients__step-no-results'}
                                                tagName={'div'}
                                                text={tBase('no-results')}
                                            />
                                        )}
                                    </div>
                                </PanelBarItem>,
                            );

                            if (idx === ffa.length - 1 && expandedFfa.findIndex(el => el.startsWith('recipients-ffa-') && el !== 'recipients-ffa-drop') > -1) {
                                acc.push(
                                    <PanelBarItem
                                        key={'recipients-ffa-drop'}
                                        id={'recipients-ffa-drop'}
                                        className={classNames('c-flow-editor-recipients__droparea', { 'c-flow-editor-recipients__droparea--active': 'recipients-ffa-drop' === draggedOverStep })}
                                        itemType={'droparea'}
                                        title={(
                                            <div className={'c-flow-editor-recipients__droparea-content'}>
                                                <i className={'fa-solid fa-plus'} />
                                                <Typography
                                                    token={TypographyToken.UiMenuXs}
                                                    text={t('new-step-dropzone-instruction')}
                                                />
                                            </div>
                                        )}
                                    />,
                                );
                            }

                            return acc;
                        }, [])}
                    </PanelBar>
                )}
                {s.length > 0 && (
                    <PanelBar
                        className={classNames('c-flow-editor-recipients__group c-flow-editor-recipients__group--s', {
                            'c-flow-editor-recipients__group--dragging-in': draggingInGroup === FlowGroupType.Signers,
                            'c-flow-editor-recipients__group--dragging-out': draggingInGroup && draggingInGroup !== FlowGroupType.Signers,
                            'c-flow-editor-recipients__group--all-collapsed': !expandedS.length || (expandedS.length === 1 && expandedS[0] === 'recipients-s-drop'),
                        })}
                        isControlled
                        onSelect={handleExpandedSChange}
                        expanded={expandedS}
                    >
                        {s.reduce((acc: ReactNode[], group, idx) => {
                            const container = contentRef.current?.querySelector<HTMLElement>('.c-flow-editor-recipients__group--s');

                            const id = `recipients-s-${idx}`;

                            acc.push(
                                <PanelBarItem
                                    id={id}
                                    key={idx}
                                    itemType={'step'}
                                    className={classNames('c-flow-editor-recipients__step', { 'c-flow-editor-recipients__step--to-expand': stepToExpand === id })}
                                    title={<Typography
                                        token={TypographyToken.DesktopTagXs}
                                        text={t('step-x', { x: idx + 1 + ffa.length }) + (IS_DEVELOPMENT_ENV ? `; gIdx= ${idx}; absIdx= ${idx + ffa.length}` : '')}
                                    />}
                                >
                                    {/* empty div is required so each element here won't be rendered as another nested panelbaritems */}
                                    <div>
                                        {group.map((el, sidx) => (
                                            <FlowEditorActor
                                                actor={el}
                                                group={FlowGroupType.Signers}
                                                step={idx}
                                                key={sidx}
                                                dragContainer={container}
                                                portalContainer={portalRef.current}
                                                paneContainer={contentRef.current}
                                                onDragStart={onDragStart}
                                                onDrag={onDrag}
                                                onDragEnd={onDragEnd}
                                            />
                                        ))}
                                        {draggedOverStep === id && idx !== draggingFromStep && (
                                            <div className={'c-flow-editor-recipients__step-drop-here'}>
                                                <div className={'c-flow-editor-recipients__step-drop-here-content'}>
                                                    {draggingType === 'actor' && 'Drop here to move recipient to this step'}
                                                    {draggingType === 'field' && 'Drop here to move element to this step'}
                                                </div>
                                            </div>
                                        )}
                                        {!group.length && filterValue && filterValue.length > 0 && (
                                            <Typography
                                                token={TypographyToken.DesktopDescriptionSm}
                                                className={'c-flow-editor-recipients__step-no-results'}
                                                tagName={'div'}
                                                text={tBase('no-results')}
                                            />
                                        )}
                                    </div>
                                </PanelBarItem>,
                            );

                            if (idx === s.length - 1 && expandedS.findIndex(el => el.startsWith('recipients-s-') && el !== 'recipients-s-drop') > -1) {
                                acc.push(
                                    <PanelBarItem
                                        key={'recipients-s-drop'}
                                        id={'recipients-s-drop'}
                                        className={classNames('c-flow-editor-recipients__droparea', { 'c-flow-editor-recipients__droparea--active': 'recipients-s-drop' === draggedOverStep })}
                                        itemType={'droparea'}
                                        title={(
                                            <div className={'c-flow-editor-recipients__droparea-content'}>
                                                <i className={'fa-solid fa-plus'} />
                                                <Typography
                                                    token={TypographyToken.UiMenuXs}
                                                    text={t('new-step-dropzone-instruction')}
                                                />
                                            </div>
                                        )}
                                    />,
                                );
                            }

                            return acc;
                        }, [])}
                    </PanelBar>
                )}
                <div className={'c-flow-editor-recipients__portal'} ref={portalRef}></div>
            </div>
            <div className={'c-flow-editor-left-pane-component__footer'}>
                <FlowEditorRecipientsReceivers
                    filter={filterValue}
                    onAddClick={onAddReceiverClick}
                    expanded={expandedR}
                    setExpanded={setExpandedR}
                />
            </div>
            <OverlayPortal type={'panel'} visible={!!addRecipientType}>
                {() => <FlowEditorAddRecipientPanel
                    addReceiver={addRecipientType === 'r'}
                    allowMultiple={true}
                    onCloseClick={() => setAddRecipientType(undefined)}
                    addClickCallback={addRecipientType === 'ffas' ? addRecipientCallback : addReceiverCallback}
                    canAddRecipientWithoutContactInfo={flowType === FlowType.Template}
                />}
            </OverlayPortal>
        </div>
    );
}

export default FlowEditorRecipients;
