import { Button, SplitButton } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { useCallback, useMemo, useState } from 'react';
import { PackageApi } from '~api/package.api';
import { PortalsApi } from '~api/portals.api';
import ButtonWithLoader from '~components/button-with-loader';
import FlowActionNeededModal from '~components/flow/flow-action-needed-modal';
import FlowEditorWrapper from '~components/flow/flow-editor/flow-editor-wrapper';
import FlowViewWrapper from '~components/flow/flow-view-wrapper';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType, DocumentGroupPermission } from '~constants';
import { FieldType } from '~constants/field-type';
import { useApi } from '~contexts/api';
import { useHasDocumentGroupPermissionFunc } from '~contexts/auth';
import { useFlowContext } from '~contexts/flow';
import { useSaveFlowDetails, useSaveFlowWorkflow } from '~contexts/flow/flow.hooks/save.flow.hooks';
import { FlowDetailsValidator, FlowFieldValidator, FlowStakeholderValidator } from '~contexts/flow/flow.types';
import { FlowValidator } from '~contexts/flow/flow.validator';
import {
    DetailsValidators,
    FieldValidators as FieldValidatorList,
    StakeholderValidators as StakeholderValidatorList,
} from '~contexts/flow/flow.validators';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useRedirectFunc } from '~hooks/redirect';
import { UrlUtil } from '~lib';
import SetTemplateNameModal from '~views/flow/package-editor/set-template-name-modal';
import './package-editor.scss';

const PackageValidators: FlowDetailsValidator[] = [DetailsValidators.callbackUrl, DetailsValidators.notificationCallbackUrl, DetailsValidators.redirectUrl];
const RecipientValidators: FlowStakeholderValidator[] = [StakeholderValidatorList.smsAuthenticationPhoneRequired, StakeholderValidatorList.unassignedToAnyField];
const FieldValidators: FlowFieldValidator[] = [
    FieldValidatorList.fieldMustBePlaced,
    FieldValidatorList.recipientsPhoneNumberRequired,
    FieldValidatorList.signingFieldOnDocWithSigningFields,
    FieldValidatorList.uniqueNameRequired,
    FieldValidatorList.signingTypeSelectionRequired,
    FieldValidatorList.nameUniqueInDocument,
];

export default function PackageEditorView() {
    const context = useFlowContext();
    const {
        isActionNeeded,
        setProcessing,
        isProcessing,
        navigateToPortal,
        leftPaneRef,
        flowState: { flowId, packageName, elements, properties, flow },
    } = context;
    const { t } = useTranslation('flow');
    const packageApi = useApi(PackageApi);
    const portalsApi = useApi(PortalsApi);

    const showNotification = useShowNotification();

    const onCloseClick = useCallback(() => {
        navigateToPortal();
    }, [navigateToPortal]);

    const [showTemplateNameModal, setShowTemplateNameModal] = useState(false);

    const hasDocGroupPerm = useHasDocumentGroupPermissionFunc();

    const {
        confirmButtonEnabled,
        confirmAndSignBtnEnabled,
    } = useMemo(() => {
        if (context.hasErrors) {
            return {
                confirmButtonEnabled: false,
                confirmAndSignBtnEnabled: false,
            };
        }
        let confirmButtonEnabled = true;
        let confirmAndSignBtnEnabled = true;

        if (elements.some(el => el.type !== FieldType.SigningField)) {
            confirmAndSignBtnEnabled = false;
        }
        if (
            !elements.length
            || elements.some(el =>
                (el.type !== FieldType.RadioGroup && !el.location)
                || (el.type === FieldType.RadioGroup && el.options.some((e) => !e.location)))
        ) {
            confirmButtonEnabled = false;
            confirmAndSignBtnEnabled = false;
        } else if (!flow.length) {
            confirmButtonEnabled = false;
            confirmAndSignBtnEnabled = false;
        } else if (flow[0][0]?.actorType !== ActorType.Signer) {
            confirmAndSignBtnEnabled = false;
        }
        if (confirmAndSignBtnEnabled) {
            // TODO find if there is server signing selected
        }

        return {
            confirmButtonEnabled,
            confirmAndSignBtnEnabled,
        };
    }, [context.hasErrors, elements, flow]);

    const { saveAsTemplateButtonVisible, confirmAndSignButtonVisible } = useMemo(() => {
        return {
            saveAsTemplateButtonVisible: properties && hasDocGroupPerm(properties.documentGroupId, DocumentGroupPermission.CreateTemplate),
            confirmAndSignButtonVisible: properties && hasDocGroupPerm(properties.documentGroupId, DocumentGroupPermission.Sign),
        };
    }, [properties, hasDocGroupPerm]);

    const saveWorkflow = useSaveFlowWorkflow();
    const saveDetails = useSaveFlowDetails();

    const saveData = useCallback(async () => {
        try {
            setProcessing(true);
            await saveDetails();
            await saveWorkflow();

            return true;
        } catch (e) {
            setProcessing(false);
            leftPaneRef.current?.goToTab('errors');
            showNotification({
                type: 'error',
                title: 'Failed to save package',
                message: 'Errors occurred while saving package data',
                hideAfterMs: 5000,
            });

            return false;
        }
    }, [
        leftPaneRef,
        saveDetails,
        saveWorkflow,
        setProcessing,
        showNotification,
    ]);

    const onSaveDraftClick = useCallback(async () => {
        if (await saveData()) {
            showNotification({
                type: 'success',
                title: 'Package saved',
                hideAfterMs: 5000,
            });
            navigateToPortal();
        }
    }, [navigateToPortal, saveData, showNotification]);

    const onSaveAsTemplateClick = useCallback(async () => {
        try {
            if (await saveData()) {
                setShowTemplateNameModal(true);
            }
        } catch (e) {
            console.warn('Some errors here, there were');
        } finally {
            setProcessing(false);
        }
    }, [saveData, setProcessing]);

    const createTemplate = useCallback(async (templateName: string) => {
        return packageApi.createTemplateFromPackage({
            flowId: flowId!,
            templateName,
        });
    }, [flowId, packageApi]);

    const onConfirmClick = useCallback(async () => {
        if (await saveData()) {
            try {
                await packageApi.submitPackage({ flowId: flowId! });
                navigateToPortal();
            } catch (e) {
                showNotification({
                    type: 'error',
                    title: 'Failed to submit package',
                    message: 'Errors occurred while sending package',
                    hideAfterMs: 5000,
                });
                leftPaneRef.current?.goToTab('errors');
                setProcessing(false);
            }
        }
    }, [
        flowId,
        leftPaneRef,
        navigateToPortal,
        packageApi,
        saveData,
        setProcessing,
        showNotification,
    ]);

    const redirectFunc = useRedirectFunc();

    const onConfirmAndSignClick = useCallback(async () => {
        if (await saveData()) {
            try {
                await packageApi.submitPackage({ flowId: flowId! });

                const packageDetails = await portalsApi.getPackageDetails({ packageId: flowId! });

                if (packageDetails.signers.length === 0) { // some extra check
                    return;
                }
                const params = {
                    packageSignId: packageDetails.packageId,
                    token: packageDetails.token,
                    expiryTag: packageDetails.expiryTag,
                    f2f: 'True',
                };

                redirectFunc(`/signinit?${UrlUtil.buildQueryParams(params)}`);
                navigateToPortal();
            } catch (e) {
                showNotification({
                    type: 'error',
                    title: 'Failed to submit package',
                    message: 'Errors occurred while sending package',
                    hideAfterMs: 5000,
                });
                leftPaneRef.current?.goToTab('errors');
                setProcessing(false);
            }
        }
    }, [
        flowId,
        leftPaneRef,
        navigateToPortal,
        packageApi,
        portalsApi,
        redirectFunc,
        saveData,
        setProcessing,
        showNotification,
    ]);

    return (
        <FlowViewWrapper
            scrollable
            blurred={isActionNeeded}
            className={'v-package-editor'}
            headerCenter={
                isActionNeeded ?
                    <Skeleton shape={'text'} animation={{ type: 'wave' }} style={{ width: '10rem' }} />
                    : <Typography token={TypographyToken.UiButtonsSm} text={packageName} />
            }
            onCloseClick={onCloseClick}
            footer={(
                <div className={'v-package-editor__footer'}>
                    {saveAsTemplateButtonVisible
                        ? (
                            <SplitButton
                                fillMode={'outline'}
                                themeColor={'secondary'}
                                text={t('save-draft-btn')}
                                disabled={isProcessing || context.hasErrors}
                                size={'medium'}
                                items={[t('save-as-template-btn')]}
                                onButtonClick={onSaveDraftClick}
                                onItemClick={onSaveAsTemplateClick}
                            />
                        )
                        : (
                            <Button
                                fillMode={'outline'}
                                themeColor={'secondary'}
                                disabled={isProcessing || context.hasErrors}
                                size={'medium'}
                                onClick={onSaveDraftClick}
                            >{t('save-draft-btn')}</Button>
                        )}
                    <div className={'v-package-editor__footer-group'}>
                        <ButtonWithLoader
                            showLoader={isProcessing}
                            themeColor={'primary'}
                            size={'medium'}
                            disabled={!confirmButtonEnabled}
                            onClick={onConfirmClick}
                        >{t('confirm-btn')}</ButtonWithLoader>
                        {confirmAndSignButtonVisible && (
                            <ButtonWithLoader
                                showLoader={isProcessing}
                                themeColor={'primary'}
                                disabled={!confirmAndSignBtnEnabled}
                                size={'medium'}
                                onClick={onConfirmAndSignClick}
                            >{t('confirm-and-sign-btn')}</ButtonWithLoader>
                        )}
                    </div>
                </div>
            )}
        >
            <FlowEditorWrapper />
            <OverlayPortal type={'modal'} visible={isActionNeeded}>
                {({ close }) => (
                    <FlowActionNeededModal
                        onCloseClick={() => {
                            close();
                            onCloseClick();
                        }}
                        context={context}
                    />
                )}
            </OverlayPortal>
            <OverlayPortal type={'modal'} visible={showTemplateNameModal}>
                {({ close }) => (
                    <SetTemplateNameModal
                        onClose={() => {
                            close();
                            setShowTemplateNameModal(false);
                        }}
                        onConfirm={async (name: string) => {
                            await createTemplate(name);
                            setShowTemplateNameModal(false);
                        }}
                        initialValue={packageName ?? ''}
                    />
                )}
            </OverlayPortal>
            <FlowValidator
                detailsValidators={PackageValidators}
                stakeholderValidators={RecipientValidators}
                fieldValidators={FieldValidators}
            />
        </FlowViewWrapper>
    );
}
