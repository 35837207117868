import { FlowError } from '~contexts/flow/flow.error';
import { FlowDetailsValidator, FlowValidationResult } from '~contexts/flow/flow.types';
import { ValidateUtils } from '~lib';

export const packageName: FlowDetailsValidator = ({ packageName }, { settings }) => {
    if (!packageName?.length) {
        return {
            error: FlowError.PackageNameCannotBeEmpty,
            result: FlowValidationResult.Error,
        };
    }
    if (packageName && packageName.length > 150) {
        return {
            error: FlowError.PackageNameBetweenLength,
            result: FlowValidationResult.Error,
        };
    }
    // if () {
    // TODO invalid characters
    // }

    return { result: FlowValidationResult.Valid };
};

export const redirectUrl: FlowDetailsValidator = ({ properties }, { settings }) => {
    if (settings.customizationSettings.isRedirectUrlVisibleInFrontend && properties?.isDefaultRedirectUrlEnabled) {
        if (!properties.defaultRedirectUrl?.length) {
            return {
                error: FlowError.PackageRedirectUrlValidUrl,
                result: FlowValidationResult.Error,
            };
        }
        if (!ValidateUtils.url(properties.defaultRedirectUrl)) {
            return {
                error: FlowError.PackageRedirectUrlValidUrl,
                result: FlowValidationResult.Error,
            };
        }
    }

    return { result: FlowValidationResult.Valid };
};
export const callbackUrl: FlowDetailsValidator = ({ properties }, { settings }) => {
    if (settings.customizationSettings.isCallbackUrlVisibleInFrontend && properties?.isCallbackUrlEnabled) {
        if (!properties.callbackUrl?.length) {
            return {
                error: FlowError.PackageCallbackUrlValidUrl,
                result: FlowValidationResult.Error,
            };
        }
        if (!ValidateUtils.url(properties.callbackUrl)) {
            return {
                error: FlowError.PackageCallbackUrlValidUrl,
                result: FlowValidationResult.Error,
            };
        }
    }

    return { result: FlowValidationResult.Valid };
};
export const notificationCallbackUrl: FlowDetailsValidator = ({ properties }, { settings }) => {
    if (settings.customizationSettings.isNotificationCallbackUrlVisibleInFrontend && properties?.isNotificationCallbackUrlEnabled) {
        if (!properties.notificationCallbackUrl?.length) {
            return {
                error: FlowError.PackageNotificationUrlValidUrl,
                result: FlowValidationResult.Error,
            };
        }
        if (!ValidateUtils.url(properties.notificationCallbackUrl)) {
            return {
                error: FlowError.PackageNotificationUrlValidUrl,
                result: FlowValidationResult.Error,
            };
        }

    }

    return { result: FlowValidationResult.Valid };
};

// export const validator: FlowPackageValidator = ({ packageName }, { settings }) => {
//
//     return { result: FlowValidationResult.Valid };
// };
