import { NdsGroup, NdsIconFont, NdsInput, NdsSelect, NdsSelectOption } from '@gonitro/rcl';
import { NdsInputTypes, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { DropDownList, DropDownListChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { cloneElement, ReactElement, useState } from 'react';
import CountryPrefixFlag from '~components/country-prefix-flag';
import PanelDropdown from '~components/panel-dropdown';
import Typography, { TypographyToken } from '~components/typography';
import { countryInfoList } from '~constants/country-info';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { useEnhancedFormContext } from '~hooks/enhanced-form/enhanced-form-context';
import { ValidateUtils } from '~lib';
import { CountryInfo } from '~models';
import './optionalFields.scss';

export interface OptionalFieldsProps {
    contactCountryInfo: CountryInfo | undefined;
    viewMode?: boolean;
    onPhoneNumberCountryChange: (countryInfo: CountryInfo | undefined) => void;
}

function OptionalFields({ contactCountryInfo, viewMode, onPhoneNumberCountryChange }: OptionalFieldsProps) {
    const { registerNdsInput, registerNdsSelect } = useEnhancedFormContext();
    const { t } = useTranslation('contacts');
    const { availableLanguages } = useLanguages();
    const [phoneNumberCountry, setPhoneNumberCountry] = useState({ value: contactCountryInfo });

    //Used any as in Kendo docs
    const valueRender = (element: ReactElement<HTMLSpanElement>, value: any) => {
        if (!value) {
            return element;
        }

        //Used any as in Kendo docs
        const children = [
            <span key={2} className='c-optional-fields__dropdown-flag'>
                <CountryPrefixFlag countryCode={value.countryCode} />
                {element.props.children as any}
            </span>,
        ];

        return cloneElement(element, { ...element.props }, children);
    };

    const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const itemChildren = (
            <span>
                <CountryPrefixFlag countryCode={itemProps.dataItem.countryCode} /> {li.props.children as any}
            </span>
        );

        return cloneElement(li, li.props, itemChildren);
    };

    const handleChange = (event: DropDownListChangeEvent) => {
        const newValue = event.target.value;

        setPhoneNumberCountry({ value: newValue });
        onPhoneNumberCountryChange(newValue);
    };

    return (
        <div className={'c-optional-fields'}>
            <PanelDropdown headerText={t('optional-fields-title')}>
                <div className={'c-optional-fields__wrapper'}>
                    <NdsInput
                        {...registerNdsInput('title')}
                        label={t('personal-title-label')}
                        size={SizesEnums.SMALL}
                        inputType={NdsInputTypes.TEXT}
                        state={viewMode? StatesEnum.DISABLED: StatesEnum.DEFAULT}
                    />
                    {/* TODO Datepicker */}
                    <NdsInput
                        {...registerNdsInput('birthDate', {
                            pattern: {
                                value: ValidateUtils.dateRegex,
                                message: 'invalid-date',
                            },
                        })}
                        label={t('date-of-birth-label')}
                        leftIcon='fa-regular-calendar'
                        placeholder='dd/mm/yyyy'
                        {...(viewMode ? { state: StatesEnum.DISABLED } : {})}
                        size={SizesEnums.SMALL}
                        inputType={NdsInputTypes.TEXT}
                    />
                    <div>
                        <Typography token={TypographyToken.UiFormsLabelSm} text={t('phone-number-label')} />
                        <div className={'c-optional-fields__phone-number-wrapper'}>
                            <div>
                                <DropDownList
                                    disabled={viewMode}
                                    data={countryInfoList}
                                    valueRender={valueRender}
                                    dataItemKey='countryCode'
                                    textField='phonePrefix'
                                    value={phoneNumberCountry.value}
                                    itemRender={itemRender}
                                    onChange={handleChange}
                                    className={'c-optional-fields__dropdown-prefix'}
                                />
                            </div>
                            <div className={'c-optional-fields__phone-number-input'}>
                                <NdsInput
                                    {...registerNdsInput('phoneNumber')}
                                    size={SizesEnums.SMALL}
                                    inputType={NdsInputTypes.TEXT}
                                    state={viewMode? StatesEnum.DISABLED: StatesEnum.DEFAULT}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'c-optional-fields__language-select'}>
                        <NdsSelect
                            {...registerNdsSelect('language')}
                            size={SizesEnums.SMALL}
                            label={t('preferred-language-label')}
                            content-align='bottom-left'
                            className={viewMode? 'c-optional-fields__disabled': ''}
                            state={viewMode? StatesEnum.DISABLED: StatesEnum.DEFAULT}
                        >
                            <NdsGroup>
                                {availableLanguages.map((lang) => (
                                    <NdsSelectOption
                                        key={lang.isoCultureCode}
                                        label={lang.nativeName}
                                        value={lang.isoCultureCode}
                                    />
                                ))}
                            </NdsGroup>
                        </NdsSelect>
                    </div>
                    <div className={'c-optional-fields__external-reference'}>
                        <Typography
                            token={TypographyToken.DesktopBylineSm}
                            text={t('external-reference-label')}
                            className={viewMode? 'c-optional-fields__disabled': ''}
                            tagName='div'
                        />
                        <Tooltip
                            anchorElement='target'
                            position='left'
                            parentTitle={true}
                            className={'c-optional-fields__external-reference-tooltip'}
                        >
                            <div title={t('external-reference-tooltip')}>
                                <NdsIconFont
                                    size={SizesEnums.SMALL}
                                    fontName='fa-solid-info-circle'
                                    className={'c-optional-fields__info-icon'}
                                />
                            </div>
                        </Tooltip>
                    </div>
                    <NdsInput
                        {...registerNdsInput('externalReference')}
                        size={SizesEnums.SMALL}
                        inputType={NdsInputTypes.TEXT}
                        state={viewMode? StatesEnum.DISABLED: StatesEnum.DEFAULT}
                    />
                </div>
            </PanelDropdown>
        </div>
    );
}

export default OptionalFields;
