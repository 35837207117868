import { NdsMenuItem } from '@gonitro/rcl';
import { BkgModesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { SplitButton, SplitButtonClickEvent, SplitButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { useCallback, useMemo, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import Loader from '~components/loader';
import PackageDetailsHeader from '~components/package-details-overview-panel/package-details-header';
import QuickActionsModal from '~components/quick-actions-modal';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { FlowAction, FlowActionI18n, FlowStatusEnum } from '~constants';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { PanelPortal } from '~contexts/overlay/overlay.components/panelPortal.overlay.component';
import { useTableFocusedRow } from '~contexts/table';
import { useAsyncEffect } from '~hooks/effects';
import { useSignerQuickActions } from '~hooks/useSignerQuickActions';
import { PackageDocument } from '~models/package-document.model';
import { SigningSessionListItemModel, SigningSessionTableDataModel } from '~models/portals.models';
import './signer-portal-details-panel.scss';

export interface SignerPortalDetailsPanelProps {
}

const SignerPortalDetailsPanel = () => {
    const portalsApi = useApi(PortalsApi);
    const { t } = useTranslation('flow');
    const { focusedRow: packageData, setFocusedRow } = useTableFocusedRow<'packageId', SigningSessionListItemModel>();
    const [documents, setDocuments] = useState<PackageDocument[]>();
    const [quickActionModalData, setQuickActionModalData] = useState<SigningSessionTableDataModel | undefined>(undefined);

    const closeModal = () => {
        setQuickActionModalData(undefined);
    };
    const { runQuickActionClick, WarningMessageDialogI18n,  AdditionalInformationI18n } = useSignerQuickActions(closeModal, packageData);

    useAsyncEffect(() => async (signal) => {
        if (!packageData) {
            return;
        }

        if (packageData.type === 1) {
            try {
                const result = await portalsApi.getDocumentsFromPackages(
                    { actionId: packageData.actionId },
                    signal,
                );

                setDocuments(result.documents);
            } catch (e) {

            }
        }
    }, [packageData, portalsApi]);

    const quickActions = useMemo((): QuickAction<FlowAction>[] => {
        switch (packageData?.status.toUpperCase()) {
            case FlowStatusEnum.PendingFormFill:
                return [
                    {
                        icon: 'fa-solid-eye',
                        type: FlowAction.Preview,
                    },
                    {
                        icon: 'fa-solid-input-text',
                        type: FlowAction.FillOutForms,
                    },
                ];
            case FlowStatusEnum.PendingApproval:
                return [
                    {
                        icon: 'fa-solid-eye',
                        type: FlowAction.Preview,
                    },
                    {
                        icon: 'fa-solid-circle-check',
                        type: FlowAction.Approve,
                    },
                ];
            case FlowStatusEnum.PendingSigning:
                return [
                    {
                        icon: 'fa-solid-eye',
                        type: FlowAction.Preview,
                    },
                    {
                        icon: 'fa-solid-signature',
                        type: FlowAction.Sign,
                    },
                ];
            case FlowStatusEnum.Received:
            case FlowStatusEnum.Archived:
            case FlowStatusEnum.Finished:
                return [
                    {
                        icon: 'fa-solid-download',
                        type: FlowAction.Download,
                    },
                    {
                        icon: 'fa-solid-trash',
                        type: FlowAction.Delete,
                    },
                ];
            default:
                return [
                    {
                        icon: 'fa-solid-eye',
                        type: FlowAction.Preview,
                    },
                ];
        }
    }, [packageData]);

    const onClose = useCallback(() => {
        setFocusedRow(undefined);
    }, [setFocusedRow]);

    const handleButtonClick = (event: SplitButtonClickEvent | SplitButtonItemClickEvent) => {
        let actionType;

        if ('item' in event && event.item) {
            actionType = event.item.action;
        } else {
            actionType = quickActions[0].type;
        }

        if (actionType === FlowAction.Delete) {
            setQuickActionModalData(packageData);
        } else {
            runQuickActionClick(actionType);
        }
    };

    const getIcon = (icon: string, type: string) => {
        if(type === 'solid') {
            return icon.replace('fa-solid-', 'fa-solid fa-');
        } else {
            return icon.replace('fa-solid-', 'fa-regular fa-');
        }
    };

    return (
        <PanelPortal visible={!!packageData} onClose={onClose}>
            {
                (packageData) ?
                    <>
                        <div className='c-signer-portal-details-panel'>
                            <PackageDetailsHeader
                                packageName={packageData.packageName}
                                packageStatus={packageData.status}
                            />
                            <div className='c-signer-portal-details-container'>
                                <h3 className='c-signer-portal-details-container__header'>{t('documents')}</h3>
                                {packageData.type !== 1 && (
                                    <div className={'c-signer-portal-details-container__item'}>
                                        <NdsMenuItem
                                            icon='fa-light-file'
                                            primaryText={packageData.packageName}
                                            bgMode={BkgModesEnum.LIGHT}
                                        />
                                    </div>
                                )}
                                {packageData.type === 1 && documents && documents.length > 0 &&
                                    documents?.map((document) => {
                                        return (
                                            <div key={document.id} className={'c-signer-portal-details-container__item'}>
                                                <NdsMenuItem
                                                    icon='fa-light-file'
                                                    primaryText={document.name}
                                                    bgMode={BkgModesEnum.LIGHT}
                                                />
                                            </div>
                                        );
                                    })
                                }
                                <div
                                    className={'c-signer-portal-details-container__menu__action-button'}
                                >
                                    <SplitButton
                                        iconClass={getIcon(quickActions[0]?.icon, 'light')}
                                        text={t(FlowActionI18n[quickActions[0].type])}
                                        items={quickActions?.filter(action => quickActions[0] !== action).map((action) => ({
                                            iconClass: getIcon(action.icon, 'solid'),
                                            text: t(FlowActionI18n[action.type]),
                                            action: action.type,
                                        }))}
                                        fillMode={'outline'}
                                        onButtonClick={(e) => handleButtonClick(e)}
                                        onItemClick={(e) => handleButtonClick(e)}
                                        popupSettings={{
                                            animate: false,
                                            anchorAlign: {
                                                horizontal: 'right',
                                                vertical: 'bottom',
                                            },
                                            popupAlign: {
                                                horizontal: 'right',
                                                vertical: 'top',
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className='c-signer-portal-details-overview-panel__loader'>
                        <Loader center size={SizesEnums.XLARGE} />
                    </div>
            }
            <OverlayPortal id='signerPortalQuickActionsModalDelete' type={'modal'} visible={quickActionModalData !== undefined}>
                {({ close }) =>
                    <QuickActionsModal
                        data={quickActionModalData}
                        actionType={FlowAction.SoftDelete}
                        handleCloseModal={() => {
                            closeModal(); close();
                        }}
                        handleClickConfirm={() => {
                            runQuickActionClick(FlowAction.Delete);
                            onClose();
                            close();
                        }}
                        additionalInformation={AdditionalInformationI18n[FlowAction.Delete]}
                        warningMessage={WarningMessageDialogI18n[FlowAction.Delete]}
                    />
                }
            </OverlayPortal>
        </PanelPortal>
    );
};

export default SignerPortalDetailsPanel;
