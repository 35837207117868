import { LanguageDataLabel } from '~models';

export enum UserManagementAction {
  Edit = 'Edit',
  Invite = 'Invite',
  Reinvite = 'Reinvite',
  Delete = 'Delete',
  View = 'View'
}

export const UserManagementActionI18n: Record<UserManagementAction, LanguageDataLabel<'user-management'>> = {
    [UserManagementAction.Edit]: 'user-management-action-edit',
    [UserManagementAction.Invite]: 'user-management-action-invite',
    [UserManagementAction.Reinvite]: 'user-management-action-reinvite',
    [UserManagementAction.Delete]: 'user-management-action-delete',
    [UserManagementAction.View]: 'user-management-action-view',
};