import { ActorType } from '~constants';
import { FlowError } from '~contexts/flow/flow.error';
import { FlowStakeholderValidator, FlowValidationResult } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { StakeholderType } from '~models';

export const smsAuthenticationPhoneRequired: FlowStakeholderValidator = (stakeholder, { properties, flow }, extras) => {
    if (properties?.isSmsOtpAuthenticationEnabled) {
        const actors = FlowUtils.findStakeholderActors(flow, stakeholder.localId);

        if (!actors.length || actors.every(({ actor }) => actor.actorType === ActorType.Receiver)) {
            return { result: FlowValidationResult.Valid };
        }


        const mailOtpEnabled = properties.isMailOtpAuthenticationEnabled;

        if (stakeholder.type === StakeholderType.Person && !stakeholder.phoneNumber) {
            return {
                error: FlowError.StakeholderPersonMissingPhoneNumberForAuthentication,
                result: mailOtpEnabled ? FlowValidationResult.Warning : FlowValidationResult.Error,
            };
        } else if (stakeholder.type === StakeholderType.Group) {
            const membersLength = stakeholder.members.length;
            let membersWithPhone = 0;
            for (const m of stakeholder.members) {
                if (m.phoneNumber) {
                    membersWithPhone++;
                }
            }
            if (membersWithPhone === 0) {
                return {
                    error: FlowError.StakeholderGroupAllMissingPhoneNumberForAuthentication,
                    result: mailOtpEnabled ? FlowValidationResult.Warning : FlowValidationResult.Error,
                };
            } else if (membersWithPhone < membersLength) {
                return {
                    error: FlowError.StakeholderGroupPartialMissingPhoneNumberForAuthentication,
                    result: FlowValidationResult.Warning,
                };
            }
        }
    }

    return { result: FlowValidationResult.Valid };
};

export const unassignedToAnyField: FlowStakeholderValidator = (stakeholder, { flow }) => {
    if (stakeholder.type === StakeholderType.Undecided) {
        return { result: FlowValidationResult.Valid };
    }
    for (const group of flow) {
        for (const actor of group) {
            if (actor.stakeholderLocalId === stakeholder.localId) {
                return { result: FlowValidationResult.Valid };
            }
        }
    }

    return {
        error: FlowError.StakeholderIsUnassigned,
        // result: FlowValidationResult.Error,
        result: FlowValidationResult.Warning, // TODO change back to error
    };
};

// export const validator: FlowStakeholderValidator = (stakeholder, { elements }) => {
//
//     return { result: FlowValidationResult.Valid };
// };
