import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from '@progress/kendo-react-common';
import { ComboBox, ComboBoxProps, ListItemProps } from '@progress/kendo-react-dropdowns';
import { InputPrefix } from '@progress/kendo-react-inputs';
import { searchIcon } from '@progress/kendo-svg-icons';
import classNames from 'classnames';
import {
    cloneElement,
    HTMLAttributes,
    MouseEvent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ContactsApi } from '~api/contacts.api';
import ContactForm from '~components/contact-form';
import { FormValues } from '~components/contact-form/contactForm';
import { ContactDiscriminator, ContactGroupDiscriminator, ContactGroupType, ContactType, Permission } from '~constants';
import { ContactOrigin } from '~constants/contact-origin';
import { useApi } from '~contexts/api';
import { useCloudContactsState } from '~contexts/app';
import { useHasPermissionFunc } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useVirtualizedCombobox, VirtualizedComboboxRef } from '~hooks/virtualizedCombobox';
import { ContactBucketItemModel } from '~models';
import { ContactModel } from '~models/contacts.models';
import { PageableListParams } from '~models/pageable-list.models';
import ContactIcon from '../contact-icon';
import Typography, { TypographyToken } from '../typography';
import { ContactSelectorItem, ContactSelectorValueItem } from './contactSelectorValueItem';
import './contactSelector.scss';


const DEFAULT_PAGE_SIZE = 14;
const ContactTypeAllArray = [ContactType.Personal, ContactType.Shared, ContactType.Cloud];
const ContactGroupTypeAllArray = [ContactGroupType.Personal, ContactGroupType.Shared];


const RenderItem = (itemProps: ListItemProps) => {
    const item: ContactSelectorItem = itemProps.dataItem;
    let name: string;
    let email: string | undefined;

    if (item.discriminator === ContactGroupDiscriminator.ContactGroup) {
        name = item.contactGroupName;
    } else {
        if (item.firstName || item.lastName) {
            name = `${item.firstName ?? ''} ${item.lastName ?? ''}`.trim();
            email = item.emailAddress;
        } else {
            name = item.emailAddress;
        }
    }

    return (
        <div className={'c-contact-selector__combo-item'}>
            <div className={'c-contact-selector__combo-item-icon'}><ContactIcon contact={item} /></div>
            <div className={'c-contact-selector__combo-item-text'}>
                <Typography token={TypographyToken.UiMenuSm} text={name} />
                {email && <Typography token={TypographyToken.DesktopBylineXs} text={email} />}
            </div>
        </div>
    );
};


const buildLocalId = (item: ContactBucketItemModel) => {
    if ('contactGroupId' in item) {
        return 'group-' + item.contactGroupId;
    } else {
        return 'contactId' in item ? `contact-${item.contactId}` : `contact-${item.emailAddress}`;
    }
};

export interface ContactSelectorProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    pageSize?: number;
    contactTypes?: ContactType[];
    contactGroupTypes?: ContactGroupType[];
    fillMode?: ComboBoxProps['fillMode'];
    value: ContactBucketItemModel[];
    disabled?: boolean;
    showCloudButton?: boolean;
    showAddContactButton?: boolean;
    allowMultiple?: boolean;
    onChange: (value: ContactBucketItemModel[], wasContactsEdited: boolean) => void;
    label?: string;
    valuesClassName?: string;
}

function ContactSelector({
    pageSize = DEFAULT_PAGE_SIZE,
    contactTypes = ContactTypeAllArray,
    contactGroupTypes = ContactGroupTypeAllArray,
    fillMode,
    onChange: onChangeProp,
    disabled,
    value: valueProp,
    showCloudButton = false,
    showAddContactButton = false,
    allowMultiple,
    label,
    className,
    valuesClassName,
    ...props
}: ContactSelectorProps) {
    const { t } = useTranslation('contacts');
    const hasPermission = useHasPermissionFunc();
    const wasContactEdited = useRef(false);
    const comboHeaderRef = useRef<HTMLDivElement>(null);

    const value = useMemo(() => valueProp.map(el => ({
        ...el,
        localId: buildLocalId(el),
    })).sort((a, b) => {
        const nameA = a.discriminator === ContactGroupDiscriminator.ContactGroup ? a.contactGroupName.toLowerCase() : `${a.firstName ?? ''}${a.lastName ?? ''}${a.emailAddress ?? ''}`.trim().toLowerCase();
        const nameB = b.discriminator === ContactGroupDiscriminator.ContactGroup ? b.contactGroupName.toLowerCase() : `${b.firstName ?? ''}${b.lastName ?? ''}${b.emailAddress ?? ''}`.trim().toLowerCase();

        if (nameA < nameB) {
            return -1;
        } else if (nameA > nameB) {
            return 1;
        }

        return 0;
    }), [valueProp]);

    const {
        validatedContactTypes,
        validatedContactGroupTypes,
    } = useMemo(() => {
        let validatedContactTypes = contactTypes.slice();
        let validatedContactGroupTypes = contactGroupTypes.slice();

        if (!hasPermission(Permission.General_ActionViewSharedContact)) {
            validatedContactTypes = validatedContactTypes.filter(el => el !== ContactType.Shared);
        }
        if (!hasPermission(Permission.General_ActionViewSharedContactGroup)) {
            validatedContactGroupTypes = validatedContactGroupTypes.filter(el => el !== ContactGroupType.Shared);
        }

        return {
            validatedContactTypes,
            validatedContactGroupTypes,
        };
    }, [contactGroupTypes, contactTypes, hasPermission]);

    const onComboboxChange = useCallback((item: ContactSelectorItem | null) => {
        if (!item) {
            return;
        }
        if (value.find(el => el.localId === item.localId)) {
            onChangeProp(value.filter(el => el.localId !== item.localId), wasContactEdited.current);
        } else {
            onChangeProp([...value, item], wasContactEdited.current);
        }
    }, [onChangeProp, value]);

    const onDeleteItem = useCallback(async (item: ContactSelectorItem) => {
        onChangeProp(value.filter(el => el.localId !== item.localId), wasContactEdited.current);
    }, [onChangeProp, value]);

    const contactsApi = useApi(ContactsApi);
    const fetchData = useCallback(async ({ filterValues, ...data }: PageableListParams, signal?: AbortSignal) => {
        const response = await contactsApi.getContactBucketNew({
            ...data,
            searchString: (filterValues || {}).searchString,
            contactTypes: validatedContactTypes,
            contactGroupTypes: validatedContactGroupTypes,
        }, signal);

        return {
            ...response,
            items: response.items.map((el) => {
                return {
                    ...el,
                    localId: buildLocalId(el),
                };
            }),
        };
    }, [validatedContactGroupTypes, validatedContactTypes, contactsApi]);

    const virtualizeRef = useRef<VirtualizedComboboxRef>(null);

    const [comboFilterValue, setComboFilterValue] = useState('');
    const onComboFilterChange = useCallback((value: string) => {
        setComboFilterValue(value);
    }, []);

    const comboboxProps = useVirtualizedCombobox<ContactSelectorItem>({
        pageSize,
        apiFilterKey: 'searchString',
        fetchData,
        valueKey: 'localId',
        labelKey: 'localId',
        onChange: onComboboxChange,
        value: value,
        RenderItem,
        ref: virtualizeRef,
        onFilterChange: onComboFilterChange,
    });

    const [contactToEdit, setContactToEdit] = useState<ContactModel>();
    const [contactEmailToAdd, setContactEmailToAdd] = useState<string>('');
    const [showContactForm, setShowContactForm] = useState(false);

    const onEditItem = useCallback(async (item: ContactSelectorItem) => {
        if (!item.isEditable || item.discriminator !== ContactDiscriminator.PortalContact || !item.contactId) {
            return;
        }
        const contact = await contactsApi.getContact({ contactId: item.contactId.toString() });

        setContactToEdit(contact);
        setShowContactForm(true);
    }, [contactsApi]);

    const onAddClick = useCallback(() => {
        setShowContactForm(true);
        setContactEmailToAdd(comboFilterValue ?? '');
    }, [comboFilterValue]);

    const onContactFormClose = useCallback((data: FormValues | undefined) => {
        if (data) {
            const localId = `contact-${data.contactId}`;
            const idx = value.findIndex(el => el.localId === localId);
            const copy = value.slice();

            if (idx > -1) {
                wasContactEdited.current = true;
                copy.splice(idx, 1, {
                    contactId: parseInt(data.contactId),
                    localId: `contact-${data.contactId}`,
                    origin: ContactOrigin.Local,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    emailAddress: data.emailAddress,
                    isEditable: true,
                    isShared: data.isShared,
                    discriminator: ContactDiscriminator.PortalContact,
                });
            } else {
                copy.push({
                    contactId: parseInt(data.contactId),
                    localId: `contact-${data.contactId}`,
                    origin: ContactOrigin.Local,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    emailAddress: data.emailAddress,
                    isEditable: true,
                    isShared: data.isShared,
                    discriminator: ContactDiscriminator.PortalContact,
                });
            }
            onChangeProp(copy, wasContactEdited.current);
        }
        setContactToEdit(undefined);
        setShowContactForm(false);
        setContactEmailToAdd('');
        virtualizeRef.current?.reset();
    }, [onChangeProp, value]);

    const {
        cloudContactsAvailable,
        cloudContactsSyncing,
        checkCloudContactsStatus,
        syncCloudContacts,
    } = useCloudContactsState();

    const prevSyncingPending = useRef(false);
    const onSyncClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        prevSyncingPending.current = true;
        syncCloudContacts(true);
    }, [syncCloudContacts]);

    useEffect(() => {
        if (!cloudContactsSyncing && prevSyncingPending.current) {
            virtualizeRef.current?.reset();
        }
        prevSyncingPending.current = cloudContactsSyncing;
    }, [cloudContactsSyncing]);

    useEffect(() => {
        checkCloudContactsStatus();
        // need empty deps array as it must run once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { withSyncCloudButton, withAddContactButton } = useMemo(() => {
        return {
            withSyncCloudButton: showCloudButton && cloudContactsAvailable,
            withAddContactButton: showAddContactButton,
        };
    }, [cloudContactsAvailable, showAddContactButton, showCloudButton]);

    const comboHeader = (withSyncCloudButton || withAddContactButton) && (
        <div className={'c-contact-selector__combobox-popup-header'} ref={comboHeaderRef}>
            <div className={'c-contact-selector__combobox-popup-header-col'}>
                {withSyncCloudButton &&
                    <Button
                        iconClass={classNames('fa-regular fa-rotate', { 'c-contact-selector__sync-icon--rotating': cloudContactsSyncing })}
                        onMouseDown={onSyncClick}
                        disabled={cloudContactsSyncing}
                    >
                        {t('sync-cloud-contacts')}
                    </Button>}
            </div>
            <div className={'c-contact-selector__combobox-popup-header-col'}>
                {withAddContactButton && <Button
                    iconClass={'fa-regular fa-plus'}
                    themeColor={'secondary'}
                    onMouseDown={onAddClick}
                >
                    {t('add-contact-button')}
                </Button>}
            </div>
        </div>
    );

    return (
        <div {...props} className={classNames('c-contact-selector', { 'c-contact-selector--multiple': allowMultiple }, className)}>
            {(allowMultiple || !value.length) && (
                <Typography
                    token={TypographyToken.UiFormsLabelSm}
                    text={label ?? t('search-address-book')}
                    tagName={'div'}
                    className={'c-contact-selector__label'}
                />
            )}
            <ComboBox
                disabled={disabled}
                fillMode={fillMode}
                className={'c-contact-selector__combobox'}
                popupSettings={{
                    popupClass: 'c-contact-selector__combobox-popup',
                    height: pageSize / 2 * 40,
                }}
                placeholder={t('search-for-name-or-add-email-address')}
                listNoDataRender={(element: ReactElement<HTMLDivElement>) =>
                    cloneElement(element, { ...element.props }, <div>{t('no-contacts-found')}</div>)
                }
                prefix={() => (
                    <InputPrefix orientation='horizontal'>
                        <SvgIcon
                            icon={searchIcon}
                        />
                    </InputPrefix>

                )}
                {...comboboxProps}
                style={{ display: (allowMultiple || !value.length) ? undefined : 'none' }}
                header={comboHeader}
                clearButton={false}
            />
            <div className={classNames('c-contact-selector__values', valuesClassName)}>
                {value.map(el => (
                    <ContactSelectorValueItem
                        key={el.localId}
                        item={el}
                        onEditClick={onEditItem}
                        onDeleteClick={onDeleteItem}
                    />
                ))}
            </div>
            <OverlayPortal type={'panel'} visible={showContactForm}>
                {() => (
                    <ContactForm
                        open={true}
                        onClose={onContactFormClose}
                        prefilledEmail={contactEmailToAdd.length ? contactEmailToAdd : undefined}
                        contact={contactToEdit}
                    />
                )}
            </OverlayPortal>
        </div>
    );
}

export default ContactSelector;
