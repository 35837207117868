import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Switch } from '@progress/kendo-react-inputs';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { DocumentGroupsListItemModel } from '~models/user-management.models';
import { SelectedDocumentGroupsState } from '../../user-group-panel';
import { initialDocumentGroupToggles } from '../document-group-toggle-definition';
import useDocumentGroupToggles, { DocumentGroupToggle } from '../useDocumentGroupToggles';
import './toggle-group.scss';


export interface ToggleGroupProps {
    selectedDocumentGroups: DocumentGroupsListItemModel[];
    setSelectedDocumentGroups: Dispatch<SetStateAction<DocumentGroupsListItemModel[]>>;
    documentGroup: DocumentGroupsListItemModel;
    onRemove: (docGroup: SelectedDocumentGroupsState | null) => void;
    editedDocGroupsRef: MutableRefObject<SelectedDocumentGroupsState[]>;
    currentPage: number;
    isViewMode: boolean;
}

const ToggleGroup = ({ selectedDocumentGroups, setSelectedDocumentGroups, documentGroup, onRemove, editedDocGroupsRef, currentPage, isViewMode }: ToggleGroupProps) => {
    const { t } = useTranslation('user-management');
    const { documentGroupToggles, handleDocumentGroupToggleChange } =
        useDocumentGroupToggles(documentGroup?.permissions?.length ? documentGroup.permissions : initialDocumentGroupToggles);

    const [isExpanded, setExpanded] = useState(false);
    const selectedToggleGroupId = useRef<number>();


    useEffect(() => {
        setSelectedDocumentGroups((prev) => {
            const updatedDocGroups = prev.map((group) =>
                group.documentGroupId === selectedToggleGroupId.current
                    ? {
                        ...group,
                        permissions: documentGroupToggles,
                    }
                    : group);

            const hasChanged = !isEqual(updatedDocGroups, prev);

            return hasChanged ? updatedDocGroups : prev;
        });


        const exists = editedDocGroupsRef.current.some(docGrp =>
            docGrp.documentGroupId === selectedToggleGroupId.current);

        if (exists) {
            editedDocGroupsRef.current = editedDocGroupsRef.current.map(docGrp =>
                docGrp.documentGroupId === selectedToggleGroupId.current
                    ? {
                        ...docGrp,
                        permissions: documentGroupToggles,
                        hasPermissionChanged: currentPage > 1,
                    } :
                    docGrp);
        } else {
            const result = selectedDocumentGroups.find((item) =>
                item.documentGroupId === selectedToggleGroupId.current);

            if (result) {

                editedDocGroupsRef.current = [
                    ...editedDocGroupsRef.current,
                    {
                        ...result,
                        permissions: documentGroupToggles,
                        hasPermissionChanged: currentPage > 1,
                    },
                ];
            }
        }

        selectedToggleGroupId.current = undefined;
    }, [
        currentPage,
        documentGroup,
        documentGroup.documentGroupId,
        documentGroupToggles,
        editedDocGroupsRef,
        selectedDocumentGroups,
        setSelectedDocumentGroups,
    ]);


    useEffect(() => {
        setExpanded(false);
    }, [selectedDocumentGroups.length]);

    const onToggleChange = (toggle: DocumentGroupToggle) => {
        selectedToggleGroupId.current = documentGroup.documentGroupId;
        handleDocumentGroupToggleChange(toggle);
    };

    return (
        <div className={'toggle-group'}>
            <div className='toggle-group__section-container'>
                <div className={'toggle-group__title'} onClick={() => setExpanded(prev => !prev)}>
                    {isExpanded
                        ? (
                            <NdsIconFont fontName='fa-solid-chevron-up' size={SizesEnums.SMALL} />
                        )
                        : (
                            <NdsIconFont fontName='fa-solid-chevron-down' size={SizesEnums.SMALL} />
                        )}
                    <Typography
                        className='toggle-group__document-group-name'
                        token={TypographyToken.UiMenuMd}
                        text={documentGroup.name}
                    />
                </div>
                <NdsIconFont
                    onClick={() => !isViewMode? onRemove(documentGroup): null}
                    className={classNames('selected-user-item__delete-icon', { 'toggle-group__disabled' : isViewMode })}
                    fontName='fa-solid-minus-circle'
                    size={SizesEnums.SMALL}
                />
            </div>

            {isExpanded &&
                <div className='toggle-group__toggle-section-container'>
                    <>
                        <Typography
                            token={TypographyToken.DesktopTagXs}
                            text={t('toggle-document-permissions-label')}
                        />
                        {documentGroupToggles.map((toggle) => (
                            <div key={toggle.permission} className='toggle-group__toggle-section'>
                                <Typography
                                    className={'toggle-group__toggle-label'}
                                    token={TypographyToken.UiButtonsSm}>{t(toggle.label)}
                                </Typography>
                                <Switch
                                    checked={toggle.hasPermission}
                                    disabled={toggle.disabled || isViewMode}
                                    onChange={() => onToggleChange(toggle)} />
                            </div>
                        ))}
                    </>
                </div>}

        </div>
    );
};

export default ToggleGroup;
