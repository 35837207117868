import { DropDownButton, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import classNames from 'classnames';
import { MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import FlowEditorAddFieldsPopup
    from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/flow-editor-add-fields-popup';
import { FlowValidationBadge, StakeholderDisplayName, StakeholderIcon } from '~components/flow/flow-util-components';
import Typography, { TypographyToken } from '~components/typography';
import { useFlowContext, useGetStakeholderColorClass } from '~contexts/flow/flow.hooks';
import { FlowEntityType, FlowValidationResult } from '~contexts/flow/flow.types';
import { useTranslation } from '~contexts/i18n';
import { DomUtils } from '~lib';
import { FlowStakeholderModel, Guid, StakeholderType } from '~models';
import './flowEditorStakeholderUi.scss';

export interface FlowEditorStakeholderUiProps {
    stakeholder: FlowStakeholderModel;
    showDragHandle?: boolean;
    withAddFieldsPopup?: boolean;
    hideValidation?: boolean;
    portalContainer?: HTMLElement | null;
    paneContainer?: HTMLElement | null;
    onDeleteClick?: (stakeholderLocalId?: Guid) => any;
    isReceiverUI?: boolean;
}

function FlowEditorStakeholderUi({
    stakeholder,
    showDragHandle,
    withAddFieldsPopup,
    hideValidation,
    portalContainer,
    paneContainer,
    onDeleteClick,
    isReceiverUI,
}: FlowEditorStakeholderUiProps) {
    const { t } = useTranslation('flow');
    const { setFocusedEntity, focusedEntityId, focusedEntityType, getEntityValidationResult } = useFlowContext();
    const getColorClass = useGetStakeholderColorClass();
    const ref = useRef<HTMLDivElement>(null);

    const stakeholderValidationResult = useMemo(() => (
        !hideValidation && stakeholder && getEntityValidationResult(stakeholder.localId)
    ), [hideValidation, stakeholder, getEntityValidationResult]);

    const [addFieldsPopupOpened, setAddFieldsPopupOpened] = useState(false);
    const hideAddFieldsPopup = useCallback(() => {
        setAddFieldsPopupOpened(false);
    }, []);

    const onRowClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
        const specificClickable = DomUtils.findParent(event.target as HTMLElement, (el) => {
            return !!el.dataset.clickable;
        }, ref.current);

        setFocusedEntity(FlowEntityType.Stakeholder, stakeholder.localId);

        if (specificClickable) {
            return;
        }

        if (withAddFieldsPopup) {
            setAddFieldsPopupOpened(true);
        }
    }, [setFocusedEntity, stakeholder, withAddFieldsPopup]);

    const onMenuClick = useCallback((event: DropDownButtonItemClickEvent) => {
        switch (event.item.action) {
            case 'addField': {
                setAddFieldsPopupOpened(true);

                return;
            }
            case 'edit': {
                alert('todo');

                return;
            }
            case 'delete': {
                onDeleteClick?.(stakeholder.localId);

                return;
            }
        }
    }, [onDeleteClick, stakeholder.localId]);

    const menuItems = useMemo(() => {
        const items = [];

        if (withAddFieldsPopup) {
            items.push({
                text: t('recipient-menu-btn-add-field'),
                iconClass: 'fa-solid fa-plus fa-xs',
                action: 'addField',
            });
        }
        if (stakeholder.type === StakeholderType.Person) {
            items.push({
                text: t('recipient-menu-btn-edit-contact-details'),
                iconClass: 'fa-solid fa-pen-to-square fa-xs',
                action: 'edit',
            });
        }
        if (onDeleteClick) {
            items.push({
                text: isReceiverUI ? t('recipient-menu-btn-remove-receiver') : t('recipient-menu-btn-remove-recipient'),
                iconClass: 'fa-solid fa-trash fa-xs',
                action: 'delete',
            });
        }

        return items;
    }, [
        isReceiverUI,
        onDeleteClick,
        stakeholder.type,
        t,
        withAddFieldsPopup,
    ]);

    return (
        <div
            className={
                classNames(getColorClass(stakeholder.localId), 'c-flow-editor-stakeholder-ui', { 'c-flow-editor-stakeholder-ui--active': focusedEntityType === FlowEntityType.Stakeholder && focusedEntityId === stakeholder.localId })}
            ref={ref}
            onMouseDown={onRowClick}
        >
            {showDragHandle && (
                <button
                    data-clickable={'1'}
                    data-drag={'actor'}
                    type={'button'}
                    className={'c-flow-editor-stakeholder-ui__drag'}
                >
                    <i className='fa-solid fa-grip-lines' role='presentation'></i>
                </button>
            )}
            <div className={'c-flow-editor-stakeholder-ui__icon'}>
                <StakeholderIcon stakeholder={stakeholder} />
            </div>
            <div className={'c-flow-editor-stakeholder-ui__name'}>
                <Typography
                    token={TypographyToken.DesktopDescriptionBoldSm}
                    className={'c-flow-editor-actor__row-text'}
                >
                    <StakeholderDisplayName stakeholder={stakeholder} />
                </Typography>
            </div>
            {!hideValidation && stakeholderValidationResult !== FlowValidationResult.Valid && (
                <div className={'c-flow-editor-stakeholder-ui__validation'}>
                    <FlowValidationBadge
                        warning={stakeholderValidationResult === FlowValidationResult.Warning}
                        error={stakeholderValidationResult === FlowValidationResult.Error}
                    />
                </div>
            )}
            <div className={'c-flow-editor-stakeholder-ui__menu'} data-clickable={'1'}>
                <DropDownButton
                    tabIndex={1}
                    fillMode={'flat'}
                    iconClass={'fa-solid fa-ellipsis-vertical'}
                    popupSettings={{
                        popupAlign: {
                            horizontal: 'right',
                            vertical: 'top',
                        },
                        anchorAlign: {
                            horizontal: 'right',
                            vertical: 'bottom',
                        },
                    }}
                    onItemClick={onMenuClick}
                    items={menuItems}
                    data-inner
                />
            </div>
            {withAddFieldsPopup && (
                <FlowEditorAddFieldsPopup
                    opened={addFieldsPopupOpened}
                    hide={hideAddFieldsPopup}
                    stakeholder={stakeholder}
                    anchor={ref.current}
                    appendTo={portalContainer}
                    paneContainer={paneContainer}
                />
            )}
        </div>
    );
}

export default FlowEditorStakeholderUi;
