import { Modal, ModalContent, ModalFooter, NdsButton, NdsGroup, NdsInput, NdsSelect, NdsSelectOption } from '@gonitro/rcl';
import { BkgModesEnum, NdsButtonStylesEnum, NdsButtonTypesEnum, NdsInputTypes, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { DropDownList, DropDownListChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { cloneElement, ReactElement, useCallback, useEffect, useState } from 'react';
import CountryPrefixFlag from '~components/country-prefix-flag';
import Typography, { TypographyToken } from '~components/typography';
import { countryInfoList } from '~constants';
import { ContactConflictFields, ContactConflictProperties } from '~constants/contact-conflict-fields';
import { useUserInfo } from '~contexts/auth';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { DateUtil } from '~lib/date.utils';
import { CountryInfo, Guid } from '~models';
import { ContactModel, CreateEditContact } from '~models/contacts.models';
import { ContactsHeaderButtonActionType } from '~views/contacts/contacts-view-header-button/contactsViewHeaderButton';
import './mergeContactModal.scss';

export interface MergeContactModalProps {
    contactsToMerge: {contactToMerge?: ContactModel, existingContact?: ContactModel, actionType?: any} | undefined;
    existingContactLabel: string;
    mergingContactLabel: string;
    isShare?: any;
    onCloseModalClik: () => void;
    onMergeContact: (contact: CreateEditContact, personalConctactId?: Guid) => void;
}

export type FormValues = {
    emailAddressToMerge: string;
    lastNameToMerge: string;
    birthDateToMerge: string;
    countryCodeToMerge: string;
    phoneNumberToMerge: string;
    languageToMerge: string;
    externalRefferenceToMerge: string;
    personalTitleToMerge: string;
    personalTitleExisting: string;
    emailAddressExisting: string;
    lastNameExisting: string;
    birthDateExisting: string;
    countryCodeExisting: string;
    phoneNumberExisting: string;
    languageExisting: string;
    externalRefferenceExisting: string;
    firstNameToMerge: string;
    firstNameExisting: string;
};

function MergeContactModal ({ contactsToMerge, mergingContactLabel, existingContactLabel, isShare, onCloseModalClik, onMergeContact }: MergeContactModalProps) {
    const { t } = useTranslation('contacts');
    const userInfo = useUserInfo();
    const { availableLanguages } = useLanguages();
    const [phoneNumberCountryExisting, setPhoneNumberCountryExisting] = useState<CountryInfo | undefined>();
    const [phoneNumberCountryToMerge, setPhoneNumberCountryToMerge] = useState<CountryInfo | undefined>();
    const [mergedFields, setMergedFields] = useState<{ [key in keyof FormValues]?: boolean }>({});

    const getCountryCodeInfo = (prefix?: string) => {
        const countryInfo = countryInfoList.find(
            (el: CountryInfo) =>
                el.countryCode === prefix || el.phonePrefix === prefix || el.phonePrefix === `+${prefix}`,
        );

        return countryInfo;
    };

    const getPhoneNumberInfo = useCallback((contact: any) => {
        // Get the prefix either from contact or userInfo
        const prefix = contact?.phoneNumberCountry
            ? contact.phoneNumberCountry
            : userInfo?.defaultPhoneNumberCountry;

        // Check if the prefix is a countryCode or phonePrefix
        const countryPhoneInfo = countryInfoList.find(
            (el: CountryInfo) =>
                el.countryCode === prefix || el.phonePrefix === prefix || el.phonePrefix === `+${prefix}`,
        );

        // Checking the phone number
        if (contact?.phoneNumber && countryPhoneInfo?.phonePrefix) {
            // Normalize both phone number and phone prefix by removing any leading '+'
            const normalizedPhonePrefix = countryPhoneInfo.phonePrefix.replace('+', '');
            const normalizedPhoneNumber = contact.phoneNumber.replace('+', '');

            // Check if the phone number starts with the normalized prefix
            if (normalizedPhoneNumber.startsWith(normalizedPhonePrefix)) {
                return {
                    normalizedPhoneNumber: normalizedPhoneNumber.slice(normalizedPhonePrefix.length),
                    countryPhoneInfo,
                };
            }
        }

        return {
            normalizedPhoneNumber: '',
            countryPhoneInfo,
        };
    }, [userInfo?.defaultPhoneNumberCountry]);


    useEffect(() => {
        if (contactsToMerge?.existingContact) {
            const { countryPhoneInfo } = getPhoneNumberInfo(contactsToMerge.existingContact);

            setPhoneNumberCountryExisting(countryPhoneInfo);  // Update state for existing contact's country info
        }

        if (contactsToMerge?.contactToMerge) {
            const { countryPhoneInfo } = getPhoneNumberInfo(contactsToMerge.contactToMerge);

            setPhoneNumberCountryToMerge(countryPhoneInfo);  // Update state for merging contact's country info
        }
    }, [contactsToMerge, getPhoneNumberInfo]);

    const buildPhoneNumber = (phoneNumber: string, countryCode: string | undefined): string => {
        if(phoneNumber === '') {
            return '';
        }
        const phoneNumberCountryCode = countryCode? countryCode : userInfo?.defaultPhoneNumberCountry;
        const countryInfo =  countryInfoList.find(
            (el: CountryInfo) =>
                el.countryCode === phoneNumberCountryCode || el.phonePrefix === phoneNumberCountryCode || el.phonePrefix === `+${phoneNumberCountryCode}`,
        );

        if (countryInfo?.phonePrefix) {
            return `${countryInfo.phonePrefix}${phoneNumber}`; // Combine phone prefix and phone number
        }

        return phoneNumber; // Fallback to just the phone number if no prefix found
    };


    const formMethods = useEnhancedForm<FormValues>({
        mode: 'all',
        defaultValues: {
            emailAddressToMerge: contactsToMerge?.contactToMerge?.emailAddress,
            firstNameToMerge: contactsToMerge?.contactToMerge?.firstName,
            lastNameToMerge: contactsToMerge?.contactToMerge?.lastName,
            birthDateToMerge: contactsToMerge?.contactToMerge?.birthDate? DateUtil.format(new Date(contactsToMerge?.contactToMerge?.birthDate), 'dd/MM/yyyy')  : undefined,
            countryCodeToMerge: contactsToMerge?.contactToMerge?.phoneNumberCountry,
            phoneNumberToMerge: getPhoneNumberInfo(contactsToMerge?.contactToMerge)?.normalizedPhoneNumber,
            languageToMerge: contactsToMerge?.contactToMerge?.language,
            externalRefferenceToMerge: contactsToMerge?.contactToMerge?.externalReference,
            personalTitleToMerge: contactsToMerge?.contactToMerge?.title ? contactsToMerge?.contactToMerge?.title : '',

            emailAddressExisting: contactsToMerge?.existingContact?.emailAddress,
            firstNameExisting: contactsToMerge?.existingContact?.firstName,
            lastNameExisting: contactsToMerge?.existingContact?.lastName,
            birthDateExisting: contactsToMerge?.existingContact?.birthDate?  DateUtil.format(new Date(contactsToMerge?.existingContact?.birthDate), 'dd/MM/yyyy'): undefined,
            countryCodeExisting: contactsToMerge?.existingContact?.phoneNumberCountry,
            phoneNumberExisting: getPhoneNumberInfo(contactsToMerge?.existingContact)?.normalizedPhoneNumber,
            languageExisting: contactsToMerge?.existingContact?.language,
            externalRefferenceExisting: contactsToMerge?.existingContact?.externalReference,
            personalTitleExisting: contactsToMerge?.existingContact?.title ? contactsToMerge?.existingContact?.title : '',
        },

    });

    useEffect(() => {
        if (contactsToMerge) {
            const existingCountryInfo = getCountryCodeInfo(contactsToMerge.existingContact?.phoneNumberCountry);
            const toMergeCountryInfo = getCountryCodeInfo(contactsToMerge.contactToMerge?.phoneNumberCountry);

            setPhoneNumberCountryExisting(existingCountryInfo);
            setPhoneNumberCountryToMerge(toMergeCountryInfo);
        }
    }, [contactsToMerge]);


    const {
        registerNdsInput,
        registerNdsSelect,
        setValue,
        reset,
    } = formMethods;

    // Sync form values when contactsToMerge changes
    useEffect(() => {
        reset({
            emailAddressToMerge: contactsToMerge?.contactToMerge?.emailAddress,
            firstNameToMerge: contactsToMerge?.contactToMerge?.firstName,
            lastNameToMerge: contactsToMerge?.contactToMerge?.lastName,
            birthDateToMerge: contactsToMerge?.contactToMerge?.birthDate
                ? DateUtil.format(new Date(contactsToMerge?.contactToMerge?.birthDate), 'dd/MM/yyyy')
                : undefined,
            countryCodeToMerge: contactsToMerge?.contactToMerge?.phoneNumberCountry,
            phoneNumberToMerge: getPhoneNumberInfo(contactsToMerge?.contactToMerge)?.normalizedPhoneNumber,
            languageToMerge: contactsToMerge?.contactToMerge?.language,
            externalRefferenceToMerge: contactsToMerge?.contactToMerge?.externalReference,
            personalTitleToMerge: contactsToMerge?.contactToMerge?.title ? contactsToMerge?.contactToMerge?.title : '',

            emailAddressExisting: contactsToMerge?.existingContact?.emailAddress,
            firstNameExisting: contactsToMerge?.existingContact?.firstName,
            lastNameExisting: contactsToMerge?.existingContact?.lastName,
            birthDateExisting: contactsToMerge?.existingContact?.birthDate
                ? DateUtil.format(new Date(contactsToMerge?.existingContact?.birthDate), 'dd/MM/yyyy')
                : undefined,
            countryCodeExisting: contactsToMerge?.existingContact?.phoneNumberCountry,
            phoneNumberExisting: getPhoneNumberInfo(contactsToMerge?.existingContact)?.normalizedPhoneNumber,
            languageExisting: contactsToMerge?.existingContact?.language,
            externalRefferenceExisting: contactsToMerge?.existingContact?.externalReference,
            personalTitleExisting: contactsToMerge?.existingContact?.title ? contactsToMerge?.existingContact?.title : '',
        });
    }, [contactsToMerge, getPhoneNumberInfo, reset]);

    // Set conflict values to contact to save
    const handleFieldTransfer = (fieldName: keyof FormValues) => {

        const toMergeValue = formMethods.getValues(`${fieldName}ToMerge` as keyof FormValues);

        setValue(`${fieldName}Existing` as keyof FormValues, toMergeValue, { shouldValidate: true });

        if(fieldName === 'phoneNumber'as  keyof FormValues) {
            setPhoneNumberCountryExisting(phoneNumberCountryToMerge);
        }

        // Mark the field as merged
        setMergedFields((prev) => ({
            ...prev,
            [fieldName]: true,
        }));

    };

    // Check if the input should be rendered by comparing the values
    const shouldRenderField = (fieldName: keyof FormValues) => {
        // If the field has been merged, always render it
        if (fieldName === 'emailAddress' as keyof FormValues) {
            return true;
        }

        if (mergedFields[fieldName]) {
            return true;
        }
        // For other fields, render only if the "to merge" value is different from the "existing" value
        const toMergeValue = formMethods.getValues(`${fieldName}ToMerge` as keyof FormValues);
        const existingValue = formMethods.getValues(`${fieldName}Existing` as keyof FormValues);

        return toMergeValue !== existingValue;
    };


    const handleChangeCountryCode = (event: DropDownListChangeEvent, isToMerge: boolean) => {
        const newValue = event.target.value;

        if (isToMerge) {
            setPhoneNumberCountryToMerge(getCountryCodeInfo(newValue.phonePrefix));
        } else {
            setPhoneNumberCountryExisting(getCountryCodeInfo(newValue.phonePrefix));
        }
    };

    const handleMergeContact = () => {
        // Get form values for the existing contact and map them to CreateEditContact model
        const updatedContact: CreateEditContact = {
            contactId: contactsToMerge?.existingContact?.contactId || undefined,
            emailAddress: formMethods.getValues('emailAddressExisting'),
            firstName: formMethods.getValues('firstNameExisting'),
            lastName: formMethods.getValues('lastNameExisting'),
            birthDate: formMethods.getValues('birthDateExisting'),
            phoneNumber: buildPhoneNumber(formMethods.getValues('phoneNumberExisting'), phoneNumberCountryExisting?.countryCode),
            language: formMethods.getValues('languageExisting'),
            externalReference: formMethods.getValues('externalRefferenceExisting'),
            phoneNumberCountry: phoneNumberCountryExisting?.countryCode,
            properties: contactsToMerge?.existingContact?.properties || [],
            isShared: contactsToMerge?.existingContact?.isShared || false,
        };

        // Send the updated contact data to the parent component
        onMergeContact(updatedContact, contactsToMerge?.actionType === ContactsHeaderButtonActionType.Share ?  contactsToMerge?.contactToMerge?.contactId : undefined );
    };

    return (
        <Modal
            headerLabel={t('merge-contact')}
            bkgMode={BkgModesEnum.LIGHT}
            size={SizesEnums.MEDIUM}
            onCloseClick={() => {
                onCloseModalClik();
            }}
            withClose={true}
            className={'c-merge-contact-modal'}
            renderContent={
                <ModalContent>
                    <form>
                        <div className='c-merge-contact-modal'>
                            <Typography
                                className='c-merge-contact-modal__warning-message'
                                token={TypographyToken.UiFormsLabelSm}
                                tagName='div'
                                text={contactsToMerge?.existingContact?.isShared ? t('merge-shared-contact-warning-message') : t('merge-personal-contact-warning-message')} />

                            <div className='c-merge-contact-modal__forms-content'>
                                <div className={'c-merge-contact-modal__subtitle'}>
                                    <Typography
                                        className='c-merge-contact-modal__form-title'
                                        token={TypographyToken.DesktopTagXs}
                                        tagName='div'
                                        text={mergingContactLabel} />
                                    <Typography
                                        className='c-merge-contact-modal__form-title'
                                        token={TypographyToken.DesktopTagXs}
                                        tagName='div'
                                        text={existingContactLabel} />
                                </div>
                                {Object.entries(ContactConflictFields).map(([fieldKey, { key, keyTranslation }]) => (
                                    shouldRenderField(fieldKey as keyof FormValues) && (
                                        <div key={key} className='c-merge-contact-modal__field'>
                                            <div className={'c-merge-contact-modal__input-wrapper'}>
                                                {fieldKey === ContactConflictProperties.Language
                                                    ? (
                                                        <LanguageSelectField
                                                            availableLanguages={availableLanguages}
                                                            registerSelect={registerNdsSelect}
                                                            isToMerge={true}
                                                        />
                                                    )
                                                    : (
                                                        <RenderInputField
                                                            name={key}
                                                            label={t(keyTranslation)}
                                                            registerInput={registerNdsInput}
                                                            isToMerge={true}
                                                            phoneNumberCountry={phoneNumberCountryToMerge}
                                                            handleChange={handleChangeCountryCode}
                                                            renderPhoneNumberInput={fieldKey === ContactConflictProperties.PhoneNumber}
                                                        />
                                                    )}
                                            </div>
                                            <div>
                                                <NdsButton
                                                    size={SizesEnums.XSMALL}
                                                    leftIcon='fa-solid-arrow-right'
                                                    className={`${key}ToMerge` === 'emailAddressToMerge' ? 'c-merge-contact-modal__hidden-button' : ''}
                                                    buttonType={NdsButtonTypesEnum.SECONDARY}
                                                    buttonStyle={NdsButtonStylesEnum.ALT}
                                                    onClick={() => handleFieldTransfer(fieldKey as keyof FormValues)}
                                                />
                                            </div>
                                            <div className={'c-merge-contact-modal__input-wrapper'}>
                                                {fieldKey === ContactConflictProperties.Language
                                                    ? (
                                                        <LanguageSelectField
                                                            availableLanguages={availableLanguages}
                                                            registerSelect={registerNdsSelect}
                                                            isToMerge={false}
                                                        />
                                                    )
                                                    : (
                                                        <RenderInputField
                                                            name={key}
                                                            label={t(keyTranslation)}
                                                            registerInput={registerNdsInput}
                                                            isToMerge={false}
                                                            phoneNumberCountry={phoneNumberCountryExisting}
                                                            handleChange={handleChangeCountryCode}
                                                            renderPhoneNumberInput={fieldKey === ContactConflictProperties.PhoneNumber}
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                    </form>
                </ModalContent>
            }
            renderFooter={
                <ModalFooter>
                    <div className={'c-merge-contact-modal__footer-buttons'}>
                        <NdsButton
                            size={SizesEnums.SMALL}
                            label={t('cancel-button')}
                            buttonType={NdsButtonTypesEnum.NEUTRAL}
                            onClick={() => {
                                onCloseModalClik();
                            }}
                        />
                        <NdsButton
                            size={SizesEnums.SMALL}
                            label={t('confirm-button')}
                            onClick={handleMergeContact}
                        />
                    </div>
                </ModalFooter>
            }
        />
    );
}

export default MergeContactModal;

interface RenderInputFieldProps {
    name: string;
    label: string;
    registerInput: (name: any) => any;
    isToMerge: boolean;
    phoneNumberCountry: CountryInfo | undefined;
    handleChange: (event: DropDownListChangeEvent, isToMerge: boolean) => void;
    renderPhoneNumberInput: boolean;
}

const RenderInputField = ({
    name,
    label,
    registerInput,
    isToMerge,
    phoneNumberCountry,
    handleChange,
    renderPhoneNumberInput,
}: RenderInputFieldProps) => {
    //Used any as in Kendo docs
    const valueRender = (element: ReactElement<HTMLSpanElement>, value: any) => {
        if (!value) {
            return element;
        }
        const children = [
            <span key={2} className='c-merge-contact-modal__dropdown-flag'>
                <CountryPrefixFlag countryCode={value.countryCode} />
                {element.props.children as any}
            </span>,
        ];

        return cloneElement(element, { ...element.props }, children);
    };

    const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {

        const itemChildren = (
            <span>
                <CountryPrefixFlag countryCode={itemProps.dataItem.countryCode} /> {li.props.children as any}
            </span>
        );

        return cloneElement(li, li.props, itemChildren);
    };

    return renderPhoneNumberInput
        ? (
            <div>
                <div className={'c-merge-contact-modal__phone-number-wrapper'}>
                    <div>
                        <DropDownList
                            key={isToMerge ? 'mergeDropdown' : 'existingDropdown'}
                            data={countryInfoList}
                            valueRender={valueRender}
                            dataItemKey='countryCode'
                            textField='phonePrefix'
                            value={phoneNumberCountry}
                            itemRender={itemRender}
                            onChange={(e) => handleChange(e, isToMerge)}
                            className={'c-merge-contact-modal__dropdown-prefix'}
                        />
                    </div>
                    <div className={'c-merge-contact-modal__phone-number-input'}>
                        <NdsInput
                            {...registerInput(isToMerge ? `${name}ToMerge` : `${name}Existing`)}
                            size={SizesEnums.SMALL}
                            inputType={NdsInputTypes.TEXT}
                        />
                    </div>
                </div>
            </div>
        )
        : (
            <NdsInput
                {...registerInput(isToMerge ? `${name}ToMerge` : `${name}Existing`)}
                state={name === 'emailAddress' ? StatesEnum.DISABLED: StatesEnum.DEFAULT}
                size={SizesEnums.SMALL}
                required
            />
        );
};

interface LanguageSelectFieldProps {
    availableLanguages: { isoCultureCode: string; nativeName: string }[];
    registerSelect: (name: any) => any;
    isToMerge: boolean;
}

const LanguageSelectField = ({
    availableLanguages,
    registerSelect,
    isToMerge,
}: LanguageSelectFieldProps) => {
    return (
        <div>
            <NdsSelect
                {...registerSelect(isToMerge? 'languageToMerge' : 'languageExisting')}
                size={SizesEnums.SMALL}
                label={''}
                content-align='bottom-left'
                className='c-merge-contact-modal__select-wrapper'
            >
                <NdsGroup>
                    {availableLanguages.map((lang) => (
                        <NdsSelectOption
                            key={lang.isoCultureCode}
                            label={lang.nativeName}
                            value={lang.isoCultureCode}
                        />
                    ))}
                </NdsGroup>
            </NdsSelect>
        </div>);
};
