import { Modal, ModalContent, ModalFooter, NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { ReactNode } from 'react';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { Actions } from '~constants';
import './unsaved-changes-modal.scss';

interface UnsavedChangesModalProps {
  withClose?: boolean;
  isExtraActionModal: boolean;
  onButtonClick: (type: Actions) => void;
  close: () => void;
  modalContentComponent?: ReactNode;
  modalHeaderLabel?: string;
  modalTitleLabel?: string;
  modalContentLabel?: string;
  cancelButtonLabel: string;
  secondaryActionButtonLabel?: string;
  primaryActionButtonLabel: string;
  loading?: boolean;
}

const UnsavedChangesModal = ({
    withClose=false,
    isExtraActionModal,
    onButtonClick,
    close,
    modalContentComponent,
    modalHeaderLabel,
    modalTitleLabel,
    modalContentLabel,
    cancelButtonLabel,
    secondaryActionButtonLabel,
    primaryActionButtonLabel,
    loading=true,
}: UnsavedChangesModalProps) => {
    const cancel = () => onButtonClick(Actions.CANCEL);
    const primaryAction = () => onButtonClick(Actions.PRIMARY_ACTION);
    const secondaryAction = () => onButtonClick(Actions.SECONDARY_ACTION);

    return (
        <>
            {
                !loading ?
                    <Modal
                        withClose={withClose}
                        onCloseClick={() => {
                            close(); cancel();
                        }}
                        headerLabel={modalHeaderLabel}
                        renderContent={
                            <ModalContent className={'edit-user-confirmation-modal'}>
                                <Typography className={'edit-user-confirmation-modal__header'} token={TypographyToken.DesktopDescriptionBoldLg}>
                                    {modalTitleLabel}
                                </Typography>
                                <Typography className={'edit-user-confirmation-modal__content'} token={TypographyToken.DesktopDescriptionMd}>
                                    {modalContentLabel}
                                </Typography>
                                {modalContentComponent}
                            </ModalContent>
                        }
                        renderFooter={
                            <ModalFooter
                                className={'edit-user-confirmation-modal__footer'}
                                style={{ width: isExtraActionModal ? '100%' : 'auto' }}
                            >
                                {
                                    isExtraActionModal ?
                                        <>
                                            <NdsButton
                                                size={SizesEnums.SMALL}
                                                className={'edit-user-confirmation-modal__footer__cancel-button'}
                                                buttonType={NdsButtonTypesEnum.NEUTRAL}
                                                label={cancelButtonLabel}
                                                onClick={() => {
                                                    close(); cancel();
                                                }}
                                            />
                                            <NdsButton
                                                size={SizesEnums.SMALL}
                                                buttonType={NdsButtonTypesEnum.SECONDARY}
                                                label={secondaryActionButtonLabel}
                                                onClick={() => {
                                                    close(); secondaryAction();
                                                }}
                                            />
                                            <NdsButton
                                                size={SizesEnums.SMALL}
                                                label={primaryActionButtonLabel}
                                                onClick={() => {
                                                    primaryAction();
                                                    cancel();
                                                    close();
                                                }}
                                            />
                                        </>
                                        :
                                    // Condition is needed because this modal is going to be used for confirmation of substitute removal too
                                        <>
                                            <NdsButton
                                                className={'edit-user-confirmation-modal__footer__cancel-button'}
                                                size={SizesEnums.SMALL}
                                                buttonType={NdsButtonTypesEnum.NEUTRAL}
                                                label={cancelButtonLabel}
                                                onClick={cancel}
                                            />
                                            <NdsButton
                                                label={primaryActionButtonLabel}
                                                size={SizesEnums.SMALL}
                                                onClick={primaryAction}
                                            />
                                        </>
                                }
                            </ModalFooter>
                        }
                    />
                    :
                    <Loader center />
            }
        </>
    );
};

export default UnsavedChangesModal;
