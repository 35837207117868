import { ConfirmModal, Modal, ModalContent, ModalFooter, NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { BkgModesEnum } from '@gonitro/ui-design-system/dist/lit';
import { useCallback, useRef, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import Error from '~components/error';
import Loader from '~components/loader';
import { FlowAction } from '~constants';
import { UserManagementAction } from '~constants/user-management-action';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { DateUtil } from '~lib/date.utils';
import { LanguageDataLabel } from '~models';
import { PackageListItemModel, SigningSessionListItemModel, TemplateListItemModel } from '~models/portals.models';
import QuickActionExtend from './quick-action-extend';
import ReassignContentQuickAction from './reassign-content-quick-action';
import { ReassignFormData } from './reassign-content-quick-action/reassignContentQuickAction';
import './quickActionsModal.scss';

const ButtonI18n: Partial<Record<FlowAction | UserManagementAction, LanguageDataLabel<'flow'>>> = {
    [FlowAction.End]: 'action-end-package',
    [FlowAction.Delete]: 'action-delete',
    [FlowAction.SendToSigners]: 'action-send-to-signers',
    [FlowAction.SendReminder]: 'action-send-reminder',
    [FlowAction.Revoke]: 'action-revoke',
    [FlowAction.Extend]: 'action-extend',
    [FlowAction.Reassign]: 'action-reassign',
    [FlowAction.SoftDelete]: 'action-delete',
};

type ListItemModel = PackageListItemModel | SigningSessionListItemModel | TemplateListItemModel | undefined;

export interface QuickActionsModalProps {
    data: ListItemModel;
    actionType: FlowAction | UserManagementAction | undefined;
    handleCloseModal: () => void;
    handleClickConfirm: () => void;
    closePanel?: () => void;
    additionalInformation?: LanguageDataLabel<'flow'>;
    warningMessage?: LanguageDataLabel<'flow'>;
}

export default function QuickActionsModal({
    data,
    actionType,
    handleCloseModal,
    handleClickConfirm,
    closePanel,
    additionalInformation,
    warningMessage,
}: QuickActionsModalProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const { t: tFlow } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');
    const { t: tNotifications } = useTranslation('notifications');
    const formRef = useRef<HTMLFormElement>(null);
    const portalsApi = useApi(PortalsApi);
    const showNotification = useShowNotification();

    const isPackageListItemModel = (data: any): data is PackageListItemModel => {
        return (data as PackageListItemModel).documentName !== undefined;
    };

    const handleSubmitExtend = useCallback(
        async (expirationTimestamp: string, data: any) => {
            const { packageId } = data;

            showNotification({
                type: 'pending',
                icon: 'fa-regular fa-calendar-circle-plus',
                title: tNotifications('pending-title'),
                message: tNotifications('extend', { documentName: data.documentName }),
                hideAfterMs: 2000,
            });
            try {
                const response = await portalsApi.extendPackage({
                    packageId,
                    expirationTimestamp,
                });

                if(response) {
                    setTimeout(() => {
                        showNotification({
                            type: 'success',
                            title: tNotifications('extend-success-title'),
                            message: tNotifications('extend-success-message', {
                                documentName: data.documentName,
                                date : DateUtil.format(new Date(expirationTimestamp), 'dd/MM/yyyy - HH:mm'),
                            }),
                            hideAfterMs: 2000,
                        });
                    }, 2300);

                    if(closePanel) {
                        closePanel();
                    }
                    handleCloseModal();
                }
            } catch (e) {
                showNotification({
                    type: 'error',
                    title: tNotifications('extend-error-title',  { documentName: data.documentName }),
                    hideAfterMs: 2000,
                });
                console.warn(e);
            }

        },
        [
            closePanel,
            handleCloseModal,
            portalsApi,
            showNotification,
            tNotifications,
        ],
    );

    const handleSubmitReassign = useCallback(
        async (formData: ReassignFormData, data: any) => {
            setIsLoading(true);
            const { selectedRecipientEmail, ...rest } = formData;
            const params = rest;

            showNotification({
                type: 'pending',
                icon: 'fa-regular fa-arrow-right',
                title: tNotifications('pending-title'),
                message: tNotifications('reassign', {
                    documentName: data.documentName,
                    selectedRecipient: selectedRecipientEmail,
                }),
                hideAfterMs: 2000,
            });
            try {
                const response = await portalsApi.reassignPackage(params);

                if(response) {
                    setTimeout(() => {
                        showNotification({
                            type: 'success',
                            title: tNotifications('reassign-success-title'),
                            message: tNotifications('reassign-success-message', {
                                documentName: data.documentName,
                                selectedRecipient: selectedRecipientEmail,
                            }),
                            hideAfterMs: 2000,
                        });
                    }, 2300);

                    if(closePanel) {
                        closePanel();
                    }
                    handleCloseModal();
                }

            } catch (error) {
                showNotification({
                    type: 'error',
                    title: tNotifications('reassign-error-title',  { documentName: data.documentName }),
                    hideAfterMs: 2000,
                });
                setError('something-went-wrong');
            } finally {
                setIsLoading(false);
            }
        },
        [
            closePanel,
            handleCloseModal,
            portalsApi,
            showNotification,
            tNotifications,
        ],
    );


    // Check to see if the data model is the template model
    const isTemplateListItemModel = (data: ListItemModel): data is TemplateListItemModel => {
        return (data as TemplateListItemModel).name !== undefined;
    };

    // Function to determine which modal to render
    return (
        <>
            {actionType && data && (
                <>
                    {warningMessage && (
                        <ConfirmModal
                            bkgMode={BkgModesEnum.LIGHT}
                            className={'c-quick-actions-modal'}
                            size={SizesEnums.MEDIUM}
                            confirmLabel={tFlow(ButtonI18n[actionType]!)}
                            description={additionalInformation && tFlow(additionalInformation)}
                            cancelLabel={tBase('cancel')}
                            title={warningMessage && tFlow(warningMessage)}
                            onCloseClick={handleCloseModal}
                            onConfirmClick={() => {
                                handleClickConfirm();

                                if(closePanel) {
                                    closePanel();
                                }
                            }}
                        >
                            <div className='c-quick-actions-modal__confirm-content'>
                                <div className='c-quick-actions-modal__confirm-item'>
                                    <div>
                                        {isPackageListItemModel(data)
                                            ? data.documentName
                                            : isTemplateListItemModel(data)
                                                ? data.name
                                                : data.packageName}
                                    </div>
                                    <div>
                                        {data.createdOn
                                            ? DateUtil.format(data.createdOn, 'dd/MM/yyyy')
                                            : 'Missing response'}
                                    </div>
                                </div>
                            </div>
                        </ConfirmModal>
                    )}
                    {!warningMessage && !isTemplateListItemModel(data) && (
                        <Modal
                            headerLabel={tFlow(ButtonI18n[actionType]!)}
                            bkgMode={BkgModesEnum.LIGHT}
                            size={SizesEnums.SMALL}
                            onCloseClick={handleCloseModal}
                            withClose={true}
                            className={'c-quick-actions-modal'}
                            renderContent={
                                <ModalContent>
                                    {actionType === FlowAction.Extend
                                        ? (
                                            <QuickActionExtend
                                                onSubmit={(expirationIso: string) => {
                                                    data.packageId && handleSubmitExtend(expirationIso, data);
                                                }}
                                                formRef={formRef}
                                            />
                                        )
                                        : (
                                            isPackageListItemModel(data) && (
                                                <>
                                                    {error && <Error
                                                        className='c-quick-actions-modal__error-text'
                                                        i18nKey={error}
                                                    />}
                                                    <ReassignContentQuickAction
                                                        data={data}
                                                        submitForm={(formData) => handleSubmitReassign(formData, data)}
                                                        formRef={formRef}
                                                    />
                                                </>
                                            )
                                        )}
                                </ModalContent>
                            }
                            renderFooter={
                                <ModalFooter>
                                    <div className={'c-quick-actions-modal__extend-reassign-footer-buttons'}>
                                        <NdsButton
                                            size={SizesEnums.SMALL}
                                            label={tBase('cancel')}
                                            buttonType={NdsButtonTypesEnum.NEUTRAL}
                                            onClick={handleCloseModal}
                                        />
                                        {isLoading
                                            ? (
                                                <div className='c-quick-actions-modal__loader'>
                                                    <Loader size={SizesEnums.SMALL} />
                                                </div>
                                            )
                                            : (
                                                <NdsButton
                                                    state={StatesEnum.DEFAULT}
                                                    size={SizesEnums.SMALL}
                                                    label={tFlow(ButtonI18n[actionType]!)}
                                                    onClick={() => {
                                                        actionType === FlowAction.Extend
                                                            ? formRef.current?.requestSubmit()
                                                            : formRef.current?.onSubmit();
                                                    }
                                                    }
                                                />
                                            )}
                                    </div>
                                </ModalFooter>
                            }
                        />
                    )}
                </>
            )}
        </>
    );
}
