import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';
import { PanelBar, PanelBarItem, PanelBarSelectEventArguments } from '@progress/kendo-react-layout';
import classNames from 'classnames';
import React, { Dispatch, MouseEvent, SetStateAction, useCallback, useMemo, useRef } from 'react';
import FlowEditorStakeholderUi
    from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/flow-editor-stakeholder-ui';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType } from '~constants';
import { useFlowContext } from '~contexts/flow';
import { useDeleteActor, useGetStakeholderInfo } from '~contexts/flow/flow.hooks';
import { FlowActionType, FlowGroupType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useTranslation } from '~contexts/i18n';
import { FlowReceiverModel, FlowStakeholderModel, Guid } from '~models';
import './flowEditorRecipientsReceivers.scss';

const ReceiversGroupTitle = ({ type, onAddClick }: {
    type: FlowGroupType,
    onAddClick: (type: FlowGroupType) => void
}) => {
    const { t } = useTranslation('flow');
    const onClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        onAddClick(type);
    }, [onAddClick, type]);

    return (
        <div className={'c-flow-editor-recipients-receivers__header'}>
            <Typography
                token={TypographyToken.MobileTagSm}
                className={'c-flow-editor-recipients-receivers__header-text'}
                text={t('recipients-title-receivers')}
            />
            <Button
                iconClass={'fa-solid fa-plus'}
                type={'button'}
                fillMode={'flat'}
                onClick={onClick}
            />
        </div>
    );
};

export interface FlowEditorRecipientsReceiversProps {
    filter?: string;
    onAddClick: () => void;
    expanded: boolean;
    setExpanded: Dispatch<SetStateAction<boolean>>
}

type FlowReceiverWithStakeholderModel = {
    actor: FlowReceiverModel;
    stakeholder?: FlowStakeholderModel;
    displayName: string;
}

const EMPTY: string[] = [];
const RECEIVERS = ['r'];

function FlowEditorRecipientsReceivers({
    filter,
    onAddClick,
    expanded,
    setExpanded,
}: FlowEditorRecipientsReceiversProps) {
    const { setFlowState, flowState: { flow, properties: flowProperties } } = useFlowContext();
    const prevLength = useRef(0);

    const handleExpandedChange = useCallback((event: PanelBarSelectEventArguments) => {
        if (event.expandedItems) {
            setExpanded(event.expandedItems.length > 0);
        }
    }, [setExpanded]);

    const { t } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');

    const getStakeholderInfo = useGetStakeholderInfo();
    const { receivers, noResults } = useMemo(() => {
        let receivers: FlowReceiverWithStakeholderModel[] = [];

        if (!flow.length || flow[flow.length - 1][0]?.actorType !== ActorType.Receiver) {
            prevLength.current = 0;

            return {
                receivers,
                noResults: false,
            };
        }
        receivers = flow[flow.length - 1].map((el) => {
            const stakeholder = getStakeholderInfo(el.stakeholderLocalId);

            return {
                actor: el as FlowReceiverModel,
                stakeholder,
                displayName: FlowUtils.getStakeholderDisplayName(stakeholder),
            };
        });

        if (filter?.length) {
            receivers = receivers.filter((el) => {
                return el.displayName.toLowerCase().search(filter) > -1;
            });
        }

        receivers = receivers.sort((a, b) => {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                return -1;
            }
            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                return 1;
            }

            return 0;
        });

        return {
            receivers,
            noResults: flow[flow.length - 1].length && receivers.length === 0,
        };
    }, [filter, flow, getStakeholderInfo]);

    const deleteActor = useDeleteActor();

    const onDeleteClick = useCallback((localId?: Guid) => {
        const receivers = flow[flow.length - 1];

        if (!receivers.length || receivers[0].actorType !== ActorType.Receiver) {
            console.warn('Last flow step supposed to be not empty or be receivers step');

            return;
        }
        const actor = receivers.find(el => el.stakeholderLocalId === localId);

        if (actor) {
            deleteActor(actor.localId);
        }
    }, [deleteActor, flow]);

    return (
        <div className={'c-flow-editor-recipients-receivers'}>
            <PanelBar
                isControlled
                onSelect={handleExpandedChange}
                expanded={expanded ? RECEIVERS : EMPTY}
            >
                <PanelBarItem
                    uniquePrivateKey={'r'}
                    id={'r'}
                    className={classNames('c-flow-editor-recipients-receivers__panelbar')}
                    title={<ReceiversGroupTitle
                        type={FlowGroupType.Receivers}
                        onAddClick={onAddClick}
                    />}
                >
                    <ul className={'c-flow-editor-recipients-receivers__list'}>
                        <li className={'c-flow-editor-recipients-receivers__list-item c-flow-editor-recipients-receivers__list-item--switch'}>
                            <Switch
                                checked={flowProperties?.addInitiatorAsReceiver ?? false}
                                size={'small'}
                                offLabel={''}
                                onLabel={''}
                                onChange={(e) => {
                                    setFlowState(FlowActionType.UpdateProperties, {
                                        ...flowProperties,
                                        addInitiatorAsReceiver: e.value,
                                    });
                                }}
                            />
                            <Typography
                                token={TypographyToken.UiFormsLabelSm}
                                text={t('set-yourself-as-receiver')}
                            />
                        </li>
                        {receivers.length > 0 && (
                            receivers.map((el, idx) => {
                                if (!el.stakeholder) {
                                    return null;
                                }

                                return (
                                    <li
                                        key={el.actor.localId}
                                        className={'c-flow-editor-recipients-receivers__list-item'}
                                    >
                                        <FlowEditorStakeholderUi
                                            stakeholder={el.stakeholder}
                                            hideValidation
                                            isReceiverUI
                                            onDeleteClick={onDeleteClick}
                                        />
                                    </li>
                                );
                            })
                        )}
                        {noResults && (
                            <li className={'c-flow-editor-recipients-receivers__list-item--empty'}>
                                <Typography
                                    token={TypographyToken.DesktopDescriptionSm}
                                    tagName={'div'}
                                    text={tBase('no-results')}
                                />
                            </li>
                        )}
                    </ul>
                </PanelBarItem>
            </PanelBar>
        </div>
    );
}

export default FlowEditorRecipientsReceivers;
