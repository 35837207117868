import { Checkbox } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Button } from '@progress/kendo-react-buttons';
import { useCallback, useRef, useState } from 'react';
import ButtonWithLoader from '~components/button-with-loader';
import ContactForm from '~components/contact-form';
import ContactMissingInformationModal from '~components/contact-missing-information-modal';
import ContactSelector from '~components/contact-selector';
import Panel from '~components/panel';
import { ContactDiscriminator } from '~constants';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { ContactBucketContactModel, ContactBucketItemModel } from '~models';
import './flowEditorAddRecipientPanel.scss';

export interface FlowEditorAddRecipientPanelProps {
    addReceiver?: boolean;
    allowMultiple?: boolean;
    canAddRecipientWithoutContactInfo?: boolean;
    onCloseClick: () => void;
    addClickCallback: (refreshStakeholders: boolean, contact?: ContactBucketItemModel[]) => Promise<any>;
}

function FlowEditorAddRecipientPanel({
    addReceiver,
    allowMultiple,
    onCloseClick: onCloseClickProp,
    canAddRecipientWithoutContactInfo,
    addClickCallback,
}: FlowEditorAddRecipientPanelProps) {
    const { t } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');
    const [contacts, setContacts] = useState<ContactBucketItemModel[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [isContactSelectionDisabled, setIsContactSelectionDisabled] = useState(false);
    const [openAddContact, setOpenAddContact] = useState<boolean>(false);
    const shouldRefreshStakeholders = useRef(false);
    const [contactMissingInfo, setContactMissingInfo] = useState<{ lastName: boolean, emailAddress: boolean }>();
    const modalContentRef = useRef<HTMLDivElement>(null);

    const onContactsChange = useCallback((data: ContactBucketItemModel[], wasContactsEdited: boolean) => {
        const missing = data.find((el) => el.discriminator === ContactDiscriminator.CloudContact && (
            !el.lastName || !el.emailAddress
        ));

        if (missing) {
            setContactMissingInfo({
                lastName: !(missing as ContactBucketContactModel).lastName,
                emailAddress: !(missing as ContactBucketContactModel).emailAddress,
            });
        } else {
            setContacts(allowMultiple ? data : data.slice(0, 1));
        }
        setTimeout(() => {
            if (modalContentRef.current) {
                modalContentRef.current?.scrollTo(0, modalContentRef.current.scrollHeight - modalContentRef.current.clientHeight);
            }
        }, 10);
        shouldRefreshStakeholders.current = shouldRefreshStakeholders.current || wasContactsEdited;
    }, [allowMultiple]);

    const onAddClick = useCallback(async () => {
        if (!contacts.length && !canAddRecipientWithoutContactInfo) {
            return;
        }

        setLoading(true);
        try {
            if (!contacts.length) {
                await addClickCallback(shouldRefreshStakeholders.current);
            } else {
                await addClickCallback(shouldRefreshStakeholders.current, contacts);
            }
        } catch (e) {
            console.warn(e);
        }
        setLoading(false);
    }, [addClickCallback, canAddRecipientWithoutContactInfo, contacts]);

    const onMissingModalCloseClick = useCallback(() => {
        setContactMissingInfo(undefined);
    }, []);


    const addBtnEnabled = (canAddRecipientWithoutContactInfo === true) || (contacts.length && !contactMissingInfo);

    return (
        <>
            <Panel
                className={'c-flow-editor-add-recipient-panel'}
                contentClassName={'c-flow-editor-add-recipient-panel__content'}
                onCloseClick={onCloseClickProp}
                title={addReceiver ? t('add-receivers-modal-title') : t('add-recipients-modal-title')}
                showLoader={isLoading}
                actions={
                    <>
                        <Button onClick={onCloseClickProp} disabled={isLoading}>{tBase('cancel')}</Button>
                        <ButtonWithLoader
                            themeColor={'primary'}
                            disabled={!addBtnEnabled}
                            showLoader={isLoading}
                            onClick={onAddClick}
                            loaderProps={{ themeColor: 'light' }}
                        >{tBase('add')}</ButtonWithLoader>
                    </>
                }
            >
                <ContactSelector
                    onChange={onContactsChange}
                    value={contacts}
                    disabled={isContactSelectionDisabled}
                    showAddContactButton
                    showCloudButton
                    allowMultiple={allowMultiple}
                    label={addReceiver ? t('add-receivers-modal-label') : t('add-recipients-modal-label')}
                    className={'c-flow-editor-add-recipient-panel__selector'}
                    valuesClassName={'c-flow-editor-add-recipient-panel__selector-values'}
                />
                {canAddRecipientWithoutContactInfo && (
                    <Checkbox
                        value={isContactSelectionDisabled}
                        size={SizesEnums.SMALL}
                        name={'without-recipient'}
                        label={t('add-recipient-without-contact-info')}
                        onChange={() => {
                            if (!isContactSelectionDisabled) {
                                setContacts([]);
                            }

                            setIsContactSelectionDisabled(!isContactSelectionDisabled);
                        }}
                    />
                )}
            </Panel>
            <OverlayPortal type={'modal'} visible={!!contactMissingInfo}>
                {() => <ContactMissingInformationModal
                    onCloseClick={onMissingModalCloseClick}
                    missing={contactMissingInfo ?? {}}
                />}
            </OverlayPortal>
            <OverlayPortal type={'panel'} visible={openAddContact}>
                {() => <ContactForm
                    open={true}
                    onClose={() => setOpenAddContact(false)}
                />}
            </OverlayPortal>
        </>
    );
}

export default FlowEditorAddRecipientPanel;
