import Typography, { TypographyToken } from '~components/typography';
import { AbsencePeriod } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { DateUtil } from '~lib/date.utils';
import './outOfOfficeBanner.scss';

export interface OutOfOfficeBannerProps {
    absencePeriod: AbsencePeriod
}

function OutOfOfficeBanner({ absencePeriod }: OutOfOfficeBannerProps) {
    const { t } = useTranslation('notifications');

    const endDate = DateUtil.format(absencePeriod.endDate, 'dd/MM/yyyy');
    const endTime = DateUtil.format(absencePeriod.endDate, 'HH:mm');

    return (
        <div className={'c-out-of-office-banner'}>
            <Typography
                className={'c-bulk-actions-modal-item__ellipsis-text'}
                text={t('out-of-office-banner-message', {
                    date: endDate,
                    time: endTime,
                })}
                token={TypographyToken.DesktopBylineXs}
            />

        </div>
    );
}

export default OutOfOfficeBanner;
