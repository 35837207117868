import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { PropsWithChildren, Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { DocumentViewerRef } from '~components/document-viewer';
import { FlowEditorRecipientsRef } from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/flowEditorRecipients';
import { FlowEditorLeftPaneRef } from '~components/flow/flow-editor/flow-editor-left-pane/flowEditorLeftPane';
import Loader from '~components/loader';
import { ApiErrors } from '~constants';
import { useAsyncEffect } from '~hooks/effects';
import { useLocationState } from '~hooks/router';
import { HttpError } from '~lib/http/http.error';
import { FlowDetailDocument } from '~models';
import { useLastWorkspaceId } from '../app';
import { useFlowApiWrapper } from './flow.api-wrapper';
import { FlowContext, FlowContextType, FlowDocumentViewerConfigType, FlowStateType } from './flow.context';
import { useFlowDispatcher } from './flow.dispatcher';
import { useFlowInternalErrors, useFlowInternalStakeholderColors } from './flow.hooks-internal';
import {
    useFlowInternalFocusedEntity,
    useFlowInternalNavigateToPortal,
    useFlowInternalNavigateToStep,
    useFlowInternalTypeAndStep,
} from './flow.hooks.internal';
import { flowReducer } from './flow.reducer';
import {
    FlowActionType,
    FlowStateAction,
    FlowStep,
} from './flow.types';

export interface FlowContextProviderProps extends PropsWithChildren {
}

export function FlowContextProvider({ children }: FlowContextProviderProps) {
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(false);
    const [isProcessing, setProcessing] = useState<boolean>(false);

    const historyState = useLocationState<{ isActionNeeded?: boolean }>();
    const documentViewerRef = useRef<DocumentViewerRef>(null);
    const leftPaneRef = useRef<FlowEditorLeftPaneRef>(null);
    const recipientsTabRef = useRef<FlowEditorRecipientsRef>(null);
    const { flowType, flowStep } = useFlowInternalTypeAndStep();
    const { typePath, id } = useMatch('/:typePath/:stepPath/:id?')!.params;

    const [isActionNeeded, setIsActionNeeded] = useState<boolean>(false);
    const [visibleDocument, setVisibleDocument] = useState<FlowDetailDocument>();
    const [visibleInViewportDocuments, setVisibleInViewportDocuments] = useState<FlowDetailDocument[]>([]);

    const lastWorkspaceId = useLastWorkspaceId()!; // if null it means there is no permission so page cannot be entered = it's always there

    const [flowState, dispatch] = useReducer<Reducer<FlowStateType, FlowStateAction>>(flowReducer, {
        flowId: id,
        workspaceId: lastWorkspaceId,
        infoDocuments: [],
        deletedDocuments: [],
        detailDocuments: [],
        isLegalNoticeEnabled: false,
        legalNotices: [],
        flow: [],
        elements: [],
        stakeholders: [],
    });

    const api = useFlowApiWrapper(flowType, flowState.flowId);
    const setFlowState = useFlowDispatcher(dispatch);

    const {
        setErrorsInternal,
        errors,
        hasErrors,
        hasWarnings,
        getEntityValidationResult,
        setError,
        clearError,
        getErrorInfo,
        hasError,
    } = useFlowInternalErrors();

    const navigateToPortal = useFlowInternalNavigateToPortal({
        flowState,
        flowType,
    });
    const navigateToStep = useFlowInternalNavigateToStep({
        flowState,
        flowTypePath: typePath!,
    });

    const [documentViewerConfig, setDocumentViewerConfig] = useState<FlowDocumentViewerConfigType>({
        zoomLevel: 100,
        justifyPages: true,
        adjustViewerToScreen: false,
    });
    const setDocumentViewerConfigCallback = useCallback((config: Partial<FlowDocumentViewerConfigType>) => {
        setDocumentViewerConfig(current => ({
            ...current,
            ...config,
        }));
    }, []);

    const stakeholderColors = useFlowInternalStakeholderColors(flowState.flowId, flowState.stakeholders);

    const {
        focusedEntityId,
        focusedEntityType,
        focusedEntityData,
        setFocusedEntity,
    } = useFlowInternalFocusedEntity();

    useEffect(() => {
        setIsReady(false);
    }, [flowStep, historyState.isActionNeeded]);

    useEffect(() => {
        if (id && id !== flowState.flowId) {
            setFlowState(FlowActionType.SetFlowId, id);
            setIsReady(false);
        }
        if (id || flowState.flowId) {
            setIsNew(false);
        }
    }, [flowState.flowId, id, setFlowState]);

    useEffect(() => {
        if (!flowState.flowId && flowStep) {
            setIsNew(true);
            setIsReady(true);
        }
    }, [flowState.flowId, flowStep]);

    const fetchBasicData = useCallback(async (signal?: AbortSignal) => {
        if (flowStep === FlowStep.Documents) {
            try {
                const info = await api.getBasicInfo(signal);

                setFlowState(FlowActionType.SetDocuments, info.documents);
                setIsReady(true);
            } catch (e) {
                if (signal?.aborted) {
                    return;
                }
                if (e instanceof HttpError) {
                    const code = e.getErrorCode();

                    if (code === ApiErrors.PackageInvalidStatusProcessing) {
                        navigateToStep(FlowStep.Processing);

                        return;
                    } else if (code?.startsWith(ApiErrors.PackageInvalidStatusGeneric)) {
                        navigateToPortal();

                        return;
                    }
                }
                throw e;
            }

        } else if (flowStep === FlowStep.Detail) {
            if (historyState.isActionNeeded) {
                setIsActionNeeded(true);
                setIsReady(true);

                return;
            } else {
                setIsActionNeeded(false);
            }
            try {
                const result = await Promise.all([
                    api.getDetails(signal),
                    api.getAvailableLegalNotices(signal),
                    api.getStakeholders(signal),
                    api.getWorkflow(signal),
                    api.getElements(signal),
                ]);

                setFlowState(FlowActionType.SetInitialDetails, result[0]);
                setFlowState(FlowActionType.SetInitialLegalNoticeConfig, result[1]);
                setFlowState(FlowActionType.SetInitialFlow, {
                    stakeholders: result[2],
                    workflow: result[3].queryResult,
                    elements: result[4],
                });

                setIsReady(true);
            } catch (e) {
                if (signal?.aborted) {
                    return;
                }

                if (e instanceof HttpError) {
                    const code = e.getErrorCode();

                    if (code === ApiErrors.PackageInvalidStatusProcessingFailed) {
                        navigateToStep(FlowStep.Documents);

                        return;
                    } else if (code?.startsWith(ApiErrors.PackageInvalidStatusGeneric)) {
                        navigateToPortal();

                        return;
                    }
                }
                throw e;
            }

        } else {
            setIsReady(true);
        }
    }, [
        api,
        flowStep,
        historyState.isActionNeeded,
        navigateToPortal,
        navigateToStep,
        setFlowState,
    ]);

    useAsyncEffect(() => async (signal: AbortSignal) => {
        if (isReady || !flowState.flowId) {
            return;
        }
        await fetchBasicData(signal);
    }, [fetchBasicData, flowState.flowId, isReady]);

    const contextValue = useMemo<FlowContextType>(() => ({
        flowType,
        isNew,
        isProcessing,
        setProcessing,
        isActionNeeded,
        flowState,
        setFlowState,
        api,
        _setErrorsInternal: setErrorsInternal,
        errors,
        hasErrors,
        hasWarnings,
        getEntityValidationResult,
        hasError,
        getErrorInfo,
        setError,
        clearError,
        step: flowStep,
        navigateToStep,
        navigateToPortal,
        refreshData: fetchBasicData,
        focusedEntityId,
        focusedEntityType,
        focusedEntityData,
        setFocusedEntity,
        visibleDocument,
        setVisibleDocument,
        visibleInViewportDocuments,
        setVisibleInViewportDocuments,
        documentViewerConfig,
        setDocumentViewerConfig: setDocumentViewerConfigCallback,
        stakeholderColors,
        documentViewerRef,
        leftPaneRef,
        recipientsTabRef,
    }), [
        flowType,
        isNew,
        isProcessing,
        isActionNeeded,
        flowState,
        setFlowState,
        api,
        setErrorsInternal,
        errors,
        hasErrors,
        hasWarnings,
        getEntityValidationResult,
        hasError,
        getErrorInfo,
        setError,
        clearError,
        flowStep,
        navigateToStep,
        navigateToPortal,
        fetchBasicData,
        focusedEntityId,
        focusedEntityType,
        focusedEntityData,
        setFocusedEntity,
        visibleDocument,
        visibleInViewportDocuments,
        setVisibleInViewportDocuments,
        documentViewerConfig,
        setDocumentViewerConfigCallback,
        stakeholderColors,
    ]);

    return (
        <FlowContext.Provider value={contextValue}>
            {!isReady && <Loader center size={SizesEnums.XLARGE} />}
            {isReady && children}
        </FlowContext.Provider>
    );
}
