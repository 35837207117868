import { Icon, IconProps } from '@progress/kendo-react-common';
import { Tooltip } from '@progress/kendo-react-tooltip';
import classNames from 'classnames';
import React from 'react';
import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { FlowStakeholderModel, StakeholderType } from '~models';
import './flow-util-components.scss';

export interface StakeholderDisplayNameProps {
    stakeholder?: FlowStakeholderModel;
}

export const StakeholderDisplayName = ({ stakeholder }: StakeholderDisplayNameProps) => {
    const name = FlowUtils.getStakeholderDisplayName(stakeholder);

    return name.length ? <>{name}</> : null;
};

export interface StakeholderIconProps {
    stakeholder?: FlowStakeholderModel;
}

export const StakeholderIcon = ({ stakeholder }: StakeholderIconProps) => {
    if (stakeholder?.type === StakeholderType.Group) {
        return <Icon className={'fa-solid fa-users c-flow-stakeholder-icon'} />;
    } else if (stakeholder?.type === StakeholderType.Person) {
        const displayName = FlowUtils.getStakeholderDisplayName(stakeholder);

        return displayName.length
            ? (
                <span
                    className={'c-flow-stakeholder-icon c-flow-stakeholder-icon--avatar'}
                >
                    {displayName[0].toUpperCase()}
                </span>
            )
            : <Icon className={'fa-solid fa-user c-flow-stakeholder-icon'} />;
    } else {
        return <Icon className={'fa-solid fa-user c-flow-stakeholder-icon'} />;
    }
};

export interface FieldTypeIconProps extends IconProps {
    type: FieldType | ActorType.Approver;
    alternate?: boolean;
}

export const FieldTypeIcon = ({ type, alternate, ...props }: FieldTypeIconProps) => {
    switch (type) {
        case FieldType.TextBox:
            return <Icon {...props} className={'fa-solid fa-text'} />;
        case FieldType.CheckBox:
            return <Icon {...props} className={'fa-regular fa-square-check'} />;
        case FieldType.RadioGroup:
            return <Icon {...props} className={'fa-regular fa-circle-dot'} />;
        case FieldType.SigningField:
            return <Icon {...props} className={'fa-solid fa-signature'} />;
        case ActorType.Approver:
            return alternate
                ? <Icon {...props} className={'fa-regular fa-circle-check'} /> :
                <Icon {...props} className={'fa-solid fa-circle-check'} />;
    }
};

export type FlowValidationBadgeProps = {
    tooltip?: string;
    error?: boolean;
    warning?: boolean;
}

export const FlowValidationBadge = ({ error, warning, tooltip }: FlowValidationBadgeProps) => {
    if (!error && !warning) {
        return null;
    }
    const icon = (
        <Icon
            className={classNames('fa-solid fa-xs c-flow-validation-badge__icon', {
                'fa-circle-exclamation': error,
                'fa-triangle-exclamation': !error && warning,
            })}
        />
    );

    const wrapperClassName = classNames('c-flow-validation-badge', {
        'c-flow-validation-badge--error': error,
        'c-flow-validation-badge--warning': !error && warning,
    });

    if (!tooltip) {
        return <span className={wrapperClassName}>{icon}</span>;
    }

    return (
        <div className={wrapperClassName}>
            <Tooltip
                anchorElement={'target'}
                parentTitle={true}
                position={'top'}
                tooltipClassName={'c-flow-validation-badge__tooltip'}
            >
                <span title={tooltip}>
                    {icon}
                </span>
            </Tooltip>
        </div>
    );
};
