import { NdsButton } from '@gonitro/rcl';
import { useIsMobile } from '@gonitro/rcl/lib/_hooks';
import { BkgModesEnum, NdsButtonTypesEnum } from '@gonitro/rcl/lib/_types';
import { SizesEnums } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { Slide } from '@progress/kendo-react-animation';
import classNames from 'classnames';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import OutOfOfficeBanner from '~components/out-of-office-banner';
import Sidebar from '~components/sidebar';
import { useAuthContext } from '~contexts/auth';
import { useSettings } from '~contexts/settings';
import useCheckAbsences from '~hooks/useCheckAbsences';
import { DomUtils } from '~lib';
import HeaderMenu from '../header-menu';
import Logo from '../logo';
import './header.scss';

export interface HeaderProps extends HTMLAttributes<HTMLElement> {
}

function Header({ className, ...props }: HeaderProps) {
    const { environmentSettings: { portalHelpUrl } } = useSettings();
    const [mobileNavOpened, setMobileNavOpened] = useState(false);
    const [isOutOfOffice, setIsOutOfOffice] = useState(false);
    const location = useLocation();
    const isMobile = useIsMobile();
    const mobileNavRef = useRef(null);
    const userInfo = useAuthContext();

    useCheckAbsences(setIsOutOfOffice);

    // close mobile nav on path change
    useEffect(() => {
        setMobileNavOpened(false);
    }, [location.pathname]);

    // close mobile nav on window resize
    useEffect(() => {
        if (!mobileNavOpened) {
            return;
        }
        const aborter = new AbortController();

        window.addEventListener('resize', () => {
            setMobileNavOpened(false);
        }, { signal: aborter.signal });

        return () => {
            aborter.abort();
        };
    }, [mobileNavOpened]);

    // close mobile (tablet) nav on click outside nav (by mouse down so it happens immediately)
    useEffect(() => {
        if (!mobileNavOpened || isMobile) {
            return;
        }
        const aborter = new AbortController();

        window.addEventListener('mousedown', (event) => {
            if (!event.target) {
                return;
            }
            const inside = DomUtils.findParent(event.target as HTMLElement, (current) => {
                return current === mobileNavRef.current;
            });

            if (!inside) {
                setMobileNavOpened(false);
            }
        }, { signal: aborter.signal });

        return () => {
            aborter.abort();
        };
    }, [isMobile, mobileNavOpened]);

    return (
        <header {...props} className={classNames('c-header-wrapper', className)}>
            <div className='c-header'>
                <div className={'c-header__area c-header__area--left'}>
                    <NdsButton
                        className={'c-header__hamburger'}
                        slot={'button'}
                        leftIcon={'fa-solid-bars'}
                        bgMode={BkgModesEnum.DARK}
                        buttonType={NdsButtonTypesEnum.NEUTRAL}
                        size={SizesEnums.XSMALL}
                        onClick={() => setMobileNavOpened(current => !current)}
                    />
                    <Logo treatAsHomeLink />
                    <div className={'c-header__nav-wrapper'}>
                        <Slide direction={isMobile ? 'down' : 'right'}>
                            {mobileNavOpened && (
                                <div className={'c-header__nav'} ref={mobileNavRef}>
                                    <div className={'c-header__nav-inner'}>
                                        <Sidebar />
                                    </div>
                                </div>
                            )}
                        </Slide>
                    </div>
                </div>
                <div className={'c-header__area c-header__area--right'}>
                    <a
                        href={portalHelpUrl}
                        target={'_blank'}
                        className={'c-header__support-link'}
                        rel='noreferrer'
                    >
                        <NdsButton
                            leftIcon={'fa-solid-circle-question'}
                            label={'Support'}
                            bgMode={BkgModesEnum.DARK}
                            buttonType={NdsButtonTypesEnum.NEUTRAL}
                            size={SizesEnums.XSMALL}
                        />
                    </a>
                    <HeaderMenu />
                </div>
            </div>
            {
                isOutOfOffice && userInfo.absences[0] !== undefined &&  <OutOfOfficeBanner absencePeriod={userInfo.absences[0]}/>
            }
        </header>
    );
}

export default Header;
