import { NdsIconFont, SidePanel } from '@gonitro/rcl';
import { ComboBoxChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { useCallback, useRef, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { PortalsApi } from '~api/portals.api';
import { UserManagementApi } from '~api/user-management.api';
import NavTabs from '~components/nav-tabs';
import Typography, { TypographyToken } from '~components/typography';
import { Actions, DocumentGroupPermission, DocumentGroupPermissionCodes, manageDocumentGroupsPanelTabs, ManageDocumentGroupsPanelTabsEnum } from '~constants';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useAsyncEffect } from '~hooks/effects';
import { useEnhancedForm } from '~hooks/enhanced-form';
import useNavTabs from '~hooks/tabs';
import { useVirtualizedCombobox } from '~hooks/virtualizedCombobox';
import { PageableListParams } from '~models/pageable-list.models';
import { DocumentGroupThemesModel, UserGroupsListItemModel } from '~models/user-management.models';
import Footer from './footer/footer';
import { hardCodedPermissions } from './hard-coded-permissions';
import useHandleToggleSwitch from './hooks/use-handle-toggle-switch';
import ThemesTab from './tab-components/themes-tab';
import UserGroupsTab from './tab-components/user-groups-tab';
import UnsavedChangesModal from '../../unsaved-changes-modal/unsaved-changes-modal';
import './manage-document-groups-panel.scss';

interface ManageDocumentGroupsPanelProps {
    documentGroupName?: string;
    documentGroupId?: number;
    initialUserGroups?: UserGroupsListItemModel[];
    initialThemes?: {
        defaultTheme: DocumentGroupThemesModel;
        themes: DocumentGroupThemesModel[];
    };
    isViewMode: boolean;
    onClosePanel: () => void;
}

export type FormValues = {
    name: string;
}

export interface hardCodedPermissionsType {
    hasPermission: boolean;
    permissionId: number;
    permission: DocumentGroupPermission;
    relatedIds?: DocumentGroupPermissionCodes;
    disabled?: boolean;
}

const ManageDocumentGroupsPanel = ({ documentGroupName, documentGroupId, initialUserGroups, initialThemes, isViewMode, onClosePanel }:ManageDocumentGroupsPanelProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const portalsApi = useApi(PortalsApi);
    //TODO: add real conditions, will do in subtask CEP-15564
    const filterTabConditions = {
        [ManageDocumentGroupsPanelTabsEnum.UserGroups]: true,
        [ManageDocumentGroupsPanelTabsEnum.Themes]: true,
    };
    const { t } = useTranslation('user-management');
    const { t: tNotifications } = useTranslation('notifications');
    const { t: tBase } = useTranslation('base');
    const { handleToggleSwitch, selectedUserGroups, setSelectedUserGroups } = useHandleToggleSwitch();
    const { currentTab, filteredTabs, selectTabHandler } = useNavTabs(manageDocumentGroupsPanelTabs, filterTabConditions);
    const userManagementApi = useApi(UserManagementApi);
    const formMethods = useEnhancedForm<FormValues>({ defaultValues: { name: documentGroupName ?? '' } });
    const { handleSubmit, control, formState: { isDirty, errors } } = formMethods;
    const showNotification = useShowNotification();
    const [themes, setThemes] = useState<DocumentGroupThemesModel[]>();
    const [defaultTheme, setDefaultTheme] = useState<DocumentGroupThemesModel>();
    const [selectedThemes, setSelectedThemes] = useState<DocumentGroupThemesModel[]>();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const removeSelectedUserGroup = (userGroup: UserGroupsListItemModel) => {
        const filteredSelecteduserGroups = selectedUserGroups?.filter((selectedUserGroup: UserGroupsListItemModel) => userGroup.userGroupId !== selectedUserGroup.userGroupId);

        setSelectedUserGroups(filteredSelecteduserGroups);
    };

    const handleSelectOrRemoveUserGroupItem = (userGroup: UserGroupsListItemModel) => {
        const id = userGroup.userGroupId;

        if (selectedUserGroups && selectedUserGroups.map((group: UserGroupsListItemModel) => group.userGroupId).includes(id)) {
            removeSelectedUserGroup(userGroup);
        } else if (!selectedUserGroups) {
            setSelectedUserGroups([
                {
                    ...userGroup,
                    permissions: hardCodedPermissions,
                },
            ]);
        } else {
            const newSelectedUserGroups = [
                ...selectedUserGroups,
                {
                    ...userGroup,
                    permissions: hardCodedPermissions,
                },
            ];

            setSelectedUserGroups(newSelectedUserGroups);
        }
    };

    const removeSelectedTheme = (theme: DocumentGroupThemesModel) => {
        const filteredThemes = selectedThemes?.filter((selectedTheme: DocumentGroupThemesModel) => theme.themeId !== selectedTheme.themeId);

        setSelectedThemes(filteredThemes);
    };

    const handleSelectOrRemoveThemeItem = (theme: DocumentGroupThemesModel) => {
        const id = theme.themeId;

        if (theme.themeId === defaultTheme?.themeId) {
            return;
        } else if (selectedThemes && selectedThemes.map((selectedTheme: DocumentGroupThemesModel) => selectedTheme.themeId).includes(id)) {
            removeSelectedTheme(theme);
        } else if (!selectedThemes) {
            setSelectedThemes([theme]);
        } else {
            const newSelectedThemes = [...selectedThemes, theme];

            setSelectedThemes(newSelectedThemes);
        }
    };

    const fetchUserGroups = useCallback(async (data: PageableListParams, signal?: AbortSignal) => {
        const userGroupResponse = await portalsApi.getUserGroups({ ...data });

        return userGroupResponse;
    }, [portalsApi]);

    useAsyncEffect(() => async () => {
        const response = await portalsApi.getThemes();

        const initialDefaultTheme = response.filter((theme: DocumentGroupThemesModel) => theme.name === 'System theme');

        if (initialUserGroups) {
            setSelectedUserGroups(initialUserGroups);
        }

        if (initialThemes) {
            setSelectedThemes(initialThemes.themes);
            setDefaultTheme(initialThemes.defaultTheme);
        } else {
            setSelectedThemes(initialDefaultTheme);
            setDefaultTheme(initialDefaultTheme[0]);
        }


        setThemes(response);
    }, [
        documentGroupId,
        initialUserGroups,
        initialThemes,
        portalsApi,
        setSelectedUserGroups,
    ]);


    const renderUserGroupItem = (itemProps: ListItemProps) => {
        return (
            <div className={'user-group-tab__user-group-combo-list-item'}>
                <div className='user-group-tab__combo-list-item-title'>
                    <Typography
                        className='user-group-tab__combo-list-item-name'
                        token={TypographyToken.UiMenuSm}
                        text={`${itemProps.dataItem.name}`}
                    ></Typography>
                </div>
                {
                    selectedUserGroups &&
                    selectedUserGroups.map((group: UserGroupsListItemModel) => group.userGroupId === itemProps.dataItem.userGroupId &&
                        <NdsIconFont
                            key={group.userGroupId}
                            className='user-group-tab__combo-check-icon'
                            fontName='fa-solid-check'
                        />)
                }
            </div>);
    };

    const onSubmitCallback = useCallback(async ({ ...data }: FormValues) => {
        let id = documentGroupId;

        if (id == null) {
            // create document group if not editing
            id = (await userManagementApi.createDocumentGroup({ name: data.name })).documentGroupId;
            showNotification({
                type: 'success',
                title: tNotifications('document-group-created-success', { documentGroup: data.name }),
                hideAfterMs: 2000,
            });
        } else {
            // update document group name
            showNotification({
                type: 'success',
                title: tNotifications('document-group-updated-success', { documentGroup: data.name }),
                hideAfterMs: 2000,
            });
            await userManagementApi.updateDocumentGroup({
                name: data.name,
                documentGroupId: id,
            });
        }

        const removedUserGroups = initialUserGroups?.filter((x) => {
            return selectedUserGroups?.findIndex((y) => x.userGroupId === y.userGroupId) === -1;
        });

        if (removedUserGroups) {
            for (let i = 0; i < removedUserGroups.length; i++) {
                const removedUserGroup = removedUserGroups[i];
                for (let j = 0; j < hardCodedPermissions.length; j++) {
                    const permission = hardCodedPermissions[j];

                    await userManagementApi.updateUserGroupPermissionChangesInDocumentGroup({
                        userGroupId: removedUserGroup.userGroupId,
                        documentGroupId: id,
                        permissionId: permission.permissionId,
                        hasPermission: false,
                    });
                }
            }
        }

        if (selectedUserGroups) {
            for (let i = 0; i < selectedUserGroups.length; i++) {
                const group = selectedUserGroups[i];
                const initialGroup = initialUserGroups?.find((x) => x.userGroupId === group.userGroupId);
                for (let j = 0; j < group.permissions.length; j++) {
                    const permission = group.permissions[j];

                    if (initialGroup) {
                        const initialPermissionValue = initialGroup.permissions.find((x) => x.permissionId === permission.permissionId);

                        if (initialPermissionValue == null || initialPermissionValue?.hasPermission !== permission.hasPermission) {
                            await userManagementApi.updateUserGroupPermissionChangesInDocumentGroup({
                                userGroupId: group.userGroupId,
                                documentGroupId: id,
                                permissionId: permission.permissionId,
                                hasPermission: permission.hasPermission,
                            });
                        }
                    } else if (permission.hasPermission) {
                        await userManagementApi.updateUserGroupPermissionChangesInDocumentGroup({
                            userGroupId: group.userGroupId,
                            documentGroupId: id,
                            permissionId: permission.permissionId,
                            hasPermission: permission.hasPermission,
                        });
                    }
                }
            }
        }

        await userManagementApi.updateUserGroupThemeChangesInDocumentGroup({
            documentGroupId: id,
            defaultTheme: { themeId: defaultTheme!.themeId },
            themes: selectedThemes!.map((selectedTheme: DocumentGroupThemesModel) => {
                return {
                    name: selectedTheme.name,
                    themeId: selectedTheme.themeId,
                };
            }),
        });

        onClosePanel();
    }, [
        defaultTheme,
        documentGroupId,
        initialUserGroups,
        onClosePanel,
        selectedThemes,
        selectedUserGroups,
        showNotification,
        tNotifications,
        userManagementApi,
    ]);


    const PAGE_SIZE = 10;
    const userGroupComboboxProps = useVirtualizedCombobox<UserGroupsListItemModel>({
        pageSize: PAGE_SIZE,
        apiFilterKey: 'name',
        fetchData: fetchUserGroups,
        valueKey: 'userGroupId',
        labelKey: 'name',
        onChange: (e) => handleSelectOrRemoveUserGroupItem(e!),
        value: '',
        RenderItem: renderUserGroupItem,
    });

    const themeComboboxProps = {
        onChange: (e: ComboBoxChangeEvent) => handleSelectOrRemoveThemeItem(e.value),
        value: '',
        data: themes,
    };

    const handleOnSaveClick = useCallback(() => {
        if (!Object.keys(errors).length) {
            formRef.current?.requestSubmit();
        } else {
            setIsErrorModalOpen(true);
        }
    }, [errors]);


    const confirmationModalOnClickHandler = useCallback((type: Actions) => {
        const closeModalAndPanel = () => {
            setIsConfirmationModalOpen(false);
            onClosePanel();
        };
        switch (type) {
            case Actions.PRIMARY_ACTION:
                handleOnSaveClick();
                break;
            case Actions.CANCEL:
                setIsConfirmationModalOpen(false);
                break;
            case Actions.SECONDARY_ACTION:
                closeModalAndPanel();
                break;
            default:
                break;
        }
    }, [onClosePanel, handleOnSaveClick]);

    const errorModalOnClickHandler = useCallback((type: Actions) => {
        switch (type) {
            case Actions.PRIMARY_ACTION:
                setIsErrorModalOpen(false);
                break;
            case Actions.CANCEL:
                setIsErrorModalOpen(false);
                break;
            default:
                break;
        }
    }, []);

    return (
        <SidePanel
            onClosePanel={onClosePanel}
            className='manage-documents-panel'
        >
            <FormProvider {...formMethods} >
                <form
                    className={'manage-documents-panel__form'}
                    onSubmit={handleSubmit(onSubmitCallback)}
                    ref={formRef}
                >
                    <Typography token={TypographyToken.DesktopHeaderXs}>{documentGroupId? isViewMode? t('manage-document-groups-view-document-group') : t('manage-document-groups-edit-document-group') : t('manage-document-groups-create-document-group')}</Typography>
                    <div>
                        <Typography token={TypographyToken.DesktopDescriptionSm}>{t('manage-document-groups-document-group-name')}</Typography>
                        <Typography
                            type='span'
                            token={TypographyToken.UiFormsLabelSm}
                            text={'*'}
                            className='manage-documents-panel__required-field'
                        />

                        <Controller
                            control={control}
                            name='name'
                            rules={{ required: t('document-group-name-is-required-error-message') }}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Input disabled={isViewMode} value={value} onChange={(e) => onChange(e)}/>
                                );
                            }}
                        />
                        {errors
                            && <Typography
                                token={TypographyToken.DesktopDescriptionSm}
                                text={
                                    errors.name?.message}
                                className={'manage-documents-panel__error-hint'}
                            />
                        }
                    </div>
                    <NavTabs
                        navTabs={filteredTabs}
                        selectTabHandler={selectTabHandler}
                        currentTab={currentTab}
                    />
                    <div className={'manage-documents-panel__tab-container'}>
                        {
                            currentTab.key === ManageDocumentGroupsPanelTabsEnum.UserGroups &&
                            <UserGroupsTab
                                isViewMode={isViewMode}
                                userGroupComboboxProps={userGroupComboboxProps}
                                selectedUserGroups={selectedUserGroups!}
                                removeSelectedUserGroupHandler={removeSelectedUserGroup}
                                setSelectedUserGroups={handleToggleSwitch}
                            />
                        }
                        {
                            currentTab.key === ManageDocumentGroupsPanelTabsEnum.Themes &&
                            <ThemesTab
                                isViewMode={isViewMode}
                                defaultTheme={defaultTheme!}
                                removeSelectedTheme={removeSelectedTheme}
                                selectedThemes={selectedThemes!}
                                setDefaultTheme={setDefaultTheme}
                                themeComboboxProps={themeComboboxProps}
                            />
                        }
                    </div>
                    {!isViewMode && <Footer
                        closePanel={() => {
                            isDirty
                                ?
                                setIsConfirmationModalOpen(true)
                                :
                                onClosePanel();
                        }}
                        handleOnSaveClick={() => formRef?.current?.requestSubmit()} /> }
                </form>
                <OverlayPortal id={'documentConfirmModal'} type={'modal'} visible={isConfirmationModalOpen}>
                    {({ close }) => (
                        <UnsavedChangesModal
                            close={close}
                            isExtraActionModal={true}
                            onButtonClick={confirmationModalOnClickHandler}
                            modalTitleLabel={t('confirm-save-modal-title')}
                            modalContentLabel={t('confirm-save-modal-message')}
                            cancelButtonLabel={tBase('cancel')}
                            secondaryActionButtonLabel={tBase('dont-save-button')}
                            primaryActionButtonLabel={tBase('save-button')}
                            loading={false}
                        />)}
                </OverlayPortal>
                <OverlayPortal id={'userManagementErrorModal'} type={'modal'} visible={isErrorModalOpen}>
                    {({ close }) => (
                        <UnsavedChangesModal
                            close={close}
                            isExtraActionModal={false}
                            onButtonClick={errorModalOnClickHandler}
                            modalTitleLabel={t('error-save-modal-title')}
                            modalContentLabel={t('error-save-modal-message')}
                            cancelButtonLabel={tBase('cancel')}
                            primaryActionButtonLabel={tBase('ok-button')}
                            loading={false}
                        />
                    )}
                </OverlayPortal>
            </FormProvider>
        </SidePanel>
    );
};

export default ManageDocumentGroupsPanel;
