import { NdsButton, NdsIconFont, SidePanel } from '@gonitro/rcl';
import { NdsButtonTypesEnum, NdsInputTypes, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import NdsInput from '@gonitro/rcl/lib/nds-input';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AuthApi } from '~api/auth.api';
import Error from '~components/error';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { ValidateUtils } from '~lib';
import { HttpError } from '~lib/http/http.error';
import { LanguageDataLabel } from '~models';
import './change-password-panel.scss';

export interface ChangePasswordPanelProps {
    closePanel: () => void;
}

type FormValues = {
    currentPassword: string;
    password: string;
    confirmPassword: string;
};

const ChangePasswordPanel = ({ closePanel }: ChangePasswordPanelProps) => {
    const authApi = useApi(AuthApi);
    const formRef = useRef<HTMLFormElement>(null);
    const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const { t } = useTranslation('user-management');
    const { t : tNotifications } = useTranslation('notifications');
    const [wrongPasswordError, setWrongPasswordError] = useState<LanguageDataLabel<'validation'>>();
    const {
        registerNdsInput,
        handleSubmit,
        trigger,
        watch,
        formState: { isValid },
    } = useEnhancedForm<FormValues>({ mode: 'onBlur' });
    const newPasswordWatch = watch('password');
    const showNotification = useShowNotification();

    useEffect(() => {
        if (wrongPasswordError !== undefined) {
            trigger('currentPassword');
        }
    }, [wrongPasswordError, trigger]);


    const onSubmit = useCallback(async (data: FormValues) => {
        setLoading(true);
        try {
            await authApi.changePassword({
                currentPassword: data.currentPassword,
                newPassword: data.password,
                confirmNewPassword: data.confirmPassword,
            });
            closePanel();
            showNotification({
                type: 'success',
                title: tNotifications('password-change-success'),
                hideAfterMs: 2000,
            });
        } catch (e) {
            if (e instanceof HttpError) {
                showNotification({
                    type: 'error',
                    title: tNotifications('password-change-error'),
                    hideAfterMs: 2000,
                });

                const code = e.getErrorCode();

                if (code === 'User.LoginFailed') {
                    setWrongPasswordError('wrong-current-password');
                }
            }
            setError('something-went-wrong');
        } finally {
            setLoading(false);
        }
    }, [authApi, showNotification, tNotifications, closePanel]);

    useEffect(() => {
        if(newPasswordWatch !== '' && newPasswordWatch !== undefined && newPasswordWatch.length > ValidateUtils.passwordMinLength) {
            trigger('confirmPassword');
        }
    }, [newPasswordWatch, trigger]);

    return (
        <SidePanel className={'c-change-password-panel'} onClosePanel={closePanel}>
            <form
                className={'c-change-password-panel__form'}
                ref={formRef}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
            >
                <Typography
                    token={TypographyToken.DesktopHeaderXs}
                    className={'edit-user-panel__title'}
                >
                    {t('change-password')}
                </Typography>
                <div className={'reset-password-form__input-wrapper'}>
                    <NdsInput
                        required
                        inputType={currentPasswordVisible ? NdsInputTypes.TEXT : NdsInputTypes.PASSWORD}
                        label={t('current-password')}
                        {...registerNdsInput('currentPassword',  {
                            required: true,
                            fieldLabel: t('current-password'),
                            validate: () => {
                                if (wrongPasswordError) {
                                    return 'wrong-current-password';
                                }
                            },
                        })}
                    />
                    <NdsIconFont
                        className={'reset-password-form__eye-icon'}
                        fontName={currentPasswordVisible ? 'fa-solid-eye-slash' : 'fa-solid-eye' }
                        onClick={() => {
                            setCurrentPasswordVisible(state => !state);
                        }}
                    />
                </div>
                <div className={'reset-password-form__input-wrapper'}>
                    <NdsInput
                        required
                        inputType={passwordVisible ? NdsInputTypes.TEXT : NdsInputTypes.PASSWORD}
                        label={t('new-password')}
                        {...registerNdsInput('password', {
                            required: true,
                            minLength: ValidateUtils.passwordMinLength,
                            maxLength: ValidateUtils.passwordMaxLength,
                            pattern: {
                                value: ValidateUtils.passwordRegex,
                                message: 'patern-regex-password',
                            },
                        })}
                    />
                    <NdsIconFont
                        className={'reset-password-form__eye-icon'}
                        fontName={passwordVisible ? 'fa-solid-eye-slash': 'fa-solid-eye' }
                        onClick={() => {
                            setPasswordVisible(state => !state);
                        }}
                    />
                </div>
                <div className={'reset-password-form__input-wrapper'}>
                    <NdsInput
                        required
                        inputType={confirmPasswordVisible ? NdsInputTypes.TEXT : NdsInputTypes.PASSWORD}
                        label={t('confirm-password')}
                        {...registerNdsInput('confirmPassword', {
                            required: true,
                            validate: (val: string, formValues: FormValues) => {
                                if (formValues.password !== val) {
                                    return 'change-password-does-not-match';
                                }
                            },
                        })}
                    />
                    <NdsIconFont
                        className={'reset-password-form__eye-icon'}
                        fontName={confirmPasswordVisible ? 'fa-solid-eye-slash': 'fa-solid-eye'}
                        onClick={() => {
                            setConfirmPasswordVisible(state => !state);
                        }}
                    />
                </div>
                {error && <Error i18nKey={error} />}
            </form>
            <div
                className={'c-change-password-panel__action-footer-container'}
            >
                <NdsButton
                    size={SizesEnums.SMALL}
                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                    label={t('btn-cancel')}
                    onClick={closePanel}
                />
                <NdsButton
                    size={SizesEnums.SMALL}
                    label={t('btn-save')}
                    state={isValid && !loading ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                    onClick={() => formRef?.current?.requestSubmit()}
                />
            </div>
        </SidePanel>
    );
};

export default ChangePasswordPanel;
