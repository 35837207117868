import { SidePanel } from '@gonitro/rcl';
import { TextBox } from '@progress/kendo-react-inputs';
import { differenceWith, isEmpty, isEqual } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { UserManagementApi } from '~api/user-management.api';
import Loader from '~components/loader';
import NavTabs from '~components/nav-tabs';
import Typography, { TypographyToken } from '~components/typography';
import { Actions, userGroupPanelTabs, UserGroupsManagementTabsEnum } from '~constants';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { UserGroupPanelContext } from '~contexts/user-group-panel/user-group-panel.context';
import { useEnhancedForm } from '~hooks/enhanced-form';
import useNavTabs from '~hooks/tabs';
import { DocumentGroupsListItemModel, UserGroupUsersListModel } from '~models/user-management.models';
import AlternateNameTab from './alternate-name-tab/alternate-name-tab';
import DocumentGroupTab from './document-group-tab/document-group-tab';
import { DocumentGroupTogglesModel, initialDocumentGroupToggles } from './document-group-tab/document-group-toggle-definition';
import GeneralTab from './general-tab/general-tab';
import { initialToggles } from './general-tab/toggle-definition';
import useGeneralToggles from './general-tab/useGeneralToggles';
import UserProfileTab from './user-profile-tab/user-profile-tab';
import UnsavedChangesModal from '../../unsaved-changes-modal/unsaved-changes-modal';
import EditUserFooter from '../../users-view/edit-user-panel/edit-user-footer/edit-user-footer';
import './user-group-panel.scss';


export interface CreateUserGroupPanelProps {
    closePanel: () => void;
    isViewMode: boolean;
}

type FormValues = {
    teamName: string;
    alternateName: string;
};

export interface AlternateNames {
    id: string | undefined;
    name: string;
    action?: 'delete' | 'add' | 'edit';
}

export interface SelectedDocumentGroupsState extends DocumentGroupsListItemModel {
    isMember?: boolean;
    hasPermissionChanged?: boolean;
    initialPermissions?: {
        hasPermission: boolean;
        permissionId: number;
        permission: string;
        disabled?: boolean;
    }[];
}

const UserGroupPanel = ({ closePanel, isViewMode }: CreateUserGroupPanelProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const userManagementApi = useApi(UserManagementApi);
    const { t } = useTranslation('user-management');
    const { t: tBase } = useTranslation('base');
    const { t: tNotifications } = useTranslation('notifications');

    const value = useContext(UserGroupPanelContext);

    const { userGroupInfo, selectedDocumentGroups } = value;
    const { userGroupId, teamName } = userGroupInfo || {};

    const { initialResponseToggles, generalToggles, handleGeneralToggleChange } = useGeneralToggles(initialToggles, userGroupId);

    const [isLoading, setLoading] = useState(false);
    const [selectedUsersForUserGroup, setSelectedUsersForUserGroup] = useState<UserGroupUsersListModel[]>([]);
    const [alternateNames, setAlternateNames] = useState<AlternateNames[]>([]);
    const [hasLoadedAlternateNames, setHasLoadedAlternateNames] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [hasAlternateNameError, setAlternateNameError] = useState(false);
    const editedMembersRef = useRef<UserGroupUsersListModel[]>([]);
    const editedDocGroupsRef = useRef<SelectedDocumentGroupsState[]>([]);
    const initialAlternateNamesRef = useRef<AlternateNames[]>([]);
    const showNotification = useShowNotification();

    const filterTabConditions = {};
    const {
        currentTab,
        selectTabHandler,
    } = useNavTabs(userGroupPanelTabs, filterTabConditions);

    const {
        handleSubmit,
        control,
        formState: { errors, isDirty },
        setError,
        clearErrors,
    } = useEnhancedForm<FormValues>({
        mode: 'all',
        defaultValues: {
            teamName: teamName || '',
            alternateName: '',
        },
    });

    useEffect(() => {
        if (alternateNames.some((item, index, arr) => arr.findIndex((obj) => obj.name === item.name) !== index)) {
            setError('alternateName', { message: 'Cannot have multiple of the same name' });
            setAlternateNameError(true);
        } else {
            clearErrors('alternateName');
            setAlternateNameError(false);
        }
    }, [alternateNames, clearErrors, setError]);

    const handleSetAlternateNames = useCallback((name: string, action: string, id?: string) => {

        if (action === 'add') {
            setAlternateNames(prev => [
                ...prev,
                {
                    name: name,
                    id: name,
                    action: 'add',
                },
            ]);
        }
        if (action === 'edit' && (initialAlternateNamesRef.current.some(altName => altName.id === id))) {
            setAlternateNames(prev => prev.map(altName => (altName.id === id
                ? {
                    ...altName,
                    name: name,
                    action: 'edit',
                }
                : altName)));
        } else {
            setAlternateNames(prev => prev.map(altName => (altName.id === id
                ? {
                    ...altName,
                    name: name,
                }
                : altName)));
        }
        if (action === 'delete') {
            if (initialAlternateNamesRef.current.some(altName => altName.id === id)) {
                setAlternateNames(prev => prev.map(altName => (altName.id === id
                    ? {
                        ...altName,
                        name: name,
                        action: 'delete',
                    }
                    : altName)));
            }
        }
    }, []);

    const setInitialAlternateNames = useCallback((initialAlternateNames: AlternateNames[]) => {
        initialAlternateNamesRef.current = initialAlternateNames;
        setAlternateNames(initialAlternateNames);
    }, []);

    const handleHasLoadedAlternateNames = useCallback((loaded: boolean) => {
        setHasLoadedAlternateNames(loaded);
    }, []);


    const handleOnSaveClick = useCallback(() => {
        formRef.current?.requestSubmit();
    }, []);

    const confirmationModalOnClickHandler = useCallback((type: Actions) => {
        const closeModalAndPanel = () => {
            setIsConfirmationModalOpen(false);
            closePanel();
        };
        switch (type) {
            case Actions.PRIMARY_ACTION:
                handleOnSaveClick();
                break;
            case Actions.CANCEL:
                setIsConfirmationModalOpen(false);
                break;
            case Actions.SECONDARY_ACTION:
                closeModalAndPanel();
                break;
            default:
                break;
        }
    }, [closePanel, handleOnSaveClick]);

    const compareGeneralToggleChanges = useCallback(() => {
        const togglesToCompare = userGroupId ? initialResponseToggles.current : initialToggles;
        const toggleChanges = differenceWith(generalToggles, togglesToCompare!, isEqual);

        return toggleChanges;
    }, [generalToggles, initialResponseToggles, userGroupId]);

    const compareDocumentGroupChanges = useCallback((): boolean => {
        let hasDifferences = false;

        for (const group of selectedDocumentGroups) {
            const togglesToCompare = userGroupId && group.initialPermissions
                ? group.initialPermissions
                : initialDocumentGroupToggles;

            const differences = differenceWith(group.permissions, togglesToCompare || [], isEqual);

            if (differences.length > 0) {
                hasDifferences = true;
                break;
            }
        }

        return hasDifferences;
    }, [selectedDocumentGroups, userGroupId]);


    const onSubmitCallback = useCallback(async (data: FormValues) => {
        setLoading(true);
        let createdUserGroupId: number;

        if (userGroupId) {
            createdUserGroupId = userGroupId;
        } else {
            createdUserGroupId = (await userManagementApi.createUserGroup({ name: data.teamName })).userGroupId;
            showNotification({
                type: 'success',
                title: tNotifications('user-group-created-success', { userGroup: data.teamName }),
                hideAfterMs: 2000,
            });
        }
        await userManagementApi.updateUserGroupName({
            userGroupId: createdUserGroupId,
            name: data.teamName,
        });
        const userGroupMemberRequests =
            editedMembersRef.current.map((user) =>
                userManagementApi.updateUserGroupMember({
                    userId: user.userId,
                    userGroupId: createdUserGroupId,
                    isMember: user.isMember,
                }));

        await Promise.all(userGroupMemberRequests);


        const generalToggleRequests =
            compareGeneralToggleChanges().map((toggle) =>
                userManagementApi.updateUserGroupPermission({
                    hasPermission: toggle.hasPermission,
                    userGroupId: createdUserGroupId,
                    permissionId: toggle.permission,
                }));

        await Promise.all(generalToggleRequests);

        // Promise.all is causing server 500 errors when using the below API
        const mergedDocGroups = [
            ...selectedDocumentGroups,
            ...editedDocGroupsRef.current.filter(newItem =>
                !selectedDocumentGroups.some(originalItem => originalItem.documentGroupId === newItem.documentGroupId)),
        ];
        for (const group of mergedDocGroups) {
            let togglesToCompare = userGroupId && group.initialPermissions ? group.initialPermissions : initialDocumentGroupToggles;

            if (!group.isMember || !group.permissions) {
                group.permissions = initialDocumentGroupToggles;
            }
            const permissionsWithDisabledRemoved = group.permissions.map(({ disabled, ...rest }: DocumentGroupTogglesModel) => rest);

            togglesToCompare = togglesToCompare.map(({ disabled, ...rest }): DocumentGroupTogglesModel => rest);

            const permissionDifferences = differenceWith(permissionsWithDisabledRemoved, togglesToCompare, isEqual);

            for (const permission of permissionDifferences) {
                await userManagementApi.updateDocumentGroupPermission({
                    userGroupId: createdUserGroupId,
                    documentGroupId: group.documentGroupId,
                    permissionId: permission.permissionId,
                    hasPermission: permission.hasPermission,
                });
            }
        }

        let alternateNamesDeleteRequests: Promise<boolean>[] = [];
        let alternateNamesEditRequests: Promise<boolean>[] = [];

        if (userGroupId) {
            alternateNamesDeleteRequests = alternateNames
                .filter((change) => change.action === 'delete')
                .map((change) =>
                    userManagementApi.deleteAlternateName({
                        name: change.name,
                        userGroupId,
                    }));
            alternateNamesEditRequests = alternateNames
                .filter((change) => change.action === 'edit')
                .map((change) =>
                    userManagementApi.updateAlternateName({
                        name: change.id!,
                        userGroupId,
                        newName: change.name,
                    }));
        }

        const alternateNamesAddRequests =
            alternateNames.map((change) =>
                change.action === 'add' && userManagementApi.createAlternateUserName({
                    name: change.name,
                    userGroupId: createdUserGroupId,
                }));

        await Promise.all([...alternateNamesDeleteRequests, ...alternateNamesAddRequests, ...alternateNamesEditRequests]);
        setLoading(false);
        closePanel();

    }, [
        userGroupId,
        userManagementApi,
        compareGeneralToggleChanges,
        selectedDocumentGroups,
        closePanel,
        alternateNames,
        showNotification,
        tNotifications,
    ]);

    const groupedErrors = errors && !isEmpty(errors)
        ? { [UserGroupsManagementTabsEnum.AlternativeUserGroupNames]: !!(errors.alternateName) }
        : null;

    return (
        <SidePanel className='user-group-panel' onClosePanel={closePanel}>
            {isLoading
                ? <Loader center /> :
                <form
                    className={'user-group-panel__form'}
                    onSubmit={handleSubmit(onSubmitCallback)}
                    ref={formRef}
                >
                    <Typography
                        token={TypographyToken.DesktopHeaderXs}
                        className={'user-group-panel__title'}>{userGroupId ? isViewMode ? t('view-user-group-panel-header') : t('edit-user-group-panel-header') : t('create-user-group-panel-header')}
                    </Typography>
                    <fieldset>
                        <Typography token={TypographyToken.DesktopDescriptionSm}>{t('team-name-text-label')}</Typography>
                        <Typography
                            type='span'
                            token={TypographyToken.UiFormsLabelSm}
                            text={'*'}
                            className='manage-documents-panel__required-field'
                        />
                        <Controller
                            name="teamName"
                            control={control}
                            defaultValue=""
                            rules={{ required: t('team-name-is-required-error-message') }}
                            render={({ field: { onChange, value } }) => (
                                <TextBox
                                    disabled={isViewMode}
                                    value={value}
                                    onChange={(e) => onChange(e.value)}
                                    placeholder={t('team-name-text-label')}
                                />
                            )}
                        />
                        {errors
                            && <Typography
                                token={TypographyToken.DesktopDescriptionMd}
                                text={
                                    errors.teamName?.message}
                                className={'user-group-panel__error-hint'}
                            />
                        }
                    </fieldset>
                    <NavTabs
                        navTabs={userGroupPanelTabs}
                        selectTabHandler={selectTabHandler}
                        currentTab={currentTab}
                        errors={groupedErrors}
                    />
                    {currentTab.key === UserGroupsManagementTabsEnum.General && (
                        <GeneralTab isViewMode={isViewMode} handleToggleChange={handleGeneralToggleChange} toggles={generalToggles} />
                    )}
                    {currentTab.key === UserGroupsManagementTabsEnum.UserProfiles && (
                        <UserProfileTab
                            isViewMode={isViewMode}
                            userGroupId={userGroupId}
                            editedMembersRef={editedMembersRef}
                            selectedUsersForUserGroup={selectedUsersForUserGroup}
                            setSelectedUsersForUserGroup={setSelectedUsersForUserGroup}
                        />
                    )}
                    {currentTab.key === UserGroupsManagementTabsEnum.DocumentGroups && (
                        <DocumentGroupTab
                            isViewMode={isViewMode}
                            userGroupId={userGroupId}
                            editedDocGroupsRef={editedDocGroupsRef}
                        />
                    )}
                    {currentTab.key === UserGroupsManagementTabsEnum.AlternativeUserGroupNames && (
                        <AlternateNameTab
                            isViewMode={isViewMode}
                            userGroupId={userGroupId}
                            alternateNames={alternateNames}
                            handleSetAlternateNames={handleSetAlternateNames}
                            setInitialAlternateNames={setInitialAlternateNames}
                            handleHasLoadedAlternateNames={handleHasLoadedAlternateNames}
                            hasLoadedAlternateNames={hasLoadedAlternateNames}
                            hasAlternateNameError={hasAlternateNameError}
                        />
                    )}
                    {
                        !isViewMode && <EditUserFooter
                            closePanel={() => {
                                (isDirty
                                    || editedMembersRef.current.length
                                    || compareGeneralToggleChanges().length
                                    || compareDocumentGroupChanges()
                                    || alternateNames.length)
                                    ?
                                    setIsConfirmationModalOpen(true)
                                    :
                                    closePanel();
                            }}
                            handleOnSaveClick={handleOnSaveClick}
                        />
                    }

                </form >
            }
            <OverlayPortal id={'userManagementErrorModal'} type={'modal'} visible={isConfirmationModalOpen}>
                {({ close }) => (
                    <UnsavedChangesModal
                        close={close}
                        isExtraActionModal={true}
                        onButtonClick={confirmationModalOnClickHandler}
                        modalTitleLabel={t('confirm-save-modal-title')}
                        modalContentLabel={t('confirm-save-modal-message')}
                        cancelButtonLabel={tBase('cancel')}
                        secondaryActionButtonLabel={tBase('dont-save-button')}
                        primaryActionButtonLabel={tBase('save-button')}
                        loading={isLoading}
                    />)}

            </OverlayPortal>

        </SidePanel >
    );
};

export default UserGroupPanel;
