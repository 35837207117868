import { useCallback, useMemo } from 'react';
import { FlowContextType, useFlowState } from '~contexts/flow';
import {
    FlowDetailsValidator,
    FlowFieldValidator,
    FlowStakeholderValidator,
    FlowValidationResult,
} from '~contexts/flow/flow.types';
import { useSettings, useSigningTypes } from '~contexts/settings';
import { FlowFieldModel, FlowStakeholderModel } from '~models';


const useValidatorExtras = () => {
    const settings = useSettings();
    const signingTypes = useSigningTypes();

    return useMemo(() => {
        return {
            settings,
            signingTypes,
        };
    }, [settings, signingTypes]);
};

export const useFlowInternalStakeholdersValidator = (validators?: FlowStakeholderValidator[]) => {
    const flowState = useFlowState();

    const extras = useValidatorExtras();

    const callback = useCallback((stakeholder: FlowStakeholderModel): FlowContextType['errors'] => {
        const errors: FlowContextType['errors'] = {};

        if (!validators?.length) {
            return errors;
        }

        for (const validator of validators) {
            const result = validator(stakeholder, flowState, extras);
            switch (result.result) {
                case FlowValidationResult.Error:
                    errors[`${result.error}:${stakeholder.localId}`] = { ...result.info };
                    break;
                case FlowValidationResult.Warning:
                    errors[`${result.error}:${stakeholder.localId}`] = {
                        ...result.info,
                        isWarning: true,
                    };
                    break;
            }
        }

        return errors;
    }, [extras, flowState, validators]);

    return validators?.length ? callback : null;
};

export const useFlowInternalPackageValidate = (validators?: FlowDetailsValidator[]) => {
    const flowState = useFlowState();
    const extras = useValidatorExtras();

    const callback = useCallback((): FlowContextType['errors'] => {
        const errors: FlowContextType['errors'] = {};

        if (!validators?.length) {
            return errors;
        }

        for (const validator of validators) {
            const result = validator(flowState, extras);
            switch (result.result) {
                case FlowValidationResult.Error:
                    errors[result.error] = { ...result.info };
                    break;
                case FlowValidationResult.Warning:
                    errors[result.error] = {
                        ...result.info,
                        isWarning: true,
                    };
                    break;
            }
        }

        return errors;
    }, [extras, flowState, validators]);

    return validators?.length ? callback : null;
};

export const useFlowInternalFieldValidate = (validators?: FlowFieldValidator[]) => {
    const flowState = useFlowState();
    const extras = useValidatorExtras();

    const callback = useCallback((field: FlowFieldModel): FlowContextType['errors'] => {
        const errors: FlowContextType['errors'] = {};

        if (!validators?.length) {
            return errors;
        }

        for (const validator of validators) {
            const result = validator(field, flowState, extras);
            switch (result.result) {
                case FlowValidationResult.Error:
                    errors[`${result.error}:${field.localId}`] = { ...result.info };
                    break;
                case FlowValidationResult.Warning:
                    errors[`${result.error}:${field.localId}`] = {
                        ...result.info,
                        isWarning: true,
                    };
                    break;
            }
        }

        return errors;
    }, [extras, flowState, validators]);

    return validators?.length ? callback : null;
};
