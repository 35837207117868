import { LanguageDataLabel } from '~models';

export enum UserStatusEnum {
  Active = 'Active',
  Inactive = 'InActive',
  Invited = 'Invited',
}

export const UserStatusI18n: Record<UserStatusEnum, LanguageDataLabel<'user-management'>> = {
    [UserStatusEnum.Active]: 'status-active',
    [UserStatusEnum.Inactive]: 'status-inactive',
    [UserStatusEnum.Invited]: 'status-invited',
};

// TODO: create translation labels
// eg.:
// export const UserStatusI18n: Record<UserStatusEnum, LanguageDataLabel<'portal'>> = {
// [UserStatusEnum.Active]: 'user-status-active'
// }
