import { ComponentType, FunctionComponent, useMemo } from 'react';
import { useFlowContext } from '~contexts/flow';
import { FlowEntityType } from '~contexts/flow/flow.types';
import FlowEditorApproverProperties from './flow-editor-approver-properties';
import FlowEditorCheckboxFieldProperties from './flow-editor-checkbox-field-properties';
import FlowEditorContactProperties from './flow-editor-contact-properties';
import FlowEditorPackageProperties from './flow-editor-package-properties';
import FlowEditorRadioFieldProperties from './flow-editor-radio-field-properties';
import FlowEditorSigningFieldProperties from './flow-editor-signing-field-properties';
import FlowEditorTextFieldProperties from './flow-editor-text-field-properties';
import './flowEditorRightPane.scss';
import './flowEditorRightPaneComponent.scss';


export interface FlowEditorRightPaneComponentType extends FunctionComponent {
    Header: ComponentType;
}

const EntityTypeComponent: Record<FlowEntityType, FlowEditorRightPaneComponentType> = {
    [FlowEntityType.Package]: FlowEditorPackageProperties,
    [FlowEntityType.Stakeholder]: FlowEditorContactProperties,
    [FlowEntityType.Approver]: FlowEditorApproverProperties,
    [FlowEntityType.TextField]: FlowEditorTextFieldProperties,
    [FlowEntityType.CheckboxField]: FlowEditorCheckboxFieldProperties,
    [FlowEntityType.RadioField]: FlowEditorRadioFieldProperties,
    [FlowEntityType.SigningField]: FlowEditorSigningFieldProperties,
    [FlowEntityType.Actor]: FlowEditorPackageProperties,
};

export interface FlowEditorRightPaneProps {

}

function FlowEditorRightPane(props: FlowEditorRightPaneProps) {
    const { focusedEntityType } = useFlowContext();

    const Component = useMemo(() => {
        return EntityTypeComponent[focusedEntityType];
    }, [focusedEntityType]);


    return (
        <div className={'c-flow-editor-right-pane'}>
            <div className={'c-flow-editor-right-pane__header'}>
                <Component.Header />
            </div>
            <div className={'c-flow-editor-right-pane__content'}>
                <Component />
            </div>
        </div>
    );
}

export default FlowEditorRightPane;
