import { SidePanel } from '@gonitro/rcl';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import EditUserProfileTab from 'views/portals/user-management/users-view/edit-user-panel/edit-user-profile-tab';
import { UserManagementApi } from '~api/user-management.api';
import Loader from '~components/loader';
import NavTabs from '~components/nav-tabs';
import Typography, { TypographyToken } from '~components/typography';
import { Actions, editUserPanelTabs, UserManagementTabsEnum } from '~constants';
import { UserManagementAction } from '~constants/user-management-action';
import { useApi } from '~contexts/api';
import { AbsencePeriod } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useSettings } from '~contexts/settings';
import { useEnhancedForm } from '~hooks/enhanced-form';
import useNavTabs from '~hooks/tabs';
import { DateUtil } from '~lib/date.utils';
import { MemberUserGroupModel, Substitute, UserGroupsListItemModel, UsersDataModel } from '~models/user-management.models';
import AddSignatureTab from './add-signature-tab/add-signature-tab';
import EditUserFooter from './edit-user-footer/edit-user-footer';
import OutOfOfficeTab from './out-of-office-tab/out-of-office-tab';
import UserGroupsTab from './user-groups-tab/user-groups-tab';
import UnsavedChangesModal from '../../unsaved-changes-modal/unsaved-changes-modal';
import './edit-user-panel.scss';


export interface EditUserPanelProps {
    userId: number;
    closePanel: () => void;
    actionType?: UserManagementAction
}

export type FormValues = {
    isActive: boolean;
    avatarb64: string;
    cloudProviderName: string;
    company: string;
    defaultPhoneNumberCountry: string;
    emailAddress: string;
    firstName: string;
    id: number;
    language: string;
    lastName: string;
    startDate: Date;
    startTime: Date;
    endDate: Date;
    endTime: Date;
    formfiller: boolean;
    approver: boolean;
    signer: boolean;
    receiver: boolean;
    reassign: boolean;
    userGroups: UserGroupsListItemModel | null;
};

const EditUserPanel = ({ userId, closePanel, actionType }: EditUserPanelProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const userManagementApi = useApi(UserManagementApi);
    const { t } = useTranslation('user-management');
    const { t: tBase } = useTranslation('base');
    const { t: tNotifications } = useTranslation('notifications');

    const [userData, setUserData] = useState<UsersDataModel>();
    const [signatureImage, setSignatureImage] = useState<string>();
    const [croppedImage, setCroppedImage] = useState<string>();
    const [isSignatureEditMode, setSignatureEditMode] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isToggleEnabled, setToggleEnabled] = useState(false);
    const [substitutes, setSubstitutes] = useState<Substitute[]>([]);
    const [selectedUserGroups, setSelectedUserGroups] = useState<MemberUserGroupModel[]>([]);
    const [hasEditedUserGroups, setHasEditedUserGroups] = useState(false);
    const showNotification = useShowNotification();
    const viewMode = useMemo(() => actionType && actionType === UserManagementAction.View, [actionType]);

    const { customizationSettings: { isOutOfOfficeEnabled } } = useSettings();
    const filterTabConditions = {
        [UserManagementTabsEnum.OutOfOffice]: isOutOfOfficeEnabled,
        // Add more conditions here
    };
    const {
        currentTab,
        filteredTabs,
        selectTabHandler,
    } = useNavTabs(editUserPanelTabs, filterTabConditions);

    const formMethods = useEnhancedForm<FormValues>({
        mode: 'all',
        defaultValues: async () => {
            const userInfo = await userManagementApi.getUser({ userId });
            const userExtended = {
                ...userInfo,
                startDate: new Date(userInfo?.absencePeriods[0]?.startDate || DateTime.now().toJSDate()),
                startTime: new Date(userInfo?.absencePeriods[0]?.startDate || DateTime.now().plus({ minutes: 5 }).toJSDate()),
                endDate: new Date(userInfo?.absencePeriods[0]?.endDate || DateTime.now().toJSDate()),
                endTime: new Date(userInfo?.absencePeriods[0]?.endDate || DateTime.now().plus({
                    hours: 1,
                    minutes: 5,
                }).toJSDate()),
                formfiller: false,
                approver: false,
                signer: false,
                receiver: false,
                reassign: false,
                userGroups: null,
            };

            setUserData(userInfo);

            setSignatureImage(userInfo.signatureImageB64);
            setSubstitutes(userInfo.absencePeriods[0]?.substitutes || []);
            setToggleEnabled(userInfo.absencePeriods.length > 0);
            setLoading(false);

            return userExtended;
        },
    });

    const { handleSubmit, formState: { isDirty, errors } } = formMethods;

    const getNotificationMessage = useCallback((absencePeriods?: any) => {
        let notification = tNotifications('user-updated-success');
        switch(currentTab.key){
            case UserManagementTabsEnum.Profile:
                notification = tNotifications('user-updated-success');
                break;
            case UserManagementTabsEnum.Signature:
                notification = tNotifications('user-updated-success');
                break;
            case UserManagementTabsEnum.OutOfOffice:
                if(isToggleEnabled) {
                    const startDate = DateUtil.format(absencePeriods.startDate, 'dd/MM/yyyy - HH:mm');
                    const endDate = DateUtil.format(absencePeriods.endDate, 'dd/MM/yyyy - HH:mm');

                    notification = tNotifications('out-of-office-enabled-message', {
                        startDate: startDate,
                        endDate: endDate,
                    });
                }
                break;
        }

        showNotification({
            type: 'success',
            title: notification,
            hideAfterMs: 2000,
        });
    }, [currentTab.key, isToggleEnabled, showNotification, tNotifications]);


    const onSubmitCallback = useCallback(({ ...data }: FormValues) => {

        const combineDateAndTime = (date: Date, time: Date, timeZoneOffset = '+02:00') => {
            const startDateTime = DateTime.fromJSDate(date);
            const startTimeTime = DateTime.fromJSDate(time).set({
                year: startDateTime.year,
                month: startDateTime.month,
                day: startDateTime.day,
            });

            const combinedDateTime = startDateTime.set({
                hour: startTimeTime.hour,
                minute: startTimeTime.minute,
                second: startTimeTime.second,
                millisecond: 0,
            });

            const combinedDateTimeWithOffset = combinedDateTime.setZone(`UTC${timeZoneOffset}`);

            return combinedDateTimeWithOffset.toISO({ suppressMilliseconds: true });
        };

        let absencePeriods: AbsencePeriod[] = [];

        if (isToggleEnabled) {
            absencePeriods = [
                {
                    substitutes: substitutes,
                    startDate: combineDateAndTime(formMethods.getValues().startDate, formMethods.getValues().startTime)!,
                    endDate: combineDateAndTime(formMethods.getValues().endDate, formMethods.getValues().endTime)!,
                    id: userData!.absencePeriods[0] && userData!.absencePeriods[0].id,
                },
            ];
        } else {
            absencePeriods = userData!.absencePeriods.slice(1);
        }

        setLoading(true);
        userManagementApi.updateUser({
            ...data,
            signatureImageB64: croppedImage || signatureImage!,
            absencePeriods: absencePeriods,
        }).then(() => {
            if (selectedUserGroups.length) {
                userManagementApi.updateMemberUserGroups({
                    userId,
                    userGroupIds: selectedUserGroups.map(user => user.id),
                });
            }
            getNotificationMessage(absencePeriods[0]);
            setLoading(false);
            closePanel();
        });
    }, [
        isToggleEnabled,
        userManagementApi,
        croppedImage,
        signatureImage,
        substitutes,
        formMethods,
        userData,
        selectedUserGroups,
        getNotificationMessage,
        closePanel,
        userId,
    ]);

    const handleSignatureOnload = useCallback((image: string) => {
        setSignatureImage(image);
    }, []);

    const handleCroppedImage = useCallback((image: string) => {
        setCroppedImage(image);
    }, []);

    const handleSignatureEditMode = useCallback((isEditMode: boolean) => {
        setSignatureEditMode(isEditMode);
    }, []);

    const handleSignatureContinue = useCallback((sigImage: string) => {
        setSignatureImage(sigImage);
        setSignatureEditMode(false);
    }, []);

    const removeSignature = useCallback(() => {
        setSignatureEditMode(false);
        setSignatureImage(undefined);
    }, []);

    const handleOnSaveClick = useCallback(() => {
        if (!Object.keys(errors).length) {
            formRef.current?.requestSubmit();
        } else {
            setIsErrorModalOpen(true);
        }
    }, [errors]);

    const handleSetUserGroups = useCallback((userGroups: MemberUserGroupModel[]) => {
        setSelectedUserGroups(userGroups);
    }, []);


    const confirmationModalOnClickHandler = useCallback((type: Actions) => {
        const closeModalAndPanel = () => {
            setIsConfirmationModalOpen(false);
            closePanel();
        };
        switch (type) {
            case Actions.PRIMARY_ACTION:
                handleOnSaveClick();
                break;
            case Actions.CANCEL:
                setIsConfirmationModalOpen(false);
                break;
            case Actions.SECONDARY_ACTION:
                closeModalAndPanel();
                break;
            default:
                break;
        }
    }, [closePanel, handleOnSaveClick]);

    const errorModalOnClickHandler = useCallback((type: Actions) => {
        switch (type) {
            case Actions.PRIMARY_ACTION:
                setIsErrorModalOpen(false);
                break;
            case Actions.CANCEL:
                setIsErrorModalOpen(false);
                break;
            default:
                break;
        }
    }, []);

    const groupedErrors = errors && !isEmpty(errors)
        ? {
            [UserManagementTabsEnum.Profile]: !!(errors.firstName || errors.lastName),
            [UserManagementTabsEnum.UserGroups]: !!errors.userGroups,
            [UserManagementTabsEnum.OutOfOffice]: !!(errors.startDate || errors.startTime || errors.endDate || errors.endTime),
        }
        : null;

    return (
        <SidePanel className='edit-user-panel' onClosePanel={closePanel}>
            {!loading ?
                <FormProvider {...formMethods} >
                    <form
                        className={'edit-user-panel__form'}
                        onSubmit={handleSubmit(onSubmitCallback)}
                        ref={formRef}
                    >
                        <Typography
                            token={TypographyToken.DesktopHeaderXs}
                            className={'edit-user-panel__title'}
                        >{t('user-management-edit-user-panel-title')}</Typography>
                        <NavTabs
                            navTabs={filteredTabs}
                            selectTabHandler={selectTabHandler}
                            currentTab={currentTab}
                            errors={groupedErrors}
                        />
                        {currentTab.key === UserManagementTabsEnum.Profile && (
                            <EditUserProfileTab viewMode={viewMode} />
                        )}
                        {currentTab.key === UserManagementTabsEnum.UserGroups && (
                            <UserGroupsTab
                                viewMode={viewMode}
                                setHasEditedUserGroups={setHasEditedUserGroups}
                                hasEditedUserGroups={hasEditedUserGroups}
                                setSelectedUserGroups={handleSetUserGroups}
                                selectedUserGroups={selectedUserGroups}
                                userId={userId} />
                        )}
                        {currentTab.key === UserManagementTabsEnum.OutOfOffice && (
                            <OutOfOfficeTab
                                viewMode={viewMode}
                                userData={userData!}
                                closePanel={closePanel}
                                substitutes={substitutes}
                                setToggleEnabled={setToggleEnabled}
                                setSubstitutes={setSubstitutes}
                                isToggleEnabled={isToggleEnabled}
                            />
                        )}
                        {currentTab.key === UserManagementTabsEnum.Signature && (
                            <AddSignatureTab
                                viewMode={viewMode}
                                signatureImage={signatureImage}
                                croppedImage={croppedImage}
                                setCroppedImage={handleCroppedImage}
                                isSignatureEditMode={isSignatureEditMode}
                                setSignatureEditMode={handleSignatureEditMode}
                                closePanel={closePanel}
                                handleSignatureContinue={handleSignatureContinue}
                                handleSignatureOnload={handleSignatureOnload}
                                removeSignature={removeSignature}
                            />
                        )}
                        {!viewMode && <EditUserFooter
                            closePanel={() => {
                                isDirty || (!!croppedImage && !isSignatureEditMode)
                                    ?
                                    setIsConfirmationModalOpen(true)
                                    :
                                    closePanel();
                            }}
                            handleOnSaveClick={handleOnSaveClick}
                        /> }
                    </form>
                    <OverlayPortal id={'UnsavedChangesModal'} type={'modal'} visible={isConfirmationModalOpen}>
                        {({ close }) => (
                            <UnsavedChangesModal
                                close={close}
                                isExtraActionModal={true}
                                onButtonClick={confirmationModalOnClickHandler}
                                modalTitleLabel={t('confirm-save-modal-title')}
                                modalContentLabel={t('confirm-save-modal-message')}
                                cancelButtonLabel={tBase('cancel')}
                                secondaryActionButtonLabel={tBase('dont-save-button')}
                                primaryActionButtonLabel={tBase('save-button')}
                                loading={loading}
                            />)}
                    </OverlayPortal>
                    <OverlayPortal id={'userManagementErrorModal'} type={'modal'} visible={isErrorModalOpen}>
                        {({ close }) => (
                            <UnsavedChangesModal
                                close={close}
                                isExtraActionModal={false}
                                onButtonClick={errorModalOnClickHandler}
                                modalTitleLabel={t('error-save-modal-title')}
                                modalContentLabel={t('error-save-modal-message')}
                                cancelButtonLabel={tBase('cancel')}
                                primaryActionButtonLabel={tBase('ok-button')}
                                loading={loading}
                            />
                        )}
                    </OverlayPortal>
                </FormProvider>
                :
                <Loader center />}
        </SidePanel>
    );
};

export default EditUserPanel;
