import { useCallback, useMemo } from 'react';
import QuickActionsTable from '~components/quick-actions-table';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { Permission } from '~constants';
import { UserManagementAction } from '~constants/user-management-action';
import { useHasPermission } from '~contexts/auth';
import { UserGroupsListItemModel } from '~models/user-management.models';

export interface UserGroupsViewQuickActionsProps {
    data: UserGroupsListItemModel;
    // data is any because no model has been set for user by user id yet
    onClickQuickAction: (data: any, action: UserManagementAction) => void;
}

type ActionFunctions = {
    [key in UserManagementAction]?: () => Promise<boolean> | void | Promise<void>;
};

const UserGroupsViewQuickActions = ({ data, onClickQuickAction }: UserGroupsViewQuickActionsProps) => {
    const adminCanEditUserGroups = useHasPermission(Permission.AccessUserManagementPortal_ActionEditUserGRoup);
    const adminCanDeleteUserGroups = useHasPermission(Permission.AccessUserManagementPortal_ActionDeleteUserGroup);
    const canViewUserGroup = useHasPermission(Permission.AccessUserManagementPortal_ActionViewUserGroup);

    const quickActions = useMemo((): QuickAction<UserManagementAction>[][] => {
        const pendingParentGroup: QuickAction<UserManagementAction>[][] = [];

        if (adminCanEditUserGroups) {
            pendingParentGroup.push([
                {
                    icon: 'fa-solid-edit',
                    type: UserManagementAction.Edit,
                },
            ]);
        } else if(canViewUserGroup) {
            pendingParentGroup.push([
                {
                    icon: 'fa-solid-eye',
                    type: UserManagementAction.View,
                },
            ]);
        }

        if (adminCanDeleteUserGroups) {
            pendingParentGroup.push([
                {
                    icon: 'fa-solid-trash',
                    type: UserManagementAction.Delete,
                },
            ]);
        }

        return pendingParentGroup;
    }, [adminCanDeleteUserGroups, adminCanEditUserGroups, canViewUserGroup]);


    const handleQuickActionClick = useCallback(
        (actionType: UserManagementAction) => {

            const actionFunctions: ActionFunctions = {
                [UserManagementAction.Edit]: () => onClickQuickAction(data, actionType),
                [UserManagementAction.Delete]: () => onClickQuickAction(data, actionType),
                [UserManagementAction.View]: () => onClickQuickAction(data, actionType),
            };

            const actionFunc = actionFunctions[actionType];

            if (actionFunc) {
                actionFunc();
            } else {
                console.error('Unsupported action type:', actionType);
            }

        }, [data, onClickQuickAction],
    );

    const openQuickActionsModal = (actionType: UserManagementAction) => {
        handleQuickActionClick(actionType);
    };

    return (
        <QuickActionsTable
            actions={quickActions}
            handleActionClick={openQuickActionsModal}
        />
    );
};

export default UserGroupsViewQuickActions;
