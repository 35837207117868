import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { DocumentGroupsListItemModel } from '~models/user-management.models';

export interface SelectedDocumentGroupsState extends DocumentGroupsListItemModel {
    isMember?: boolean;
    hasPermissionChanged?: boolean;
    initialPermissions?: {
        hasPermission: boolean;
        permissionId: number;
        permission: string;
        disabled?: boolean;
    }[];
}

export type UserGroupPanelContextType = {
    userGroupInfo?: { userGroupId: number, teamName: string; };
    setSelectedDocumentGroups: Dispatch<SetStateAction<SelectedDocumentGroupsState[]>>;
    selectedDocumentGroups: SelectedDocumentGroupsState[];
};

type UserGroupPanelProviderProps = {
    children: ReactNode;
    userGroupInfo?: { userGroupId: number, teamName: string; };
};

export const UserGroupPanelContext = createContext<UserGroupPanelContextType>({} as UserGroupPanelContextType);

export const UserGroupPanelContextProvider = ({ children, userGroupInfo }: UserGroupPanelProviderProps) => {

    const [selectedDocumentGroups, setSelectedDocumentGroups] = useState<SelectedDocumentGroupsState[]>([]);
    const value: UserGroupPanelContextType = {
        userGroupInfo,
        setSelectedDocumentGroups,
        selectedDocumentGroups,
    };

    return (
        <UserGroupPanelContext.Provider value={value}>
            {children}
        </UserGroupPanelContext.Provider>
    );
};
