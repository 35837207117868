import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { FlowFieldModel, FlowRadioGroupFieldModel, Guid, StakeholderType } from '~models';
import { FlowActionType, FlowReducerFunc } from '../flow.types';

export type FlowReducerElementActionPayloads = {
    [FlowActionType.AddElement]: {
        actorLocalId: Guid;
        element: FlowFieldModel;
    };
    [FlowActionType.DeleteElement]: {
        localId: Guid;
        type: FieldType;
    };
    [FlowActionType.UpdateElement]: NonNullable<FlowFieldModel>;
}
export const AddElementReducer: FlowReducerFunc<FlowActionType.AddElement> = (
    state,
    { actorLocalId, element },
) => {
    const flow = [...state.flow];
    const elements = state.elements;

    const foundActor = FlowUtils.findActor(flow, actorLocalId);

    if (element.type === FieldType.SigningField && foundActor) {

        const actorsOfStakeholder = FlowUtils.findStakeholderActors(flow, foundActor?.actor.stakeholderLocalId);
        let elementFound = false;

        for (let i = 0; i < actorsOfStakeholder.length; i++) {
            const actor = actorsOfStakeholder[i].actor;

            if (actor && actor.actorType === ActorType.Signer && actor.elements?.length > 0) {
                const signingFieldLocalId = actor.elements[0];
                const signingField = elements.find((element) => element.localId === signingFieldLocalId);

                if (signingField && signingField.type === FieldType.SigningField) {
                    element.signingMethods = signingField.signingMethods;
                    element.legalNoticeCode = signingField.legalNoticeCode;
                    element.legalNoticeText = signingField.legalNoticeText;

                    elementFound = true;

                    break;
                }
            }
        }

        // use the package defaults if we dont have a prior element to copy from
        if (!elementFound) {
            element.legalNoticeCode = state.properties?.legalNoticeDefaultCode;
            element.legalNoticeText = state.properties?.legalNoticeDefaultText;
        }
    }

    if (!element.stakeholderLocalId) {
        element.stakeholderLocalId = foundActor?.actor.stakeholderLocalId ?? state.stakeholders.find(el => el.type === StakeholderType.Undecided)?.localId;
    }

    for (const step of flow) {
        for (const actor of step) {
            if (actor.localId === actorLocalId && 'elements' in actor && !actor.elements.includes(element.localId)) {
                actor.elements = [...actor.elements, element.localId];
            }
        }
    }

    return {
        ...state,
        elements: [...elements, element],
        flow,
    };
};
export const DeleteElementReducer: FlowReducerFunc<FlowActionType.DeleteElement> = (
    state,
    { localId, type },
) => {
    const elements = [...state.elements];
    let flow = [...state.flow];

    let updateFlow = true;

    if (type === FieldType.RadioGroup) { // it can remove whole element or just option
        const index = elements.findIndex((el) => {
            if (el.type !== FieldType.RadioGroup) {
                return false;
            }

            return el.localId === localId || el.options.find((opt) => opt.localId === localId);
        });

        if (index > -1) {
            const element = elements[index] as FlowRadioGroupFieldModel;

            if (element.localId === localId || element.options.length === 1) {
                elements.splice(index, 1);
            } else {
                updateFlow = false;
                const optionIndex = element.options.findIndex((opt) => opt.localId === localId);

                if (optionIndex > -1) {
                    const options = element.options.slice();

                    options.splice(optionIndex, 1);
                    element.options = options;
                    elements.splice(index, 1, { ...element });
                }
            }
        }
    } else {
        const index = elements.findIndex((el) => el.localId === localId);

        if (index > -1) {
            elements.splice(index, 1);
        }
    }
    if (updateFlow) {
        for (let i = 0; i < flow.length; i++) {
            const step = flow[i];
            for (let j = 0; j < step.length; j++) {
                const actor = step[j];

                if ('elements' in actor && actor.elements.includes(localId)) {
                    actor.elements = actor.elements.filter(el => el !== localId);

                    if (actor.elements.length === 0) {
                        step.splice(j, 1);
                    } else {
                        step.splice(j, 1, { ...actor });
                    }
                    if (step.length === 0) {
                        flow.splice(i, 1);
                        flow = flow.slice();
                    }
                }
            }
        }
    }

    return {
        ...state,
        flow,
        elements,
    };
};

export const UpdateElementReducer: FlowReducerFunc<FlowActionType.UpdateElement> = (
    state,
    element,
) => {
    const elements = state.elements.slice();

    if (element != null) {
        const index = elements.findIndex((el) => {
            return el.localId === element.localId;
        });

        if (index < 0) {
            return {
                ...state,
                elements,
            };
        }


        elements[index] = {
            ...elements[index],
            ...element,
        } as FlowFieldModel;
    }

    return {
        ...state,
        elements,
    };
};
