import { useCallback, useMemo, useState } from 'react';
import { FlowContextType } from '~contexts/flow';
import { FlowError } from '~contexts/flow/flow.error';
import { FlowValidationResult } from '~contexts/flow/flow.types';

export const useFlowInternalErrors = () => {
    const [errors, setErrors] = useState<FlowContextType['errors']>({});

    const hasErrors = useMemo(() => {
        return Object.values(errors).some(el => !el.isWarning);
    }, [errors]);
    const hasWarnings = useMemo(() => {
        return Object.values(errors).some(el => el.isWarning);
    }, [errors]);

    /** @deprecated */
    const setError = useCallback<FlowContextType['setError']>((error: FlowError, errorInfo?: Record<string, any> | string) => {
        const id = typeof errorInfo === 'string' ? errorInfo : (errorInfo || {}).entityId;
        const errorKey = id ? `${error}:${id}` : error;

        setErrors((errors) => (
            {
                ...errors,
                [errorKey]: typeof errorInfo === 'object' ? errorInfo : true,
            }
        ));
    }, []);

    /** @deprecated */
    const clearError = useCallback<FlowContextType['clearError']>((error: FlowError, entityId?: string) => {
        setErrors((errors) => {
            const newErrors = { ...errors };
            const key = entityId ? `${error}:${entityId}` as unknown as FlowError : error;

            delete newErrors[key];

            return newErrors;
        });
    }, []);

    const getEntityValidationResult = useCallback((entityId: string) => {
        const entityErrors = Object.entries(errors).filter(([err]) => {
            const [, errId] = err.split(':');

            return errId && errId === entityId;
        });

        if (entityErrors.some(([e, info]) => !info.isWarning)) {
            return FlowValidationResult.Error;
        } else if (entityErrors.length) {
            return FlowValidationResult.Warning;
        } else {
            return FlowValidationResult.Valid;
        }
    }, [errors]);

    const hasError = useCallback((error: FlowError, entityId?: string) => {
        if (error) {
            return Object.keys(errors).some((err) => {
                const [key, errId] = err.split(':');
                const has = key === error as unknown as string;

                return has && errId === entityId;
            });
        }

        return Object.keys(errors).length > 0;
    }, [errors]);

    const getErrorInfo = useCallback((error?: FlowError, entityId?: string) => {
        if (error) {
            let errorInfo: any = undefined;

            Object.keys(errors).forEach((err) => {
                const [key, errId] = err.split(':');
                const has = key === error as unknown as string;

                if (has && errId === entityId) {
                    errorInfo = errors[err as FlowError];
                }
            });

            return errorInfo;
        }
    }, [errors]);

    return {
        setErrorsInternal: setErrors,
        errors,
        hasErrors,
        hasWarnings,
        getEntityValidationResult,
        setError,
        clearError,
        getErrorInfo,
        hasError,
    };
};
