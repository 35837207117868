import { NdsButton } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { useCallback, useRef, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import { UserManagementApi } from '~api/user-management.api';
import Content from '~components/content';
import NavTabs from '~components/nav-tabs';
import Typography, { TypographyToken } from '~components/typography';
import { Actions, Permission, USER_MANAGEMENT_PORTAL_TABS } from '~constants';
import { UserManagementAction } from '~constants/user-management-action';
import { PortalTableContainer } from '~containers/portal-table';
import { useApi } from '~contexts/api';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { TableContextProvider } from '~contexts/table';
import { FetchTableDataDelegate } from '~contexts/table/table.types';
import { UserGroupPanelContextProvider } from '~contexts/user-group-panel/user-group-panel.context';
import useNavTabs from '~hooks/tabs';
import { UserGroupsListItemModel, UserGroupsListTableDataModel } from '~models/user-management.models';
import { UserGroupsTableDefinition } from './table-definition';
import { useUserGroupsTableFilters } from './use-user-groups-table-filter';
import UserGroupPanel from './user-group-panel/user-group-panel';
import UserGroupsViewQuickActions from './user-groups-view-quick-actions/user-groups-view-quick-actions';
import useFilterTabConditions from '../hooks/useFilterTabConditions';
import UnsavedChangesModal from '../unsaved-changes-modal/unsaved-changes-modal';

export interface UserGroupsPortalViewProps { }

const UserGroupsPortalView = () => {
    const portalsApi = useApi(PortalsApi);
    const userManagementApi = useApi(UserManagementApi);

    const { t } = useTranslation('user-management');
    const { t: tBase } = useTranslation('base');
    const { t: tNotifications } = useTranslation('notifications');
    const filters = useUserGroupsTableFilters();

    const filterTabConditions = useFilterTabConditions();
    const userCanCreateUserGroup = useHasPermission(Permission.AccessUserManagementPortal_ActionCreateUserGroup);

    const { filteredTabs } = useNavTabs(USER_MANAGEMENT_PORTAL_TABS, filterTabConditions);

    const [createUserGroupSidePanelVisible, setUserGroupSidePanelVisble] = useState(false);
    const [isDeleteUserGroupModalVisible, setIsDeleteUserGroupModalVisible] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const userGroupIdRef = useRef<{ userGroupId: number, teamName: string; }>();
    const showNotification = useShowNotification();

    const fetchData: FetchTableDataDelegate<UserGroupsListTableDataModel> = useCallback(async (params, signal?: AbortSignal) => {
        return portalsApi.getUserGroups({ ...params }, signal);
    }, [portalsApi]);

    const closePanel = useCallback(() => {
        setUserGroupSidePanelVisble(false);
    }, []);

    const onClickQuickAction = useCallback((data: UserGroupsListItemModel, action: UserManagementAction) => {
        userGroupIdRef.current = {
            userGroupId: data.userGroupId,
            teamName: data.name,
        };

        if(action === UserManagementAction.View) {
            setIsViewMode(true);
            setUserGroupSidePanelVisble(true);
        }

        if (action === UserManagementAction.Edit) {
            setUserGroupSidePanelVisble(true);
        }
        if (action === UserManagementAction.Delete) {
            setIsDeleteUserGroupModalVisible(true);
            setLoading(false);
        }
    }, []);

    const deleteUserGroupModalActionsHandler = useCallback(async (type: Actions) => {

        switch (type) {
            case Actions.CANCEL:
                setIsDeleteUserGroupModalVisible(false);
                setLoading(false);

                break;
            case Actions.PRIMARY_ACTION:
                try {
                    const response = await userManagementApi.deleteUserGroup({ userGroupId: userGroupIdRef.current!.userGroupId });

                    setIsDeleteUserGroupModalVisible(false);
                    setLoading(false);

                    showNotification({
                        type: 'success',
                        title: tNotifications('user-group-deleted-success', { userGroup: userGroupIdRef.current!.teamName }),
                        hideAfterMs: 2000,
                    });

                    return response;
                } catch (error) {
                    setLoading(false);
                    showNotification({
                        type: 'error',
                        title: tNotifications('user-group-deleted-failed', { userGroup: userGroupIdRef.current!.teamName }),
                        hideAfterMs: 2000,
                    });
                    throw (error);
                }
            default:
                break;
        }

    }, [showNotification, tNotifications, userManagementApi]);

    const handleUserGroupSidePanel = useCallback(() => {
        userGroupIdRef.current = undefined;
        setUserGroupSidePanelVisble(true);
    }, []);

    return (
        <Content noDefaultPadding>
            <TableContextProvider<'userGroupId', UserGroupsListTableDataModel>
                definition={UserGroupsTableDefinition}
                fetchData={fetchData}>
                <PortalTableContainer<'userGroupId', UserGroupsListTableDataModel>
                    tabs={<NavTabs navTabs={filteredTabs} />}
                    pageTitle={t('user-management-portal-view-title-name')}
                    filtersDefinition={filters}
                    headerButton={
                        userCanCreateUserGroup &&
                        <NdsButton
                            onClick={() => handleUserGroupSidePanel()}
                            label={t('user-groups-portal-header-button')}
                            size={SizesEnums.SMALL}
                        />}
                    renderColumns={(data) => {
                        return {
                            name: data.name,
                            action: <UserGroupsViewQuickActions
                                data={data}
                                onClickQuickAction={onClickQuickAction}
                            />,
                        };
                    }}
                />
                <OverlayPortal type={'panel'} visible={createUserGroupSidePanelVisible}>
                    {() => (
                        <UserGroupPanelContextProvider userGroupInfo={userGroupIdRef?.current}>
                            <UserGroupPanel
                                isViewMode={isViewMode}
                                closePanel={() => {
                                    closePanel(); setIsViewMode(false);
                                }}
                            />
                        </UserGroupPanelContextProvider>
                    )}
                </OverlayPortal>
            </TableContextProvider>
            <OverlayPortal id={'userManagementDeleteUserGroup'} type={'modal'} visible={isDeleteUserGroupModalVisible}>
                {({ close }) => (
                    <UnsavedChangesModal
                        close={close}
                        withClose
                        loading={loading}
                        isExtraActionModal={false}
                        onButtonClick={deleteUserGroupModalActionsHandler}
                        modalHeaderLabel={'todo: Delete user group'}
                        modalTitleLabel={'todo: Are you sure you want to delete this user group?'}
                        cancelButtonLabel={tBase('cancel')}
                        primaryActionButtonLabel={tBase('delete')}
                        modalContentComponent={
                            <div className='delete-user-modal-content'>
                                <Typography token={TypographyToken.UiFormsLabelSm}>
                                    {userGroupIdRef.current?.teamName}
                                </Typography>
                            </div>
                        }
                    />)}
            </OverlayPortal>

        </Content>
    );
};

export default UserGroupsPortalView;
