import { DateFilterEnum } from '@gonitro/rcl/lib/_types/filter';
import { useMemo } from 'react';
import { FlowStatusEnum } from '~constants';
import { UserStatusEnum, UserStatusI18n } from '~constants/user-status';
import { PortalTableFiltersDefinition } from '~containers/portal-table/portal-table-filters/portal-table-filters';
import { useTranslation } from '~contexts/i18n';


const FilterStatuses = [UserStatusEnum.Active, UserStatusEnum.Inactive, UserStatusEnum.Invited];

export const useUsersTableFilters = () => {
    const { t: tBase } = useTranslation('base');
    const { t: tPortals } = useTranslation('portals');
    const { t: tUserManagement } = useTranslation('user-management');

    return useMemo<PortalTableFiltersDefinition>(() => {
        return {
            searchAll: {
                type: 'single', // TODO should be multi
                key: 'name',
                placeholder: tBase('search-input-placeholder'),
            },
            dropdowns: [
                {
                    type: 'single',
                    key: 'status',
                    options: FilterStatuses.map(status => ({
                        value: status,
                        label: tUserManagement(UserStatusI18n[status]),
                    })),
                    defaultOption: FlowStatusEnum.All,
                    translations: {
                        label: tPortals('status-filter-label'),
                        allItems: tPortals('status-filter-label-all'),
                        none: tPortals('status-filter-label-none'),
                    },
                },
                {
                    type: 'date',
                    key: 'dateRange',
                    options: 'default',
                    defaultOption: DateFilterEnum.ALL_TIME,
                    translations: {
                        date: tUserManagement('user-management-portal-table-col-title-registration-date'),
                        from: 'afterDate',
                        to: 'beforeDate',
                    },
                },
            ],
        };
    }, [tBase, tPortals, tUserManagement]);
};
