import { NdsIconFont, TablePaginator } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { ComboBox, ListItemProps } from '@progress/kendo-react-dropdowns';
import { MutableRefObject, useCallback, useContext, useRef, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import { UserManagementApi } from '~api/user-management.api';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { UserGroupPanelContext } from '~contexts/user-group-panel/user-group-panel.context';
import { useAsyncEffect } from '~hooks/effects';
import { useVirtualizedCombobox } from '~hooks/virtualizedCombobox';
import { PageableListParams } from '~models/pageable-list.models';
import { EditedDocumentGroupPermissionsModel } from '~models/user-management.models';
import { DocumentGroupTogglesModel, initialDocumentGroupToggles } from './document-group-toggle-definition';
import { SelectedDocumentGroupsState } from '../user-group-panel';
import ToggleGroup from './toggle-group/toggle-group';
import './document-group-tab.scss';


export interface DocumentGroupTabProps {
    userGroupId: number | undefined;
    editedDocGroupsRef: MutableRefObject<SelectedDocumentGroupsState[]>;
    isViewMode: boolean;
}

const PAGE_SIZE = 8;

const DocumentGroupTab = ({ userGroupId, editedDocGroupsRef, isViewMode }: DocumentGroupTabProps) => {

    const userManagementApi = useApi(UserManagementApi);
    const portalsApi = useApi(PortalsApi);
    const { t } = useTranslation('user-management');
    const { t: tBase } = useTranslation('base');

    const value = useContext(UserGroupPanelContext);

    const { setSelectedDocumentGroups, selectedDocumentGroups } = value;


    const [initialEditedDocPermissions, setInitialEditedDocPermissions] = useState<EditedDocumentGroupPermissionsModel[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [isLoading, setLoading] = useState(false);

    const listRef = useRef<{ reset: () => void; }>(null);


    const fetchData = useCallback(async (data: PageableListParams, signal?: AbortSignal) => {
        const userResponse = await portalsApi.getDocumentGroups({ ...data });

        return userResponse;

    }, [portalsApi]);

    const mapPermissions = (items: EditedDocumentGroupPermissionsModel[]) => {
        return items.map(item => ({
            ...item,
            isMember: item.permissions.some(permission => permission.hasPermission),
        }));
    };

    const updateDocumentGroups = useCallback((items: EditedDocumentGroupPermissionsModel[]) => {
        return items.map(item => {
            const editedGroup = editedDocGroupsRef.current.find(group => group.documentGroupId === item.documentGroupId);

            return {
                ...item,
                isMember: editedGroup ? editedGroup.isMember : true,
            };
        });
    }, [editedDocGroupsRef]);

    const updateDocumentGroupPermissions = (
        filteredDocumentGroups: SelectedDocumentGroupsState[],
        editedDocGroupsRef: React.MutableRefObject<SelectedDocumentGroupsState[]>,
        initialDocumentGroupToggles: DocumentGroupTogglesModel[],
    ): SelectedDocumentGroupsState[] => {
        return filteredDocumentGroups.map((docGroup) => {
            const editedGroup = editedDocGroupsRef.current.find(
                (group) => group.documentGroupId === docGroup.documentGroupId,
            );

            const updatedPermissions = docGroup.permissions.map((permission: DocumentGroupTogglesModel) => ({
                ...permission,
                label: initialDocumentGroupToggles.find(
                    (toggle) => toggle.permissionId === permission.permissionId,
                )?.label,
            }));

            return editedGroup
                ? {
                    ...docGroup,
                    isMember: editedGroup.isMember,
                    permissions: editedGroup.permissions,
                    initialPermissions: updatedPermissions,
                }
                : {
                    ...docGroup,
                    isMember: true,
                    permissions: updatedPermissions,
                    initialPermissions: updatedPermissions,
                };
        });
    };


    const fetchSelectedUsersByUserGroup = useCallback(async (signal?: AbortSignal) => {
        try {
            setLoading(true);
            const documentGroupsWithPermissionsResponse = await userManagementApi.getEditedDocumentGroupPermissions({
                userGroupId,
                isMember: true,
                page: currentPage,
                maxQuantity: 6,
            }, signal);

            const groupsWithPermissions = mapPermissions(documentGroupsWithPermissionsResponse.items);

            setInitialEditedDocPermissions((prev) => {
                const newDocs = groupsWithPermissions;

                const uniqueDocs = [...prev, ...newDocs].reduce((acc: typeof prev, item) => {
                    if (!acc.some(existingItem => existingItem.documentGroupId === item.documentGroupId)) {
                        acc.push(item);
                    }

                    return acc;
                }, []);

                return uniqueDocs;
            });

            setTotalPages(Math.ceil(documentGroupsWithPermissionsResponse.totalItems / documentGroupsWithPermissionsResponse.itemsPerPage));

            const filteredDocumentGroups = updateDocumentGroups(documentGroupsWithPermissionsResponse.items);

            const updatedDocs = updateDocumentGroupPermissions(
                filteredDocumentGroups,
                editedDocGroupsRef,
                initialDocumentGroupToggles,
            );

            const uniqueSelectedMembers =
                [...editedDocGroupsRef.current.filter((docGrp) => !docGrp.hasPermissionChanged), ...updatedDocs]
                    .reduce((acc: SelectedDocumentGroupsState[], current: SelectedDocumentGroupsState) => {
                        if (!acc.some(item => item.documentGroupId === current.documentGroupId)) {
                            acc.push(current);
                        }

                        return acc;
                    }, []);

            if (currentPage === 1) {
                setSelectedDocumentGroups(uniqueSelectedMembers);
            } else {
                setSelectedDocumentGroups(updatedDocs);
            }
            setLoading(false);
        } catch (error: any) {
            console.warn(error);
        }
    }, [
        currentPage,
        editedDocGroupsRef,
        setSelectedDocumentGroups,
        updateDocumentGroups,
        userGroupId,
        userManagementApi,
    ]);

    useAsyncEffect(() => async (signal) => {
        if (userGroupId) {
            await fetchSelectedUsersByUserGroup(signal);
        }
    }, [fetchSelectedUsersByUserGroup, currentPage, userGroupId]);


    const fetchDataByUserGroupId = useCallback(async (data: PageableListParams, signal?: AbortSignal) => {
        const documentGroupsResponse = await userManagementApi.getEditedDocumentGroupPermissions({
            ...data,
            userGroupId,
        });

        const groupsWithPermissions = documentGroupsResponse.items.map(item => ({
            ...item,
            isMember: item.permissions.some(permission => permission.hasPermission),
        })).map((group) => {
            const editedGroup = editedDocGroupsRef.current.find(selectedGroup => selectedGroup.documentGroupId === group.documentGroupId);

            if (editedGroup) {
                return {
                    ...group,
                    isMember: editedGroup.isMember,
                };
            }

            return group;
        });

        return {
            ...documentGroupsResponse,
            items: groupsWithPermissions,
        };

    }, [editedDocGroupsRef, userGroupId, userManagementApi]);

    const RenderItem = (itemProps: ListItemProps) => {

        return (
            <div className={'document-group-tab__document-group-item'}>
                <Typography
                    className='document-group-tab__document-group-name'
                    token={TypographyToken.UiMenuSm}
                    text={`${itemProps.dataItem.name}`}
                />
                {(itemProps.dataItem.isMember || (!userGroupId && selectedDocumentGroups.some((group) => group.documentGroupId === itemProps.dataItem.documentGroupId))) &&
                    <NdsIconFont
                        className='document-group-tab__combo-check-icon'
                        fontName='fa-solid-check'
                        size={SizesEnums.SMALL}
                    />}
            </div>);
    };

    const handleSetSelectedDocumentGroups = useCallback((docGroup: SelectedDocumentGroupsState | null) => {

        if (docGroup) {
            if (userGroupId) {
                const group = initialEditedDocPermissions.find((docgrp) => docgrp.documentGroupId === docGroup.documentGroupId);

                editedDocGroupsRef.current = editedDocGroupsRef.current.filter((selectedFilter) => selectedFilter.documentGroupId !== docGroup.documentGroupId);

                if (group) {
                    const updatedPermissions = initialDocumentGroupToggles.map((toggle) => {
                        const matchingPermission = group.permissions.find(
                            (permissionObj) => permissionObj.permissionId === toggle.permissionId,
                        );

                        return {
                            ...toggle,
                            hasPermission: matchingPermission ? matchingPermission.hasPermission : toggle.hasPermission,
                        };
                    });

                    group.permissions = updatedPermissions;
                }
                docGroup.permissions = group?.permissions.map((permissions) => permissions);
                docGroup.initialPermissions = group?.permissions.map((permissions) => permissions);
                editedDocGroupsRef.current.push({
                    ...docGroup,
                    isMember: !docGroup.isMember,
                });

                if (docGroup.isMember === false && currentPage !== 1) {
                    setCurrentPage(1);
                } else {
                    fetchSelectedUsersByUserGroup();
                }
            } else {
                const groupAlreadyExists = selectedDocumentGroups.some((group) => group.documentGroupId === docGroup.documentGroupId);
                let updatedGroupList;

                if (groupAlreadyExists) {
                    updatedGroupList = selectedDocumentGroups.filter((group) => group.documentGroupId !== docGroup.documentGroupId);
                } else {
                    updatedGroupList = [
                        {
                            ...docGroup,
                            isMember: true,
                        },
                        ...selectedDocumentGroups,
                    ];
                }

                setSelectedDocumentGroups(updatedGroupList);
            }


            listRef.current?.reset();
        }

    }, [
        currentPage,
        editedDocGroupsRef,
        fetchSelectedUsersByUserGroup,
        initialEditedDocPermissions,
        selectedDocumentGroups,
        setSelectedDocumentGroups,
        userGroupId,
    ]);

    const comboboxProps = useVirtualizedCombobox<any>({
        pageSize: PAGE_SIZE,
        apiFilterKey: 'name',
        fetchData: userGroupId ? fetchDataByUserGroupId : fetchData,
        valueKey: 'documentGroupId',
        labelKey: 'name',
        onChange: (e) => handleSetSelectedDocumentGroups(e),
        value: '',
        RenderItem,
        ref: listRef,
    });

    const onPageChange = useCallback((page: number) => {
        setCurrentPage(page);
    }, []);

    return (
        <div className={'document-group-tab'}>
            <Typography token={TypographyToken.UiFormsLabelSm} text={t('document-group-tab-search-label')} />
            <ComboBox
                disabled={isViewMode}
                popupSettings={{ height: 216 }}
                className={'document-group-tab__'}
                {...comboboxProps}
                clearButton={false}
                onClose={() => listRef.current?.reset()}
            />
            {isLoading
                ? <Loader center size={SizesEnums.XLARGE} />
                :
                <div className={'document-group-tab__toggle-container'}>
                    {selectedDocumentGroups.length > 0 && selectedDocumentGroups.map((docGroup) => (
                        (docGroup.isMember) &&
                        <ToggleGroup
                            isViewMode={isViewMode}
                            documentGroup={docGroup}
                            key={docGroup.documentGroupId}
                            setSelectedDocumentGroups={setSelectedDocumentGroups}
                            selectedDocumentGroups={selectedDocumentGroups}
                            editedDocGroupsRef={editedDocGroupsRef}
                            currentPage={currentPage}
                            onRemove={handleSetSelectedDocumentGroups} />
                    ))}
                    {userGroupId && totalPages > 1 &&
                        <TablePaginator
                            positionBottomFixed={false}
                            totalPages={totalPages}
                            currentPage={currentPage!}
                            prefixLabel={''}
                            ofLabel={tBase('table-pagination-of')}
                            onPageChange={onPageChange}
                        />
                    }
                </div>}
        </div>
    );
};

export default DocumentGroupTab;
