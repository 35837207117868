import { NdsIconFont } from '@gonitro/rcl';
import { SplitButton, SplitButtonClickEvent, SplitButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { useMemo, useState } from 'react';
import { getActionsFromPackageState } from 'views/portals/document/quick-action-state';
import QuickActionsModal from '~components/quick-actions-modal';
import Typography, { TypographyToken } from '~components/typography';
import { FlowAction, FlowActionI18n } from '~constants';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useDocumentQuickActions } from '~hooks/useDocumentQuickActions';
import { PackageDetailDocument, PackageDetails } from '~models/package.models';
import { PackageListItemModel, PackagesTableDataModel } from '~models/portals.models';
import './package-details-container.scss';

export interface PackageDetailsContainerProps {
    packageDetails: PackageDetails;
    packageData: PackageListItemModel;
    selectedDocument: any;
    setDocument: (document: any) => void;
    onClosePanel: () => void;
}

// TODO to be compared with handlers from in-row quickActions task
const PackageDetailsContainer = ({ packageDetails, packageData, selectedDocument, setDocument, onClosePanel }: PackageDetailsContainerProps) => {
    const { t } = useTranslation('flow');
    const documentActions = useMemo(() => getActionsFromPackageState(packageData), [packageData]);
    const [quickActionModalData, setQuickActionModalData] = useState<{
        data: PackagesTableDataModel | undefined, actionType: FlowAction | undefined;
    }>({
        data: undefined,
        actionType: undefined,
    });

    const closeModal = () => {
        setQuickActionModalData({
            data: undefined,
            actionType: undefined,
        });
    };

    const { handleActionClick, QuickActionsNoModal, WarningMessageDialogI18n,  AdditionalInformationI18n } = useDocumentQuickActions( packageData, closeModal);


    const getIcon = (icon: string, type: string) => {
        if(type === 'solid') {
            return icon.replace('fa-solid-', 'fa-solid fa-');
        } else {
            return icon.replace('fa-solid-', 'fa-regular fa-');
        }
    };

    const getFlowActionFromString = (action: string): FlowAction | undefined => {
        // Find the key in FlowActionI18n that corresponds to the given action string
        const entry = Object.entries(FlowActionI18n).find(([, value]) => value === action);

        return entry ? (entry[0] as unknown as FlowAction) : undefined;
    };

    const handleButtonClick = (event: SplitButtonClickEvent | SplitButtonItemClickEvent) => {
        let actionTypeString;

        // Type guard to check if event has 'item' property
        if ('item' in event && event.item) {
            actionTypeString = event.item.action;
        } else {
            actionTypeString = documentActions[0].label;
        }
        // Transform the action string to FlowAction enum
        const actionType = Number(getFlowActionFromString(actionTypeString));

        if (actionType && QuickActionsNoModal.includes(actionType)) {
            handleActionClick(actionType);
            onClosePanel();
        } else {
            setQuickActionModalData({
                data: packageData,
                actionType: actionType,
            });
        }
    };

    return (
        <div className='c-package-details-container'>
            <h3 className='c-package-details-container__header'>{t('documents')}</h3>
            {
                packageDetails?.documents?.map((document: PackageDetailDocument) => {
                    // TODO change this to simple div when refactoring this component
                    return (
                        <div key={document.signingFlowId} className={document.signingFlowId === selectedDocument.signingFlowId ? 'c-package-details-container__item_selected' : 'c-package-details-container__item'}>
                            <div className='c-package-details-container__menu-item'  onClick={() => setDocument(document)}>
                                <NdsIconFont fontName='fa-light-file' />
                                <Typography
                                    token={TypographyToken.UiMenuSm}
                                    text={document.documentName}
                                    tagName='div'
                                    className='c-package-details-container__menu-item-label'
                                />
                            </div>
                        </div>
                    );
                })
            }

            {/* TODO: Figure out how to change dropdown arrow icon */}
            {/* TODO: Figure out how to add separator lines between specific menu item groups */}
            {/* TODO: Fix icon margins in main button and menu items */}
            <div
                className={'c-package-details-container__menu__action-button'}
            >
                <SplitButton
                    iconClass={getIcon(documentActions[0]?.icon, 'light')}
                    text={t(documentActions[0]?.label)}
                    items={documentActions?.filter(action => documentActions[0]?.label !== action.label).map(({
                        icon,
                        label,
                        groupAction,
                    }) => ({
                        iconClass: getIcon(icon, 'solid'),
                        text: t(label),
                        groupAction: groupAction,
                        action: label,
                    }))}
                    onButtonClick={(e) => handleButtonClick(e)}
                    onItemClick={(e) => handleButtonClick(e)}
                    themeColor={'base'}
                    fillMode={'outline'}
                    popupSettings={{
                        animate: false,
                        anchorAlign: {
                            horizontal: 'right',
                            vertical: 'bottom',
                        },
                        popupAlign: {
                            horizontal: 'right',
                            vertical: 'top',
                        },
                    }}
                />

            </div>
            <OverlayPortal id='packageDetailsQuickActionsModal' type={'modal'} visible={quickActionModalData.data !== undefined}>
                {({ close }) =>
                    <QuickActionsModal
                        data={quickActionModalData.data}
                        actionType={quickActionModalData.actionType}
                        handleCloseModal={() => {
                            closeModal(); close();
                        }}
                        closePanel={onClosePanel}
                        handleClickConfirm={() => {
                            handleActionClick(quickActionModalData.actionType!);
                        }}
                        additionalInformation={AdditionalInformationI18n[quickActionModalData.actionType!]}
                        warningMessage={WarningMessageDialogI18n[quickActionModalData.actionType!]}
                    />
                }
            </OverlayPortal>

            {/* Leaving this custom component here for now as a reference */}

            {/* <div className={'c-package-details-container__menu'}> */}
            {/*     { */}
            {/*         <div */}
            {/*             className={ */}
            {/*                 buttonAction?.actionName === 'Sign' */}
            {/*                     ? */}
            {/*                     `${'c-package-details-container__menu__action-button'} ${'c-package-details-container__menu__action-button--sign'}` */}
            {/*                     : */}
            {/*                     `${'c-package-details-container__menu__action-button'}` */}
            {/*             } */}
            {/*             onClick={buttonAction?.action} */}
            {/*         > */}
            {/*             <NdsIconFont className='c-package-details-container__menu__action-button__icon' fontName={buttonAction?.icon} /> */}
            {/*             <span>{buttonAction?.actionName}</span> */}
            {/*         </div> */}
            {/*     } */}
            {/*     <NdsMenu */}
            {/*         state={StatesEnum.DEFAULT} */}
            {/*         closeOnItemClick */}
            {/*         contentAlign={RelativePositionsEnum.BOTTOM_RIGHT} */}
            {/*         open={isNdsMenuOpen} */}
            {/*     > */}
            {/*         <div */}
            {/*             className={ */}
            {/*                 buttonAction?.actionName === 'Sign' */}
            {/*                     ? */}
            {/*                     `${'c-package-details-container__menu__menu-button'} ${'c-package-details-container__menu__menu-button--sign'}` */}
            {/*                     : */}
            {/*                     `${'c-package-details-container__menu__menu-button'}` */}
            {/*             } */}
            {/*             slot={'button'} */}
            {/*             onClick={() => setIsNdsMenuOpen(!isNdsMenuOpen)} */}
            {/*         > */}
            {/*             <NdsIconFont className='c-package-details-container__menu__menu-button__icon' fontName={'fa-solid-chevron-down'} /> */}
            {/*         </div> */}
            {/*         { */}
            {/*             // Menu group for certain action types */}
            {/*             documentActions.filter(action => action.actionType === 1).length > 0 && */}
            {/*                 <NdsGroup slot={'menu'}> */}
            {/*                     { */}
            {/*                         // This prevents showing the action that is already selected and shown on the bigger button. */}
            {/*                         documentActions.filter(action => action.actionName !== buttonAction?.actionName && action.actionType === 1).map(action => { */}
            {/*                             return ( */}
            {/*                                 <NdsMenuItem */}
            {/*                                     key={action.key} */}
            {/*                                     slot={'items'} */}
            {/*                                     primaryText={action.actionName} */}
            {/*                                     onClick={() => setButtonAction(action)} */}
            {/*                                     icon={action.icon} */}
            {/*                                 /> */}
            {/*                             ); */}
            {/*                         }) */}
            {/*                     } */}
            {/*                 </NdsGroup> */}
            {/*         } */}
            {/*         { */}
            {/*             // show the divider between action groups with conditions */}
            {/*             documentActions.filter(action => action.actionType === 1).length > 0 && */}
            {/*             documentActions.filter(action => action.actionType === 2).length > 0 && */}
            {/*             documentActions.length > 1 && */}
            {/*             <NdsDivider slot={'menu'} bgMode={BkgModesEnum.LIGHT} /> */}
            {/*         } */}
            {/*         { */}
            {/*             // Menu group for certain action types */}
            {/*             documentActions.filter(action => action.actionType === 2).length > 0 && */}
            {/*             <NdsGroup slot={'menu'}> */}
            {/*                 { */}
            {/*                     // This prevents showing the action that is already selected and shown on the bigger button. */}
            {/*                     documentActions.filter(action => action.actionName !== buttonAction?.actionName && action.actionType === 2).map(action => { */}
            {/*                         return ( */}
            {/*                             <NdsMenuItem */}
            {/*                                 key={action.key} */}
            {/*                                 slot={'items'} */}
            {/*                                 primaryText={action.actionName} */}
            {/*                                 onClick={() => setButtonAction(action)} */}
            {/*                                 icon={action.icon} */}
            {/*                             /> */}
            {/*                         ); */}
            {/*                     }) */}
            {/*                 } */}
            {/*             </NdsGroup> */}
            {/*         } */}
            {/*         { */}
            {/*             // show the divider between action groups with conditions */}
            {/*             documentActions.filter(action => action.actionType === 2).length > 0 && */}
            {/*             documentActions.filter(action => action.actionType === 3).length > 0 && */}
            {/*             documentActions.length > 2 && */}
            {/*             <NdsDivider slot={'menu'} bgMode={BkgModesEnum.LIGHT} /> */}
            {/*         } */}
            {/*         { */}
            {/*             // Menu group for certain action types */}
            {/*             documentActions.filter(action => action.actionType === 3).length > 0 && */}
            {/*                 <NdsGroup slot={'menu'}> */}
            {/*                     { */}
            {/*                         // This prevents showing the action that is already selected and shown on the bigger button. */}
            {/*                         documentActions.filter(action => action.actionName !== buttonAction?.actionName && action.actionType === 3).map(action => { */}
            {/*                             return ( */}
            {/*                                 <NdsMenuItem */}
            {/*                                     key={action.key} */}
            {/*                                     slot={'items'} */}
            {/*                                     primaryText={action.actionName} */}
            {/*                                     onClick={() => setButtonAction(action)} */}
            {/*                                     icon={action.icon} */}
            {/*                                 /> */}
            {/*                             ); */}
            {/*                         }) */}
            {/*                     } */}
            {/*                 </NdsGroup> */}
            {/*         } */}
            {/*     </NdsMenu> */}
            {/* </div> */}
        </div>
    );
};
export default PackageDetailsContainer;
