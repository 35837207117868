import { NdsButton, NdsIconFont, SidePanel } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useEffect, useRef, useState } from 'react';
import { ContactsApi } from '~api/contacts.api';
import Error from '~components/error';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { Permission } from '~constants';
import { useApi } from '~contexts/api';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import useMapContactToComboboxModel from '~hooks/contact-combobox';
import { Guid, LanguageDataLabel } from '~models';
import { ContactGroupModel } from '~models/contact-group.model';
import { ComboboxContactModel } from '~models/contacts.models';
import AddContactToGroup from './add-contact-to-group';
import './contactGroupForm.scss';

export interface ContactGroupFormProps {
    contactGroup?: ContactGroupModel;
    onClose: () => void;
    open: boolean;
}

export type FormValuesContactGroup = {
    id: Guid;
    name: string;
    externalReference: string;
    contactIds: number[];
    isShared: boolean;
};


function ContactGroupForm({ contactGroup, open, onClose }: ContactGroupFormProps) {
    const { t } = useTranslation('contacts');
    const { t: tNotifications } = useTranslation('notifications');
    const contactsApi = useApi(ContactsApi);
    const hasCreateSharedContactGtoupPermission = useHasPermission(Permission.General_ActionCreateSharedContactGroup);
    const hasViewSharedContactPermission = useHasPermission(Permission.General_ActionViewSharedContact);
    const formRef = useRef<HTMLFormElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const [selectedContacts, setSelectedContacts] = useState<ComboboxContactModel[]>();
    const showNotification = useShowNotification();

    const handleClose = () => {
        onClose();
    };

    const { transformModel } = useMapContactToComboboxModel();

    const handleValidateAndSubmit = async () => {
        if(formRef.current?.values.name === undefined || ( selectedContacts === undefined || selectedContacts?.length === 0)) {
            setError('something-went-wrong');
            setIsLoading(false);
        } else {
            setError(undefined);
            const contactIds = selectedContacts.map((el: ComboboxContactModel) => (Number(el.id)));
            try{
                setIsLoading(true);
                const params = {
                    name : formRef.current?.values.name,
                    externalReference: formRef.current?.values.externalReference,
                    isShared: formRef.current?.values.isShared,
                    contactIds: contactIds,
                    id: contactGroup?.id,
                };

                if (contactGroup) {
                    await contactsApi.editContactGroup(params);
                    showNotification({
                        type: 'success',
                        title: tNotifications('edit-contact-group-success'),
                        hideAfterMs: 2000,
                    });
                } else {
                    await contactsApi.createContactGroup(params);
                    showNotification({
                        type: 'success',
                        title: tNotifications('create-contact-group-success', { contactGroup: params.name }),
                        hideAfterMs: 2000,
                    });
                }

                setIsLoading(false);
                onClose();
            } catch(e) {
                setIsLoading(false);
                showNotification({
                    type: 'error',
                    title: tNotifications('unknown-error-title'),
                    message: tNotifications('unknown-error-message'),
                    hideAfterMs: 2000,
                });
                setError('something-went-wrong');
            }
        }
    };

    useEffect(() => {
        if(contactGroup?.contacts ) {
            setSelectedContacts(contactGroup.contacts.map(transformModel));
        }
    }, [contactGroup?.contacts, transformModel]);

    return (
        <SidePanel
            className='contact-group-panel'
            onClosePanel={handleClose}
            footer={
                <FooterContactGroup
                    contactGroup={contactGroup}
                    isLoading={isLoading}
                    onAddClick={handleValidateAndSubmit}
                    onClose={handleClose}
                />
            }
        >
            {open
                ? (
                    <div className={'c-contact-group-form'}>
                        <div>{error && <Error className={'c-contact-group-form__error-text'} i18nKey={error} />}</div>
                        <Typography
                            token={TypographyToken.DesktopHeaderXs}
                            text={contactGroup ? t('edit-contact-group') : t('create-contact-group')}
                            tagName='div'
                        />
                        <Form
                            ref={formRef}
                            initialValues={{
                                name: contactGroup?.name,
                                externalReference: contactGroup?.externalReference,
                                isShared: contactGroup?.isShared,
                            }}
                            render={(formRenderProps) => (
                                <FormElement>
                                    <div className={'c-contact-group-form__form-wrapper'}>
                                        <div className={'c-contact-group-form__name-wrapper'}>
                                            <div className={'c-contact-group-form__name'}>
                                                <div className={'c-contact-group-form__name-label'}>
                                                    <Typography
                                                        token={TypographyToken.DesktopBylineSm}
                                                        text={t('contact-group-name')}
                                                        tagName='div'
                                                    />
                                                    <Typography
                                                        type='span'
                                                        token={TypographyToken.UiFormsLabelSm}
                                                        text={'*'}
                                                        className='c-contact-group-form__required-field'
                                                    />
                                                </div>
                                                <Field
                                                    name={'name'}
                                                    component={Input} />
                                            </div>
                                            <div className={'c-contact-group-form__code'} >
                                                <Typography
                                                    token={TypographyToken.DesktopDescriptionSm}
                                                    text={`${t('code')}: `}
                                                    tagName='div'
                                                />

                                                <Typography
                                                    token={TypographyToken.DesktopDescriptionBoldSm}
                                                    text={contactGroup?.code ? contactGroup.code : 'XXXXX'}
                                                    tagName='div'
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className={'c-contact-group-form__external-reference'}>
                                                <Typography
                                                    token={TypographyToken.DesktopBylineSm}
                                                    text={t('external-reference-label')}
                                                    tagName='div'
                                                />
                                                <Tooltip
                                                    anchorElement="target"
                                                    position="left"
                                                    parentTitle={true}
                                                    className={'c-contact-group-form__external-reference-tooltip'}
                                                >
                                                    <div title={t('external-reference-tooltip')}>
                                                        <NdsIconFont
                                                            size={SizesEnums.SMALL}
                                                            fontName='fa-solid-info-circle'
                                                            className={'c-contact-group-form__info-icon'}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <Field name={'externalReference'} component={Input} />
                                        </div>
                                    </div>
                                    <AddContactToGroup hasViewSharedContactPermission={hasViewSharedContactPermission} selectedContacts={selectedContacts} setSelectedContacts={(contacts) => setSelectedContacts(contacts)}/>
                                    {hasCreateSharedContactGtoupPermission && !contactGroup&& (
                                        <div className='c-contact-group-form__shared-wrapper'>
                                            <Field name={'isShared'} component={Checkbox} />
                                            <div className='c-contact-group-form__shared-label'>
                                                <Typography
                                                    token={TypographyToken.DesktopBylineSm}
                                                    text={t('shared-contact-group')}
                                                    tagName='div'
                                                />
                                                <Typography
                                                    token={TypographyToken.UiFormsLabelXs}
                                                    text={t('shared-contact-group-subtitle')}
                                                    className={'c-contact-group-form__shared-subtitle'}
                                                    tagName='div'
                                                />
                                            </div>
                                        </div>
                                    )}
                                </FormElement>
                            )}
                        />
                    </div>
                )
                : (
                    <div className='c-contact-group-form__loader'>
                        <Loader center size={SizesEnums.XLARGE} />
                    </div>
                )}
        </SidePanel>
    );
}

export default ContactGroupForm;

interface FooterProps {
    contactGroup?: ContactGroupModel;
    isLoading: boolean;
    onAddClick: () => void;
    onClose: () => void;
}

const FooterContactGroup = ({ contactGroup, isLoading, onAddClick, onClose }: FooterProps) => {
    const { t } = useTranslation('contacts');

    return (
        <div className={'c-contact-group-form__footer'}>
            <NdsButton
                size={SizesEnums.SMALL}
                label={t('cancel-button')}
                buttonType={NdsButtonTypesEnum.NEUTRAL}
                onClick={onClose}
            />
            {isLoading
                ? (
                    <div className={'c-contact-group-form__footer-loader'} style={{}}>
                        <Loader size={SizesEnums.SMALL} />
                    </div>
                )
                : (
                    <NdsButton
                        size={SizesEnums.SMALL}
                        label={contactGroup ? t('save-button') : t('add-button')}
                        buttonType={NdsButtonTypesEnum.PRIMARY}
                        onClick={onAddClick}
                    />
                )}
        </div>
    );
};
