import { Button } from '@progress/kendo-react-buttons';
import ContactIcon from '~components/contact-icon';
import Typography, { TypographyToken } from '~components/typography';
import { ContactGroupDiscriminator, Permission } from '~constants';
import { useHasPermissionFunc } from '~contexts/auth';
import { ContactBucketItemModel } from '~models';

export type ContactSelectorItem =
    ContactBucketItemModel & { localId: string };

export interface ContactSelectorValueItemProps {
    item: ContactSelectorItem;
    onEditClick: (item: ContactSelectorItem) => void;
    onDeleteClick: (item: ContactSelectorItem) => void;
}

export const ContactSelectorValueItem = ({ item, onEditClick, onDeleteClick }: ContactSelectorValueItemProps) => {
    const hasPermission = useHasPermissionFunc();

    const canEdit =
        item
        && item?.discriminator !== ContactGroupDiscriminator.ContactGroup
        && item.isEditable
        && (!item.isShared || hasPermission(Permission.General_ActionEditSharedContact));

    return (
        <div className={'c-contact-selector__value'}>
            <ContactIcon contact={item} />
            {item.discriminator === ContactGroupDiscriminator.ContactGroup
                ? (
                    <Typography
                        token={TypographyToken.UiFormsLabelSm}
                        text={item.contactGroupName}
                        className='c-contact-selector__value-name'
                    />
                )
                : (item.firstName || item.lastName
                    ? (
                        <Typography
                            token={TypographyToken.UiFormsLabelSm}
                            text={`${item.firstName ?? ''} ${item.lastName ?? ''}`.trim()}
                            className='c-contact-selector__value-name'
                        />
                    )
                    : null
                )
            }
            {'emailAddress' in item && item.emailAddress && (
                <Typography
                    token={TypographyToken.UiFormsLabelSm}
                    text={item.emailAddress}
                    className='c-contact-selector__value-email'
                />
            )}

            {canEdit && (
                <Button
                    className={'c-contact-selector__value-btn'}
                    iconClass={'fa-solid fa-edit'}
                    onClick={() => onEditClick(item)}
                    fillMode={'flat'}
                />
            )}
            <Button
                className={'c-contact-selector__value-btn'}
                iconClass={'fa-solid fa-minus-circle'}
                onClick={() => onDeleteClick(item)}
                fillMode={'flat'}
            />
        </div>
    );
};
