import { NdsIconFont } from '@gonitro/rcl';
import { ComboBox, ListItemProps } from '@progress/kendo-react-dropdowns';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import { UserManagementApi } from '~api/user-management.api';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { Permission } from '~constants';
import { useApi } from '~contexts/api';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useAsyncEffect } from '~hooks/effects';
import { useEnhancedFormContext } from '~hooks/enhanced-form/enhanced-form-context';
import { useVirtualizedCombobox } from '~hooks/virtualizedCombobox';
import { PageableListParams } from '~models/pageable-list.models';
import { MemberUserGroupModel, UserGroupsListItemModel } from '~models/user-management.models';
import UserGroupItem from './user-group-item/user-group-item';
import './user-groups-tab.scss';


export interface UserGroupsTabProps {
    userId: number;
    setSelectedUserGroups: (userGroups: MemberUserGroupModel[]) => void;
    selectedUserGroups: MemberUserGroupModel[];
    hasEditedUserGroups: boolean;
    setHasEditedUserGroups: (hasEdited: boolean) => void;
    viewMode?: boolean;
}

const UserGroupsTab = ({ userId, setSelectedUserGroups, selectedUserGroups, hasEditedUserGroups, setHasEditedUserGroups, viewMode }: UserGroupsTabProps) => {

    const { setError, clearErrors, formState } = useEnhancedFormContext();

    const { t } = useTranslation('user-management');
    const portalsApi = useApi(PortalsApi);
    const userManagementApi = useApi(UserManagementApi);
    const hasEditUserGroupPermission = useHasPermission(Permission.AccessUserManagementPortal_ActionEditUserGRoup);
    const [isLoading, setLoading] = useState(true);

    const fetchData = useCallback(async (data: PageableListParams, signal?: AbortSignal) => {

        const userGroupResponse = await portalsApi.getUserGroups({ ...data });

        return userGroupResponse;

    }, [portalsApi]);

    useAsyncEffect(() => async (signal) => {
        if (!hasEditedUserGroups) {
            setLoading(true);
            const memberUserGroupIdResponse = await userManagementApi.getMemberUserGroups({ userId });

            setSelectedUserGroups(memberUserGroupIdResponse);
        }
        setLoading(false);
    }, [setSelectedUserGroups, userId, userManagementApi, hasEditedUserGroups]);

    const PAGE_SIZE = 10;

    const RenderItem = (itemProps: ListItemProps) => {
        return (
            <div className={'user-group-tab__user-group-combo-list-item'}>
                <div className='user-group-tab__combo-list-item-title'>
                    <Typography
                        className='user-group-tab__combo-list-item-name'
                        token={TypographyToken.UiMenuSm}
                        text={`${itemProps.dataItem.name}`}
                    ></Typography>
                </div>

                {selectedUserGroups.map((user) => user.id === itemProps.dataItem.userGroupId &&
                    <NdsIconFont
                        key={user.id}
                        className='user-group-tab__combo-check-icon'
                        fontName='fa-solid-check'
                    />)}

            </div>);
    };

    const handleSelectOrRemoveMemberUserGroup = (id: number | undefined, name: string | undefined) => {
        if (id && name) {
            const isSelected = selectedUserGroups.some((group) => group.id === id);

            if (isSelected) {
                const filteredSelectedUserGroups = selectedUserGroups.filter((group) => group.id !== id);

                setSelectedUserGroups(filteredSelectedUserGroups);
            } else {
                const newSelectedUserGroups = [
                    ...selectedUserGroups,
                    {
                        id,
                        name: name,
                    },
                ];

                setSelectedUserGroups(newSelectedUserGroups);
            }
            setHasEditedUserGroups(true);
        }

    };

    const comboboxProps = useVirtualizedCombobox<UserGroupsListItemModel>({
        pageSize: PAGE_SIZE,
        apiFilterKey: 'name',
        fetchData,
        valueKey: 'userGroupId',
        labelKey: 'name',
        onChange: (e) => handleSelectOrRemoveMemberUserGroup(e?.userGroupId, e?.name),
        value: '',
        RenderItem,
    });

    useEffect(() => {
        if (selectedUserGroups.length) {
            clearErrors('userGroups');
        } else {
            !isLoading && setError('userGroups', { message: 'must be member of a user group' });
        }

    }, [clearErrors, setError, selectedUserGroups, isLoading]);

    return (


        <div className={classNames('user-group-tab', { 'user-group-tab--disabled': !hasEditUserGroupPermission })}>
            <Typography
                token={TypographyToken.DesktopDescriptionSm}
                text={t('user-groups-tab-add-access')}
                tagName={'label'}
                className={'user-group-tab__add-substitute-label'}
            />
            <ComboBox
                disabled={viewMode}
                popupSettings={{ height: 185 }}
                className={classNames({ 'user-group-tab__error': formState.errors.userGroups })}
                {...comboboxProps}
                clearButton={false}
            />
            {formState.errors.userGroups &&
                <Typography
                    className='user-group-tab__error-text'
                    token={TypographyToken.UiFormsLabelXs}
                    text={t('user-groups-tab-select-one-error')}
                ></Typography>}
            {isLoading
                ? <Loader center /> :
                <div className={classNames('user-group-tab__item-list-container', { 'user-group-tab__disabled': viewMode })}>
                    {selectedUserGroups.map((userGroup) =>
                        <UserGroupItem
                            handleRemoveMemberUserGroup={handleSelectOrRemoveMemberUserGroup}
                            userGroup={userGroup}
                            key={userGroup.id}
                        />)}
                </div>}
        </div>
    );
};

export default UserGroupsTab;
