import { LanguageDataLabel } from '~models';

export enum FlowError {
    GenericError = 'GenericError',
    DocumentUploadFailure = 'DocumentUploadFailure',
    DocumentProcessingFailure = 'DocumentProcessingFailure',
    TemplateNameNotUnique = 'TemplateNameNotUnique',
    PackageNameCannotBeEmpty = 'PackageNameCannotBeEmpty',
    PackageNameBetweenLength = 'PackageNameBetweenLength',
    PackageRedirectUrlValidUrl = 'PackageRedirectUrlValidUrl',
    PackageCallbackUrlValidUrl = 'PackageCallbackUrlValidUrl',
    PackageNotificationUrlValidUrl = 'PackageNotificationUrlValidUrl',
    StakeholderPersonMissingPhoneNumberForAuthentication = 'StakeholderPersonMissingPhoneNumberForAuthentication',
    StakeholderGroupAllMissingPhoneNumberForAuthentication = 'StakeholderGroupAllMissingPhoneNumberForAuthentication',
    StakeholderGroupPartialMissingPhoneNumberForAuthentication = 'StakeholderGroupPartialMissingPhoneNumberForAuthentication',
    StakeholderIsUnassigned = 'StakeholderIsUnassigned',
    FieldMissingUniqueName = 'FieldMissingUniqueName',
    FieldMissingSigningTypeSelection = 'FieldMissingSigningTypeSelection',
    FieldStakeholderPersonHasMissingPhoneNumberForSMSSigning = 'FieldStakeholderPersonHasMissingPhoneNumberForSMSSigning',
    FieldStakeholderGroupAllHasMissingPhoneNumberForSMSSigning = 'FieldStakeholderGroupAllHasMissingPhoneNumberForSMSSigning',
    FieldStakeholderGroupPartialHasMissingPhoneNumberForSMSSigning = 'FieldStakeholderGroupPartialHasMissingPhoneNumberForSMSSigning',
    SigningFieldPlacedOnDocWithSignatures = 'SigningFieldPlacedOnDocWithSignatures',
    FieldIsNotPlacedOnDocument = 'FieldIsNotPlacedOnDocument',
    FieldNameNotUniqueOnDocument = 'FieldNameNotUniqueOnDocument',
}

export type FlowErrorsKey = FlowError | `${FlowError}:${string}`;

export type FlowErrorsType = Partial<Record<FlowErrorsKey, any>>;

export const FlowDetailsErrors = [
    FlowError.GenericError,
    FlowError.TemplateNameNotUnique,
    FlowError.PackageNameCannotBeEmpty,
    FlowError.PackageNameBetweenLength,
    FlowError.PackageRedirectUrlValidUrl,
    FlowError.PackageCallbackUrlValidUrl,
    FlowError.PackageNotificationUrlValidUrl,
    FlowError.PackageNameBetweenLength,
] as const;

export const FlowStakeholderErrors = [FlowError.StakeholderPersonMissingPhoneNumberForAuthentication, FlowError.StakeholderGroupAllMissingPhoneNumberForAuthentication, FlowError.StakeholderGroupPartialMissingPhoneNumberForAuthentication, FlowError.StakeholderIsUnassigned] as const;

export const FlowElementErrors = [
    FlowError.FieldMissingUniqueName,
    FlowError.FieldMissingSigningTypeSelection,
    FlowError.FieldStakeholderPersonHasMissingPhoneNumberForSMSSigning,
    FlowError.FieldStakeholderGroupAllHasMissingPhoneNumberForSMSSigning,
    FlowError.FieldStakeholderGroupPartialHasMissingPhoneNumberForSMSSigning,
    FlowError.SigningFieldPlacedOnDocWithSignatures,
    FlowError.FieldIsNotPlacedOnDocument,
    FlowError.FieldNameNotUniqueOnDocument,
] as const;

export const FlowErrorTitleI18n: Record<FlowError, LanguageDataLabel<'flow'> | undefined> = {
    [FlowError.GenericError]: undefined,
    [FlowError.DocumentUploadFailure]: undefined,
    [FlowError.DocumentProcessingFailure]: undefined,
    [FlowError.TemplateNameNotUnique]: undefined,
    [FlowError.StakeholderPersonMissingPhoneNumberForAuthentication]: undefined,
    [FlowError.StakeholderGroupAllMissingPhoneNumberForAuthentication]: undefined,
    [FlowError.StakeholderGroupPartialMissingPhoneNumberForAuthentication]: undefined,
    [FlowError.StakeholderIsUnassigned]: undefined,
    // defined:
    [FlowError.PackageNameCannotBeEmpty]: 'package-title',
    [FlowError.PackageNameBetweenLength]: 'package-title',
    [FlowError.PackageRedirectUrlValidUrl]: 'redirect-url-title',
    [FlowError.PackageCallbackUrlValidUrl]: 'callback-url-title',
    [FlowError.PackageNotificationUrlValidUrl]: 'notification-callback-url-title',
    [FlowError.FieldMissingUniqueName]: 'field-missing-unique-name-title',
    [FlowError.FieldNameNotUniqueOnDocument]: 'field-name-not-unique-on-document-title',
    [FlowError.FieldStakeholderPersonHasMissingPhoneNumberForSMSSigning]: 'signature-title',
    [FlowError.FieldStakeholderGroupAllHasMissingPhoneNumberForSMSSigning]: 'signature-title',
    [FlowError.FieldStakeholderGroupPartialHasMissingPhoneNumberForSMSSigning]: 'signature-title',
    [FlowError.FieldMissingSigningTypeSelection]: 'signature-title',
    [FlowError.SigningFieldPlacedOnDocWithSignatures]: 'signature-title',
    [FlowError.FieldIsNotPlacedOnDocument]: 'signature-title',
};

export const FlowErrorTextI18n: Record<FlowError, LanguageDataLabel<'flow'> | undefined> = {
    [FlowError.GenericError]: undefined,
    [FlowError.DocumentUploadFailure]: undefined,
    [FlowError.DocumentProcessingFailure]: undefined,
    [FlowError.TemplateNameNotUnique]: undefined,
    // defined:
    [FlowError.PackageNameCannotBeEmpty]: 'package-name-cannot-be-empty',
    [FlowError.PackageNameBetweenLength]: 'package-name-between-length',
    [FlowError.PackageRedirectUrlValidUrl]: 'enter-valid-url',
    [FlowError.PackageCallbackUrlValidUrl]: 'enter-valid-url',
    [FlowError.PackageNotificationUrlValidUrl]: 'enter-valid-url',
    [FlowError.StakeholderPersonMissingPhoneNumberForAuthentication]: 'recipient-missing-number-for-authentication',
    [FlowError.StakeholderGroupAllMissingPhoneNumberForAuthentication]: 'recipient-group-missing-number-for-authentication',
    [FlowError.StakeholderGroupPartialMissingPhoneNumberForAuthentication]: 'recipient-group-part-missing-number-for-authentication',
    [FlowError.StakeholderIsUnassigned]: 'stakeholder-is-not-assigned-to-any-action',
    [FlowError.FieldMissingUniqueName]: 'field-missing-unique-name',
    [FlowError.FieldNameNotUniqueOnDocument]: 'field-name-not-unique-on-document',
    [FlowError.FieldStakeholderPersonHasMissingPhoneNumberForSMSSigning]: 'recipient-missing-number-for-sms-signing',
    [FlowError.FieldStakeholderGroupAllHasMissingPhoneNumberForSMSSigning]: 'recipient-group-missing-number-for-sms-signing',
    [FlowError.FieldStakeholderGroupPartialHasMissingPhoneNumberForSMSSigning]: 'recipient-group-part-missing-number-for-sms-signing',
    [FlowError.FieldMissingSigningTypeSelection]: 'field-no-signing-type-selected',
    [FlowError.SigningFieldPlacedOnDocWithSignatures]: 'signing-field-placed-on-doc-with-signatures',
    [FlowError.FieldIsNotPlacedOnDocument]: 'field-is-not-placed-on-document',
};
