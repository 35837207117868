import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useAuthContext } from '~contexts/auth';

type Callback = (value: boolean) => void;

function useCheckAbsences( callback: Callback) {
    const userInfo = useAuthContext();

    useEffect(() => {
        if (userInfo?.absences.length > 0) {
            const { startDate: rawStartDate, endDate: rawEndDate } = userInfo.absences[0];
            const startDate = DateTime.fromISO(rawStartDate).startOf('minute');
            const endDate = DateTime.fromISO(rawEndDate).startOf('minute');
            const nowDate = DateTime.now().startOf('minute');

            if (nowDate >= startDate && nowDate <= endDate) {
                callback(true);
            } else {
                callback(false);
            }
        }
    }, [userInfo, callback]);
}

export default useCheckAbsences;