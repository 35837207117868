import { MutableRefObject, useCallback, useState } from 'react';
import { Actions } from '~constants';
import { useTranslation } from '~contexts/i18n';
import DeleteUserModalContent from './delete-user-modal-content/delete-user-modal-content';
import UnsavedChangesModal from '../../unsaved-changes-modal/unsaved-changes-modal';
import './deleteUserModalWrapper.scss';

export interface DeleteUserModalWrapperProps {
    onCloseModal: () => void;
    userId?: number;
    isInvitedUserDelete: boolean;
    userFullName?: string;
    userEmail: string;
    setIsDeleteUserModalVisible: (visible: boolean) => void;
    setIsInvitedUserDelete: (visible: boolean) => void;
    userManagementApi: {
        deleteUser: (params: { userId: number }) => Promise<any>;
        deleteInvitedUser: (params: { email: string }) => Promise<any>;
    };
    showNotification: (params: { type: 'success' | 'error'; title: string; hideAfterMs: number }) => void;
    setUserId: (userId?: number) => void;
    userEmailRef: MutableRefObject<string>;
}

const DeleteUserModalWrapper = ({
    onCloseModal,
    userId,
    isInvitedUserDelete,
    userFullName,
    userEmail,
    setIsDeleteUserModalVisible,
    setIsInvitedUserDelete,
    userManagementApi,
    showNotification,
    setUserId,
    userEmailRef,
}: DeleteUserModalWrapperProps) => {
    const [loading, setLoading] = useState(false);
    const [userDeleteError, setUserDeleteError] = useState<string>();
    const { t: tUserManagement } = useTranslation('user-management');
    const { t: tBase } = useTranslation('base');
    const { t: tNotifications } = useTranslation('notifications');
    const { t: tError } = useTranslation('error');

    const deleteUserModalActionsHandler = useCallback(async (type: Actions) => {

        switch (type) {
            case Actions.CANCEL:
                setUserId(undefined);
                setUserDeleteError(undefined);
                setIsDeleteUserModalVisible(false);
                setIsInvitedUserDelete(false);
                setLoading(false);

                break;
            case Actions.PRIMARY_ACTION:
                // User deletion based on userId works for active/inactive users only
                if (isInvitedUserDelete) {
                    try {
                        const response = await userManagementApi.deleteInvitedUser({ email: userEmailRef.current });

                        showNotification({
                            type: 'success',
                            title: tNotifications('user-delete-success', { emailAddress: userEmailRef.current }),
                            hideAfterMs: 2000,
                        });
                        setIsDeleteUserModalVisible(false);
                        setIsInvitedUserDelete(false);
                        setLoading(false);

                        return response;
                    } catch (error) {
                        showNotification({
                            type: 'error',
                            title: tNotifications('user-delete-failed', { emailAddress: userEmailRef.current }),
                            hideAfterMs: 2000,
                        });
                        setLoading(false);
                        throw (error);
                    }
                }

                if (userId) {
                    try {
                        const response = await userManagementApi.deleteUser({ userId: userId });

                        showNotification({
                            type: 'success',
                            title: tNotifications('user-delete-success', { emailAddress: userEmailRef.current }),
                            hideAfterMs: 2000,
                        });

                        setUserId(undefined);
                        setUserDeleteError(undefined);
                        setIsDeleteUserModalVisible(false);
                        setLoading(false);

                        return response;
                    } catch (error: any) {
                        const code = error.body[0]?.ErrorCode;

                        if (code === 'User.StillOwnsPackagesOrDocuments') {
                            setUserDeleteError(tUserManagement('user-still-owns-one-or-more-packages-or-documents'));

                            return showNotification({
                                type: 'error',
                                title: tUserManagement('user-still-owns-one-or-more-packages-or-documents'),
                                hideAfterMs: 2000,
                            });
                        }

                        if (code === 'User.StillOwnsTemplates') {
                            setUserDeleteError(tUserManagement('user-still-owns-one-or-more-templates'));

                            return showNotification({
                                type: 'error',
                                title: tUserManagement('user-still-owns-one-or-more-templates'),
                                hideAfterMs: 2000,
                            });
                        }

                        if (code === `User.CannotRemoveSelf:${userId}`) {
                            setUserDeleteError(tError('delete-yourself'));

                            return showNotification({
                                type: 'error',
                                title: tError('delete-yourself'),
                                hideAfterMs: 2000,
                            });
                        }
                        showNotification({
                            type: 'error',
                            title: tNotifications('user-delete-failed', { emailAddress: userEmailRef.current }),
                            hideAfterMs: 2000,
                        });
                        setLoading(false);
                    }
                }
                break;
            default:
                break;
        }

    }, [
        setUserId,
        setIsDeleteUserModalVisible,
        setIsInvitedUserDelete,
        isInvitedUserDelete,
        userId,
        userManagementApi,
        userEmailRef,
        showNotification,
        tNotifications,
        tUserManagement,
        tError,
    ]);


    return (
        <UnsavedChangesModal
            close={onCloseModal}
            withClose
            loading={loading}
            isExtraActionModal={false}
            onButtonClick={deleteUserModalActionsHandler}
            modalHeaderLabel={tUserManagement('delete-user-modal-header')}
            modalTitleLabel={tUserManagement('delete-user-modal-title')}
            cancelButtonLabel={tBase('cancel')}
            primaryActionButtonLabel={tBase('delete')}
            modalContentComponent={
                <DeleteUserModalContent
                    isInvitedUserDelete={isInvitedUserDelete}
                    userFullName={userFullName}
                    userDeleteError={userDeleteError}
                    userEmail={userEmail}
                />
            }
        />
    );
};

export default DeleteUserModalWrapper;
