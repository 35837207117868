import { cloneDeep } from 'lodash';
import { ActorType } from '~constants';
import { FlowActionType, FlowGroupType, FlowReducerFunc } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { FlowActorModel, FlowFormFillerModel, FlowSignerModel, Guid } from '~models';

export type FlowReducerActorActionPayloads = {
    [FlowActionType.AddActor]: {
        actor: FlowActorModel;
        groupIndex: number;
        newGroup: boolean;
    };
    [FlowActionType.DeleteActor]: { actorId: Guid, deleteElementsFromViewer?: boolean };
    [FlowActionType.AddActorElements]: { actorId: Guid, elementIds: Guid[] };
    [FlowActionType.DeleteActorElements]: { actorId: Guid, elementIds?: Guid[] };
    [FlowActionType.SetActorStakeholder]: { actorId: Guid, stakeholderId?: Guid };
}

type ExistenceParam = { ffaExists: boolean, sExists: boolean, rExists: boolean }

const GroupTypeForActorType = {
    [ActorType.Approver]: (p: ExistenceParam) => [ActorType.Approver, ActorType.FormFiller],
    [ActorType.FormFiller]: (p: ExistenceParam) => [ActorType.Approver, ActorType.FormFiller],
    [ActorType.Signer]: (p: ExistenceParam) => [ActorType.Signer],
    [ActorType.Receiver]: (p: ExistenceParam) => [ActorType.Receiver],
};

const PrevGroupTypeForActorType = {
    [ActorType.Approver]: (p: ExistenceParam) => [ActorType.Approver, ActorType.FormFiller],
    [ActorType.FormFiller]: (p: ExistenceParam) => [ActorType.Approver, ActorType.FormFiller],
    [ActorType.Signer]: (p: ExistenceParam) => [ActorType.Approver, ActorType.FormFiller, ActorType.Signer],
    [ActorType.Receiver]: (p: ExistenceParam) => p.sExists ? [ActorType.Signer] : [ActorType.FormFiller],
};
const NextGroupTypeForActorType = {
    [ActorType.Approver]: (p: ExistenceParam) => p.sExists ? [ActorType.Approver, ActorType.FormFiller, ActorType.Signer] : [ActorType.Approver, ActorType.FormFiller, ActorType.Receiver],
    [ActorType.FormFiller]: (p: ExistenceParam) => p.sExists ? [ActorType.Approver, ActorType.FormFiller, ActorType.Signer] : [ActorType.Approver, ActorType.FormFiller, ActorType.Receiver],
    [ActorType.Signer]: (p: ExistenceParam) => [ActorType.Signer, ActorType.Receiver],
    [ActorType.Receiver]: (p: ExistenceParam) => [] as ActorType[],
};
export const AddActorReducer: FlowReducerFunc<FlowActionType.AddActor> = (
    state,
    { actor, newGroup, groupIndex },
) => {
    const flow = [...state.flow];
    const actorType = actor.actorType;

    const res = FlowUtils.findFlowActorGroupsIndexes(flow);
    const ffaExists = res[FlowGroupType.FormFillersApprovers].first > -1;
    const sExists = res[FlowGroupType.Signers].first > -1;
    const rExists = res[FlowGroupType.Receivers].first > -1;

    if (newGroup) {
        const prevGroup = groupIndex > 0 ? flow[groupIndex - 1] : null;
        const nextGroup = groupIndex < flow.length ? flow[groupIndex] : null;

        if (prevGroup) {
            const groupType = prevGroup[0].actorType;
            const validPrev = PrevGroupTypeForActorType[actorType]({
                ffaExists,
                sExists,
                rExists,
            });

            if (!validPrev.includes(groupType)) {
                throw new Error(`Invalid actor type (${actorType}) group was tried to be added after group (${groupType}) of index '${groupIndex}'`);
            }
        }
        if (nextGroup) {
            const groupType = nextGroup[0].actorType;
            const validNext = NextGroupTypeForActorType[actorType]({
                ffaExists,
                sExists,
                rExists,
            });

            if (!validNext.includes(groupType)) {
                throw new Error(`Invalid actor type (${actorType}) group was tried to be added before group (${groupType}) of index '${groupIndex}'`);
            }
        }
        flow.splice(groupIndex, 0, [actor]);
    } else {
        const group = flow[groupIndex];

        if (!group) {
            throw new Error(`Flow group of index '${groupIndex}' doesn't exist`);
        }
        const groupType = group[0].actorType;
        const validGroup = GroupTypeForActorType[actorType]({
            ffaExists,
            sExists,
            rExists,
        });

        if (!validGroup.includes(groupType)) {
            throw new Error(`Invalid actor type (${actorType}) was tried to be added to group of index '${groupIndex}'`);
        }
        const existingIndex = group.findIndex(el => el.stakeholderLocalId === actor.stakeholderLocalId);

        if (existingIndex > -1 && group[existingIndex].actorType === actorType) {
            if (actorType === ActorType.Approver || actorType === ActorType.Receiver) {
                return {
                    ...state,
                    flow,
                };
            }
            const existing = group[existingIndex] as (FlowFormFillerModel | FlowSignerModel);
            const sourceElements = actor.elements;
            const targetElements = existing.elements;
            const newElements = [...targetElements, ...sourceElements];

            flow[groupIndex].splice(existingIndex, 1, {
                ...existing,
                elements: newElements,
            });
        } else {
            flow.splice(groupIndex, 1, [...group, actor]);
        }
    }

    let stakeholders = state.stakeholders;

    if (actor.actorType !== ActorType.Receiver) {
        const index = stakeholders.findIndex(el => el.localId === actor.stakeholderLocalId);

        if (index > -1) {
            stakeholders = stakeholders.slice();
            stakeholders.splice(index, 1, {
                ...stakeholders[index],
                hidden: true,
            });
        }
    }

    return {
        ...state,
        stakeholders,
        flow,
    };
};
export const DeleteActorReducer: FlowReducerFunc<FlowActionType.DeleteActor> = (
    state,
    { actorId, deleteElementsFromViewer },
) => {
    const flow = cloneDeep(state.flow);
    const elements = state.elements.slice();

    const found = FlowUtils.findActor(flow, actorId);

    if (!found) {
        return state;
    }
    const { stepIndex, actorIndex, isAlone } = found;

    if (isAlone) {
        flow.splice(stepIndex, 1);
    } else {
        const step = flow[stepIndex];

        step.splice(actorIndex, 1);
        flow[stepIndex] = step;
    }

    if (deleteElementsFromViewer === true) {
        if (found.actor && ('elements' in found.actor)) {
            found.actor.elements.forEach((element) => {
                const index = elements.findIndex((el) => el.localId === element);

                if (index > -1) {
                    elements.splice(index, 1);
                }
            });
        }
    }

    return {
        ...state,
        elements,
        flow,
    };
};

export const DeleteActorElementsReducer: FlowReducerFunc<FlowActionType.DeleteActorElements> = (
    state,
    { actorId, elementIds },
) => {
    const flow = [...state.flow];

    const found = FlowUtils.findActor(flow, actorId);

    if (!found || !('elements' in found.actor)) {
        return state;
    }
    const actorElements = found.actor.elements;
    const filteredElements = !elementIds ? [] : actorElements.filter(el => !elementIds.includes(el));

    flow[found.stepIndex].splice(found.actorIndex, 1, {
        ...found.actor,
        elements: filteredElements,
    });

    return {
        ...state,
        flow,
    };
};
export const AddActorElementsReducer: FlowReducerFunc<FlowActionType.AddActorElements> = (
    state,
    { actorId, elementIds },
) => {
    const flow = [...state.flow];

    const found = FlowUtils.findActor(flow, actorId);

    if (!found || !('elements' in found.actor)) {
        return state;
    }
    const actorElements = found.actor.elements;
    const newElements = [...actorElements, ...elementIds.filter(el => !actorElements.includes(el))];

    flow[found.stepIndex].splice(found.actorIndex, 1, {
        ...found.actor,
        elements: newElements,
    });

    return {
        ...state,
        flow,
    };
};

export const SetActorStakeholder: FlowReducerFunc<FlowActionType.SetActorStakeholder> = (
    state,
    { actorId, stakeholderId },
) => {
    const flow = [...state.flow];

    if (!state.stakeholders.find(el => el.localId === stakeholderId)) {
        throw new Error('Tried to set not existing stakeholder id on actor');
    }

    // find details of actor on which stakeholder will be set
    const found = FlowUtils.findActor(flow, actorId);

    if (!found) {
        return state;
    }

    // find if there is already an actor for desired stakeholder
    const existing = flow[found.stepIndex].find(el => el.stakeholderLocalId === stakeholderId && el.actorType === found.actor.actorType && el.localId !== actorId);

    // so there is existing actor
    if (existing) {
        if ( // if existing actor is receiver or approver there cannot be another one in same step so need to remove 'found' actor and leave
            existing.actorType === ActorType.Receiver
            || existing.actorType === ActorType.Approver
            || found.actor.actorType === ActorType.Receiver
            || found.actor.actorType === ActorType.Approver
        ) {
            flow[found.stepIndex].splice(found.actorIndex, 1);
        } else {
            const foundActor = found.actor as FlowFormFillerModel | FlowSignerModel;

            flow[found.stepIndex].splice(found.actorIndex, 1, {
                ...found.actor,
                elements: [...foundActor.elements, ...existing.elements],
            });
            const existingIndexes = FlowUtils.findActor(flow, existing.localId)!;

            flow[existingIndexes.stepIndex].splice(existingIndexes.actorIndex, 1);
        }
    } else { // there is no existing actor so simply assign new stakeholderId
        flow[found.stepIndex].splice(found.actorIndex, 1, {
            ...found.actor,
            stakeholderLocalId: stakeholderId,
        });
    }
    flow[found.stepIndex] = flow[found.stepIndex].slice();

    return {
        ...state,
        flow,
    };
};
