import { Modal, ModalContent, ModalFooter, NdsButton } from '@gonitro/rcl';
import { BkgModesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { Avatar } from '@progress/kendo-react-layout';
import classNames from 'classnames';
import { useState } from 'react';
import { ContactsApi } from '~api/contacts.api';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useTableContext } from '~contexts/table';
import { ContactGroupModel } from '~models/contact-group.model';
import './deleteContactGroupModal.scss';

export interface DeleteContactGroupModalProps {
    onCloseModalClick: () => void;
    contactGroup?: ContactGroupModel;
    openDeleteModal: boolean;
}

function DeleteContactGroupModal({ onCloseModalClick, contactGroup, openDeleteModal }: DeleteContactGroupModalProps) {
    const contactsApi = useApi(ContactsApi);
    const { t } = useTranslation('contacts');
    const { t: tBase } = useTranslation('base');
    const { t: tNotifications } = useTranslation('notifications');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { refresh } = useTableContext();
    const showNotification = useShowNotification();

    const handleDeleteContactGroup = async (close: () => void) => {
        if (contactGroup?.id) {
            setIsLoading(true);
            showNotification({
                type: 'pending',
                icon: 'fa-regular fa-trash',
                title: tNotifications('delete-contact-group-pending', { contactGroup: contactGroup.name }),
                hideAfterMs: 2000,
            });
            try {
                await contactsApi.deleteContactGroup({ id: Number(contactGroup?.id) });
                refresh();
                onCloseModalClick();
                close();
                setTimeout(() => {
                    showNotification({
                        type: 'success',
                        title: tNotifications('delete-contact-group-success', { contactGroup: contactGroup.name }),
                        hideAfterMs: 2000,
                    });
                }, 2300);
            } catch (error) {
                console.error('Error deleting the contact group:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <OverlayPortal id={`deleteContactGroupModal-${contactGroup?.id}`} type={'modal'} visible={openDeleteModal}>
            {({ close }) => (
                <Modal
                    headerLabel={t('delete-contact-group-modal-title')}
                    bkgMode={BkgModesEnum.LIGHT}
                    size={SizesEnums.SMALL}
                    onCloseClick={() => {
                        onCloseModalClick();close();
                    }}
                    withClose={true}
                    className={'c-delete-contact-group-modal'}
                    renderContent={
                        <ModalContent>
                            <div className={'c-delete-contact-group-modal__content'}>
                                <Typography
                                    className={'c-delete-contact-group-modal__warning-message'}
                                    tagName='div'
                                    token={TypographyToken.DesktopDescriptionLg}
                                    text={t('delete-contact-group-warning')}
                                />
                                <div className={'c-delete-contact-group-modal__item'}>
                                    <Avatar rounded='full' type='text'>
                                        {contactGroup?.name[0]}
                                    </Avatar>
                                    <div className={'c-delete-contact-group-modal__info'}>
                                        <Typography
                                            className={'c-delete-contact-group-modal__name'}
                                            tagName='div'
                                            token={TypographyToken.UiMenuMd}
                                            text={contactGroup?.name}
                                        />
                                        <Typography
                                            className={'c-delete-contact-group-modal__email'}
                                            tagName='div'
                                            token={TypographyToken.UiMenuSm}
                                            text={contactGroup?.code}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ModalContent>
                    }
                    renderFooter={
                        <ModalFooter>
                            <NdsButton
                                size={SizesEnums.SMALL}
                                label={tBase('cancel')}
                                buttonType={NdsButtonTypesEnum.NEUTRAL}
                                onClick={() => {
                                    onCloseModalClick() ;close();
                                }}
                            />
                            <div className={classNames('c-loader-wrapper', { 'c-loader-wrapper--show': isLoading })}>
                                <NdsButton
                                    size={SizesEnums.SMALL}
                                    label={tBase('delete')}
                                    onClick={() => handleDeleteContactGroup(close)}
                                />
                                <Loader center size={SizesEnums.SMALL} />
                            </div>

                        </ModalFooter>
                    }
                />)}
        </OverlayPortal>
    );
}

export default DeleteContactGroupModal;