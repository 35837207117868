import { NdsButton, NdsIconFont, NdsInput } from '@gonitro/rcl';
import { NdsInputTypes, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Error from '~components/error';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { HttpStatus } from '~lib/http/http.enums';
import { HttpError } from '~lib/http/http.error';
import { LanguageDataLabel } from '~models';
import './confirm-change-email-form.scss';

export interface ConfirmChangeEmailFormProps {
    onSubmit: (password: string) => Promise<void>;
    wrongPasswordError: LanguageDataLabel<'validation'>;
}

type FormValues = {
    password: string,
};

function ConfirmChangeEmailForm({ onSubmit, wrongPasswordError }: ConfirmChangeEmailFormProps) {
    const { t: tBase } = useTranslation('base');
    const { t: tUnauthorized } = useTranslation('unauthorized');
    const formRef = useRef<HTMLFormElement>(null);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const [error, setError] = useState<LanguageDataLabel<'error'>>();

    const {
        registerNdsInput,
        handleSubmit,
        trigger,
        formState: { isValid },
    } = useEnhancedForm<FormValues>({ mode: 'onChange' });

    useEffect(() => {
        if (wrongPasswordError !== undefined) {
            trigger('password');
        }
    }, [wrongPasswordError, trigger]);

    const onSubmitCallback = useCallback(async (values: FormValues) => {
        try {
            await onSubmit(values.password);
        } catch (e) {
            if (e instanceof HttpError) {
                if (e.response.status === HttpStatus.TooManyRequests) {
                    setError('too-many-retries');

                    return;
                }
            }
            setError('something-went-wrong');
        }
    }, [onSubmit]);

    return (
        <form
            className='v-confirm-change-email-form'
            ref={formRef}
            onSubmit={handleSubmit(onSubmitCallback)}
            noValidate
        >
            {error && <Error i18nKey={error} />}
            <div className='v-confirm-change-email-form__input-content'>
                <NdsInput
                    {...registerNdsInput('password', {
                        required: true,
                        fieldLabel: tBase('password'),
                        validate: () => {
                            if (wrongPasswordError) {
                                return 'wrong-current-password';
                            }
                        },
                    })}
                    label={tBase('password')}
                    inputType={passwordVisible ? NdsInputTypes.TEXT : NdsInputTypes.PASSWORD}
                    required
                />
                <NdsIconFont
                    className={'v-confirm-change-email-form__eye-icon'}
                    fontName={passwordVisible ? 'fa-solid-eye-slash': 'fa-solid-eye' }
                    onClick={() => {
                        setPasswordVisible(state => !state);
                    }}
                />
            </div>
            <div className={'v-confirm-change-email-form__forgot-password-container'}>
                <Typography
                    tagName={'p'}
                    token={TypographyToken.MobileDescriptionSm}
                    mediumScreenToken={TypographyToken.DesktopDescriptionSm}
                    className={'v-confirm-change-email-form__extra-action'}
                >
                    <Link to='/lost-password' className='v-confirm-change-email-form__forgot-password-link'>{tUnauthorized('forgot-password')}</Link>

                </Typography>
            </div>
            <NdsButton
                className='v-confirm-change-email-form__button'
                label={tBase('confirm')}
                onClick={() => formRef.current?.requestSubmit()}
                state={isValid ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                displayBlock
            />
        </form>
    );
}

export default ConfirmChangeEmailForm;