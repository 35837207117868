import React, { useCallback, useMemo } from 'react';
import FlowEditorStakeholderUi
    from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/flow-editor-stakeholder-ui';
import { FlowValidationBadge } from '~components/flow/flow-util-components';
import Typography, { TypographyToken } from '~components/typography';
import { useFlowState, useSetFlowState } from '~contexts/flow';
import { FlowActionType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useTranslation } from '~contexts/i18n';
import { FlowStakeholderModel, Guid } from '~models';
import './flowEditorUnassignedRecipients.scss';

export interface FlowEditorUnassignedRecipientsProps {
    filter?: string;
    portalContainer: HTMLElement | null;
    paneContainer: HTMLElement | null;
}

type StakeholderModelWithDisplayName = FlowStakeholderModel & {
    displayName: string;
}

function FlowEditorUnassignedRecipients({
    filter,
    portalContainer,
    paneContainer,
}: FlowEditorUnassignedRecipientsProps) {
    const { stakeholders } = useFlowState();
    const setFlowState = useSetFlowState();
    const { t } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');

    const unassignedStakeholders = useMemo<StakeholderModelWithDisplayName[]>(() => {
        return stakeholders.filter(el => !el.hidden).map(el => ({
            ...el,
            displayName: FlowUtils.getStakeholderDisplayName(el),
        }));
    }, [stakeholders]);

    const filteredStakeholders = useMemo(() => {
        const filtered = !filter?.length
            ? unassignedStakeholders
            : unassignedStakeholders.filter(el => {
                return el.displayName.toLowerCase().search(filter) > -1;
            });

        return filtered.sort((a, b) => {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                return -1;
            } else if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                return 1;
            }

            return 0;
        });
    }, [filter, unassignedStakeholders]);

    const onDeleteClick = useCallback((localId?: Guid) => {
        setFlowState(FlowActionType.HideStakeholder, localId);
    }, [setFlowState]);

    if (!unassignedStakeholders.length) {
        return null;
    }

    return (
        <div className={'c-flow-editor-unassigned-recipients'}>
            <div className={'c-flow-editor-unassigned-recipients__header'}>
                <div className={'c-flow-editor-unassigned-recipients__title'}>
                    <Typography
                        token={TypographyToken.DesktopTagXs}
                        text={t('unassigned-recipients-title')}
                    />
                </div>
                <div className={'c-flow-editor-unassigned-recipients__warning'}>
                    <FlowValidationBadge
                        warning
                        tooltip={t('unassigned-recipients-warning')}
                    />
                </div>
            </div>
            <ul className={'c-flow-editor-unassigned-recipients__list'}>
                {filteredStakeholders.map(el => {
                    return (
                        <li key={el.localId} className={'c-flow-editor-unassigned-recipients__list-item'}>
                            <FlowEditorStakeholderUi
                                stakeholder={el}
                                hideValidation
                                portalContainer={portalContainer}
                                paneContainer={paneContainer}
                                withAddFieldsPopup
                                onDeleteClick={onDeleteClick}
                            />
                        </li>
                    );
                })}
                {filteredStakeholders.length === 0 && (
                    <Typography
                        tagName={'div'}
                        className={'c-flow-editor-unassigned-recipients__empty'}
                        token={TypographyToken.DesktopDescriptionSm}
                        text={tBase('no-results')}
                    />
                )}
            </ul>
        </div>
    );
}

export default FlowEditorUnassignedRecipients;
