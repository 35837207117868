import { Modal, ModalContent, ModalFooter, NdsButton } from '@gonitro/rcl';
import {
    BkgModesEnum,
    NdsButtonTypesEnum,
    SizesEnums,
} from '@gonitro/rcl/lib/_types';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { ModalPortal } from '~contexts/overlay';
import { ContactBasicModel } from '~models/contact.model';
import { ContactsHeaderButtonActionType } from '../contacts-view-header-button/contactsViewHeaderButton';
import ExistingContactItem from './existing-contact-item/existingContactItem';
import './existingContactModal.scss';

export enum ExistingCotnactActionType {
    Copy,
    Share,
}

const WarningI18n: any = {
    [ContactsHeaderButtonActionType.Copy]: 'existing-contact-warning-copy',
    [ContactsHeaderButtonActionType.Share]: 'existing-contact-warning-share',
    [ContactsHeaderButtonActionType.AddContact]: 'existing-contact-warning-add',
};

const TitleI18n: any = {
    [ContactsHeaderButtonActionType.Copy]: 'copy-contacts-title',
    [ContactsHeaderButtonActionType.Share]: 'share-contacts-title',
    [ContactsHeaderButtonActionType.AddContact]: 'add-existing-contact-title',
};

export interface ExistingContactModalProps {
    actionType?: ContactsHeaderButtonActionType;
    contactsToMerge?: ContactBasicModel[];
    onCloseModalClik: () => void;
    onMergeContact: (contactToMerge: ContactBasicModel) => void;
    onSkipItemClick: (contactToSkip: ContactBasicModel) => void;
}

function ExistingContacttModal({
    onCloseModalClik,
    onMergeContact,
    onSkipItemClick,
    contactsToMerge,
    actionType,
}: ExistingContactModalProps) {
    const { t } = useTranslation('contacts');

    const onSkipItem = (contact: ContactBasicModel) => {
        if (contactsToMerge?.length === 1) {
            onCloseModalClik();
        } else {
            onSkipItemClick(contact);
        }
    };

    return (
        <ModalPortal visible={!!contactsToMerge && contactsToMerge.length > 0}>
            {actionType !== undefined && (
                <Modal
                    headerLabel={t(TitleI18n[actionType])}
                    bkgMode={BkgModesEnum.LIGHT}
                    size={SizesEnums.MEDIUM}
                    onCloseClick={() => {
                        onCloseModalClik();
                    }}
                    withClose={true}
                    className={'c-existing-contact-modal'}
                    renderContent={
                        <ModalContent>
                            <div className="c-existing-contact-modal__modal-content">
                                <Typography
                                    className="c-existing-contact-modal__warning-message"
                                    token={TypographyToken.UiFormsLabelSm}
                                    tagName="div"
                                    text={t(WarningI18n[actionType], { count: contactsToMerge?.length })}
                                />

                                <div className="c-existing-contact-modal__contacts-to-merge-wrapper">
                                    {contactsToMerge &&
                                        contactsToMerge.map((contact: any) => {
                                            return (
                                                <ExistingContactItem
                                                    key={contact.contactId}
                                                    onMergeClick={() => {
                                                        onMergeContact(contact); onCloseModalClik();
                                                    }
                                                    }
                                                    onSkipClick={() =>
                                                        onSkipItem(contact)
                                                    }
                                                    contactDetails={contact}
                                                />
                                            );
                                        })}
                                </div>
                            </div>
                        </ModalContent>
                    }
                    renderFooter={
                        <ModalFooter>
                            <div
                                className={
                                    'c-existing-contact-modal__footer-button'
                                }
                            >
                                <NdsButton
                                    size={SizesEnums.SMALL}
                                    label={'Skip All'}
                                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                                    onClick={() => {
                                        onCloseModalClik();
                                    }}
                                />
                            </div>
                        </ModalFooter>
                    }
                />
            )}
        </ModalPortal>
    );
}

export default ExistingContacttModal;
