import { NormalizedDragEvent, useDraggable } from '@progress/kendo-react-common';
import { Popup } from '@progress/kendo-react-popup';
import { Tooltip } from '@progress/kendo-react-tooltip';
import classNames from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FieldTypeIcon } from '~components/flow/flow-util-components/index';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import {
    useAddActor,
    useAddElement,
    useDeleteActor,
    useFlowContext,
    useStakeholderColorClass,
    useUndecidedStakeholderId,
} from '~contexts/flow/flow.hooks';
import { FlowGroupType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { useAbortEffect } from '~hooks/effects';
import { DomUtils } from '~lib';
import { DefinedLocationModel, FlowActorModel, FlowStakeholderModel, Offset, Size } from '~models';
import './flowEditorAddFieldsPopup.scss';


const Types: FieldType[] = [FieldType.TextBox, FieldType.CheckBox, FieldType.RadioGroup, FieldType.SigningField];

export interface FlowEditorAddFieldsPopupProps {
    opened: boolean;
    actor?: FlowActorModel;
    // undefined means undecided
    stakeholder?: FlowStakeholderModel;
    hide: () => void;
    anchor?: HTMLElement | null;
    appendTo?: HTMLElement | null;
    paneContainer?: HTMLElement | null;
}

function FlowEditorAddFieldsPopup({
    opened,
    hide,
    actor,
    stakeholder,
    anchor,
    appendTo,
    paneContainer,
}: FlowEditorAddFieldsPopupProps) {
    const colorClass = useStakeholderColorClass(stakeholder?.localId);
    const undecidedId = useUndecidedStakeholderId();
    const { flowState: { flow, detailDocuments }, recipientsTabRef, visibleInViewportDocuments } = useFlowContext();
    const { t } = useTranslation('flow');

    // closing on click outside effects
    useAbortEffect((signal: AbortSignal) => {
        if (!opened || !paneContainer || !anchor) {
            return;
        }

        paneContainer.addEventListener('scroll', (event) => {
            hide();
        }, { signal });
        window.addEventListener('mousedown', (ev) => {
            const inPopup = DomUtils.findParent(ev.target as HTMLElement, (el) => {
                return el === appendTo;
            }, appendTo?.parentNode as HTMLElement);

            if (inPopup) {
                return;
            }
            const inAnchor = DomUtils.findParent(ev.target as HTMLElement, (el) => {
                return el === anchor;
            }, anchor?.parentNode as HTMLElement);

            if (!inAnchor) {
                hide();

                return;
            }
            const inAnchorClickable = DomUtils.findParent(ev.target as HTMLElement, (el) => {
                return !!el.dataset.clickable;
            }, anchor?.parentNode as HTMLElement);

            if (inAnchorClickable) {
                hide();
            }
        }, { signal });

    }, [
        anchor,
        appendTo,
        hide,
        opened,
        paneContainer,
    ]);

    const [types, hasApproval] = useMemo(() => {
        if (!actor) {
            return [Types, false];
        }
        const types = Types.slice();
        let hasApproval = false;

        if (actor.actorType === ActorType.Signer || actor.actorType === ActorType.Receiver) {// find if last step of ffa contains approver for this stakeholder
            const idx = FlowUtils.findFlowActorGroupIndexes(flow, ActorType.Approver);

            if (idx.last > -1) {
                hasApproval = !!FlowUtils
                    .findStakeholderActors(flow, actor.stakeholderLocalId ?? undecidedId)
                    .find(el => el.stepIndex === idx.last && el.actor.actorType === ActorType.Approver);
            }
        } else { // find if current step contains approver for this stakeholder
            const thisInfo = FlowUtils.findActor(flow, actor.localId);
            const actors = FlowUtils
                .findStakeholderActors(flow, actor.stakeholderLocalId ?? undecidedId)
                .filter(el => el.stepIndex === thisInfo?.stepIndex);

            hasApproval = !!actors.find(el => el.actor.actorType === ActorType.Approver);
        }

        return [types, hasApproval];
    }, [actor, flow, undecidedId]);

    const popupRef = useRef<Popup>();

    const validatePosition = useCallback((pos: Offset) => {
        const rect = popupRef.current?.element.getBoundingClientRect();

        if (!rect) {
            return false;
        }
        const inX = pos.left >= rect.left && pos.left <= rect.right;
        const inY = pos.top >= rect.top && pos.top <= rect.bottom;

        return !(inX && inY);
    }, []);

    const addActor = useAddActor();
    const deleteActor = useDeleteActor();

    const onApprovalClick = useCallback(() => {
        if (!actor) { // unassigned recipients case
            if (!hasApproval) {
                const addedIndex = addActor(ActorType.Approver, stakeholder?.localId, 'last').groupIndex;

                recipientsTabRef.current?.expandStep(FlowGroupType.FormFillersApprovers, addedIndex);
            }

            return;
        }
        const info = FlowUtils.findActor(flow, actor.localId);

        if (!info) {
            return;
        }
        if (!hasApproval) {
            let addedIndex: number | undefined;

            if (info.actor.actorType !== ActorType.Signer) {

                addedIndex = addActor(ActorType.Approver, stakeholder?.localId, info.stepIndex).groupIndex;
            } else {
                const indexes = FlowUtils.findFlowActorGroupIndexes(flow, ActorType.Approver);

                addedIndex = addActor(ActorType.Approver, stakeholder?.localId, indexes.last < 0 ? 'new' : indexes.last).groupIndex;
            }
            const emptyFormFiller = flow[addedIndex].find(el => (
                el.actorType === ActorType.FormFiller
                && el.elements.length === 0
                && el.stakeholderLocalId === stakeholder?.localId
            ));

            recipientsTabRef.current?.expandStep(FlowGroupType.FormFillersApprovers, addedIndex);

            if (emptyFormFiller) {
                deleteActor(emptyFormFiller.localId);
            }
        } else {
            deleteActor(actor.localId);
        }
    }, [
        actor,
        addActor,
        deleteActor,
        flow,
        hasApproval,
        recipientsTabRef,
        stakeholder?.localId,
    ]);
    const onlyDocsWithSignatures = useMemo(() =>
        detailDocuments.every(el => el.hasSignatures)
    , [detailDocuments]);

    const ffDisabled = useMemo(() => {
        return onlyDocsWithSignatures || visibleInViewportDocuments?.every(el => el.hasSignatures);
    }, [onlyDocsWithSignatures, visibleInViewportDocuments]);

    return (
        <Popup
            appendTo={appendTo}
            anchor={anchor}
            margin={{
                horizontal: 16,
                vertical: -32,
            }}
            show={opened}
            className={classNames('c-flow-editor-add-fields-popup', colorClass)}
            popupAlign={{
                horizontal: 'left',
                vertical: 'top',
            }}
            anchorAlign={{
                horizontal: 'right',
                vertical: 'top',
            }}
            ref={popupRef}
        >
            <div className={'c-flow-editor-add-fields-popup__title'}>{t('add-field-title')}</div>
            <div className={'c-flow-editor-add-fields-popup__content'}>
                {types.map((el) => (
                    <DraggableField
                        key={el}
                        type={el}
                        actor={actor}
                        validatePosition={validatePosition}
                        stakeholder={stakeholder}
                        disabled={el !== FieldType.SigningField && ffDisabled}
                    />
                ))}
                <div className={'c-flow-editor-add-fields-popup__divider'} />
                <button
                    className={classNames('c-flow-editor-add-fields-popup__approval', { 'c-flow-editor-add-fields-popup__approval--active': hasApproval })}
                    type={'button'}
                    onClick={onApprovalClick}
                >
                    <span className={'c-flow-editor-add-fields-popup__icon'}>
                        <FieldTypeIcon type={ActorType.Approver} alternate />
                    </span>
                    <Typography
                        className={'c-flow-editor-add-fields-popup__text'}
                        text={t('request-approval')}
                        token={TypographyToken.UiFormsLabelXs}
                    />
                </button>
            </div>
            {ffDisabled && (
                <Tooltip
                    showCallout
                    position='right'
                    parentTitle
                    tooltipClassName={'c-flow-editor-add-fields-popup__tooltip'}
                >
                    <div
                        className={'c-flow-editor-add-fields-popup__tooltip-placeholder'}
                        title={
                            onlyDocsWithSignatures
                                ? t('add-fields-document-has-signatures-tooltip', { count: detailDocuments.length })
                                : t('add-fields-cannot-add-form-field-tooltip')}
                    />
                </Tooltip>
            )}
        </Popup>
    );
}

interface DraggableFieldProps {
    type: FieldType;
    actor?: FlowActorModel;
    stakeholder?: FlowStakeholderModel;
    validatePosition: (pos: Offset) => boolean;
    disabled?: boolean;
}

const DraggableField = ({ type, actor, stakeholder, validatePosition, disabled }: DraggableFieldProps) => {
    const { t } = useTranslation('flow');
    const { t: tNotifications } = useTranslation('notifications');
    const {
        documentViewerRef,
        recipientsTabRef,
        flowState: { flow, detailDocuments },
    } = useFlowContext();

    const [dragged, setDragged] = useState<boolean>(false);
    const [relativeOffset, setRelativeOffset] = useState<Offset>();
    const [limits, setLimits] = useState<{ max: Offset, min: Offset }>();
    const [position, setPosition] = useState<Offset>({
        left: 0,
        top: 0,
    });

    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0,
    });

    const ref = useRef<HTMLDivElement>(null);
    const onPress = useCallback((event: NormalizedDragEvent) => {
        if (!documentViewerRef.current || !ref.current) {
            return;
        }

        const rect = ref.current.getBoundingClientRect();
        const docViewerRect = documentViewerRef.current.container?.getBoundingClientRect();

        if (!docViewerRect) {
            return;
        }
        setLimits({
            min: {
                left: docViewerRect.left,
                top: docViewerRect.top,
            },
            max: {
                left: docViewerRect.right - rect.width,
                top: docViewerRect.bottom - rect.height,
            },
        });
        setSize({
            width: rect.width,
            height: rect.height,
        });
        setRelativeOffset({
            left: event.clientX - rect.left,
            top: event.clientY - rect.top,
        });
        setPosition({
            left: rect.left,
            top: rect.top,
        });
        setDragged(true);
    }, [documentViewerRef]);

    const onDrag = useCallback((event: NormalizedDragEvent) => {
        if (!limits || !relativeOffset || disabled) {
            return;
        }
        setPosition({
            left: Math.min(limits.max.left, Math.max(limits.min.left, event.clientX - relativeOffset.left)),
            top: Math.min(limits.max.top, Math.max(limits.min.top, event.clientY - relativeOffset.top)),
        });
    }, [disabled, limits, relativeOffset]);

    const addElement = useAddElement();
    const addActor = useAddActor();
    const showNotification = useShowNotification();

    const onRelease = useCallback((event: NormalizedDragEvent) => {
        setDragged(false);
        setLimits(undefined);
        setRelativeOffset(undefined);

        if (!dragged || !documentViewerRef.current) {
            return;
        }
        if (!validatePosition({
            left: event.clientX,
            top: event.clientY,
        })) {
            return;
        }

        const rect = documentViewerRef.current.container?.getBoundingClientRect();

        if (!rect) {
            return;
        }
        const pageInfo = documentViewerRef.current.getPageFromVisiblePosition({
            left: position.left - rect.left,
            top: position.top - rect.top,
        });

        if (!pageInfo) {
            return;
        }

        if (type !== FieldType.SigningField && detailDocuments[pageInfo.documentIndex].hasSignatures) {
            showNotification({
                type: 'warning',
                closeButton: true,
                hideAfterMs: 3000,
                title: tNotifications('cannot-place-form-field-on-doc-title'),
                message: tNotifications('cannot-place-form-field-on-doc-message'),
            });

            return;
        }

        // ====== SIZE
        const size = FlowUtils.fieldDefaultSize(type);
        const newPos = { ...pageInfo.onPagePos };

        if (newPos.left + size.width > pageInfo.originalSize.width) {
            newPos.left = pageInfo.originalSize.width - size.width;
        }
        if (newPos.top + size.height > pageInfo.originalSize.height) {
            newPos.top = pageInfo.originalSize.height - size.height;
        }
        const location: DefinedLocationModel = {
            pageNumber: pageInfo.pageIndex + 1,
            documentNumber: pageInfo.documentIndex + 1,
            width: size.width,
            height: size.height,
            left: newPos.left,
            top: newPos.top,
            isLocked: false,
        };

        // ====== WHERE TO ADD

        if (!actor) {
            if (type === FieldType.SigningField) {
                const id = addActor(ActorType.Signer, stakeholder?.localId, 'last');

                recipientsTabRef.current?.expandStep(FlowGroupType.Signers, id.groupIndex);
                addElement(type, id.actorId, location);
            } else {
                const id = addActor(ActorType.FormFiller, stakeholder?.localId, 'last');

                recipientsTabRef.current?.expandStep(FlowGroupType.FormFillersApprovers, id.groupIndex);
                addElement(type, id.actorId, location);
            }
        } else if (type === FieldType.SigningField && actor.actorType !== ActorType.Signer) {// if adding signing field from formfiller/approver group (actor)
            const id = addActor(ActorType.Signer, stakeholder?.localId, 'last');

            recipientsTabRef.current?.expandStep(FlowGroupType.Signers, id.groupIndex);
            addElement(type, id.actorId, location);
        } else if (type !== FieldType.SigningField && actor.actorType === ActorType.Signer) { // or form field from signer group (actor)
            const id = addActor(ActorType.FormFiller, stakeholder?.localId, 'last');

            recipientsTabRef.current?.expandStep(FlowGroupType.FormFillersApprovers, id.groupIndex);
            addElement(type, id.actorId, location);
        } else if (actor.actorType === ActorType.Approver) { // optionally it can be approver actor but adding form field (signing field is handled in previous if)

            const info = FlowUtils.findActor(flow, actor.localId);

            if (!info) {
                console.warn('error adding form filler from approver actor');

                return;
            }

            const id = addActor(ActorType.FormFiller, stakeholder?.localId, info.stepIndex);

            recipientsTabRef.current?.expandStep(FlowGroupType.FormFillersApprovers, id.groupIndex);
            addElement(type, id.actorId, location);
        } else if (actor.actorType === ActorType.Receiver) {
            const id = addActor(ActorType.FormFiller, stakeholder?.localId, 'last');

            recipientsTabRef.current?.expandStep(FlowGroupType.FormFillersApprovers, id.groupIndex);
            addElement(type, id.actorId, location);
        } else {
            addElement(type, actor.localId, location);
        }

    }, [
        actor,
        addActor,
        addElement,
        detailDocuments,
        documentViewerRef,
        dragged,
        flow,
        position.left,
        position.top,
        recipientsTabRef,
        showNotification,
        stakeholder?.localId,
        tNotifications,
        type,
        validatePosition,
    ]);

    useDraggable(ref, {
        onPress,
        onDrag,
        onRelease,
    });

    const { text, icon } = useMemo(() => {
        switch (type) {
            case FieldType.TextBox:
                return {
                    text: t('field-object-label-input'),
                    icon: <FieldTypeIcon type={FieldType.TextBox} />,
                };
            case FieldType.CheckBox:
                return {
                    text: t('field-object-label-check'),
                    icon: <FieldTypeIcon type={FieldType.CheckBox} />,
                };
            case FieldType.RadioGroup:
                return {
                    text: t('field-object-label-radio'),
                    icon: <FieldTypeIcon type={FieldType.RadioGroup} />,
                };
            case FieldType.SigningField:
                return {
                    text: t('field-object-label-signature'),
                    icon: <FieldTypeIcon type={FieldType.SigningField} />,
                };
        }
    }, [t, type]);

    return (
        <>
            {dragged && <div
                className={'c-flow-editor-add-fields-popup__field c-flow-editor-add-fields-popup__field--ghost'}
                style={size}
            ></div>}
            <div
                className={
                    classNames('c-flow-editor-add-fields-popup__field', {
                        'c-flow-editor-add-fields-popup__field--is-dragging': dragged,
                        'c-flow-editor-add-fields-popup__field--disabled': disabled,
                    })}
                ref={ref}
                style={dragged
                    ? {
                        ...size,
                        ...position,
                    }
                    : {}}
            >
                <span className={'c-flow-editor-add-fields-popup__grip'}>
                    <i className={'fa-solid fa-grip-lines-vertical'} />
                </span>
                <span className={'c-flow-editor-add-fields-popup__icon'}>
                    {icon}
                </span>
                <Typography
                    className={'c-flow-editor-add-fields-popup__text'}
                    text={text}
                    token={TypographyToken.UiFormsLabelSm}
                />
            </div>
        </>
    );
};

export default FlowEditorAddFieldsPopup;
