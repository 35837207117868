import { NdsBadge } from '@gonitro/rcl';
import { NdsBadgeStatesEnum, NdsBadgeTypesEnum } from '@gonitro/rcl/lib/_types';
import { UserStatusEnum, UserStatusI18n } from '~constants/user-status';
import { useTranslation } from '~contexts/i18n';

export interface UserStatusBadgeProps {
  status: UserStatusEnum;
  icon?: string;
}

const UserStatusBadge = ({ status, icon }: UserStatusBadgeProps) => {
    const { t } = useTranslation('user-management');
    const mapStatusToState: Record<UserStatusEnum, NdsBadgeStatesEnum> = {
        [UserStatusEnum.Active]: NdsBadgeStatesEnum.SUCCESS,
        [UserStatusEnum.Inactive]: NdsBadgeStatesEnum.NEUTRAL,
        [UserStatusEnum.Invited]: NdsBadgeStatesEnum.PENDING,
    };

    return (
        <NdsBadge
            text={t(UserStatusI18n[status])}
            badgeType={NdsBadgeTypesEnum.STATUS}
            state={mapStatusToState[status]}
            icon={icon}
        />
    );
};

export default UserStatusBadge;
