import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthApi } from '~api/auth.api';
import Error from '~components/error';
import Typography, { TypographyToken } from '~components/typography';
import { BaseRoutes } from '~constants/routes';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { UrlUtil } from '~lib';
import { HttpError } from '~lib/http/http.error';
import { LanguageDataLabel } from '~models';
import ConfirmChangeEmailForm from './confirm-change-email-form';
import './confirm-change-email.scss';

export interface ConfirmChangeEmailProps {

}

type ConfirmPasswordLocation = {
    id: string;
    token: string;
    error?: string;
}

function ConfirmChangeEmail(props: ConfirmChangeEmailProps) {
    const { t: tBase } = useTranslation('base');
    const { t: tNotifications } = useTranslation('notifications');
    const authApi = useApi(AuthApi);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const [wrongPasswordError, setWrongPasswordError] = useState<LanguageDataLabel<'validation'>>();
    const navigate = useNavigate();
    const queryParams = useMemo(() =>
        UrlUtil.getLocationInfo<ConfirmPasswordLocation>(window.location.href).getQueryParams(),
    []);
    const showNotification = useShowNotification();

    const confirmChangeEmail = useCallback(async (password: string) => {
        const { id, token } = queryParams;

        try {
            await authApi.confirmEmailChange({
                userId: id,
                token,
                password,
            });
            navigate(BaseRoutes.DocumentPortal);

            showNotification({
                type: 'success',
                title: tNotifications('reassign-success-title'),
                message: tNotifications('email-address-updated-success'),
                hideAfterMs: 2000,
            });

        } catch (error) {
            if (error instanceof HttpError) {
                showNotification({
                    type: 'error',
                    title: tNotifications('password-change-error'),
                    hideAfterMs: 2000,
                });

                const code = error.getErrorCode();

                if (code === 'User.LoginFailed') {
                    setWrongPasswordError('wrong-current-password');
                }
            }
            setError('something-went-wrong');
            showNotification({
                type: 'error',
                title: tNotifications('unknown-error-title'),
                message: tNotifications('unknown-error-message'),
                hideAfterMs: 2000,
            });
        }
    }, [
        authApi,
        navigate,
        queryParams,
        showNotification,
        tNotifications,
    ]);


    return (
        <div className='v-confirm-change-email'>
            {error
                && <Error
                    className='v-confirm-change-email__error-text'
                    i18nKey={error}
                />
            }
            <div className={'v-confirm-change-email__header'}>
                <Typography
                    tagName={'h1'}
                    token={TypographyToken.DesktopHeaderSm}
                    text={tBase('confirm-email-enter-password-title')}
                />
            </div>
            <div className='v-confirm-change-email__content'>
                <ConfirmChangeEmailForm
                    wrongPasswordError={wrongPasswordError as LanguageDataLabel<'validation'>}
                    onSubmit={confirmChangeEmail}
                />
            </div>
        </div>
    );
}

export default ConfirmChangeEmail;
