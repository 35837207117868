import { NdsGroup, NdsInput, NdsSelect, NdsSelectChangeEvent, NdsSelectOption } from '@gonitro/rcl';
import { SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { DropDownList, DropDownListChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { cloneElement, ReactElement, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CountryPrefixFlag from '~components/country-prefix-flag';
import Typography, { TypographyToken } from '~components/typography';
import { countryInfoList } from '~constants';
import { useUserInfo } from '~contexts/auth';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { useEnhancedFormContext } from '~hooks/enhanced-form/enhanced-form-context';
import { CountryInfo } from '~models';
import './edit-profile-tab.scss';

export interface EditProfileTabProps {
}

const EditProfileTab = () => {
    const { t } = useTranslation('user-management');
    const userInfo = useUserInfo();
    const { availableLanguages } = useLanguages();
    const { formState: { defaultValues }, setValue } = useFormContext();
    const { registerNdsInput, registerNdsSelect, getValues } = useEnhancedFormContext();
    const [selectedPhoneNumberCountry, setSelectedPhoneNumberCountry] =
    useState<CountryInfo | undefined>(countryInfoList.find(
        (el: CountryInfo) =>
            el.countryCode === (getValues('defaultPhoneNumberCountry') ? getValues('defaultPhoneNumberCountry') : userInfo?.defaultPhoneNumberCountry),
    ));

    const valueRender = (element: ReactElement<HTMLSpanElement>, value: CountryInfo) => {
        if (!value) {
            return element;
        }
        const children = [
            <span key={2} className='edit-user-profile-tab__dropdown-flag'>
                <CountryPrefixFlag countryCode={value.countryCode} />
                {value.countryName}
                {element.props.children as any}
            </span>,
        ];

        return cloneElement(element, { ...element.props }, children);
    };

    const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {

        const itemChildren = (
            <span >
                <CountryPrefixFlag countryCode={itemProps.dataItem.countryCode} /> {itemProps.dataItem.countryName} {li.props.children as any}
            </span>
        );

        return cloneElement(li, li.props, itemChildren);
    };

    const handleChange= (e : DropDownListChangeEvent) => {
        setValue('defaultPhoneNumberCountry', e.value.countryCode);
        setSelectedPhoneNumberCountry(e.value);
    };

    //TODO, udnerstand why language is not setting without using useEffect, not setting with form (data is present)
    useEffect(() => {
        setValue('language', userInfo?.language);
    }, [setValue, userInfo?.language]);

    return (
        <div
            className={'edit-user-profile-tab'}
        >
            <NdsInput
                {...registerNdsInput('firstName', { required: true })}
                label={t('user-management-edit-user-profile-tab-first-name')}
                size={SizesEnums.SMALL}
                required
            />
            <NdsInput
                {...registerNdsInput('lastName', { required: true })}
                label={t('user-management-edit-user-profile-tab-last-name')}
                size={SizesEnums.SMALL}
                required
            />
            <NdsInput
                {...registerNdsInput('emailAddress')}
                state={StatesEnum.DISABLED}
                label={t('user-management-edit-user-profile-tab-email')}
                size={SizesEnums.SMALL}
                hintText={defaultValues?.cloudProviderName && t('user-management-edit-user-profile-tab-email-hint-text', { cloudProviderName: defaultValues?.cloudProviderName })}
            />
            <NdsInput
                {...registerNdsInput('company')}
                state={StatesEnum.DEFAULT}
                label={t('user-management-edit-user-profile-tab-company')}
                size={SizesEnums.SMALL}
            />
            <div>

                <NdsSelect
                    {...registerNdsSelect('language', {
                        onChange: (event: NdsSelectChangeEvent) => {
                            setValue('language', event.target.value);
                        },
                    })}
                    label={t('user-management-edit-user-profile-tab-language')}
                    size={SizesEnums.SMALL}
                >
                    <NdsGroup>
                        {
                            availableLanguages.map(lang =>
                                <NdsSelectOption
                                    key={lang.isoCultureCode}
                                    label={lang.nativeName}
                                    value={lang.isoCultureCode}
                                />)
                        }
                    </NdsGroup>
                </NdsSelect>
                <Typography token={TypographyToken.UiFormsLabelXs} text={t('user-management-edit-user-profile-tab-language-hint-text')} className={'edit-user-profile-tab__hint-text-select'} />
            </div>

            <div>
                <Typography token={TypographyToken.UiFormsLabelSm} text={'Country prefix'} className={'edit-user-profile-tab__form-label'}/>
                <DropDownList
                    data={countryInfoList}
                    valueRender={valueRender}
                    dataItemKey='countryCode'
                    textField='phonePrefix'
                    value={selectedPhoneNumberCountry}
                    itemRender={itemRender}
                    onChange={(e) => handleChange(e)}
                    className={'edit-user-profile-tab__dropdown-prefix'}
                />
                <Typography token={TypographyToken.UiFormsLabelXs} text={t('user-management-edit-user-profile-tab-prefix-hint-text')} className={'edit-user-profile-tab__hint-text-select'} />
            </div>
        </div>
    );
};

export default EditProfileTab;
